<template>
  <div class="container">
    <div class="wrapper">
      <div class="top">
        <div class="top-left">
          <div class="title">待结算返利</div>
          <div class="value">10000.00</div>
          <div class="countdown">结算倒计时：23H 23M 08S</div>
        </div>
        <div class="top-right">
          <div class="top-right-item">
            <div class="item-value">
              <div>当日有效下注</div>
              <div>23564.66</div>
            </div>
          </div>
          <div class="top-right-item">
            <div class="item-value">
              <div>下注返利</div>
              <div>200.00</div>
            </div>
            <div class="item-btn">领取</div>
          </div>
        </div>
      </div>
      <div class="bottom">
        <div class="bottom-title">Explanation of the rules</div>
        <div class="bottom-des">
          Silver VIP can activate the rebate entitlement. Every day at 17:00 settlement of the previous 24 hours of
          betting rebate. The higher the VIP level, the higher the rebate percentage.
        </div>
        <div class="table">
          <div class="table-header">
            <div>VIP</div>
            <div>Taxa de Rebate</div>
          </div>
          <div class="cell" v-for="n in 10" :key="n">
            <div>Unranked</div>
            <div>0‰</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  .wrapper {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    margin-bottom: 60px;

    .top {
      display: flex;
      justify-content: space-between;
      .top-left {
        flex: 0 1 49%;
        height: 234px;
        background: linear-gradient(112deg, #eccf83 0%, #e9bf72 99%);
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;

        .title {
          font-size: 20px;
          font-weight: 500;
          color: #070704;
        }
        .value {
          font-size: 48px;
          font-weight: 600;
          color: #070704;
        }
        .value::before {
          content: "R$";
          font-size: 24px;
          font-weight: 600;
        }
        .countdown {
          font-size: 16px;
          font-weight: 500;
          color: #070704;
        }
      }

      .top-right {
        flex: 0 1 49%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .top-right-item {
          flex: 0 1 45%;
          background: #202020;
          border-radius: 8px;
          display: flex;
          align-items: center;

          .item-value {
            margin: 10px 16px;
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            div:first-of-type {
              font-size: 12px;
              font-weight: normal;
              color: #ffffff;
            }

            div:last-of-type {
              margin-top: 4px;
              font-size: 24px;
              font-weight: 600;
              line-height: 32px;
              color: #e9c97e;
            }
          }

          .item-btn {
            margin-right: 30px;
            width: 58px;
            height: 28px;
            border-radius: 6px;
            background: linear-gradient(90deg, #e9bf72 0%, #eccf83 100%);
            font-size: 12px;
            font-weight: 600;
            line-height: 28px;
            text-align: center;
            color: #070704;
          }
        }
      }
    }

    .bottom {
      margin-top: 30px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .bottom-title {
        font-size: 24px;
        font-weight: 600;
        color: #ffffff;
      }

      .bottom-des {
        margin-top: 18px;
        font-size: 12px;
        font-weight: normal;
        color: #ffffff;
        opacity: 0.8;
        text-align: left;
      }

      .table {
        margin-top: 30px;
        width: 100%;
        background: #1a1919;
        border-radius: 8px;
        display: flex;
        flex-direction: column;

        .table-header {
          margin-left: 10px;
          margin-right: 10px;
          display: flex;
          align-items: center;
          height: 56px;

          div {
            flex: 1;
            font-size: 14px;
            font-weight: 500;
            color: #ffffff;
          }
        }

        .cell {
          margin-left: 10px;
          margin-right: 10px;
          margin-bottom: 8px;
          display: flex;
          align-items: center;
          height: 40px;
          box-sizing: border-box;
          border: 1px solid #2b2b2b;
          border-radius: 8px;

          div {
            flex: 1;
            font-size: 12px;
            font-weight: normal;
            line-height: 40px;
            color: #ffffff;
            opacity: 0.8;
          }
        }
      }
    }
  }

  // @media (max-width: 768px) {
  //   .wrapper .top {
  //     flex-wrap: wrap;
  //   }
  //   .wrapper .top .top-left,
  //   .wrapper .top .top-right {
  //     flex: 0 1 100%;
  //     margin-bottom: 10px;
  //   }

  //   .wrapper .top .top-right .top-right-item {
  //     margin-bottom: 10px;
  //   }
  // }
}
</style>
