import http from "@/utils/http";
import global from "@/utils/global";

function isValidEmail(email) {
  // 使用正则表达式进行邮箱验证
  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  return emailRegex.test(email);
}

function isNumeric(str) {
  // 使用正则表达式检查是否只包含数字
  return /^\d+$/.test(str);
}

function isMobile() {
  const ua = navigator.userAgent;
  return /Mobi|Android/i.test(ua) && /mobile|tablet/i.test(ua);
}

function isiOSDevice() {
  const ua = navigator.userAgent;
  return /iPhone|iPad|iPod/i.test(ua);
}

function isAndroidDevice() {
  const ua = navigator.userAgent;
  return /Android/i.test(ua);
}

/**
 * 将字符串日期装换成dd/MM/yyyy格式
 * @param {string} dateStr
 * @returns
 */
function formattedDate(dateStr) {
  // 将原始字符串日期转换为 Date 对象
  const dateObject = new Date(dateStr);

  // 获取日期、月份和年份
  const day = dateObject.getDate().toString().padStart(2, "0");
  const month = (dateObject.getMonth() + 1).toString().padStart(2, "0"); // 月份是从0开始的，所以要加1
  const year = dateObject.getFullYear();

  // 拼接成目标格式
  return `${day}/${month}/${year}`;
}

function formatIsoDate(isoString) {
  // 创建Date对象
  const date = new Date(isoString);

  // 获取年、月、日、小时和分钟
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // 月份从0开始，所以加1
  const day = date.getDate().toString().padStart(2, "0");
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");

  // 格式化日期和时间
  const formattedDate = `${year}/${month}/${day} ${hours}:${minutes}`;
  return formattedDate;
}

function userTrackReport(params) {
  const data = {
    action: "user_track_report",
    data: params,
  };

  http
    .post("userTrack", data)
    .then(() => {})
    .catch((error) => {
      console.log("error", error);
    });
}

function openTelegram(url) {
  this.userTrackReport({ track_id: "36" });

  if (url === null || url === undefined || url === "") {
    url = global.data.customer_service;
  }

  newerActivityFinish({ join_channel_finish: 1 });

  let target = "_blank";
  if (isiOSDevice()) {
    // ios设备使用当前页打开，如果使用_blank的方式，网页发送桌面后，点击相关的跳转业务返回会有白屏的情况
    target = "_self";
  } else {
    // android设备使用新窗口打开，如果使用_self的方式，三星手机自带的浏览器会报错
    target = "_blank";
  }

  window.open(url, target);
}

function newerActivityFinish(params, callback) {
  const data = {
    action: "new_user_active_finish",
    data: params,
  };

  http
    .post("activity", data)
    .then(() => {
      if (callback) {
        callback();
      }
    })
    .catch((error) => {
      console.log("error", error);
    });
}
/**
 *
 * @param {*} value
 * @param {*} style decimal:数字 currency:货币
 * @returns
 */
function formatCurrency(value, style = "decimal") {
  return new Intl.NumberFormat("pt-BR", {
    style,
    currency: "BRL",
    minimumFractionDigits: 2, // 确保小数点后有两位
    maximumFractionDigits: 2,
  }).format(value);
}

function today() {
  const date = new Date();
  return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;
}

export default {
  isValidEmail,
  isNumeric,
  isMobile,
  isiOSDevice,
  isAndroidDevice,
  formattedDate,
  formatIsoDate,
  userTrackReport,
  openTelegram,
  newerActivityFinish,
  formatCurrency,
  today,
};
