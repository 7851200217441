<template>
  <div class="container">
    <DownloadAppTipsComp v-if="isShowTopTips && isLoggedIn"></DownloadAppTipsComp>
    <HeaderView
      class="m-header"
      :isLoggedIn="isLoggedIn"
      :isShowExtend="isShowExtend"
      @popupLogin="handleOfLogin"
      @popupRegister="handleOfRegister"
    ></HeaderView>

    <div class="content-box" ref="boxRef">
      <!-- 轮播图 -->
      <SwiperComp :bannerList="bannerList"></SwiperComp>

      <PiggyBannerComp></PiggyBannerComp>

      <CategoryHorizontalComp class="m-cate" v-model:index="category" :list="cateListWithLoddy" @changeEvent="setupCategory"></CategoryHorizontalComp>

      <div class="main-wrapper">
        <div class="right">
          <!-- 大厅数据 -->
          <div v-if="category === 0" class="lobby">
            <div class="lobby-wrapper">
              <!-- 最近游戏 -->
              <GamesSetWithTitleComp v-if="isLoggedIn" type="recent" :title="$t('recent_games')" :icon="$global.data.recent_icon"></GamesSetWithTitleComp>
              <!-- tags list -->
              <div class="tags-list" v-for="(item, index) in tagsList" :key="index">
                <GamesSetWithTitleComp type="tag" :id="item.id" :title="item.tag_name" :icon="item.tag_icon"></GamesSetWithTitleComp>
              </div>
              <div class="provider-item" v-for="(item, index) in provider_list" :key="index">
                <GamesSetWithTitleComp type="provider" :id="item.id" :title="item.provider_name + ' GAMING'" :icon="item.provider_icon"></GamesSetWithTitleComp>
              </div>
              <!-- 厂商 -->
              <ProvidersSetWithTitleComp
                class="providers"
                title="Provedores de Jogos"
                :icon="provider_icon"
                :providers="provider_list"
              ></ProvidersSetWithTitleComp>
            </div>
          </div>

          <!-- 非大厅数据 -->
          <div v-else class="cate-game-list">
            <div class="collection">
              <div class="item" v-for="game in cateGameList(category - 1)" :key="game.game_id">
                <GameCard :game="game"></GameCard>
              </div>
            </div>
            <div class="footer">
              <LoadMoreComp
                :cur-count="cateGameList(category - 1).length"
                :total-count="cateGameTotalCount(category - 1)"
                @loadMoreEvent="fetchGameListTypeData('game_category', cateList[category - 1])"
              ></LoadMoreComp>
            </div>
          </div>
          <MobileFooter></MobileFooter>
        </div>
      </div>
    </div>

    <div class="float-box" :style="{ top: `${floatBoxTop}px` }">
      <div class="float-content">
        <!-- 礼包 -->
        <div class="gift-box">
          <Transition name="fade">
            <img v-if="!isOpenFloatGift" class="gift-icon" src="@/assets/float/gift.webp" alt="" @click="isOpenFloatGift = true" />
          </Transition>
          <div
            class="gift-wrapper"
            :style="{
              maxHeight: this.isOpenFloatGift ? '300px' : '0', // 假设内容高度不会超过300px
              opacity: this.isOpenFloatGift ? 1 : 0,
              overflow: 'hidden',
              transition: 'all 0.5s ease-in-out',
            }"
          >
            <img class="close" src="@/assets/float/gift_close.webp" alt="" @click="isOpenFloatGift = false" />
            <!-- buff -->
            <div class="float-buff" v-if="buffInfo !== null">
              <div class="buff-info" v-if="isShowBuffDetail">
                <div>{{ buffInfo?.prize_name }}</div>
                <div>{{ buffInfo?.describe }}</div>
              </div>
              <div class="buff-icon" @click="handleOfBuffIcon">
                <img class="buff-img" :src="$global.data.cdn_url + buffInfo?.prize_image_url" alt="" />
                <div class="buff-timer">{{ buffCountDownTimeStr }}</div>
              </div>
            </div>
            <!-- 每日任务 -->
            <div class="float-item">
              <img v-if="isLoggedIn" src="@/assets/dayday.webp" alt="" @click="$router.push({ name: 'm-dailyTasks' })" />
            </div>
            <!-- 红包雨new -->
            <div class="float-item">
              <img v-if="isLoggedIn" src="@/assets/redbao.webp" alt="" @click="$router.push({ name: 'm-redbaoyu' })" />
            </div>
            <!-- 每日签到 -->
            <div v-if="isLoggedIn && dailyCheckInTotalGiveCoins > 0" class="daily" @click="$router.push('/daily-check-in')"></div>
          </div>
        </div>
      </div>
    </div>

    <div class="float2-box">
      <!-- 飞机 -->
      <img class="icon" src="@/assets/float/telegram.webp" alt="" @click="handleOfTelegram" />
      <!-- 客服 -->
      <img src="@/assets/casino_cs.webp" alt="" @click="handleOfCS" />
    </div>

    <!-- 活动弹窗集合 -->
    <DialogCollectComp></DialogCollectComp>
  </div>
</template>

<script>
import MobileFooter from "@/components/MobileFooter.vue";
import GameCard from "@/components/GameCard.vue";
import SwiperComp from "@/components/SwiperComp.vue";
import LoadMoreComp from "@/components/LoadMoreComp.vue";
import CategoryHorizontalComp from "@/components/CategoryHorizontalComp.vue";
import HeaderView from "@/pages/HeaderView.vue";
import PiggyBannerComp from "@/components/PiggyBannerComp.vue";
import DownloadAppTipsComp from "@/components/DownloadAppTipsComp.vue";
import DialogCollectComp from "@/components/DialogCollectComp.vue";
import GamesSetWithTitleComp from "@/components/GamesSetWithTitleComp.vue";
import ProvidersSetWithTitleComp from "@/components/ProvidersSetWithTItleComp.vue";

export default {
  components: {
    GameCard,
    SwiperComp,
    LoadMoreComp,
    MobileFooter,
    CategoryHorizontalComp,
    HeaderView,
    PiggyBannerComp,
    DownloadAppTipsComp,
    DialogCollectComp,
    GamesSetWithTitleComp,
    ProvidersSetWithTitleComp,
  },
  props: {
    isShowExtend: {
      type: Boolean,
      default: false,
    },
    registerGiveCoins: {
      type: Number,
      default: 0,
    },
  },
  data() {
    const activityId = this.$store.getters.activityId;
    const isJoinActivity = this.$store.getters.isJoinActivity;
    const isLoggedIn = this.$store.getters.isLoggedIn;
    const isShowTopTips = sessionStorage.getItem("isShowTopTips") !== "false";
    return {
      category: -1,
      cateListWithLoddy: [],
      cateList: [],
      tagsList: [],
      bannerList: [],
      isJoinActivity,
      activityId,
      countDownTimeStr: "",
      isLoggedIn,
      buffInfo: null,
      buffTimer: null,
      buffCountDownTimeStr: "",
      isShowBuffDetail: false,
      showBuffDetailTimer: null,
      isShowTopTips,
      floatBoxTop: 0,
      provider_list: [],
      provider_icon: "",
      dailyCheckInTotalGiveCoins: 0,
      isOpenFloatGift: false,
    };
  },
  watch: {
    isLoggedIn() {
      this.$nextTick(() => {
        this.setupFloatBoxTop();
      });
    },
  },
  unmounted() {
    clearInterval(this.buffTimer);
  },
  mounted() {
    localStorage.setItem("isJump", 0);
    this.$nextTick(() => {
      this.setupFloatBoxTop();
      const uc = sessionStorage.getItem("uc");
      if (uc !== null && uc !== undefined && uc !== "") {
        this.$eventBus.emit("showRegister", 2);
      }
    });
    this.$tools.userTrackReport({ track_id: "10" });
    this.activityId = this.$store.getters.activityId;
    this.isJoinActivity = this.$store.getters.isJoinActivity;
    if (this.isLoggedIn && this.isJoinActivity && this.activityId !== "") {
      this.$eventBus.emit("fireGlobalTimer");
    }
    this.$eventBus.on("logoutSuccessHandle", this.logoutSuccessHandle);
    this.$eventBus.on("globalCountDown", (totalSeconds) => {
      if (totalSeconds <= 0) {
        this.countDownTimeStr = "";
        return;
      }

      const minutes = Math.floor(totalSeconds / 60);
      const seconds = totalSeconds % 60;
      const formattedMinutes = String(minutes).padStart(2, "0");
      const formattedSeconds = String(seconds).padStart(2, "0");

      this.countDownTimeStr = `${formattedMinutes}:${formattedSeconds}`;
    });

    this.setupCategory(0);
    if (this.$tools.isMobile() && this.$store.getters.isLoggedIn) {
      this.fetchBuff();
      this.fetchDailyCheckInInfo();
    }
  },
  computed: {
    isShowActivity() {
      return this.activityId !== "" && this.isJoinActivity === true && this.countDownTimeStr !== "";
    },
  },
  methods: {
    async fetchDailyCheckInInfo() {
      const data = {
        action: "active_daily_check_in_info",
        data: {},
      };
      try {
        const response = await this.$http.post("activity", data);
        this.dailyCheckInTotalGiveCoins = 0;
        const list = response.data.active_daily_check_in_info?.activity_prize_list;
        list.forEach((item) => {
          this.dailyCheckInTotalGiveCoins += item.coins_give;
        });
      } catch (error) {
        console.log("error", error);
      }
    },
    handleOfCS() {
      if (this.isLoggedIn) {
        this.$router.push("m-service");
      } else {
        this.$eventBus.emit("isNeedLogin");
      }
    },
    setupFloatBoxTop() {
      const boxRef = this.$refs.boxRef;
      if (boxRef) {
        const rect = boxRef.getBoundingClientRect();
        const topDistance = rect.top + window.pageYOffset;
        this.floatBoxTop = topDistance;
      } else {
        console.error('Element with ref "boxRef" not found');
      }
    },
    handleOfBuffIcon() {
      this.isShowBuffDetail = !this.isShowBuffDetail;
      if (this.isShowBuffDetail) {
        clearTimeout(this.showBuffDetailTimer);
        this.showBuffDetailTimer = setTimeout(() => {
          this.isShowBuffDetail = false;
        }, 10000);
      }
    },
    handleOfTelegram() {
      this.$tools.newerActivityFinish({ join_channel_finish: 1 }, () => {});
      this.$tools.openTelegram();
    },
    async fetchBuff() {
      const data = {
        action: "user_raffle_prize_buff",
        data: {},
      };
      try {
        const response = await this.$http.post("userVip", data);
        this.buffInfo = response.data.buff_info;
        if (this.buffInfo) {
          this.startBuffCountDown();
        }
      } catch (error) {
        console.log("error", error);
      }
    },
    startBuffCountDown() {
      const targetTime = new Date(this.buffInfo.state_duration);
      const targetTimestamp = targetTime.getTime();
      const currentTimestamp = Date.now();
      let diff = targetTimestamp - currentTimestamp;
      this.buffTimer = setInterval(() => {
        if (diff <= 0) {
          clearInterval(this.buffTimer);
          this.buffInfo = null;
          this.buffCountDownTimeStr = "";
          return;
        }
        const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
          .toString()
          .padStart(2, "0");
        const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60))
          .toString()
          .padStart(2, "0");
        const seconds = Math.floor((diff % (1000 * 60)) / 1000)
          .toString()
          .padStart(2, "0");
        this.buffCountDownTimeStr = `${hours}:${minutes}:${seconds}`;
        diff -= 1000;
      }, 1000);
    },
    handleOfLogin() {
      this.$eventBus.emit("showLogin");
    },
    handleOfRegister() {
      const trackId = "1"; // 注册按钮点击埋点id
      this.$eventBus.emit("showRegister", trackId);
    },
    setupCategory(cate) {
      this.category = cate;
      if (cate === 0) {
        this.fetchLobbyData();
      } else {
        let index = cate - 1;
        const list = this.cateGameList(index);
        if (list.length === 0) {
          if (index < 0) {
            return;
          }
          if (this.cateList.length - 1 < index) {
            return;
          }
          let obj = this.cateList[index];
          if (obj.id === 4) {
            // novo 新的
            this.$tools.userTrackReport({ track_id: "30" });
          } else if (obj.id === 2) {
            // Slots 老虎机
            this.$tools.userTrackReport({ track_id: "31" });
          } else if (obj.id === 3) {
            // Pesca 捕鱼
            this.$tools.userTrackReport({ track_id: "32" });
          } else if (obj.id === 1) {
            // Arcade 街机
            this.$tools.userTrackReport({ track_id: "33" });
          } else if (obj.id === 5) {
            // Card 棋牌
            this.$tools.userTrackReport({ track_id: "34" });
          }
          this.fetchGameListTypeData("game_category", obj);
        }
      }
    },
    fetchLobbyData() {
      const data = {
        action: "game_lobby",
      };
      this.$http
        .post("gameCenter", data)
        .then((response) => {
          // tags
          this.tagsList = response.data.game_tags_list.list;
          // banner
          this.bannerList = response.data.banner_list;
          this.bannerList.forEach((item) => {
            item.picture = this.$global.data.cdn_url + item.picture;
            item.phone_picture = this.$global.data.cdn_url + item.phone_picture;
          });
          // categories
          let categoryList = response.data.category_list;

          // 添加lobby分类
          const lobby = {
            catagory_name: this.$t('lobby'),
            category_icon: this.$global.data.home_icon,
            icon_select: this.$global.data.home_icon_select,
          };

          if (this.isLoggedIn) {
            // 添加收藏分类
            const favorite = {
              id: -999, // 前端自定义的收藏分类id
              catagory_name: "Favoritos",
              category_icon: require("@/assets/common/favorite.webp"),
              icon_select: require("@/assets/common/favorite_h.webp"),
            };
            categoryList = [...categoryList, favorite];
          }
          this.cateListWithLoddy = [lobby, ...categoryList];
          this.cateList = categoryList;

          // 厂商
          this.provider_list = response.data.provider_list;
          this.provider_icon = response.data.provider_icon;
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    logoutSuccessHandle() {
      this.dailyCheckInTotalGiveCoins = 0;
    },
    /**
     * 分类游戏列表
     * @param {*} type 接口参数字段 (目前支持的字段：game_category, game_tag)
     * @param {*} item
     */
    fetchGameListTypeData(type, item) {
      let page = 0;
      if ("page" in item) {
        page = item.page + 1;
      }

      let action = "game_list";
      if (item.id === -999) {
        // 收藏
        action = "user_game_collect_list";
      }

      const data = {
        action,
        data: {
          page,
        },
      };

      if (item.id !== -999) {
        data.data[type] = item.id;
        if (type === "game_tag") {
          data.data.page_size = 20;
        }
      }

      this.$http
        .post("gameCenter", data)
        .then((response) => {
          // 请求成功后的处理
          if ("list" in item) {
            item.list.push(...response.data.game_list);
          } else {
            item.list = response.data.game_list;
          }
          item.page = page;
          item.moveIndex = 0;
          item.totalCount = response.data.count;
        })
        .catch((error) => {
          // 请求失败后的处理
          console.error(error);
        });
    },
    /**
     * 根据下标获取本地分类游戏列表
     * @param {*} index
     */
    cateGameList(index) {
      if (index < 0) {
        return [];
      }
      if (this.cateList.length - 1 < index) {
        return [];
      }
      let obj = this.cateList[index];
      if ("list" in obj) {
        return obj.list;
      } else {
        return [];
      }
    },
    /**
     * 根据下标获取本地分类游戏的总个数
     * @param {*} index
     */
    cateGameTotalCount(index) {
      if (index < 0) {
        return 0;
      }
      if (this.cateList.length - 1 < index) {
        return 0;
      }
      let obj = this.cateList[index];
      return obj.totalCount;
    },
  },
};
</script>

<style lang="scss" scoped>
@keyframes shake-animation {
  0% {
    transform: scale(1) rotate(-10deg);
  }

  20% {
    transform: scale(1) rotate(-10deg);
  }

  30% {
    transform: scale(1.2) rotate(-15deg);
  }

  40% {
    transform: scale(1.2) rotate(-5deg);
  }

  50% {
    transform: scale(1.2) rotate(-10deg);
  }

  60% {
    transform: scale(1.2) rotate(-15deg);
  }

  70% {
    transform: scale(1.2) rotate(-5deg);
  }

  80% {
    transform: scale(1.1) rotate(-10deg);
  }

  100% {
    transform: scale(1) rotate(-10deg);
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease-in-out;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.container {
  margin: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  position: relative;

  .float-box {
    position: fixed;
    display: flex;
    justify-content: space-between;
    right: 6px;
    z-index: 1;

    .float-content {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      & > img {
        width: 49px;
        height: 49px;
        margin-bottom: 10px;
      }

      & > .gift-box {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        & > .gift-icon {
          position: absolute;
          z-index: 1;
          width: 49px;
          height: 49px;
          margin-bottom: 10px;
          animation: shake-animation 1.5s infinite;
          // transition: opacity 0.5s ease-in-out;
        }

        & > .gift-wrapper {
          position: absolute;
          transition: max-width 0.5s ease-in-out;
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          overflow: hidden;

          & > .close {
            width: 30px;
            height: 30px;
            margin-right: 10px;
          }

          .float-buff {
            display: flex;
            justify-content: flex-end;
            align-items: center;

            .buff-info {
              background: linear-gradient(0deg, #0c2133, #0c2133), linear-gradient(180deg, #164100 0%, #095000 100%);
              box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.4), 0px 0px 0px 1px rgba(6, 19, 28, 0.2);
              border-radius: 13px 0 0 13px;
              flex: 1;
              display: flex;
              flex-direction: column;
              justify-content: space-around;
              align-items: flex-start;
              padding-left: 10px;
              padding-right: 10px;
              margin-right: -15px;
              margin-top: -6px;
              width: 150px;
              height: 60px;

              & > div:nth-child(1) {
                color: #ffd400;
                font-size: 12px;
                font-weight: 500;
              }

              & > div:nth-child(2) {
                color: #ffffff;
                font-size: 9px;
                font-weight: 500;
                text-align: left;
              }
            }

            .buff-icon {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              background-image: url("../../assets/float/buff_bg.png");
              background-repeat: no-repeat;
              background-size: cover;
              width: 65px;
              height: 65px;

              .buff-img {
                width: 30px;
                height: 20px;
              }

              .buff-timer {
                color: #ffffff;
                font-size: 12px;
                font-weight: 900;
              }
            }
          }

          .float-item {
            margin-bottom: 10px;
            display: flex;
            align-items: flex-start;

            & > img {
              width: 49px;
              height: 49px;
            }

            .close-box {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              padding: 0 0 3px;

              & > img {
                width: 15px;
                height: 15px;
              }
            }
          }

          .new-user-box {
            margin-bottom: 10px;
            display: flex;
            flex-direction: column;
            position: relative;

            & > img {
              width: 49px;
              height: 49px;
            }

            & > .progress {
              position: absolute;
              top: 30px;
              width: 100%;
              font-size: 12px;
              color: #ffffff;
            }

            & > .countDown {
              margin-top: 2px;
              background: #dde8e7;
              height: 19px;
              line-height: 19px;
              border-radius: 5px;
              font-size: 12px;
              font-weight: bold;
              color: #d80000;
            }
          }

          .daily {
            margin-bottom: 10px;
            background: url("../../assets/daily-check-in/float_icon.webp") no-repeat;
            background-size: cover;
            width: 49px;
            height: 49px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            & > img {
              width: 29px;
              height: 26px;
            }

            & > div {
              color: #ffffff;
              font-size: 8px;
              font-weight: 1000;
            }
          }

          & > .raffle-box {
            display: flex;
            flex-direction: column;
            position: relative;
            height: 49px;
            margin-bottom: 10px;

            & > img {
              width: 49px;
              height: 49px;
              // margin-bottom: 10px;
            }

            & > div {
              position: absolute;
              top: 0;
              right: 0;
              width: 15px;
              height: 15px;
              box-sizing: border-box;
              border: 0.5px solid #ff0000;
              border-radius: 50%;
              background: #ffffff;
              color: #ff0000;
              font-size: 10px;
              font-weight: 900;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }

          & > img {
            width: 49px;
            height: 49px;
            margin-bottom: 10px;
          }
        }
      }
    }
  }

  .float2-box {
    position: fixed;
    display: flex;
    flex-direction: column;
    right: 5px;
    bottom: 68px;
    z-index: 1;

    & > img {
      width: 49px;
      height: 49px;
      margin-bottom: 10px;
    }
  }

  & > .content-box {
    display: flex;
    flex-direction: column;

    .m-header {
      display: flex;
      margin: 10px 0;
    }

    .m-cate {
      position: -webkit-sticky;
      /* Safari */
      position: sticky;
      top: 0px;
      z-index: 1;
    }

    .main-wrapper {
      display: flex;

      .right {
        flex: 1;
        display: flex;
        flex-direction: column;
        width: 100%;

        .lobby {
          display: flex;
          flex-direction: column;

          .lobby-wrapper {
            display: flex;
            flex-direction: column;

            .tags-list {
              display: flex;
              flex-direction: column;
            }
          }
        }

        .cate-game-list {
          display: flex;
          flex-direction: column;

          .collection {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            row-gap: 10px;
            column-gap: 10px;
            margin: 3px 15px 6px;

            .item {
              height: auto;
              min-width: 90px;
            }
          }
        }
      }
    }
  }
}
</style>
