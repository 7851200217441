<template>
  <ModalPopupComp :contextWidth="computedMaxWidthValue">
    <div class="title">Depósito</div>
    <div class="section">
      <div class="subTitle">{{ $t("select_deposit_amount") }}</div>
      <div class="list">
        <div class="item" :class="index === selRechargeIndex ? 'sel-item' : ''"
          v-for="(item, index) in depositAmountList" :key="index" @click="onTapRechargeItem(index)">
          <div class="deposit">
            <div class="unit">{{ depositUnit }}</div>
            <div class="name">{{ item.deposit_amount }}</div>
          </div>
          <div class="give" v-if="isFirst && item.is_give === 1 && item.give_amount !== null">
            +{{ item.give_amount }}&nbsp;gratuities
          </div>
          <img class="gift" v-if="isFirst && item.is_give === 1 && item.give_amount !== null" src="@/assets/gift.png"
            alt="" />
        </div>
        <div class="item">
          <div class="deposit">
            <div class="unit pleft">{{ depositUnit }}</div>
            <input type="text" placeholder="Customize" v-model="customInputValue" />
          </div>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="subTitle">{{ $t("deposit_method") }}</div>
      <div class="methods">
        <div class="selecter">PIX</div>
        <img src="../../../assets/header/down_arrow.png" alt="" />
      </div>
    </div>
    <button @click="saveHandle">{{ $t("deposit") }}</button>
  </ModalPopupComp>
</template>

<script>
import ModalPopupComp from "@/components/ModalPopupComp.vue";
export default {
  data() {
    return {
      depositUnit: "R$",
      depositAmountList: [],
      isFirst: false,
      selRechargeIndex: -1,
      customInputValue: "",
    };
  },
  components: { ModalPopupComp },
  mounted() {

    this.fetchDepositList();
  },
  computed: {
    computedMaxWidthValue() {
      const rootStyles = getComputedStyle(document.documentElement);
      const maxWidth = rootStyles.getPropertyValue('--max-width').trim();
      return parseInt(maxWidth, 10) - 30;
    }
  },
  watch: {
    customInputValue(newValue) {
      if (newValue.length > 0) {
        this.selRechargeIndex = -1;
      }
    },
  },
  methods: {
    fetchDepositList() {
      const data = {
        action: "query_deposit_list",
        data: {},
      };

      this.$http
        .post("userWallet", data)
        .then((response) => {
          this.depositAmountList = response.data.deposit_list;
          this.isFirst = response.data.is_first_pay === 1;
          if (this.isFirst) {
            this.selRechargeIndex = this.depositAmountList.findIndex((item) => {
              return item.give_amount !== 0;
            });
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    },
    onTapRechargeItem(index) {
      this.customInputValue = "";
      this.selRechargeIndex = index;
    },
    saveHandle() {
      this.recharge();
    },
    recharge() {
      if (this.selRechargeIndex === -1 && this.customInputValue === "") {
        this.$toast.error(this.$t("sel_recharge_opt"));
        return;
      }

      let amount;
      let product_id = "";
      if (this.customInputValue !== "") {
        if (this.$tools.isNumeric(this.customInputValue) && parseInt(this.customInputValue) > 0) {
          amount = this.customInputValue;
        } else {
          this.$toast.error(this.$t("enter_valid_number"));
          return;
        }
      } else {
        let item = this.depositAmountList[this.selRechargeIndex];
        amount = item.deposit_amount;
        product_id = item.id;
      }

      const data = {
        action: "collection",
        data: {
          scene: "login",
          amount: parseInt(amount) * 100,
          product_id,
          pay_method: "hkpPay",
        },
      };

      this.$http
        .post("pay", data)
        .then((response) => {
          const url = response.data.order.payUrl;
          this.$emit("showUrl", url);
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  margin: 30px auto 30px;
  font-size: 20px;
  font-weight: 500;
  color: var(--color-popup-title);
}

.section:last-of-type {
  margin-top: 17px;
}

.section {
  margin: 0 20px;
  display: flex;
  flex-direction: column;

  .subTitle {
    font-size: 12px;
    font-weight: 500;
    color: #e9c97e;
    text-align: left;
  }

  .list {
    margin-top: 12px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;

    .item {
      position: relative;
      background: #1f1e1e;
      height: 40px;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .gift {
        position: absolute;
        width: 19px;
        height: 19px;
        top: -8px;
        right: -2px;
      }

      .deposit {
        display: flex;
        align-items: center;

        .unit {
          margin-right: 4px;
          font-size: 12px;
          font-weight: 250;
          color: #ffffff;

        }

        .pleft {
          padding-left: 10px;
        }

        .name {
          font-size: 16px;
          font-weight: 250;
          color: #ffffff;
        }

        input {
          width: 70px;
          line-height: 16px;
          color: #d1d1d1;
          background-color: transparent;
          border: 0;
          outline-style: none;
          font-size: 12px;
        }
      }

      .give {
        font-size: 12px;
        font-weight: 250;
        color: #15ad51;
      }
    }

    .sel-item {
      background: linear-gradient(90deg, #e9bf72 0%, #eccf83 100%);

      .deposit {
        .unit {
          color: #090909;
        }

        .name {
          color: #090909;
        }
      }

      .give {
        color: #090909;
      }
    }
  }

  .methods {
    margin-top: 12px;
    background: #202020;
    border-radius: 8px;
    display: flex;
    width: 100%;
    height: 40px;

    .selecter {
      margin: auto;
      margin-left: 20px;
      font-size: 12px;
      font-weight: 600;
      line-height: 16px;
      color: #ffffff;
    }

    img {
      margin: auto;
      margin-right: 12px;
      width: 16px;
      height: 16px;
    }
  }
}

button {
  margin: 40px auto;
  width: 90%;
  height: 40px;
  border-radius: 8px;
  background: linear-gradient(90deg, #e9bf72 0%, #eccf83 100%);
  font-size: 14px;
  font-weight: 500;
  color: #070704;
  border: none;
}
</style>
