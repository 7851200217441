import { createApp, reactive, h } from "vue";
import ToastComp from "@/components/ToastComp.vue";

function createDialog() {
  dialog = document.createElement("dialog");
  document.body.appendChild(dialog);
}

let appInstance = null;
let dialog = null;
const params = reactive({
  message: "",
  type: "",
  countDownStr: "",
});

createDialog();

appInstance = createApp({
  render() {
    return h(ToastComp, params);
  },
});

appInstance.mount(dialog);
let timer = null;
let second = 0;

const toastTools = {
  show(msg, type, sec = 2) {
    if (timer != null) {
      clearInterval(timer);
    }
    second = sec;
    dialog.close();

    params.message = msg;
    params.type = type;
    params.countDownStr = second.toString().padStart(2, "0");
    dialog.show();
    timer = setInterval(() => {
      second--;
      if (second <= 0) {
        dialog.close();
        clearInterval(timer);
        timer = null;
      }
      params.countDownStr = second.toString().padStart(2, "0");
    }, 1000);
  },

  success(msg, sec = 2) {
    this.show(msg, "success", sec);
  },
  error(msg, sec = 2) {
    this.show(msg, "error", sec);
  },
  info(msg, sec = 2) {
    this.show(msg, "info", sec);
  },
  warning(msg, sec = 2) {
    this.show(msg, "warning", sec);
  },
};

export default toastTools;
