<template>
  <div class="redesSociais">
    <div class="cover">
      <img src="@/assets/redesSociais.webp" alt="">
    </div>
    <p>{{ $t('socialMedia_des1') }}</p>
    <ul>
      <li v-for="(item, index) in thirdPlatform" :key="index" @click="handleOfThirdPlatForm(item)">
        <img :src="$global.data.cdn_url + item.icon" alt="">
        <span>{{ item.name }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      thirdPlatform: []
    }
  },
  methods: {
    handleOfThirdPlatForm(item) {
      window.open(item.link, "_self");
    },
    fetchPlatform() {
      const data = {
        action: "platform_link",
        data: {},
      };

      this.$http
        .post("userCenter", data)
        .then((response) => {
          this.thirdPlatform = response.data.platform_link;
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
  },
  mounted() {
    this.$emit("update:title", this.$t("redesSociais"));
    this.fetchPlatform()
  },
}
</script>

<style lang="scss" scoped>
.redesSociais {
  user-select: none;

  .cover {
    height: 140px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  p {
    font-size: 10px;
    color: #fff;
    text-align: left;
    padding-left: 14px;
    margin: 14px 0;
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    padding: 0 15px 12px 15px;
    box-sizing: border-box;
    justify-content: space-between;

    li {
      width: 48%;
      height: 64px;
      border-radius: 10px;
      background: #006A56;
      display: flex;
      color: #81D6BA;
      font-size: 12px;
      align-items: center;
      padding: 0 12px;
      box-sizing: border-box;
      margin-bottom: 12px;

      img {
        width: 25px;
        height: 25px;
        margin-right: 12px;
      }
    }
  }
}
</style>