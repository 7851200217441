<template>
  <div class="container">
    <div class="wrapper">
      <div class="card">
        <div class="level">
          <img class="icon" v-if="dataInfo?.vip_info?.vip_icon"
            :src="$global.data.cdn_url + dataInfo?.vip_info?.vip_icon" alt="" />
          <img class="icon" v-else src="@/assets/viptop.webp" alt="" />
          <div class="name">{{ dataInfo?.vip_info?.vip_level_name }}</div>
          <div class="vip-des" @click="$router.push({ name: 'vip-benefits' })">Benefícios VIP</div>
        </div>
        <div class="progress-box">
          <div class="progress-info">
            <div class="min-value">VIP:{{ dataInfo?.vip_info?.vip_small_level }}</div>
            <div class="cur-value">
              <span>{{ dataInfo?.vip_info?.vip_up_init_experience }} XP</span>
              <span>/{{ dataInfo?.vip_info?.next_vip_level_small_need_exp }} XP</span>
            </div>
            <div class="max-value">VIP:{{ dataInfo?.vip_info?.next_vip_level_small }}</div>
          </div>
          <div class="progress">
            <div class="current" :style="{ width: vipSchedule + '%' }"></div>
          </div>
        </div>
      </div>
      <div class="title">{{ $t('membership_benefits') }} VIP{{ dataInfo?.vip_info?.vip_small_level }}</div>
      <!-- 存钱罐 -->
      <div class="item-box">
        <div class="title-box">
          <div>
            {{ $t('piggy_bank') }}<span class="tips"
              v-if="dataInfo?.cofrinho_info?.tips && dataInfo?.cofrinho_info?.tips > 0">{{ dataInfo?.cofrinho_info?.tips
              }}</span>
          </div>
          <img src="@/assets/level/info_icon.webp" alt=""
            @click="$router.push({ name: 'vip-benefits', query: { tabIndex: 3 } })" />
        </div>
        <div class="des">
          {{ $t('vip_des1') }} <br />
          {{ $t('vip_des2') }}
        </div>
        <div class="other-info">
          <div class="money-box">
            <div>{{ $t('savings_bank_balance') }}</div>
            <div>{{ dataInfo?.cofrinho_amount || $tools.formatCurrency(piggyAmount) }}</div>
          </div>
          <div class="button" @click="$router.push({ name: 'piggy-bank-page' })">{{ $t('see') }}</div>
        </div>
      </div>
      <!-- 每日奖金 -->
      <div class="item-box">
        <div class="title-box">
          <div>
            {{ $t('daily_bonus') }}
            <span class="tips" v-if="dataInfo?.daily_bounty_info?.tips && dataInfo?.daily_bounty_info?.tips > 0">{{
              dataInfo?.daily_bounty_info?.tips }}</span>
          </div>
        </div>
        <div class="des">{{ $t('vip_des3') }} <br />{{ $t('vip_des4') }}</div>
        <div class="other-info">
          <div class="money-box">
            <div>{{ $t('waiting_to_receive_bonus') }}</div>
            <div>{{ $tools.formatCurrency(dataInfo?.daily_bounty || 0) }}</div>
          </div>
          <div class="button" @click="$router.push({ name: 'm-dailyTasks' })">{{ $t('see') }}</div>
        </div>
      </div>
      <!-- 每周奖金 -->
      <div class="item-box" v-if="dataInfo?.week_bounty_info?.status === true">
        <div class="title-box">
          <div>
            {{ $t('weekly_bonus') }}
            <span class="tips" v-if="dataInfo?.week_bounty_info?.tips && dataInfo?.week_bounty_info?.tips > 0">{{
              dataInfo?.week_bounty_info?.tips }}</span>
          </div>
          <img src="@/assets/level/info_icon.webp" alt=""
            @click="$router.push({ name: 'vip-benefits', query: { tabIndex: 1 } })" />
        </div>
        <div class="des">
          {{ $t('valid_bets_this_week') }}:<span class="normal">{{ dataInfo?.week_bounty_info?.this_week_bet
            }}</span><br />
          {{ $t('premium_estimate') }}:<span class="money">{{ dataInfo?.week_bounty_info?.this_week_amount
            }}</span><br />
          {{ $t('rescue_countdown') }}:<span class="normal">{{ weekCountdownShowValue }}</span><br />
        </div>
        <div class="line"></div>
        <div class="last-info">
          {{ $t('valid_bets_from_last_week') }}:<span>{{ dataInfo?.week_bounty_info?.last_week_bet }}</span>
        </div>
        <div class="other-info">
          <div class="money-box">
            <div>{{ $t('waiting_to_receive_bonus') }}</div>
            <div>{{ $tools.formatCurrency(dataInfo?.week_bounty_info?.last_week_amount || 0) }}</div>
          </div>
          <div class="button" :class="dataInfo?.week_bounty_info?.last_week_amount_receive === 0
            ? 'btn-disable'
            : dataInfo?.week_bounty_info?.last_week_amount_receive === 1
              ? 'btn-enable'
              : 'btn-received'
            " @click="handleOfWeekBounty">
            {{
              dataInfo?.week_bounty_info?.last_week_amount_receive === 0
                ? "Resgatar"
                : dataInfo?.week_bounty_info?.last_week_amount_receive === 1
                  ? "Resgatar"
                  : $t('obtained')
            }}
          </div>
        </div>
      </div>
      <div v-else class="item-box-close">
        <div>{{ $t('weekly_bonus') }}</div>
        <div>{{ $t('weekly_bonus_des') }}</div>
        <div @click="$router.push({ name: 'vip-benefits' })">VIP {{ dataInfo?.vip_info?.next_vip_level_small }} {{
          $t('enabled') }}
        </div>
      </div>
      <!-- 每月奖金 -->
      <div class="item-box" v-if="dataInfo?.month_bounty_info?.status === true">
        <div class="title-box">
          <div>
            {{ $t('monthly_bonus') }}
            <span class="tips" v-if="dataInfo?.month_bounty_info?.tips && dataInfo?.month_bounty_info?.tips > 0">{{
              dataInfo?.month_bounty_info?.tips }}</span>
          </div>
          <img src="@/assets/level/info_icon.webp" alt=""
            @click="$router.push({ name: 'vip-benefits', query: { tabIndex: 2 } })" />
        </div>
        <div class="des">
          {{ $t('valid_bets_this_week') }}:<span class="normal">{{ dataInfo?.month_bounty_info?.this_month_bet
            }}</span><br />
          {{ $t('premium_estimate') }}:<span class="money">{{ dataInfo?.month_bounty_info?.this_month_amount
            }}</span><br />
          {{ $t('rescue_countdown') }}:<span class="normal">{{ monthCountdownShowValue }}</span><br />
        </div>
        <div class="line"></div>
        <div class="last-info">
          {{ $t('valid_bets_from_last_week') }}:<span>{{ dataInfo?.month_bounty_info?.last_month_bet }}</span>
        </div>
        <div class="other-info">
          <div class="money-box">
            <div>{{ $t('waiting_to_receive_bonus') }}</div>
            <div>{{ $tools.formatCurrency(dataInfo?.month_bounty_info?.last_month_amount || 0) }}</div>
          </div>
          <div class="button" :class="dataInfo?.month_bounty_info?.last_month_amount_receive === 0
            ? 'btn-disable'
            : dataInfo?.month_bounty_info?.last_month_amount_receive === 1
              ? 'btn-enable'
              : 'btn-received'
            " @click="handleOfMonthBounty">
            {{
              dataInfo?.month_bounty_info?.last_month_amount_receive === 0
                ? "Resgatar"
                : dataInfo?.month_bounty_info?.last_month_amount_receive === 1
                  ? "Resgatar"
                  : $t('obtained')
            }}
          </div>
        </div>
      </div>
      <div v-else class="item-box-close">
        <div>{{ $t('monthly_bonus') }}</div>
        <div>{{ $t('monthly_bonus_des') }}</div>
        <div @click="$router.push({ name: 'vip-benefits' })">VIP {{ dataInfo?.vip_info?.next_vip_level_small }} {{
          $t('enabled') }}
        </div>
      </div>
      <!-- 升级奖金 -->
      <div class="item-box" v-if="dataInfo?.vip_up_bounty_info?.status === true">
        <div class="title-box">
          <div>
            {{ $t('upgrade_bonus') }}<span class="tips"
              v-if="dataInfo?.vip_up_bounty_info?.tips && dataInfo?.vip_up_bounty_info?.tips > 0">{{
                dataInfo?.vip_up_bounty_info?.tips
              }}</span>
          </div>
          <img src="@/assets/level/info_icon.webp" alt=""
            @click="$router.push({ name: 'vip-benefits', query: { tabIndex: 0 } })" />
        </div>
        <div class="next-box" v-for="(item, index) in dataInfo?.vip_up_bounty_info?.vip_up_bounty_list" :key="index">
          <div>
            Bônus ao subir para <span class="normal">VIP{{ item.vip_small_level }}</span>:<span class="money">R${{
              $tools.formatCurrency(item.level_up_bounty_amount) }}</span>
          </div>
        </div>
        <div class="other-info">
          <div class="next-info">
            {{ $t('distance_to_next_level') }}
            <span class="highlight">VIP{{ dataInfo?.vip_info?.next_vip_level_small }}</span> {{
              $t('experience_is_still_lacking') }}:<span class="highlight">{{
              $tools.formatCurrency(dataInfo?.vip_info?.next_vip_level_small_need_exp -
                dataInfo?.vip_info?.vip_up_init_experience)
            }}</span>
          </div>

          <div class="button" :class="dataInfo?.vip_up_bounty_info?.vip_up_bounty_list !== null && dataInfo?.vip_up_bounty_info?.vip_up_bounty_list.length !== 0
            ? 'btn-enable'
            : 'btn-hidden'
            " @click="handleOfUpgradeBonus">
            {{ dataInfo?.vip_up_bounty_info?.vip_up_bounty_list?.length > 1 ? "Receber tudo" : "Resgatar" }}
          </div>
        </div>
      </div>
      <div v-else class="item-box-close">
        <div>{{ $t('upgrade_bonus') }}</div>
        <div>{{ $t('upgrade_VIP_level_to_receive_bonuses') }}</div>
        <div @click="$router.push({ name: 'vip-benefits' })">VIP {{ dataInfo?.vip_info?.next_vip_level_small }} {{ $t('enabled') }}
        </div>
      </div>
    </div>
    <dialog ref="receivedSuccessDialog">
      <div class="dialog-content">
        <div class="header">
          <div>{{ $t('prompt') }}</div>
          <img class="icon" src="@/assets/level/dialog_icon.webp" alt="" />
          <img class="close" src="@/assets/level/dialog_close_icon.webp" alt="" @click="handlOk()" />
        </div>
        <div class="title">{{ $t('success_in_withdrawal') }}</div>
        <div class="money">R$ {{ $tools.formatCurrency(receiveAmount) }}</div>
        <div class="des">{{ $t('successfully_des') }}</div>
        <div class="btn" @click="handlOk()">{{ $t('bom') }}</div>
      </div>
    </dialog>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      dataInfo: null,
      weekCountdownShowValue: "",
      monthCountdownShowValue: "",
      timer: null,
      receiveAmount: 0,
      piggyAmount: 0,
    };
  },
  unmounted() {
    if (this.timer !== null) {
      clearInterval(this.timer);
      this.timer = null;
    }
  },
  mounted() {
    this.$emit("update:title", this.$t("vip"));
    this.$tools.newerActivityFinish({ view_member_finish: 1 });
    this.fetchVipInfo();
    this.fetchPiggyInfo();
    // this.$eventBus.emit("refreshCount");
  },
  computed: {
    vipSchedule() {
      if (this.dataInfo === null || this.dataInfo?.vip_info?.next_vip_level_small_need_exp === 0) {
        return 0;
      }
      const ret = (this.dataInfo?.vip_info?.vip_up_init_experience / this.dataInfo?.vip_info?.next_vip_level_small_need_exp) * 100;
      return parseInt(ret) > 100 ? 100 : ret;
    },
  },
  methods: {
    handlOk() {
      this.fetchVipInfo();
      this.fetchPiggyInfo();
      this.$refs.receivedSuccessDialog.close();
      this.$eventBus.emit("refreshCount");
    },
    async fetchPiggyInfo() {
      const data = {
        action: "cofrinho_active_info",
        data: {},
      };
      try {
        const response = await this.$http.post("activity", data);
        const cofrinho_active_info = response.data.cofrinho_active_info;
        this.piggyAmount = parseFloat(cofrinho_active_info.amount - cofrinho_active_info.extract_amount).toFixed(4);
      } catch (error) {
        console.log("error", error);
      }
    },
    handleOfWeekBounty() {
      if (this.dataInfo.week_bounty_info.last_week_amount_receive === 1) {
        this.receiveVipBounty(1);
      }
    },
    handleOfMonthBounty() {
      if (this.dataInfo.month_bounty_info.last_month_amount_receive === 1) {
        this.receiveVipBounty(2);
      }
    },
    handleOfUpgradeBonus() {
      if (this.dataInfo.vip_up_bounty_info.vip_up_bounty_list != null && this.dataInfo.vip_up_bounty_info.vip_up_bounty_list.length !== 0) {
        this.receiveVipBounty(3);
      }
    },
    async receiveVipBounty(type) {
      const data = {
        action: "receive_vip_bounty",
        data: {
          type,
        },
      };
      try {
        const response = await this.$http.post("userVip", data);
        this.receiveAmount = response.data.amount;
        this.$refs.receivedSuccessDialog.showModal();
        this.fetchVipInfo();
      } catch (error) {
        console.log("error", error);
      }
    },
    getShowCountdownValue(diff) {
      if (diff < 0) {
        return "0 dias 00:00:00";
      }

      // 计算天数差
      const days = Math.floor(diff / (60 * 60 * 24));
      diff -= days * (60 * 60 * 24);

      // 计算小时数
      const hours = Math.floor(diff / (60 * 60));
      diff -= hours * (60 * 60);

      // 计算分钟数
      const minutes = Math.floor(diff / 60);
      diff -= minutes * 60;

      // 计算秒数
      const seconds = Math.floor(diff);

      // 返回格式化的字符串
      return `${days} dias ${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
    },
    async fetchVipInfo() {
      const data = {
        action: "vip_info",
        data: {},
      };
      try {
        const response = await this.$http.post("userVip", data);
        response.data.vip_up_bounty_info.vip_up_bounty_list.sort((a, b) => a.vip_small_level - b.vip_small_level);
        this.dataInfo = response.data;

        let weekDiff = this.dataInfo?.week_bounty_info?.this_week_end_time;
        let monthDiff = this.dataInfo?.month_bounty_info?.this_month_end_time;
        this.timer = setInterval(() => {
          this.weekCountdownShowValue = this.getShowCountdownValue(weekDiff);
          weekDiff -= 1;
          this.monthCountdownShowValue = this.getShowCountdownValue(monthDiff);
          monthDiff -= 1;
        }, 1000);
      } catch (error) {
        console.log("error", error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  .wrapper {
    display: flex;
    flex-direction: column;
    padding: 22px 15px;
  }

  .card {
    position: relative;
    background: url("@/assets/level/card_bg.webp");
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    padding: 11px 10px 15px;

    .level {
      display: flex;
      align-items: center;
      line-height: 40px;

      .icon {
        width: 40px;
        height: 40px;
      }

      .name {
        margin-left: 7px;
        color: #029479;
        font-size: 18px;
        font-weight: 300;
      }

      .vip-des {
        line-height: 14px;
        margin-left: auto;
        color: #81d6ba;
        font-size: 10px;
        font-weight: 500;
        border-bottom: #81d6ba 1px solid;
        box-sizing: border-box;
      }
    }

    .progress-box {
      margin-top: 15px;
      display: flex;
      flex-direction: column;

      .progress-info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #ffffff;
        font-size: 10px;
        font-weight: 500;

        .min-value,
        .max-value {
          color: #ffffff;
          font-size: 12px;
          font-weight: bold;
        }

        .cur-value {
          font-size: 10px;
          font-weight: 500;

          &>span:first-child {
            color: #ffffff;
          }

          &>span:last-child {
            color: #006a56;
          }
        }
      }

      .progress {
        margin-top: 8px;
        background: #121212;
        border-radius: 5px;
        height: 10px;
        display: flex;
        align-items: center;

        .current {
          background: #00cb47;
          border-radius: 5px;
          height: 7px;
        }
      }
    }
  }

  .title {
    margin: 24px 15px 7px;
    display: flex;
    justify-content: space-between;
    color: #ffffff;
    font-size: 14px;
    font-weight: 500;
  }

  .item-box {
    margin-top: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #006a56;
    border-radius: 10px;
    padding: 10px 14px;

    .tips {
      width: 15px;
      height: 15px;
      display: inline-block;
      text-align: center;
      line-height: 15px;
      background: #f04770;
      font-size: 10px;
      border-radius: 20px;
      margin-left: 4px;
    }

    &>.title-box {
      display: flex;
      justify-content: space-between;
      color: #ffffff;
      font-size: 12px;
      font-weight: bold;

      &>img {
        width: 15px;
        height: 15px;
      }
    }

    .next-box {
      display: flex;
      flex-direction: column;

      &>div {
        text-align: left;
        color: #81d6ba;
        font-size: 10px;
        font-weight: normal;

        &>.normal {
          color: #ffffff;
        }

        &>.money {
          color: #ffd400;
        }
      }
    }

    .des {
      text-align: left;
      color: #81d6ba;
      font-size: 10px;
      font-weight: normal;

      &>.normal {
        color: #ffffff;
      }

      &>.money {
        color: #ffd400;
      }
    }

    &>.line {
      margin-top: 7px;
      margin-bottom: 5px;
      height: 1px;
      background-color: #015545;
    }

    .last-info {
      text-align: left;
      color: #81d6ba;
      font-weight: 400;
      font-size: 10px;

      &>span {
        font-weight: 500;
        font-size: 10px;
        color: #ffffff;
      }
    }

    .other-info {
      margin-top: 7px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .next-info {
        margin-right: 10px;
        text-align: left;
        font-size: 9px;
        font-weight: normal;
        color: #81d6ba;

        &>span {
          color: #ffffff;
        }
      }

      .money-box {
        display: flex;
        flex-direction: column;

        &>div:first-child {
          color: #ffffff;
          font-size: 10px;
          font-weight: normal;
          text-align: left;
        }

        &>div:last-child {
          color: #ffd400;
          font-size: 12px;
          font-weight: 500;
          text-align: left;
        }
      }

      &>.button {
        background: #029479;
        min-width: 158.5px;
        max-width: 158.5px;
        height: 35px;
        line-height: 35px;
        border-radius: 6px;
        color: #ffffff;
        font-size: 12px;
        font-weight: 500;
      }

      &>.btn-disable {
        background: #676767;
        color: #999999;
      }

      &>.btn-enable {
        background: #ffd400;
        color: #000000;
      }

      &>.btn-received {
        box-sizing: border-box;
        border: 1px solid #029479;
        background: #006a56;
        color: #10b349;
      }

      &>.btn-hidden {
        background: transparent;
        color: transparent;
      }
    }
  }

  .item-box-close {
    margin-top: 5px;
    background: #004033;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px 15px 6px;

    &>div:nth-child(1) {
      color: #006955;
      font-size: 12px;
      font-weight: bold;
    }

    &>div:nth-child(2) {
      color: #006955;
      font-size: 9px;
      font-weight: normal;
    }

    &>div:nth-child(3) {
      margin-top: 25px;
      color: #81d6ba;
      text-decoration: underline;
      font-size: 10px;
      font-weight: normal;
    }
  }

  .dialog-content {
    background: #015545;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;

    &>.header {
      width: 100%;
      background: #012c23;
      height: 48px;
      display: flex;
      position: relative;

      &>.icon {
        margin: auto;
        width: 67px;
        height: auto;
      }

      &>div {
        position: absolute;
        color: #81d6ba;
        font-size: 14px;
        font-weight: bold;
        left: 17px;
        top: 14px;
      }

      &>.close {
        position: absolute;
        right: 11px;
        top: 14px;
        width: 20px;
        height: 20px;
      }
    }

    &>.title {
      color: #ffffff;
      font-size: 12px;
      font-weight: 500;
    }

    &>.money {
      color: #ffd400;
      font-size: 32px;
      font-weight: bold;
    }

    &>.des {
      margin: 15px 60px;
      color: #81d6ba;
      font-size: 12px;
      font-weight: 500;
    }

    &>.btn {
      width: 80%;
      background: #029479;
      border-radius: 6px;
      height: 40px;
      line-height: 40px;
      margin: 30px auto 25px;
      color: #ffffff;
      font-size: 17px;
      font-weight: bold;
    }
  }
}
</style>
