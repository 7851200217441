<template>
  <div class="flow">
    <div class="wrapper">
      <mine-empty v-if="isShowEmpty"></mine-empty>
      <div class="m-table" v-if="isShowEmpty === false">
        <ul>
          <li v-for="(item, index) in tableData" :key="index" @click="showDetailDialog(item)">
            <div class="li-top">
              <span :class="item.transfer_amount >= 0 ? 'm-value-positive' : 'm-value-negative'"
                >R${{ $tools.formatCurrency(parseFloat(item.transfer_amount)) }}</span
              >
              <span>{{ $t('balance') }}:</span>
            </div>
            <div class="li-center">
              <span>{{ item.bet_type_str }}</span>
              <span>R${{ $tools.formatCurrency(parseFloat(item.coins)) + $t('cash') }}</span>
            </div>
            <div class="li-bottom">
              <span>{{ item.create_time }}</span>
            </div>
          </li>
        </ul>
        <LoadMoreComp :curCount="tableData.length" :totalCount="totalPage" @loadMoreEvent="fatchBetHistory(page + 1)"> </LoadMoreComp>
      </div>
    </div>
    <!-- 验证手机or邮箱弹窗 -->
    <dialog ref="verifyDialog">
      <VerifyDialogForPhoneOrEmail :verifyType="3" :title="$t('bet_record')" @closeEvent="handleOfVerifyDialogClose">
        <template v-slot:transactionFlow>
          <h1 :class="info.transfer_amount >= 0 ? 'm-value-positive dialog-title' : 'm-value-negative dialog-title'">
            R${{ $tools.formatCurrency(parseFloat(info.transfer_amount)) }}
          </h1>
          <div class="row">
            <p>
              <span>{{ $t('date') }}</span>
              <span>{{ info.create_time }}</span>
            </p>
            <p>
              <span>{{ $t('game') }}</span>
              <span>{{ info.bet_type_str }}</span>
            </p>
            <p>
              <span>{{ $t('number') }}</span>
              <span>{{ info.bet_id }}</span>
              <span style="position: relative">
                <img src="@/assets/verify_dialog_slot_copy.webp" @click="copyHandle(info.bet_id)" alt="" />
                <img class="copiado" v-show="isShowCopiado" src="@/assets/user/copiado.png" alt="" />
              </span>
            </p>
            <p>
              <span>{{ $t('wallet') }}</span>
              <span>{{ info.currency_code }}</span>
            </p>
            <p>
              <span>{{ $t('type') }}</span>
              <span class="win">Win</span>
            </p>
          </div>
          <div class="row last">
            <p>
              <span>{{ $t('balance') }}</span>
              <span>R${{ $tools.formatCurrency(parseFloat(info.coins)) + " Real" }}</span>
            </p>
          </div>
        </template>
      </VerifyDialogForPhoneOrEmail>
    </dialog>
  </div>
</template>

<script>
import MineEmpty from "@/components/MineEmpty.vue";
import LoadMoreComp from "@/components/LoadMoreComp.vue";
import VerifyDialogForPhoneOrEmail from "@/components/VerifyDialogForPhoneOrEmail.vue";
import clipboard from "clipboard";
export default {
  data() {
    return {
      tableData: [],
      page: 0,
      totalPage: 0,
      pageSize: 10,
      isShowEmpty: true,
      isMobile: true,
      info: {},
      isShowCopiado: false,
    };
  },
  components: {
    MineEmpty,
    LoadMoreComp,
    VerifyDialogForPhoneOrEmail,
  },
  mounted() {
    this.isMobile = this.$tools.isMobile();
    this.fatchBetHistory(this.page);
  },
  methods: {
    copyHandle(bet_id) {
      clipboard.copy(bet_id);
      this.isShowCopiado = true;
      setTimeout(() => {
        this.isShowCopiado = false;
      }, 2000);
    },
    handleOfVerifyDialogClose() {
      this.$refs.verifyDialog.close();
    },
    showDetailDialog(item) {
      this.info = item;
      this.$refs.verifyDialog.showModal();
    },
    changePage(index) {
      this.fatchBetHistory(index - 1);
    },
    fatchBetHistory(page) {
      const data = {
        action: "game_bet_history",
        data: {
          page,
          page_size: this.pageSize,
        },
      };
      this.$http
        .post("gameCenter", data)
        .then((response) => {
          const list = response.data.bet_history;
          const vmList = list.map((item) => {
            if (item.bet_type === 1) {
              item.bet_type_str = this.$t("place_a_bet");
            } else {
              item.bet_type_str = this.$t("other");
            }

            return item;
          });
          if (this.isMobile) {
            this.tableData.push(...vmList);
          } else {
            this.tableData = vmList;
          }

          this.page = response.data.page;
          this.isShowEmpty = this.tableData.length === 0;
          this.totalPage = response.data.count;
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
:deep(.el-pagination.is-background .btn-prev) {
  background-color: #1f1e1e;
  color: #fff;
}

:deep(.el-pagination.is-background .btn-prev:disabled) {
  background-color: #1f1e1e;
  color: #fff;
}

:deep(.el-pagination.is-background .el-pager li:not(.disabled)) {
  background-color: #1f1e1e;
  color: #fff;
}

:deep(.el-pagination.is-background .el-pager li:not(.disabled).is-active) {
  background-color: #e9c97e;
  color: #070704;
}

:deep(.el-pagination.is-background .btn-next) {
  background-color: #1f1e1e;
  color: #fff;
}

:deep(.el-pagination.is-background .btn-next:disabled) {
  background-color: #1f1e1e;
  color: #fff;
}

.flow {
  display: flex;

  .wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;

    .table {
      margin-bottom: 20px;
      background: #1a1919;
      border-radius: 8px;

      .header {
        display: flex;
        height: 56px;
        border-radius: 8px;
        font-size: 12px;
        color: rgba($color: #ffffff, $alpha: 0.8);
        justify-content: space-between;
        align-items: center;
        padding: 0 30px;

        div {
          flex: 1;
          display: flex;
          justify-content: center;
        }
      }

      .cell {
        margin: 0 10px 8px;
        display: flex;
        background: #1a1919;
        height: 40px;
        border-radius: 8px;
        font-size: 12px;
        line-height: 40px;
        color: rgba($color: #ffffff, $alpha: 0.8);
        justify-content: space-between;
        align-items: center;
        padding: 0 20px;
        box-sizing: border-box;
        border: 1px solid #2b2b2b;

        div {
          flex: 1;
          display: flex;
          justify-content: center;
        }

        .value-positive {
          flex: 1;
          font-size: 12px;
          color: #42f388;
          opacity: 0.8;
          text-align: left;
        }

        .value-negative {
          flex: 1;
          font-size: 12px;
          color: #d04835;
          opacity: 0.8;
          text-align: left;
        }

        .balance {
          flex: 1;
          display: flex;
          justify-content: center;

          div {
            flex: 1;
            display: flex;
            justify-content: center;
          }
        }
      }
    }

    .m-table {
      display: flex;
      flex-direction: column;
      border-radius: 4px;
      padding-bottom: 20px;
      margin-top: -15px;

      ul {
        li {
          height: 88px;
          background: #006a56;
          border: 1px solid #007a63;
          border-radius: 10px;
          display: flex;
          flex-direction: column;
          padding: 8px 10px;
          box-sizing: border-box;
          margin-bottom: 12px;

          .m-value-positive {
            color: #fff !important;
          }

          .m-value-negative {
            color: #ff9d70 !important;
          }

          .li-top {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-bottom: 4px;

            span {
              font-size: 18px;
              color: #7bffa9;
              font-weight: bold;

              &:last-child {
                font-size: 12px;
                color: #81d6ba;
              }
            }
          }

          .li-center {
            display: flex;
            justify-content: space-between;
            font-size: 12px;
            line-height: 2;
            color: #81d6ba;

            span {
              font-weight: 500;
            }
          }

          .li-bottom {
            display: flex;
            justify-content: space-between;
            font-size: 11px;
            color: #81d6ba;
            font-weight: 500;
          }
        }
      }
    }
  }

  // @media (max-width: 768px) {
  //   .wrapper .pagination {
  //     display: none;
  //   }
  // }
}

.dialog-title {
  margin: 0;
  font-size: 32px;
  font-weight: bold;
  color: #7bffa9;
  line-height: 1;
  border-bottom: 1px solid #007760;
  margin-bottom: 20px;
  padding-bottom: 14px;
}

.m-value-positive {
  color: #fff !important;
}

.m-value-negative {
  color: #ff9d70 !important;
}

.row {
  border-bottom: 1px solid #007760;
  margin-bottom: 20px;

  p {
    display: flex;
    align-items: center;
    width: 100%;
    line-height: 1;
    justify-content: space-between;

    span {
      font-size: 12px;

      &:first-child {
        width: 60px;
        color: #00c19c;
        font-weight: 500;
        text-align: left;
      }

      &:nth-child(2) {
        flex: 1;
        text-align: left;
        color: #fff;
        font-weight: 500;
      }

      &:last-child {
        display: flex;

        img {
          width: 15px;
          height: 16px;
        }

        .copiado {
          position: absolute;
          top: -28px;
          left: -20px;
          width: 50px;
          height: auto;
        }
      }
    }

    span.win {
      color: #7bffa9;
    }
  }
}

.last {
  border: none;

  p {
    margin: 0;
    line-height: 1.7;
  }
}
</style>
