<template>
  <!-- 每日签到 -->
  <div class="daily-check-in-page" @click="clearShowGiftTimer">
    <div class="gift-list" v-if="isShowGiftList">
      <div class="item">
        <div class="img-box">
          <img class="money" src="@/assets/daily-check-in/gift_coin.webp" alt="" />
        </div>
        <div class="name">{{ $t('cash') }}</div>
        <div class="money">+{{ $tools.formatCurrency(active_daily_check_in_info?.coins) }}</div>
      </div>
      <div class="item">
        <div class="img-box">
          <img class="money" src="@/assets/daily-check-in/coin.webp" alt="" />
        </div>
        <div class="name">{{ $t('bonus') }}</div>
        <div class="money-green">+{{ $tools.formatCurrency(active_daily_check_in_info?.coins_give) }}</div>
      </div>
      <div class="item" v-for="(item, index) in active_daily_check_in_info?.prize_list" :key="index">
        <div class="img-box">
          <img class="prize" :src="$global.data.cdn_url + item.prize_image_url" alt="" />
        </div>
        <div class="name">{{ item.prize_name }}</div>
        <div class="count">x{{ item.prize_count }}</div>
      </div>
    </div>
    <div class="collection" v-if="active_daily_check_in_info">
      <div class="item" :class="item.receive_state === 2 ? 'state-purple' : ''"
        v-for="(item, index) in active_daily_check_in_info?.activity_prize_list.slice(0, 6)" :key="index">
        <img
          :src="item.coins_give_type === 1 ? require('@/assets/daily-check-in/gift_coin.webp') : require('@/assets/daily-check-in/coin.webp')"
          alt="" />
        <div class="money" :class="item.coins_give_type === 1 ? 'yellow' : ''">+{{
          $tools.formatCurrency(item.coins_give) }}</div>
        <div class="fail-box" v-if="item.receive_state === 0">
          <img src="@/assets/daily-check-in/fail.webp" alt="" />
        </div>
        <div class="success-box" v-else-if="item.receive_state === 1 || item.receive_state === 3">
          <img src="@/assets/daily-check-in/success.webp" alt="" />
        </div>

        <div class="day" :class="item.receive_state != 4 ? 'whiteColor' : ''" v-else>{{ showStateStr(index) }}</div>
      </div>
      <!-- 大礼包 -->
      <div class="item" :class="active_daily_check_in_info?.pack_state === 1 ? 'state-purple' : ''"
        @click.stop="handleOfShowGiftList">
        <img src="@/assets/daily-check-in/gift.webp" alt="" />
        <div>{{ $t('big_gift_package') }}</div>
        <div class="success-box" v-if="active_daily_check_in_info?.pack_state === 2">
          <img src="@/assets/daily-check-in/success.webp" alt="" />
        </div>
        <div v-else>{{ $t('7_consecutive_days') }}</div>
        <img class="info" src="@/assets/daily-check-in/info.webp" alt="" />
      </div>
    </div>
    <div class="button"
      :class="active_daily_check_in_info?.button_state === 3 || active_daily_check_in_info?.button_state === 2 ? 'btn-disable' : ''"
      @click="handleOfBtn">
      {{ showBtnStr() }}
    </div>
    <div class="rule-box">
      <div class="item">
        <div class="title">{{ $t('participation_conditions') }}</div>
        <!-- <div class="value yellow">Depósito ≥R${{ active_daily_check_in_info?.pay_requirement }} durante o período</div> -->
        <div class="value yellow">
          {{ $t('daily_lottery_draw_des3') }} {{ $tools.formatCurrency(active_daily_check_in_info?.pay_requirement) }}
          {{ $t('daily_lottery_draw_des4') }}
          {{ $tools.formatCurrency(active_daily_check_in_info?.bet_requirement) }}
        </div>
      </div>
      <div class="item">
        <div class="title">{{$t('activity_rules')}}：</div>
        <div class="value">
          {{ $t('daily_lottery_draw_des5') }}<br />
          {{ $t('daily_lottery_draw_des6') }}<br />
          {{ $t('daily_lottery_draw_des7') }}<br />
          {{ $t('daily_lottery_draw_des8') }}<br />
          {{ $t('daily_lottery_draw_des9') }}<br />
        </div>
      </div>
    </div>
    <dialog ref="successDialog">
      <div class="success-wrapper">
        <div class="header">
          <div>{{ $t('prompt') }}</div>
          <img src="@/assets/award-task/coin.webp" alt="" />
          <img src="@/assets/newer-task/close.webp" alt="" @click="handleOfCloseSuccessDialog" />
        </div>
        <div class="title">{{ $t('check_in_successful') }}</div>
        <div class="package-list" v-if="active_daily_check_in_info?.pack_state === 1">
          <div class="item">
            <div class="img-box">
              <img class="coins" src="@/assets/daily-check-in/coin.webp" alt="" />
            </div>
            <div>{{ $t('bonus') }}</div>
            <div class="money-green">+{{ $tools.formatCurrency(reciveData?.coins_give) }}</div>
          </div>
          <div class="item">
            <div class="img-box">
              <img class="coins" src="@/assets/daily-check-in/gift_coin.webp" alt="" />
            </div>
            <div>{{ $t('dinheiro') }}</div>
            <div class="money-yellow">+{{ $tools.formatCurrency(reciveData?.coins) }}</div>
          </div>

          <div class="item" v-for="(item, index) in active_daily_check_in_info?.prize_list" :key="index">
            <div class="img-box">
              <img :src="$global.data.cdn_url + item.prize_image_url" alt="" />
            </div>
            <div>{{ item.prize_name }}</div>
            <div class="count">x{{ item.prize_count }}</div>
          </div>
        </div>
        <div class="money" v-else>+{{ $tools.formatCurrency(reciveData?.coins_give) }}</div>
        <div class="des">{{ $t('daily_lottery_draw_des10') }}</div>
        <div class="btn" @click="handleOfCloseSuccessDialog">{{ $t('understand') }}</div>
      </div>
    </dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isShowGiftList: false,
      showGiftTimer: null,
      active_daily_check_in_info: null,
      reciveData: null,
    };
  },
  mounted() {
    this.$emit("update:title", this.$t('daily_check_in'));
    this.fetchData();
  },
  unmounted() {
    this.clearShowGiftTimer();
  },
  methods: {
    clearShowGiftTimer() {
      this.isShowGiftList = false;
      if (this.showGiftTimer) {
        clearTimeout(this.showGiftTimer);
        this.showGiftTimer = null;
      }
    },
    handleOfShowGiftList() {
      if (this.isShowGiftList) {
        return;
      }
      this.isShowGiftList = true;
      this.showGiftTimer = setTimeout(() => {
        this.clearShowGiftTimer();
      }, 5000);
    },
    handleOfCloseSuccessDialog() {
      this.$refs.successDialog.close();
      this.fetchData();
    },
    showBtnStr() {
      //按钮状态：0存款不足，1可签到，2已签到
      switch (this.active_daily_check_in_info?.button_state) {
        case 0:
          return this.$t('deposit');
        case 1:
          return this.$t('check_in');
        case 2:
          return this.$t('check_in');
        default:
          return this.$t('check_in');
      }
    },
    handleOfBtn() {
      //按钮状态：0存款不足，1可签到，2已签到, 3不可签到
      switch (this.active_daily_check_in_info?.button_state) {
        case 0:
          this.$router.push({ name: "m-deposit-page" });
          break;
        case 1:
          this.receiveData();
          break;
        case 2:
          // this.$router.push({ name: "award-task-page" });
          this.$toast.info(this.$t('daily_lottery_draw_des2'));
          break;
        case 3:
          this.$toast.info(this.$t('daily_lottery_draw_des1'));
          break;
      }
    },
    showStateStr(index) {
      const item = this.active_daily_check_in_info?.activity_prize_list[index];
      //0已过期未签到，1已过期已签到，2今日可签到，3今日已签到，4未到期
      if (item.receive_state === 0) {
        return this.$t('did_not_check_in');
      } else if (item.receive_state === 1 || item.receive_state === 3) {
        return this.$t('already_checked_in');
      } else {
        return this.$t('day') + (index + 1);
      }
    },
    async fetchData() {
      const data = {
        action: "active_daily_check_in_info",
        data: {},
      };
      try {
        const response = await this.$http.post("activity", data);
        this.active_daily_check_in_info = response.data.active_daily_check_in_info;
      } catch (error) {
        console.log("error", error);
      }
    },
    async receiveData() {
      const data = {
        action: "active_daily_check_in_receive",
        data: {},
      };
      try {
        const response = await this.$http.post("activity", data);
        this.reciveData = response.data;
        this.$refs.successDialog.showModal();
      } catch (error) {
        console.log("error", error);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.daily-check-in-page {
  margin-top: 15px;
  width: 100vw;
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: var(--max-width);

  .gift-list {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 15px;
    display: flex;
    justify-content: space-between;
    background: #0085a9;
    border: 1px solid #ffd400;
    height: 93px;
    border-radius: 10px;
    padding: 0 20px;

    &>.item {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;

      &>.img-box {
        margin-top: 14px;
        display: flex;

        &>.money {
          width: 25px;
          height: auto;
        }

        &>.prize {
          width: 37.5px;
          height: auto;
        }
      }

      &>.name {
        color: #ffffff;
        font-size: 9px;
        font-weight: 500;
      }

      &>.money {
        color: #ffd400;
        font-size: 12px;
        font-weight: bold;
      }

      &>.money-green {
        color: #21b300;
        font-size: 12px;
        font-weight: bold;
      }

      &>.count {
        color: #ffffff;
        font-size: 12px;
        font-weight: bold;
      }
    }
  }

  .collection {
    margin: 0 15px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;

    .item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background: #006955;
      border-radius: 10px;
      padding: 10px 0 5px;

      &>img {
        width: 25px;
        height: 25px;
      }

      &>.money {
        color: #21b300;
        font-size: 14px;
        font-weight: bold;
        margin-top: 5px;
        margin-bottom: 7px;
      }

      &>.yellow {
        color: #ffd400;
      }

      &>.day {
        color: #54e6b4;
        font-size: 9px;
        font-weight: 500;
        line-height: 20px;
      }

      .whiteColor {
        color: #ffffff;
      }

      .success-box {
        width: 90%;
        background: #21b300;
        height: 20px;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;

        &>img {
          width: 12px;
          height: auto;
        }
      }

      .fail-box {
        width: 90%;
        height: 20px;
        background: #ff5000;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;

        &>img {
          width: 9px;
          height: auto;
        }
      }
    }

    .item:last-of-type {
      position: relative;
      grid-column: span 2;

      &>.info {
        position: absolute;
        top: 4px;
        right: 3px;
        width: 15px;
        height: 15px;
      }

      &>img {
        width: 40px;
        height: 40px;
      }

      &>div:first-of-type {
        color: #ffd400;
        font-size: 9px;
        font-weight: bold;
      }

      &>div:last-of-type {
        color: #ffffff;
        font-size: 9px;
        font-weight: 500;
      }
    }

    .state-red {
      background: #903d3d;
    }

    .state-green {
      background: #1b4429;
    }

    .state-purple {
      background: #0085a9;
    }
  }

  .button {
    margin: 20px 15px 0;
    background: #0085a9;
    color: #d8d8d8;
    height: 40px;
    line-height: 40px;
    border-radius: 8px;
    font-size: 15px;
    font-weight: bold;
  }

  .btn-state-yellow {
    background: #ffe600;
    color: #06131c;
  }

  &>.btn-disable {
    background: #676767;
    color: #a5a5a5;
  }

  .rule-box {
    margin: 20px 15px;
    display: flex;
    flex-direction: column;

    &>.item {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 10px;

      &>.title {
        color: #ffffff;
        font-size: 12px;
        font-weight: bold;
      }

      &>.value {
        margin-top: 5px;
        text-align: left;
        color: #81d6ba;
        font-size: 10px;
        font-weight: 500;
        line-height: 2;
      }

      .yellow {
        color: #ffd400;
      }
    }
  }

  .success-wrapper {
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    background: #015545;
    min-height: 247px;

    &>.header {
      border-radius: 10px 10px 0px 0px;
      background: #012c23;
      height: 48px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 13px 0 23px;

      &>div:first-child {
        color: #ffffff;
        font-size: 18px;
        font-weight: 500;
      }

      &>img:first-of-type {
        width: 70px;
        height: 48px;
      }

      &>img:last-of-type {
        width: 20px;
        height: 20px;
      }
    }

    &>.title {
      margin-top: 15px;
      color: #ffffff;
      font-size: 12px;
      font-weight: 500;
    }

    &>.package-list {
      margin: 0 30px;
      display: flex;
      flex-direction: column;

      &>.item {
        margin-top: 16px;
        display: flex;
        justify-content: space-around;
        align-items: center;

        &>.img-box {
          display: flex;
          justify-content: center;
          width: 45px;

          &>img {
            width: 45px;
            height: auto;
          }

          &>.coins {
            width: 25px;
            height: auto;
          }
        }

        &>div:nth-child(2) {
          margin-left: 20px;
          flex: 1;
          text-align: left;
          color: #ffffff;
          font-size: 10px;
          font-weight: 500;
        }

        &>.count {
          text-align: right;
          color: #ffffff;
          font-size: 16px;
          font-weight: bold;
        }

        &>.money-green {
          color: #21b300;
          text-align: right;
          font-size: 16px;
          font-weight: bold;
        }

        &>.money-yellow {
          color: #ffd400;
          text-align: right;
          font-size: 16px;
          font-weight: bold;
        }
      }
    }

    &>.money {
      margin-top: 15px;
      color: #21b300;
      font-size: 21px;
      font-weight: bold;
    }

    &>.des {
      margin: 25px 80px 0;
      color: #54e6b4;
      font-size: 11px;
      font-weight: 500;
      margin-bottom: 12px;
    }

    &>.btn {
      margin: 5px 20px 20px;
      background: #029479;
      height: 40px;
      line-height: 40px;
      border-radius: 10px;
      font-size: 15px;
      font-weight: 500;
      color: #d8d8d8;
    }
  }
}
</style>
