<template>
  <ModalPopupComp :contextWidth="computedMaxWidthValue">
    <div class="box">
      <iframe ref="myIframe" :src="payUrl" width="100%" height="100%" scrolling="auto" frameborder="0"></iframe>
    </div>
  </ModalPopupComp>
</template>
<script>
import ModalPopupComp from "@/components/ModalPopupComp.vue";

export default {
  props: {
    payUrl: String,
  },
  components: { ModalPopupComp },
};
</script>
<style lang="scss" scoped>
.box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 700px;

  iframe {
    width: 100%;
  }
}

// @media (max-width: 768px) {
//   .box {
//     height: 100vh;
//   }
//   .box iframe {
//     margin-top: 50px;
//     margin-bottom: var(--tab-bar-height);
//   }
// }
</style>
