<template>
  <div class="invite">
    <div class="wrapper">
      <category-comp class="cate" :list="cateList" v-model:index="index"></category-comp>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import CategoryComp from "@/components/CategoryComp.vue";
export default {
  components: { CategoryComp },
  props: {
    title: {
      type: String,
      default: "",
    },
  },
  emits: ["update:title"],
  data() {
    return {
      index: 0,
      cateList: [
        { title: this.$t("commissions"), path: "commission" },
        { title: this.$t("team"), path: "team" },
        { title: this.$t("records"), path: "log" },
      ],
    };
  },
  mounted() {
    this.$emit("update:title", this.$t("my_invitations"));
    setTimeout(() => {
      this.$eventBus.emit("resetTabbarIndex", 2);
    }, 0);
    this.$tools.newerActivityFinish({ view_invite_finish: 1 });
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.invite {
  display: flex;
  flex-direction: column;
  width: 100%;

  .wrapper {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    margin: 0 15px;

    .cate {
      margin-top: 20px;
      margin-bottom: 15px;
    }
  }
}
</style>
