<template>
  <div class="chc-container" :style="showPc ? { padding: '10px 30px' } : {}" v-if="list.length > 0">
    <div class="lefticon common" v-if="showPc" @click="scrollRow('left')">
      <img src="@/assets/jiantou.webp" alt="" />
    </div>
    <div class="list">
      <div class="item" :class="i === index ? 'higlight' : 'normal'" v-for="(item, i) in list" :key="i" @click="handleOfItem(i)">
        <img :src="showIcon(item, i)" alt="" />
        <div>{{ item.catagory_name }}</div>
      </div>
    </div>
    <div class="righticon common" v-if="showPc" @click="scrollRow('right')">
      <img src="@/assets/jiantou.webp" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showPc: false,
    };
  },
  props: {
    index: {
      type: Number,
      default: 0,
    },
    list: {
      type: Array,
      default: () => [],
    },
  },
  emits: ["update:index"],
  methods: {
    scrollRow(direction) {
      const container = this.$el.querySelector(".list"); // 获取滑动区域的 DOM 元素
      const scrollAmount = 100; // 每次滚动的距离，根据需要调整

      if (direction === "left") {
        container.scrollBy({
          left: -scrollAmount,
          behavior: "smooth", // 启用平滑滚动
        });
      } else if (direction === "right") {
        container.scrollBy({
          left: scrollAmount,
          behavior: "smooth", // 启用平滑滚动
        });
      }
    },
    isPC() {
      // 获取用户代理字符串
      var userAgent = navigator.userAgent || navigator.vendor || window.opera;

      // 检查是否包含常见的移动设备标识
      var isMobile = /iPad|iPhone|iPod|Android|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);

      // 如果不包含移动设备标识，则认为是PC端
      return !isMobile;
    },
    showIcon(item, i) {
      let iconUrl = "";
      if (i === this.index) {
        iconUrl = item.icon_select;
      } else {
        iconUrl = item.category_icon;
      }

      if (iconUrl?.includes("data:image")) {
        return iconUrl;
      } else {
        return this.$global.data.cdn_url + iconUrl;
      }
    },
    handleOfItem(i) {
      this.$emit("update:index", i);
      this.$emit("changeEvent", i);
    },
  },
  mounted() {
    if (this.isPC()) {
      this.showPc = true;
    } else {
      this.showPc = false;
    }
  },
};
</script>

<style lang="scss" scoped>
.chc-container {
  background: #015545;
  padding: 10px 0 10px 15px;
  display: flex;
  flex-direction: column;

  .list {
    display: flex;
    overflow-x: scroll;

    .item {
      margin-right: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      min-width:60px;
      height: 15px;
      border-radius: 5px;
      padding: 10px;

      img {
        width: 14px;
        height: 14px;
        margin-right: 6px;
      }

      & > div {
        text-wrap: nowrap;
        color: #ffffff;
        font-size: 12px;
      }
    }

    .higlight {
      background: #ffd400;

      & > div {
        color: #062017;
        font-weight: bold;
      }
    }

    .normal {
      background: #006a56;

      & > div {
        color: #c8e8db;
        font-weight: normal;
      }
    }
  }
}

.common {
  position: absolute;
  top: 50%;
  width: 20px;
  height: 34px;
  background: #006a56;
  border-radius: 4px;
  color: #fff;
  margin-top: -17px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.lefticon {
  left: 4px;

  img {
    width: 50%;
    transform: rotate(90deg);
  }
}

.righticon {
  right: 4px;

  img {
    width: 50%;
    transform: rotate(-90deg);
  }
}
</style>
