<template>
  <div class="secure">
    <div class="wrapper" v-if="showDom">
      <div class="yes" v-if="userInfo?.pay_out_pwd_set">
        <div class="card">
          <img  src="@/assets/account/success_anquan.webp" alt="">
          <span class="success">
            {{ $t('withdrawal_security') }}
            <br />
            {{ $t('protection') }}
          </span>
        </div>
        <div class="btn anquanbtn" @click="handleOfModifyPW">{{ $t('change_withdrawal_password') }}</div>
        <div class="zhuyi">
          {{ $t('attention_text') }}
        </div>
      </div>
      <div class="no" v-else>
        <div class="card">
          <img src="@/assets/account/secure_icon.webp" alt="">
          <span>
            {{ $t('set_withdrawal_password') }}
            <br />
            {{ $t('must_bind_mobile_phone') }}
          </span>
        </div>
        <div class="btn" @click="handleOfSetPW">{{ $t('bind_phone') }}</div>
      </div>
    </div>
    <dialog ref="verifyDialog">
      <VerifyDialogForPhoneOrEmail :title="dialogTitle" :verifyType="2" :cpf="true" :phone="userInfo?.phone"
        @closeEvent="handleOfVerifyDialogClose" @endVerifyEvent="
          $router.push({ name: 'set-password', query: { type: this.userInfo.pay_out_pwd_set ? '1' : '0' } })
          "></VerifyDialogForPhoneOrEmail>
    </dialog>
  </div>
</template>

<script>
import VerifyDialogForPhoneOrEmail from "@/components/VerifyDialogForPhoneOrEmail.vue";
export default {
  components: {
    VerifyDialogForPhoneOrEmail,
  },
  data() {
    return {
      userInfo: null,
      dialogTitle: "",
      showDom:false
    };
  },
  mounted() {
    this.getUserInfo();
  },
  methods: {
    handleOfVerifyDialogClose() {
      this.$refs.verifyDialog.close();
      this.userInfo = null;
      this.getUserInfo();
    },
    handleOfSetPW() {
      this.dialogTitle = this.$t('set_pwd_text');
      this.$refs.verifyDialog.showModal();
    },
    handleOfModifyPW() {
      this.dialogTitle = this.$t('verify_identity');
      this.$refs.verifyDialog.showModal();
    },
    getUserInfo() {
      const data = {
        action: "my_account",
      };
      this.$http
        .post("userCenter", data)
        .then((response) => {
          this.userInfo = response.data.data;
          this.showDom = true
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.secure {
  display: flex;

  .wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;

    .no {
      width: 100%;
      display: flex;
      flex-direction: column;

      
    }
    .card {
        background: url("@/assets/account/card_bg.webp");
        width: 230px;
        height: 140px;
        border-radius: 10px;
        margin: auto;
        margin-top: 75px;
        background-size: 100% 100%;
        position: relative;
        padding-bottom: 25px;
        box-sizing: border-box;

        img {
          height: 100px;
          position: absolute;
          top: -35%;
          left: 31%;
        }
        .success{
          color: #00EB52;
        }

        span {
          color: #fff;
          font-size: 12px;
          display: flex;
          justify-content: center;
          align-items: flex-end;
          height: 100%;
          font-weight: bold;
          text-align: center;
        }
      }
      .btn{
        width: 80%;
        margin: auto;
        height: 40px;
        color: #06131C;
        background: #FFD400;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: Alibaba PuHuiTi 2.0;
        font-size: 18px;
        margin-top: 60px;
      }
      .anquanbtn{
        background: #10B349;
        color: #ffffff;
      }
      .zhuyi{
        font-size: 12px;
        color: #81D6BA;
        padding: 24px 30px;
      }
    .yes {
      display: flex;
      flex-direction: column;
      text-align: left;

      &>.tips-box {
        margin-top: 10px;
        background: #01b740;
        height: 45px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        padding: 0 13px;

        &>img {
          width: 20px;
          height: 20px;
        }

        &>div {
          margin-right: 20px;
          text-align: center;
          flex: 1;
          color: #ffffff;
          font-size: 12px;
          font-weight: 500;
        }
      }

      .button {
        margin-top: 26px;
        margin-left: auto;
        color: #96a7c6;
        font-size: 12px;
        font-weight: 500;
        text-decoration: underline;
      }

      .mind {
        margin-top: 80px;
        color: #96a7c6;
        font-size: 12px;
        font-weight: 500;
      }
    }
  }
}
</style>
