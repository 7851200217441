<template>
  <div class="team">
    <div class="wrapper">
      <div class="param1">
        <div class="box">
          <div>{{ $t('team_des1') }}</div>
          <div>&nbsp;{{ teamInfo.total_number_invite||0 }}</div>
        </div>
        <div class="box">
          <div>{{ $t('team_des2') }}</div>
          <div>&nbsp;{{ teamInfo.total_number_first_pay||0 }}</div>
        </div>
        <div class="box">
          <div>{{ $t('team_des3') }}</div>
          <div>&nbsp;{{ teamInfo.total_amount_first_pay||0 }}</div>
        </div>
      </div>

      <div class="data">
        <div class="level">
          <div class="title">{{ $t("team_des4") }}</div>
          <div class="value">
            <div>
              <span>{{ $t("team_des5") }}</span><span>{{ teamInfo.total_number_invite_level1 ||0}}</span>
            </div>
            <div>
              <span>{{ $t("team_des6") }}</span><span>{{ teamInfo.total_number_first_pay_level1 ||0}}</span>
            </div>
            <div>
              <span>{{ $t("team_des7") }}</span><span>{{ teamInfo.total_amount_first_pay_level1||0 }}</span>
            </div>
            <div>
              <span>{{ $t("team_des8") }}</span><span>{{ teamInfo.total_salary_code_level1||0 }}</span>
            </div>
          </div>
        </div>
        <div class="level">
          <div class="title">{{ $t("team_des9") }}</div>
          <div class="value">
            <div>
              <span>{{ $t("team_des10") }}</span><span>{{ teamInfo.total_number_invite_level2||0 }}</span>
            </div>
            <div>
              <span>{{ $t("team_des11") }}</span><span>{{ teamInfo.total_number_first_pay_level2||0 }}</span>
            </div>
            <div>
              <span>{{ $t("team_des12") }}</span><span>{{ teamInfo.total_amount_first_pay_level2||0 }}</span>
            </div>
            <div>
              <span>{{ $t("team_des13") }}</span><span>{{ teamInfo.total_salary_code_level2||0 }}</span>
            </div>
          </div>
        </div>
        <div class="level">
          <div class="title">{{ $t("team_des14") }}</div>
          <div class="value">
            <div>
              <span>{{ $t("team_des15") }}</span><span>{{ teamInfo.total_number_invite_level3||0 }}</span>
            </div>
            <div>
              <span>{{ $t("team_des16") }}</span><span>{{ teamInfo.total_number_first_pay_level3||0 }}</span>
            </div>
            <div>
              <span>{{ $t("team_des17") }}</span><span>{{ teamInfo.total_amount_first_pay_level3||0 }}</span>
            </div>
            <div>
              <span>{{ $t("team_des18") }}</span><span>{{ teamInfo.total_salary_code_level3||0 }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      teamInfo: {},
    };
  },
  mounted() {
    this.fetchTeamData();
  },
  methods: {
    fetchTeamData() {
      const data = {
        action: "agent_item_info",
        data: {},
      };
      this.$http
        .post("agentCenter", data)
        .then((response) => {
          this.teamInfo = response.data.direct_info;
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.team {
  display: flex;

  .wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;

    .param1 {
      padding: 9px 16px;
      background: #006A56;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 72px;

      .box {
        flex: 1;
        display: flex;
        justify-content: space-between;

        div {
          color: rgba($color: #fff, $alpha: 1);
          font-size: 12px;
          font-weight: bold;
        }

        div:last-of-type {
          color: #ffffff;
          font-size: 12px;
          font-weight: bold;
        }
      }
    }

    .data {
      margin-top: 20px;
      display: flex;
      flex-direction: column;

      .level {
        display: flex;
        flex-direction: column;
        background: #006A56;
        border-radius: 8px;
        margin-bottom: 20px;
        padding: 3px 16px;

        .title {
          margin-top: 10px;
          margin-bottom: 10px;
          font-size: 14px;
          font-weight: bold;
          color: #ffffff;
          text-align: left;
        }
        .value {
          display: flex;
          flex-direction: column;

          div {
            margin-bottom: 10px;
            display: flex;
            justify-content: space-between;

            & > span:nth-child(1) {
              color: #81D6BA;
              font-size: 12px;
              font-weight: bold;
            }

            & > span:nth-child(2) {
              color: #ffffff;
              font-size: 12px;
              font-weight: bold;
            }
          }
        }
      }
    }
  }
}
</style>
