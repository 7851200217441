<template>
  <div class="hongbaoyubg">
    <div class="banner">
      <img src="@/assets/redbaoyu/hongbaoyubg.webp" alt="">
    </div>
    <div class="qiwu">
      <div class="title">{{ $t('red_des1') }}</div>
      <div class="bar">
        <div class="l">
          <div class="t">
            <div><span>{{ info.done_amount || 0 }}</span>/{{ info.target_amount || 0 }}</div>
            <div>{{ (info.done_amount / info.target_amount * 100) || 0 }}%</div>
          </div>
          <div class="b">
            <span :style="{ width: (info.done_amount / info.target_amount * 100) + '%', maxWidth: '100%' }"></span>
          </div>
        </div>
        <div class="r" v-if="(((info.done_amount / info.target_amount) || 0) * 100) < 100"
          @click="$router.push('/m-deposit-page')">{{ $t('deposit') }}</div>
        <div class="r succ" v-else>{{ $t('concluído') }}</div>
      </div>
    </div>
    <!-- 第一部分：Período regular (diário) -->
    <div class="timeLine">
      <div class="h1">{{ $t('red_des2') }}</div>
      <div class="table">
        <div class="tbody">
          <span>{{ $t('red_des3') }}</span>
          <span>{{ $t('red_des4') }}</span>
          <span class="right">{{ $t('red_des5') }}</span>
        </div>
        <div v-for="(item) in regularTasks" :key="item.task_id" class="tbody tr">
          <span>{{ item.get_deadline_from_time }}~{{ item.get_deadline_to_time }}</span>
          <!-- 当日无活动 -->
          <span class="fff" v-if="item.status == -2">{{ $t('there_are_no_activities_today') }}</span>
          <!-- 未开始 -->
          <span class="fff" v-if="item.status == -1">{{ $t('not_started_yet') }}</span>
          <!-- 活动进行中，任务未完成，不可领取 -->
          <span class="fff" v-if="item.status == 0">{{ $t('have_in_hand') }}</span>
          <!-- 活动进行中，任务已完成，可领取 -->
          <span class="fff" v-if="item.status == 1">{{ $t('ended') }}</span>
          <!-- 已领取 -->
          <span class="fff" v-if="item.status == 2">{{ $t('recebido') }}</span>
          <!-- 已结束 -->
          <span class="fff" v-if="item.status == 3">{{ $t('ended') }}</span>
          <!-- 按钮区域 -->
          <!-- 已领取 -->
          <span class="not" v-if="item.status == 2">
            <i class="gre">{{ $t('recebido') }}</i>
          </span>
          <!-- 超时未领取 -->
          <span class="not" v-if="item.status == 3">
            <i>{{ $t('intervalo') }}</i>
          </span>
          <!-- 不可领取 ==0 -->
          <span class="not" v-if="item.status == 0">
            <i class="btns">{{ $t('receber') }}</i>
          </span>
          <!-- 可领取 ==1 -->
          <span class="not" v-if="item.status == 1">
            <i @click="handleClick(item)" class="btns success">{{ $t('receber') }}</i>
          </span>
          <!-- 不可领取 -->
          <span class="not" v-if="item.status == -1">
            <i class="btns">{{ $t('receber') }}</i>
          </span>
        </div>
      </div>
    </div>

    <!-- 第二部分：Período extra (todos os sábados, domingos e dia 7 de cada mês) -->
    <div class="timeLine">
      <div class="h1">{{ $t('red_des6') }}</div>
      <div class="table">
        <div class="tbody">
          <span>{{ $t('red_des3') }}</span>
          <span>{{ $t('red_des4') }}</span>
          <span class="right">{{ $t('red_des5') }}</span>
        </div>
        <div v-for="(item) in extraTasks" :key="item.task_id" class="tbody tr">
          <span>{{ item.get_deadline_from_time }}~{{ item.get_deadline_to_time }}</span>
          <!-- 当日无活动 -->
          <span class="fff" v-if="item.status == -2">{{ $t('there_are_no_activities_today') }}</span>
          <!-- 未开始 -->
          <span class="fff" v-if="item.status == -1">{{ $t('not_started_yet') }}</span>
          <!-- 活动进行中，任务未完成，不可领取 -->
          <span class="fff" v-if="item.status == 0">{{ $t('have_in_hand') }}</span>
          <!-- 活动进行中，任务已完成，可领取 -->
          <span class="fff" v-if="item.status == 1">{{ $t('ended') }}</span>
          <!-- 已领取 -->
          <span class="fff" v-if="item.status == 2">{{ $t('recebido') }}</span>
          <!-- 已结束 -->
          <span class="fff" v-if="item.status == 3">{{ $t('ended') }}</span>
          <!-- 按钮区域 -->
          <!-- 已领取 -->
          <span class="not" v-if="item.status == 2">
            <i class="gre">{{ $t('recebido') }}</i>
          </span>
          <!-- 超时未领取 -->
          <span class="not" v-if="item.status == 3">
            <i>{{ $t('intervalo') }}</i>
          </span>
          <!-- 不可领取 ==0 -->
          <span class="not" v-if="item.status == 0">
            <i class="btns">{{ $t('receber') }}</i>
          </span>
          <!-- 可领取 ==1 -->
          <span class="not" v-if="item.status == 1">
            <i @click="handleClick(item)" class="btns success">{{ $t('receber') }}</i>
          </span>
          <!-- 不可领取 -->
          <span class="not" v-if="item.status == -1">
            <i class="btns">{{ $t('receber') }}</i>
          </span>
        </div>
      </div>
    </div>
    <div class="guize">
      <div class="h1">{{ $t('activity_rules') }}</div>
      <pre>
{{ $t('rules_des1') }}
{{ $t('rules_des2') }}
{{ $t('rules_des3') }}
{{ $t('rules_des4') }}
{{ $t('rules_des5') }}
{{ $t('rules_des6') }}
      </pre>
    </div>
    <dialog ref="taskDialog">
      <div class="task-dialog-wrapper">
        <div class="header">
          <div>{{ $t('prompt') }}</div>
          <img src="@/assets/daole.webp" alt="" />
          <img src="@/assets/account/close.webp" alt="" @click="handleOk()" />
        </div>
        <div class="sucesso">{{ $t('first_deposit_des24') }}</div>
        <div class="addPrice">+{{ $tools.formatCurrency(amount) }}</div>
        <div class="desc">
          {{ $t('daily_lottery_draw_des10') }}
        </div>
        <div class="btns" @click="handleOk()">{{ $t('bom') }}</div>
      </div>
    </dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      info: {},
      tasks: [],
      amount: 0
    }
  },
  mounted() {
    this.$emit("update:title", this.$t('red_envelope_rain'));
    this.getInfo()

  },
  computed: {
    // 分离出 regular 和 extra 的任务
    regularTasks() {
      return this.tasks.filter(task => task.get_deadline_order <= 3);
    },
    extraTasks() {
      return this.tasks.filter(task => task.get_deadline_order > 3);
    },
  },
  methods: {
    handleOk() {
      this.$refs.taskDialog.close()
      this.getInfo()
    },
    async handleClick(item) {
      const data = {
        action: "receive_red_packet_rain",
        cmd: "activity",
        data: {
          type: 3,
          task_id: item.task_id
        },
      };
      try {
        const response = await this.$http.post("/gateway", data);
        this.amount = response.data.amount;
        this.$refs.taskDialog.showModal();
      } catch (error) {
        console.log("error", error);
      }
    },
    async getInfo() {
      const data = {
        action: "red_packet_rain_info",
        cmd: "activity",
        data: {},
      };
      try {
        const response = await this.$http.post("/gateway", data);
        console.log(response);
        this.info = response.data.red_packet_rain_info
        this.tasks = response.data.red_packet_rain_info.task_list.sort((a, b) => a.get_deadline_order - b.get_deadline_order);
      } catch (error) {
        console.log("error", error);
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.hongbaoyubg {
  padding: 15px;
  box-sizing: border-box;
  color: #fff;

  .banner {
    width: 100%;
    height: 156px;

    img {
      width: 100%;
      // height: 100%;
    }
  }

  .qiwu {
    margin-top: 10px;
    width: 100%;
    height: 75px;
    background: #004033;
    border-radius: 10px;
    font-size: 11px;
    text-align: left;
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .title {
      color: #fff;
      font-weight: 500;
      margin-bottom: 9px;
    }

    .bar {
      display: flex;
      align-items: flex-end;
      color: #81D6BA;
      font-weight: 500;

      .l {
        flex: 1;

        .t {
          display: flex;
          font-size: 10px;
          justify-content: space-between;
          margin-bottom: 1px;
          padding: 0 2px;
          box-sizing: border-box;

          span {
            color: #fff;
          }
        }

        .b {
          width: 100%;
          height: 6px;
          border-radius: 10px;
          background: #015444;

          span {
            background: #00CB47;
            width: 0%;
            height: 100%;
            display: flex;
            border-radius: 10px;
          }
        }
      }

      .r {
        width: 70px;
        height: 34px;
        border-radius: 8px;
        background: #FFE600;
        font-size: 12px;
        font-weight: bold;
        color: #0B0B0B;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 17px;
      }

      .succ {
        background: #00CB47;
      }
    }
  }

  .timeLine {
    text-align: left;
    margin-top: 10px;
    color: #00CDA6;
    font-size: 11px;

    .h1 {
      font-size: 12px;
      font-weight: 500;
      color: #fff;
      margin-bottom: 8px;
    }

    .table {
      .tbody {
        display: flex;
        padding: 0 0 0 12px;
        margin-bottom: 8px;

        .right {
          display: flex;
          justify-content: flex-end;
          padding-right: 6px;
        }

        span {
          flex: 1;
          white-space: nowrap;
          display: flex;

          &:first-child {
            flex: none;
            width: 80px;
          }
        }
      }

      .fff {
        color: #fff;
      }

      .not {
        color: #A5A5A5;
        display: flex;
        justify-content: flex-end;
        padding-right: 12px;

        i {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 80px;
          height: 30px;
          border-radius: 8px;
          font-style: normal;
          font-weight: 500;
        }

        i.btns {
          background: #676767;
        }

        i.success {
          background: #FFE600;
          color: #0B0B0B;
        }

        i.gre {
          color: #00CB47;
        }
      }

      .tr {
        height: 40px;
        border-radius: 5px;
        border: 1px solid #006A56;
        display: flex;
        align-items: center;
        padding: 0 0 0 12px;
        margin-bottom: 5px;
      }
    }
  }

  .guize {
    text-align: left;
    font-size: 12px;
    font-weight: bold;
    color: #fff;
    margin-top: 10px;

    pre {
      font-size: 10px;
      color: #81D6BA;
      font-weight: normal;
      white-space: pre-wrap;
      line-height: 20px;
    }
  }

  .task-dialog-wrapper {
    display: flex;
    flex-direction: column;
    width: 320px;
    height: 247px;
    background: #015545;
    border-radius: 10px;

    &>.header {
      border-radius: 10px 10px 0px 0px;
      background: #012c23;
      height: 48px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 13px 0 23px;

      &>div:first-child {
        color: #81d6ba;
        font-size: 12px;
        font-weight: 500;
      }

      &>img:first-of-type {
        width: 70px;
        height: 48px;
      }

      &>img:last-of-type {
        width: 20px;
        height: 20px;
      }
    }

    .sucesso {
      text-align: center;
      font-size: 12px;
      font-weight: bold;
      color: #FFFFFF;
      margin: 15px 0;
    }

    .addPrice {
      text-align: center;
      font-size: 21px;
      color: #00E550;
      font-weight: bold;
    }

    .desc {
      color: #54E6B4;
      font-size: 11px;
      text-align: center;
      padding: 0 55px;
      font-weight: 500;
      margin-top: 20px;
    }

    .btns {
      width: 90%;
      margin: auto;
      height: 40px;
      background: #0085A9;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 15px;
      color: #D8D8D8;
      font-weight: 500;
    }
  }
}
</style>