<template>
  <div class="container">
    <img :src="$global.data.cdn_url + info?.daily_activity_info?.image_url" alt="" />
    <div class="des1">Recompensas de depósitos diários Só uma oportunidade por dia</div>
    <div class="list">
      <div class="header">
        <div>Montante de depósito</div>
        <div>Montante de recompensa</div>
      </div>
      <div class="cell" v-for="(item, index) in info?.pay_amount_list" :key="index">
        <div>R$ {{ $tools.formatCurrency(item.amount) }}</div>
        <div>{{ item.give_coins }}</div>
      </div>
    </div>
    <div class="btn" :class="info?.daily_activity_info?.activity_status === 2 ? 'disable' : 'enable'" @click="handleOfBtn">
      {{ btnTitle }}
    </div>
    <div class="des2">
      <div class="title">Condições de participação</div>
      <div class="content">
        1. Os usuários recentemente registados não são capazes de participar em atividades no mesmo dia (os novos usuários registados têm atividades exclusivas
        de depósito de primeira vez);<br />
        2. Cada usuário só pode participar nessa atividade uma vez por dia; permanentes;
      </div>
      <div class="title">Regras de atividade</div>
      <div class="content">
        1.Quanto mais depósitos acumulados ontem, maior é o valor de recompensa dos depósitos hoje;<br />
        2. Recompensar aleatoriamente o montante máximo de R $8888<br />
        3. Multiplas contas com o mesmo IP participando em atividades são consideradas inválidas. Deduções de bónus e proibições
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      info: null,
    };
  },
  computed: {
    btnTitle() {
      if (this.info === null) {
        return "";
      } else if (this.info?.daily_activity_info?.activity_status === 0) {
        return "Participar em atividades";
      } else if (this.info?.daily_activity_info?.activity_status === 1) {
        return "Participar em atividades";
      } else if (this.info?.daily_activity_info?.activity_status === 2) {
        return "Concluído";
      }

      return "";
    },
  },
  mounted() {
    this.$emit("update:title", "Diariamente");
    this.dailyActivityInfo();
  },
  methods: {
    handleOfBtn() {
      if (this.info?.daily_activity_info?.activity_status !== 2) {
        this.$router.push({ path: "/first-deposit-page/" + this.$route.params.id });
      }
    },
    async dailyActivityInfo() {
      const aId = this.$route.params.id;
      const data = {
        action: "daily_activity_info",
        data: {
          daily_activity_id: parseInt(aId),
        },
      };
      try {
        const response = await this.$http.post("activity", data);
        console.log("response", response);
        this.info = response.data;
      } catch (error) {
        console.log("error", error);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 15px;

  img {
    border-radius: 10px;
    width: 100%;
    height: auto;
  }

  .des1 {
    margin-top: 15px;
    font-size: 12px;
    font-weight: 500;
    color: #ffd400;
    width: 200px;
  }

  .list {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    background: #0a1f32;
    border-radius: 10px;
    width: 100%;
    font-size: 12px;
    font-weight: 500;
    color: #dadada;
    padding: 10px 0;

    .header {
      display: flex;
      justify-content: space-around;
      margin-bottom: 13px;
    }

    .cell {
      display: flex;
      justify-content: space-around;
      margin-bottom: 4px;
    }
  }

  .btn {
    background: #33439e;
    height: 45px;
    border-radius: 8px;
    color: #ffffff;
    font-size: 18px;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    width: 100%;
  }

  .disable {
    background: #0a1f32;
  }

  .enable {
    background: #33439e;
  }

  .des2 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 30px;

    .title {
      margin-top: 20px;
      color: #dadada;
    }

    .content {
      color: #3e7bb0;
      text-align: left;
    }
  }
}
</style>
