<template>
  <div class="wrapper">
    <!-- <img class="cs" src="@/assets/mine-cs.png" alt="" /> -->
    <div class="kf">
      <div class="kf-l">
        <img src="@/assets/kf.webp" alt="" />
      </div>
      <div class="kf-r">
        <div class="t">{{ $t('service_des1') }}</div>
        <div class="des">{{ $t('service_des2') }}</div>
      </div>
    </div>
    <div class="cell" v-for="(item, index) in linkList" :key="index">
      <img :src="$global.data.cdn_url + item.customer_service_icon" alt="" />
      <div class="content">{{ item.customer_service_title }}</div>
      <div class="ext-btn" @click="handleOfLink(index)">{{ $t('contact') }}</div>
    </div>
    <!-- <div class="btn" @click="contactHandle">Contate-nos</div> -->
    <!-- <div v-if="liveChatVisibility !== 'hidden'">
      <LiveChatWidget
        license="16897962"
        :visibility="liveChatVisibility"
        @ready="handleReady"
        @new-event="handleNewEvent"
        @availability-changed="handleAvailabilityChanged"
        @customer-status-changed="handleCustomerStatusChanged"
      ></LiveChatWidget>
    </div> -->
  </div>
</template>

<script>
// import { LiveChatWidget } from "@livechat/widget-vue";
export default {
  components: {
    // LiveChatWidget,
  },
  data() {
    return {
      // liveChatVisibility: "hidden",
      linkList: [],
    };
  },
  mounted() {
    this.$emit("update:title", this.$t("customer_service02"));
    this.fetchData();
  },
  methods: {
    fetchData() {
      const data = {
        action: "customer_service",
        data: {},
      };
      this.$http
        .post("userCenter", data)
        .then((response) => {
          this.linkList = response.data.customer_service;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    handleOfLink(index) {
      const item = this.linkList[index];
      if (this.$tools.isiOSDevice()) {
        window.open(item.customer_service_url, "_self");
      } else {
        window.open(item.customer_service_url, "_blank");
      }
    },
    // contactHandle() {
    //   // 打开https://t.me/Betmace
    //   // window.open("https://t.me/Betmace", "_blank");
    //   this.liveChatVisibility = "maximized";
    //   this.$load.show("loading...");
    // },
    handleReady() {
      console.log("LiveChatWidget.onReady");
      this.$load.hide();
    },
    handleNewEvent(event) {
      console.log("LiveChatWidget.onNewEvent", event);
    },
    handleAvailabilityChanged(event) {
      console.log("LiveChatWidget.onAvailabilityChanged", event);
    },
    handleCustomerStatusChanged(event) {
      console.log("LiveChatWidget.onCustomerStatusChanged", event);
      if (event.status === "browsing") {
        this.liveChatVisibility = "hidden";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  .cs {
    margin-top: 22px;
    width: 90%;
    height: auto;
  }

  .kf {
    background-color: #b9bfd2;
    width: 90%;
    height: 64px;
    border-radius: 10px;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    .kf-l {
      margin: 12px;
    }

    img {
      width: 32px;
      height: 39px;
    }

    .kf-r {
      color: #1b1b1b;
      text-align: left;

      .t {
        font-size: 14px;
        font-weight: bold;
      }

      .des {
        font-size: 10px;
        font-weight: 500;
        padding-right: 12px;
      }
    }
  }

  .cell {
    width: 90%;
    margin: 14px 0;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background: #004134;
    border-radius: 10px;
    height: 60px;

    &>img {
      margin-left: 10px;
      width: 30px;
      height: 30px;
    }

    &>.content {
      flex: 1;
      text-align: left;
      margin-left: 15px;
      color: #ffffff;
      font-size: 12px;
      font-weight: 500;
    }

    .ext-btn {
      margin-right: 10px;
      background: #029479;
      width: 80px;
      height: 28px;
      color: var(--color-btn-text);
      font-size: 14px;
      font-weight: 500;
      border-radius: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .btn {
    margin-top: 56px;
    width: 275px;
    height: 40px;
    background: var(--color-btn-bg);
    font-size: 14px;
    font-weight: bold;
    border-radius: 8px;
    color: var(--color-btn-text);
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
