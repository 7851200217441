<template>
  <div class="context" @click="onTapGameCard">
    <div class="card">
      <img v-if="!isImageLoading" :src="imgUrl" alt="" @load="onImageLoad" @error="onImageError" />
      <div v-else class="placeholder">
        <img src="@/assets/game_default.webp" />
        <div class="animate"></div>
      </div>
      <img
        class="tag"
        v-if="game?.is_hot === 1 || game?.is_new === 1"
        :src="game?.is_hot === 1 ? require('@/assets/common/hot_tag.webp') : require('@/assets/common/new_tag.webp')"
        alt=""
      />
      <div class="maintenance" v-if="game?.status === 2">
        <img src="@/assets/common/maintenance_icon.webp" alt="" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    game: null,
  },
  data() {
    return {
      isImageLoading: false,
    };
  },
  mounted() {
    this.isImageLoading = true;
  },
  methods: {
    onImageLoad() {
      setTimeout(() => {
        this.isImageLoading = false;
      }, 500);
    },
    onImageError() {
      this.isImageLoading = false;
    },
    onTapGameCard() {
      this.$tools.userTrackReport({ track_id: "14", track_link_id: this.game.game_id });
      if (this.$store.getters.isLoggedIn) {
        this.$router.push({
          path: "/m-game-details/" + this.game.id,
        });
      } else {
        if (window.location.href.indexOf("casino") > -1) {
          this.$eventBus.emit("isNeedLogin");
        } else {
          this.$router.push({
            name: "casino",
          });
          setTimeout(() => {
            this.$eventBus.emit("isNeedLogin");
          }, 100);
        }
      }
    },
  },
  computed: {
    imgUrl() {
      return this.$global.data.cdn_url + this.game.image_url;
    },
  },
};
</script>
<style lang="scss" scoped>
@keyframes lazy-loading {
  0% {
    background-position-x: 150%;
  }
  100% {
    background-position-x: -150%;
  }
}
.context {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  // .active {
  //   transform: translateY(-10px);
  // }

  // .card:active {
  //   transform: scale(0.97);
  // }

  .card {
    width: 100%;
    height: 100%;
    transition: transform 0.5s;
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    & > img {
      width: 100%;
      height: 100%;
    }

    .placeholder {
      width: 100%;
      height: 100%;
      background: url("@/assets/game_default.webp");
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;

      & > img {
        width: 100%;
        height: 100%;
      }

      .animate {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: url("@/assets/game_default2.png") transparent no-repeat;
        background-size: 200%;
        background-position-y: 40%;
        animation: lazy-loading 1.2s linear infinite;
      }
    }

    & > .tag {
      position: absolute;
      left: 5px;
      top: 5px;
      width: 20px;
      height: 20px;
    }

    & > .maintenance {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #031810;
      opacity: 0.8;
      display: flex;
      justify-content: center;
      align-items: center;

      & > img {
        width: 30px;
        height: 30px;
      }
    }
  }
}
</style>
