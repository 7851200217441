<template>
  <div class="container">
    <div class="task">
      <div class="title">
        <div>O desafio de hoje</div>
        <div>Classificação de hoje</div>
      </div>
      <div class="info">
        <div>R$:{{ dailyDetail?.daily_activity_info?.daily_activity_reward_fix_amount }}</div>
        <div>
          {{ dailyDetail?.user_game_rank?.rank === null ? "Tarefa não concluída" : dailyDetail?.user_game_rank?.rank }}
        </div>
      </div>
    </div>
    <div class="challenge">
      <img class="info" src="@/assets/level-info.png" alt="" @click="handleOfShowRule" />
      <div class="title">Desafio de hoje | {{ dailyDetail?.daily_activity_day }}</div>
      <div class="games">
        <div class="game" v-for="(item, index) in dailyDetail?.today_game_list" :key="index">
          <img :src="$global.data.cdn_url + item.image_url" alt="" @click="handleOfGame(item)" />
        </div>
      </div>
      <div class="progres">
        <div class="text">
          <div>{{ dailyDetail?.daily_activity_info?.name }}</div>
          <div>
            {{ dailyDetail?.user_bet_count || 0 }} /
            {{ dailyDetail?.daily_activity_info?.daily_activity_condition }}
          </div>
        </div>
        <div class="progress-bg">
          <div class="progress-bar" :style="{ width: progress }"></div>
        </div>
      </div>
    </div>
    <div class="yesterday">
      <div class="rank">
        <div>classificação de ontem</div>
        <div>{{ dailyDetail?.yesterday_record?.reward_rank }}</div>
      </div>
      <div class="reward">
        <div>Bónus de ontem</div>
        <div>R${{ $tools.formatCurrency(dailyDetail?.yesterday_record?.reward_amount) }}</div>
      </div>
      <div class="state">
        <div class="fail" v-if="dailyDetail?.yesterday_record?.receive_state === -1">Não ganhou prêmio</div>
        <div class="success" v-else>
          <div>Contagem regressiva {{ countDownTimeStr }}</div>
          <div class="enalbe" v-if="dailyDetail?.yesterday_record?.receive_state === 0" @click="handleOfReward">Resgatar</div>
          <div class="disable" v-if="dailyDetail?.yesterday_record?.receive_state === 1">Já recebido</div>
        </div>
      </div>
    </div>
    <div class="today">
      <div>Classificações de hoje | {{ dailyDetail?.daily_activity_day }}</div>
      <div class="header">
        <div>Classificação</div>
        <div>ID</div>
        <div>Apostas Válidas</div>
      </div>
      <div class="self">
        <div class="none" v-if="dailyDetail?.user_game_rank?.rank === null">Completar tarefas e participar na classificação</div>
        <div class="some" v-else>
          <div>{{ dailyDetail?.user_game_rank?.rank.toString().padStart(2, "0") }}</div>
          <div>{{ dailyDetail?.user_game_rank?.userid }}</div>
          <div>{{ dailyDetail?.user_game_rank?.effective_bet_amount }}</div>
        </div>
      </div>
      <div class="list">
        <div class="cell" v-for="(item, index) in dailyDetail?.today_game_rank" :key="index">
          <div>{{ (index + 1).toString().padStart(2, "0") }}</div>
          <div>{{ item.userid }}</div>
          <div>{{ item.effective_bet_amount }}</div>
        </div>
      </div>
    </div>
    <dialog ref="rewardDialogTip">
      <CommonDialogTipContent
        des="O bônus foi depositado na sua carteira"
        :value="'R$:' + $tools.formatCurrency(reward_amount)"
        buttonTitle="Bem"
        @closeEvent="$refs.rewardDialogTip.close()"
      ></CommonDialogTipContent>
    </dialog>
    <dialog ref="ruleDialog">
      <div class="rule-dialog">
        <div class="rule-bg">
          <div class="rule-title">Regras de atividade</div>
          <div class="rule-des">
            1. Completar as tarefas de apostas para o jogo designado diário (≥
            {{ dailyDetail?.daily_activity_info?.daily_activity_reward_count }} vezes) e participar na distribuição diária de bónus;<br />
            2. Quanto maior a aposta efetiva, maior a quantidade de bónus；<br />
            3. Avançar nos 30 melhores pode ganhar recompensas correspondentes<br />
            4. O desafio diário termina às 24:00 no mesmo dia;<br />
            5. Os usuários participantes podem receber o bónus de ontem no dia seguinte;<br />
            6. A coleta de bónus só é válida no mesmo dia e não pode ser coletada se falhar;<br />
            7. Multiplas contas com o mesmo IP participando em atividades são consideradas inválidas. Deduções de bónus e proibições permanentes;<br />
            <br />
            O direito de interpretar a atividade pertence à plataforma
          </div>
        </div>
        <img src="@/assets/login/close_icon.png" alt="" @click="$refs.ruleDialog.close()" />
      </div>
    </dialog>
  </div>
</template>
<script>
import CommonDialogTipContent from "@/components/CommonDialogTipContent.vue";
export default {
  components: {
    CommonDialogTipContent,
  },
  data() {
    return {
      dailyDetail: null,
      // {
      //   today_game_rank: [
      //     // 今日挑战排行榜 (下标+1为排名)
      //     {
      //       effective_bet_amount: 32968.0, // 有效下注金额
      //       userid: "2P***QQ", // 用户id
      //     },
      //     {
      //       effective_bet_amount: 316.96,
      //       userid: "CU***QQ",
      //     },
      //     {
      //       effective_bet_amount: 171.2,
      //       userid: "NM***QQ",
      //     },
      //     {
      //       effective_bet_amount: 97.68,
      //       userid: "CM***QQ",
      //     },
      //     {
      //       effective_bet_amount: 29.6,
      //       userid: "CK***QQ",
      //     },
      //   ],
      //   user_game_rank: {
      //     // 当前用户今日挑战数据
      //     rank: 1, // 排名
      //     effective_bet_amount: 123123, // 有效下注
      //     userid: "WU9UQQQ", // userid
      //   },
      //   write_back_time: 1711858271,
      //   today_game_list: [
      //     // 游戏列表
      //     {
      //       game_category: 506, // 游戏分类id
      //       game_provider: 1001, // 游戏厂商id
      //       image_url: "/source/game/98/bg.png", // 游戏封面图
      //       game_name_en: "Fortune Ox", // 游戏名称
      //       poster_url: "/source/game/98/poster.webp", // 游戏启动图
      //       id: 17,
      //       game_id: "98", // 游戏id
      //     },
      //     {
      //       game_category: 510,
      //       game_provider: 1001,
      //       image_url: "/source/game/1543462/bg.png",
      //       game_name_en: "Fortune Rabbit",
      //       poster_url: "/source/game/1543462/poster.webp",
      //       id: 100,
      //       game_id: "1543462",
      //     },
      //     {
      //       game_category: 576,
      //       game_provider: 1001,
      //       image_url: "/source/game/126/bg.png",
      //       game_name_en: "Fortune Tiger",
      //       poster_url: "/source/game/126/poster.webp",
      //       id: 106,
      //       game_id: "126",
      //     },
      //   ],
      //   user_bet_count: 15, // 今日完成进度
      //   yesterday_record: {
      //     // 昨日奖励数据
      //     reward_rank: 2, // 昨日排行
      //     reward_amount: 1.0, // 昨日奖金
      //     receive_state: 0, // 领取状态 0待领取，1已领取，2已失效 -1未获奖
      //   },
      //   daily_activity_day: "2024-03-31", // 今日日期
      //   daily_activity_info: {
      //     // 活动详情 - 详见优惠充值
      //     daily_activity_reward_number: 30,
      //     daily_activity_reward_fix_amount: 1888.0,
      //     image_url: "/oper/game/2024-03-22/da47fabd893a68f61f5704e448da44ea.png",
      //     daily_activity_id: 3,
      //     name: "今日挑战巴普名称",
      //     daily_activity_condition: "20",
      //     describe: "今日挑战巴普描述",
      //     daily_activity_reward_count: 1,
      //     state: 1,
      //     activity_status: 0,
      //     daily_activity_type: 2,
      //   },
      // },
      reward_amount: 0,
      countDownTimeStr: "00:00:00",
      timer: null,
    };
  },
  computed: {
    // 今日挑战进度条
    progress() {
      if (
        this.dailyDetail === null ||
        this.dailyDetail?.user_bet_count === null ||
        this.dailyDetail?.daily_activity_info?.daily_activity_condition === null ||
        this.dailyDetail?.daily_activity_info?.daily_activity_condition === 0
      ) {
        return "0%";
      }
      const pro = (this.dailyDetail?.user_bet_count / this.dailyDetail?.daily_activity_info?.daily_activity_condition) * 100 + "%";
      return pro;
    },
  },
  unmounted() {
    clearInterval(this.timer);
  },
  mounted() {
    this.$emit("update:title", "Diariamente");
    this.dailyActivityInfo();
  },
  methods: {
    handleOfGame(item) {
      this.$router.push({
        path: "m-game-details/" + item.id,
      });
    },
    handleOfShowRule() {
      this.$refs.ruleDialog.showModal();
    },
    async handleOfReward() {
      const aId = this.$route.params.id;
      const data = {
        action: "receive_yesterday_reward",
        data: {
          daily_activity_id: parseInt(aId),
        },
      };
      try {
        const response = await this.$http.post("activity", data);
        this.reward_amount = response.data.reward_amount;
      } catch (error) {
        console.log("error", error);
      }
    },
    async dailyActivityInfo() {
      const aId = this.$route.params.id;
      const data = {
        action: "daily_activity_info",
        data: {
          daily_activity_id: parseInt(aId),
        },
      };
      try {
        const response = await this.$http.post("activity", data);
        this.dailyDetail = response.data;
        if (this.dailyDetail.yesterday_record.receive_state !== -1) {
          this.startCountDown();
        }
      } catch (error) {
        console.log("error", error);
      }
    },
    startCountDown() {
      const targetTime = new Date(this.dailyDetail.daily_activity_day + " 23:59:59");
      console.log("this.dailyDetail.daily_activity_day", this.dailyDetail.daily_activity_day);
      console.log("targetTime", targetTime);
      const targetTimestamp = targetTime.getTime();
      const currentTimestamp = Date.now();
      let diff = targetTimestamp - currentTimestamp;
      this.timer = setInterval(() => {
        if (diff <= 0) {
          clearInterval(this.timer);
          this.buffCountDownTimeStr = "00:00:00";
          return;
        }
        const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
          .toString()
          .padStart(2, "0");
        const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60))
          .toString()
          .padStart(2, "0");
        const seconds = Math.floor((diff % (1000 * 60)) / 1000)
          .toString()
          .padStart(2, "0");
        this.countDownTimeStr = `${hours}:${minutes}:${seconds}`;
        diff -= 1000;
      }, 1000);
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  padding: 20px 15px;

  .task {
    background: linear-gradient(90deg, #d95420 0%, #e90052 100%);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 60px;
    padding: 10px;

    & > .title {
      display: flex;
      justify-content: space-between;
      color: #ffffff;
      font-size: 12px;
      font-weight: 500;
    }

    & > .info {
      margin-top: 5px;
      display: flex;
      justify-content: space-between;

      & > div:nth-child(1) {
        font-size: 32px;
        font-weight: bold;
        color: #fbff00;
      }

      & > div:nth-child(2) {
        margin-top: auto;
        margin-bottom: 10px;
        font-size: 15px;
        font-weight: bold;
        color: #ffffff;
      }
    }
  }

  .challenge {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    position: relative;
    background: #0a1f32;
    border-radius: 10px;
    padding: 10px;

    .info {
      position: absolute;
      width: 20px;
      height: 20px;
      top: 5px;
      right: 10px;
    }

    .title {
      color: #ffffff;
      font-size: 14px;
      font-weight: 500;
      text-align: left;
    }

    .games {
      margin-top: 20px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      overflow: auto;
      .game {
        display: flex;
        flex-direction: column;
        margin-left: 12px;
        img {
          width: 98px;
          height: auto;
        }
      }
    }

    .progres {
      margin-top: 20px;
      display: flex;
      flex-direction: column;

      .text {
        display: flex;
        justify-content: space-between;
        color: #ffffff;
        font-size: 12px;
        font-weight: 500;
      }

      .progress-bg {
        margin-top: 6px;
        background: #1b4c1e;
        border-radius: 5px;
        height: 12px;
        display: flex;
        flex-direction: column;

        .progress-bar {
          flex: 1;
          border-radius: 5px;
          width: 30%;
          background: #15ad51;
        }
      }
    }
  }
  .yesterday {
    margin-top: 10px;
    background: #0a1f32;
    border-radius: 5px;
    display: flex;
    padding: 10px;

    .rank {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;

      div:nth-child(1) {
        color: #ffffff;
        font-size: 12px;
        font-weight: 500;
        text-align: left;
      }
      div:nth-child(2) {
        margin-top: 5px;
        color: #15ad51;
        font-size: 21px;
        font-weight: bold;
      }
    }
    .reward {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;

      div:nth-child(1) {
        color: #ffffff;
        font-size: 12px;
        font-weight: 500;
        text-align: left;
      }
      div:nth-child(2) {
        margin-top: 5px;
        color: #ffd400;
        font-size: 21px;
        font-weight: bold;
      }
    }
    .state {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;

      .fail {
        flex: 1;
        color: #ee3838;
        font-size: 18px;
        font-weight: 500;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .success {
        display: flex;
        flex-direction: column;

        div:nth-child(1) {
          color: #ffffff;
          font-size: 12px;
          font-weight: 500;
          text-align: center;
          margin-bottom: 5px;
        }

        .enalbe {
          background: #3e7bb0;
          border-radius: 8px;
          color: #ffffff;
          font-size: 12px;
          font-weight: 500;
          height: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .disable {
          background: #14324b;
          border-radius: 8px;
          color: #ffffff;
          font-size: 12px;
          font-weight: 500;
          height: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
  .today {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    background: #0a1f32;
    border-radius: 10px;
    padding: 10px;

    & > div:nth-child(1) {
      text-align: left;
      color: #3e7bb0;
      font-size: 12px;
      font-weight: 500;
    }

    .header {
      margin-top: 6px;
      display: flex;
      justify-content: space-between;
      color: #3e7bb0;
      font-size: 12px;
      font-weight: 500;
      & > div:nth-child(1) {
        flex: 1;
        text-align: left;
      }
      & > div:nth-child(2) {
        flex: 1;
        text-align: center;
      }
      & > div:nth-child(3) {
        flex: 1;
        text-align: right;
      }
    }

    .self {
      margin-top: 6px;
      background: #ffd400;
      border-radius: 5px;
      height: 23.5px;
      color: #000000;
      font-size: 12px;
      font-weight: 500;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .none {
        width: 100%;
      }
      .some {
        width: 100%;
        display: flex;
        justify-content: space-between;
        & > div:nth-child(1) {
          flex: 1;
          text-align: left;
        }
        & > div:nth-child(2) {
          flex: 1;
          text-align: center;
        }
        & > div:nth-child(3) {
          flex: 1;
          text-align: right;
        }
      }
    }

    .list {
      margin-top: 6px;
      display: flex;
      flex-direction: column;

      .cell {
        display: flex;
        justify-content: space-between;
        color: #ffffff;
        font-size: 12px;
        font-weight: 500;
        margin-bottom: 3px;

        & > div:nth-child(1) {
          flex: 1;
          text-align: left;
        }
        & > div:nth-child(2) {
          flex: 1;
          text-align: center;
        }
        & > div:nth-child(3) {
          flex: 1;
          text-align: right;
        }
      }
    }
  }

  .rule-dialog {
    display: flex;
    flex-direction: column;
    align-items: center;
    .rule-bg {
      background: #194164;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      padding: 30px 20px;

      .rule-title {
        color: #ffd400;
        font-size: 14px;
        font-weight: 500;
        text-align: left;
      }

      .rule-des {
        margin-top: 6px;
        color: #ffffff;
        font-size: 12px;
        font-weight: 500;
        text-align: left;
      }
    }
    img {
      margin-top: 20px;
      width: 30px;
      height: 30px;
    }
  }
}
</style>
