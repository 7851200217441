<template>
  <div class="deposit-collect-container">
    <div class="tab-box">
      <div :class="payTabIndex === 0 ? 'selected' : 'normal'" @click="payTabIndex = 0">{{ $t('daily_deal') }}</div>
      <div :class="payTabIndex === 1 ? 'selected' : 'normal'" @click="payTabIndex = 1">{{ $t('daily_deposit') }}</div>
    </div>
    <div class="content-box">
      <div v-if="payTabIndex === 0">
        <div class="title">{{ $t('special_offera_amount') }}</div>
        <div class="list-box">
          <div class="item" v-for="(item, index) in payInfoData?.daily_deals_pay_list" :key="index">
            <div class="info-wrapper">
              <div class="detail-box">
                <div class="detail-item">
                  <div class="title">{{ $t('actual_receipt') }}</div>
                  <div class="money-yellow">{{ item.amount + item.give_amount }}</div>
                </div>
                <div class="detail-item">
                  <div class="title">{{ $t('gift_bonus') }}</div>
                  <div class="money-green">+{{ item.give_amount }}</div>
                </div>
              </div>
              <div class="count">
                {{ $t('remaining_times') }}: <span>{{ item.give_number }}</span>
              </div>
            </div>
            <div class="button" :class="item.give_number > 0 ? 'enable' : 'disable'"
              @click="handleOfDailyDeal(item, index)">
              <div>{{ $t('deposit_page') }}</div>
              <div>{{ item.amount }}</div>
            </div>
          </div>
        </div>
        <div class="tips">{{ $t('deposit_page_des') }}</div>
      </div>
      <div v-else class="deposit-list-box">
        <div class="custom-input-box" v-if="isCanCustomInput">
          <div class="title">{{ $t('enter_amount') }}</div>
          <div class="bg">
            <div>R$</div>
            <input type="number"
              :placeholder="$t('deposit_page_input') + $tools.formatCurrency($global.data.mini_pay_amount)"
              v-model="customInputValue" />
            <div class="percent">{{ giveText }}</div>
          </div>
        </div>
        <DepositComp :depositAmountList="payInfoData?.deposit_list" :selIndex="selRechargeIndex"
          @selectEvent="onTapRechargeItem"></DepositComp>
        <div class="coupon-box" v-if="couponList?.length > 0">
          <div class="title">{{ $t('deposit_coupons') }}（{{ couponList?.length }}）</div>
          <div class="coupons">
            <div class="coupon" v-for="(item, i) in couponList" :key="i" @click="handleOfCoupon(i)">
              <img
                :src="couponIndex === i ? require('@/assets/wallet/coupon_bg_highlight.webp') : require('@/assets/wallet/coupon_bg_normal.webp')"
                alt="" />
              <div :class="couponIndex === i ? 'highlight' : 'normal'">{{ item.prize_info.prize_name }}</div>
            </div>
          </div>
        </div>
        <div class="deposit-btn" @click="handleOfDeposit">{{ $t('deposit_page') }}</div>
      </div>
    </div>
  </div>
</template>
<script>
import DepositComp from "@/components/DepositComp.vue";
export default {
  components: {
    DepositComp,
  },
  props: {
    isCanCustomInput: {
      type: Boolean,
      default: false,
    },
    tabIndex: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      payTabIndex: this.tabIndex,
      payInfoData: null,
      selRechargeIndex: -1,
      customInputValue: "",
      depositAmountList: null,
      couponIndex: -1,
      couponList: null,
    };
  },
  watch: {
    tabIndex(newValue) {
      this.payTabIndex = newValue;
    },
    customInputValue(newValue) {
      if (newValue > 0) {
        this.selRechargeIndex = -1;
        this.couponIndex = -1;
        const depositItem = this.depositAmountList?.toReversed().find((item) => {
          return newValue >= item.deposit_amount;
        });
        if (depositItem && depositItem.give_amount !== 0) {
          this.giveText = depositItem.give_amount;
        } else {
          this.giveText = "";
        }
      }
    },
  },
  mounted() {
    this.$tools.userTrackReport({ track_id: "20" });
    this.queryDepositList();
    this.fetchCouponList();
  },
  methods: {
    onTapRechargeItem(index) {
      this.giveText = "";
      this.customInputValue = "";
      this.selRechargeIndex = index;
      this.couponIndex = -1;
    },
    handleOfCoupon(i) {
      this.customInputValue = "";
      this.couponIndex = i;
      this.selRechargeIndex = -1;
    },
    handleOfDailyDeal(item, index) {
      if (item.give_number > 0) {
        this.dailyDealIndex = index;
        this.handleOfDeposit();
      }
    },
    async handleOfDeposit() {
      this.$tools.userTrackReport({ track_id: "21" });

      if (this.selRechargeIndex === -1 && this.customInputValue === "" && this.couponIndex === -1) {
        this.$toast.error(this.$t("sel_recharge_opt"));
        return;
      }

      let amount;
      let product_id = "";
      let activity_type = 0;
      let activity_id = 0;
      if (this.customInputValue !== "") {
        if (this.$tools.isNumeric(this.customInputValue) && parseInt(this.customInputValue) > 0) {
          amount = this.customInputValue;
          activity_type = 1;
          activity_id = 1;
        } else {
          this.$toast.error(this.$t("enter_valid_number"));
          return;
        }
      } else if (this.couponIndex !== -1) {
        let item = this.couponList[this.couponIndex];
        amount = item.prize_info.prize_require_amount;
        activity_type = 2;
        activity_id = item.id;
      } else {
        if (this.payTabIndex === 0) {
          // 每日特惠
          let item = this.payInfoData.daily_deals_pay_list[this.dailyDealIndex];
          amount = item.amount;
          activity_id = item.id;
          activity_type = 6;
        } else {
          // 普通充值
          let item = this.depositAmountList[this.selRechargeIndex];
          amount = item.deposit_amount;
          product_id = item.id;
          activity_type = 1;
          activity_id = 1;
        }
      }

      const data = {
        action: "collection",
        data: {
          scene: "login",
          amount: parseInt(amount) * 100,
          product_id,
          pay_method: "hkpPay",
          activity_type,
          activity_id,
        },
      };

      data.data.ks = this.$global.kwaiPixelId || "";
      data.data.fb = this.$global.fbPixelId || "";

      try {
        const response = await this.$http.post("pay", data);
        const orderInfo = response.data.order;
        const userOrder = response.data.user_order;
        this.$emit("depositEvent", orderInfo, userOrder);
      } catch (error) {
        console.log("error", error);
      }
    },
    async queryDepositList() {
      const data = {
        action: "query_deposit_list",
        data: {},
      };
      try {
        const response = await this.$http.post("userWallet", data);
        this.payInfoData = response.data;
        this.selRechargeIndex = this.payInfoData.deposit_list.findIndex((item) => {
          return item.is_hot === 1;
        });
        this.depositAmountList = response.data.deposit_list;
        // if(this.payInfoData.is_first_pay==1&&localStorage.getItem("isJump")==0){
        //   this.handleOfDailyDeal(this.payInfoData.daily_deals_pay_list[1],1)
        //   localStorage.setItem('isJump',1)
        // }
      } catch (error) {
        console.log("error", error);
      }
    },
    async fetchCouponList() {
      const data = {
        action: "user_pay_coupon_list",
        data: { type: 1 },
      };
      try {
        const response = await this.$http.post("userWallet", data);
        this.couponList = response.data.coupon_list;
      } catch (error) {
        console.log("error", error);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.deposit-collect-container {
  width: 100%;
  background: #015545;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .tab-box {
    background: #004134;
    border-radius: 10px;
    min-height: 40px;
    margin: 15px 10px 0;
    display: flex;
    padding: 5px;

    &>div {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &>.selected {
      background: #029479;
      border-radius: 10px;
      color: #ffffff;
      font-size: 12px;
      font-weight: bold;
    }

    &>.normal {
      background: transparent;
      color: #029479;
      font-size: 12px;
      font-weight: 500;
    }
  }

  .content-box {
    display: flex;
    flex-direction: column;
    margin: 15px 10px;

    &>div {
      flex: 1;
      display: flex;
      flex-direction: column;

      &>.title {
        color: #ffffff;
        font-size: 12px;
        font-weight: 500;
        text-align: left;
      }

      &>.list-box {
        display: flex;
        flex-direction: column;

        &>.item {
          margin-top: 10px;
          background: #006a56;
          border: 1px solid #007a63;
          box-sizing: border-box;
          border-radius: 10px;
          display: flex;
          padding: 10px;

          &>.info-wrapper {
            flex: 1;
            display: flex;
            flex-direction: column;

            &>.detail-box {
              display: flex;

              &>.detail-item {
                flex: 1;
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                &>.title {
                  color: #81d6ba;
                  font-size: 10px;
                  font-weight: 500;
                }

                &>.money-yellow {
                  color: #ffd400;
                  font-size: 18px;
                  font-weight: bold;
                }

                &>.money-green {
                  color: #00dc4d;
                  font-size: 18px;
                  font-weight: bold;
                }
              }
            }

            &>.count {
              text-align: left;
              color: #81d6ba;
              font-size: 10px;
              font-weight: 500;

              &>span {
                color: #ffffff;
                font-size: 10px;
                font-weight: 500;
              }
            }
          }

          &>.button {
            width: 82.5px;
            height: 50px;
            border-radius: 8px;
            font-size: 12px;
            font-weight: bold;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          }

          &>.enable {
            background: #ffd400;
            color: #00110b;
          }

          &>.disable {
            background: #676767;
            color: #a5a5a5;
          }
        }
      }

      &>.tips {
        margin-top: 5px;
        text-align: left;
        color: #81d6ba;
        font-size: 10px;
        font-weight: 500;
      }
    }

    .deposit-list-box {
      display: flex;
      flex-direction: column;

      .custom-input-box {
        &>.title {
          color: #ffffff;
          font-size: 12px;
          font-weight: 500;
          text-align: left;
        }

        .bg {
          margin-top: 10px;
          margin-bottom: 20px;
          display: flex;
          align-items: center;
          height: 50px;
          background: #004134;
          border-radius: 10px;
          border: 1px solid #007a63;

          div {
            margin: 12px 16px;
            font-size: 18px;
            font-weight: bold;
            color: #81d6ba;
            text-align: left;
          }

          input {
            flex: 1;
            background: transparent;
            border: 0;
            outline: 0;
            font-size: 16px;
            font-weight: bold;
            color: #ffd400;
          }

          input::placeholder {
            color: #029479;
          }

          .percent {
            color: #ffd400;
            font-size: 16px;
            font-weight: 500;
          }
        }
      }

      .coupon-box {
        .title {
          margin-top: 10px;
          text-align: left;
          font-size: 12px;
          font-weight: 500;
          color: #ffffff;
        }

        .coupons {
          margin-top: 10px;
          display: flex;
          overflow-x: scroll;

          .coupon {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 6px;

            img {
              width: 165px;
              height: 45px;
            }

            &>div {
              position: absolute;
              font-size: 12px;
            }

            &>.highlight {
              font-weight: bold;
              color: #01221c;
            }

            &>.normal {
              font-weight: 500;
              color: #ffffff;
            }
          }
        }
      }

      &>.deposit-btn {
        margin-top: 15px;
        background: #ffd400;
        border-radius: 10px;
        height: 45px;
        line-height: 45px;
        color: #01221c;
        font-size: 18px;
        font-weight: 500;
      }
    }
  }
}
</style>
