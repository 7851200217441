<template>
  <div class="header">
    <img
      class="tools-icon"
      :class="{ 'rotate-from-0': isShowExtend, 'rotate-to-0': !isShowExtend }"
      src="@/assets/casino_more.png"
      alt=""
      @click="handleOfShowExtend"
    />
    <div class="logo-box">
      <img class="logo" @click="cateItemHandler(0)" src="@/assets/header/logo.webp" alt="" />
    </div>

    <div class="user">
      <!-- 已登录 -->
      <div v-if="isLoggedIn" class="logged-in">
        <div class="search" @click="searchInputHandle">
          <img src="@/assets/header/search.webp" alt="" />
        </div>
        <div class="money-box" @click="handlerOfWithdraw">
          <div class="unit">R$</div>
          <div class="money">{{ $tools.formatCurrency(coins) }}</div>
        </div>
        <div class="wallet" @click="handlerOfDeposit">
          <img src="@/assets/header/wallet.webp" alt="" />
        </div>
      </div>
      <!-- 未登录 -->
      <div v-else class="not-logged-in">
        <div @click="loginHandle">{{ $t("login") }}</div>
        <div @click="registerHandle">{{ $t("register") }}</div>
      </div>
    </div>
    <DepositPage v-if="isShowDeposit" @close="isShowDeposit = isShow" @showUrl="showPay"></DepositPage>
    <PayPage v-if="isShowPay" :payUrl="payUrl" @close="closePayPage"></PayPage>
  </div>
</template>

<script>
import DepositPage from "./mine/wallet/DepositPage.vue";
import PayPage from "./mine/wallet/PayPage.vue";
import { withdrawTools } from "@/utils/withdraw.js";
import FbPixel from "@/utils/facebookPixel";
import KwaiPixel from "@/utils/kwaiPixel";

export default {
  components: { DepositPage, PayPage },
  props: {
    isLoggedIn: Boolean,
    isShowExtend: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["popupLogin", "popupRegister", "showMineMenu"],
  data() {
    return {
      cateList: [
        { title: "Casino", path: "casino" },
        // { title: "Sports", path: "sports" },
      ],
      cateIndex: 0,
      isShowDeposit: false,
      isShowPay: false,
      payUrl: "",
      coins: "",
      intervalId: null,
    };
  },
  created() {
    this.intervalId = setInterval(() => {
      if (location.href.includes("/game-details/") && this.isLoggedIn) {
        // this.$toast.success("游戏页面，5s刷新一次钱包");
        this.getUserWallet();
      }
    }, 5000);
  },
  mounted() {
    this.$eventBus.on("showDeposit", this.handlerOfDeposit);
    this.$eventBus.on("refreshHeaderCoins", this.getUserWallet);
    if (this.isLoggedIn) {
      this.getUserWallet();
    }
  },
  beforeUnmount() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  },
  watch: {
    isLoggedIn(newValue) {
      if (newValue === true) {
        // 重新登录成功，获取钱包信息
        this.getUserWallet();
      }
    },
  },
  methods: {
    handlerOfWithdraw() {
      withdrawTools.withdrawEvent(1);
    },
    searchInputHandle() {
      this.$tools.userTrackReport({ track_id: "19" });
      if (!this.isLoggedIn) {
        this.$eventBus.emit("isNeedLogin");
        return;
      }
      this.$router.push("m-search-detail");
    },
    handleOfShowExtend() {
      this.$eventBus.emit("emitOfShowExtend", !this.isShowExtend);
    },
    levelHandle() {
      this.$router.push("/membership/vip");
    },
    /**
     * 关闭充值页，刷新用户钱包
     */
    closePayPage() {
      this.isShowPay = false;
      this.getUserWallet();
    },
    /**
     * 展示充值弹窗
     * @param {*} url
     */
    showPay(url) {
      this.payUrl = url;
      this.isShowDeposit = false;
      this.isShowPay = true;
    },
    /**
     * 获取用户余额
     */
    getUserWallet() {
      const data = {
        action: "query_wallet",
        data: {},
      };

      this.$http
        .post("userWallet", data)
        .then((response) => {
          this.coins = response.data.user_wallet.coins;
          const promote_param = response.data.promote_param;
          if (promote_param !== null && promote_param.includes("=")) {
            const arr = promote_param.split("=");
            if (arr[0] === "fb") {
              // this.$fbq("track", "Purchase", { currency: "USD", value: 0 });
              FbPixel.trackEvent("Purchase", { currency: "USD", value: 0 });
              this.upPixelAdDone();
            } else if (arr[0] === "ks") {
              // this.$kwaiq.instance(this.$global.kwaiPixelId).track("purchase");
              KwaiPixel.trackEvent.track("purchase");
              this.upPixelAdDone();
            }
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    async upPixelAdDone() {
      const data = {
        action: "up_pixed_ad_done",
        data: {},
      };
      try {
        await this.$http.post("userWallet", data);
      } catch (error) {
        console.log("error", error);
      }
    },
    /**
     * 点击用户信息
     */
    onTapUserInfo() {
      this.$emit("showMineMenu");
    },
    /**
     * 登录事件
     */
    loginHandle() {
      this.$tools.userTrackReport({ track_id: "6" });
      this.$emit("popupLogin");
      this.$gtag.event("button_click", {
        event_category: "login",
        event_label: "Login Button Clicked",
      });
    },
    /**
     * 注册事件
     */
    registerHandle() {
      this.$emit("popupRegister");
      this.$gtag.event("button_click", {
        event_category: "login",
        event_label: "Register Button Clicked",
      });
    },
    /**
     * 分类点击相应事件
     * @param {Int} index 分类下标
     */
    cateItemHandler(index) {
      this.cateIndex = index;
      this.$router.push({ name: this.cateList[index].path });
    },
    handlerOfDeposit() {
      // if (this.$tools.isMobile()) {
      //   this.$router.push({ name: "m-deposit-page" });
      // } else {
      //   this.isShowDeposit = true;
      // }
      this.$router.push({ name: "m-deposit-page" });
      this.$gtag.event("button_click", {
        event_category: "deposit",
        event_label: "deposit Button Clicked",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@keyframes rotateFrom0 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(90deg);
  }
}

.rotate-from-0 {
  animation: rotateFrom0 0.5s ease forwards;
}

@keyframes rotateTo0 {
  0% {
    transform: rotate(90deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.rotate-to-0 {
  animation: rotateTo0 0.5s ease forwards;
}

.header {
  background: #004134;
  max-width: var(--max-width);
  width: 100%;
  min-height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .tools-icon {
    display: flex;
    margin-left: 15px;
    width: 15px;
    height: 15px;
  }

  .logo-box {
    margin-left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;

    .logo {
      width: 58px;
      height: auto;
    }
  }

  .user {
    margin-left: auto;
    margin-right: 15px;
    display: flex;

    .logged-in {
      display: flex;

      .search {
        background: #004134;
        border: 1px solid #015545;
        border-radius: 7px;
        box-sizing: border-box;
        display: flex;
        width: 30px;
        height: 30px;
        margin-right: 5px;

        & > img {
          margin: auto;
          width: 15px;
          height: 15px;
        }
      }

      .money-box {
        margin: auto;
        background: #004134;
        border-radius: 10px;
        display: flex;
        width: 100px;
        height: 30px;
        border-radius: 5px;
        border: 1px solid #015545;
        box-sizing: border-box;

        & > img {
          margin: auto;
          margin-right: 2px;
          width: 25px;
          height: 25px;
        }

        .unit {
          line-height: 30px;
          color: #ffd400;
          margin-left: 10px;
          font-size: 12px;
          font-weight: bold;
        }

        .money {
          flex: 1;
          width: 80px;
          line-height: 30px;
          margin-left: 10px;
          color: var(--header-balance-color);
          margin-right: 10px;
          text-align: left;
          font-size: 12px;
          font-weight: 500;
        }
      }

      .wallet {
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #ffd400;
        border-radius: 7px;
        margin-left: 7px;

        & > img {
          width: 15px;
          height: 15px;
        }
      }
    }

    .not-logged-in {
      display: flex;

      & > div:nth-child(1) {
        padding: 0 10px;
        height: 30px;
        font-size: 11px;
        font-weight: bold;
        margin-right: 7px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        color: #ffffff;
        background: transparent;
        border: 1px solid #ffd400;
        box-sizing: border-box;
      }

      & > div:nth-child(2) {
        padding: 0 10px;
        height: 30px;
        font-size: 11px;
        font-weight: bold;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        background: #ffd400;
        color: #0c080e;
      }
    }
  }
}
</style>
