<template>
  <div class="wrapper" @click="isShowMineMenu = false">
    <div class="main">
      <router-view class="box" :isLoggedIn="isLoggedIn" :isShowExtend="isShowExtend" :registerGiveCoins="registerGiveCoins"></router-view>
    </div>
    <TabbarView class="m-tabbar"></TabbarView>

    <!-- 隐私政策 -->
    <PrivacyPolicy v-if="isShowPrivacyPolicy" @close="isShowPrivacyPolicy = false"></PrivacyPolicy>
    <!-- 服务条款 -->
    <ServiceTerms v-if="isShowService" @close="isShowService = false"></ServiceTerms>

    <!-- 忘记密码弹窗 -->
    <ForgetPassword
      v-if="isShowForgetPW"
      :isPhone="isForgetOfPhone"
      @close="isShowForgetPW = false"
      @endChangedPasswordEvent="endChangedPasswordEvent"
    ></ForgetPassword>

    <!-- 登录弹窗 -->
    <ModalPopupComp v-if="isShowLoginModal" :isShowDefaultCloseBtn="false">
      <LoginComp :isRegisterStatus="isRegisterForLoginPage" @close-login="closeLogin" @registerSuccess="handleOfRegisterSuccess"></LoginComp>
    </ModalPopupComp>

    <!-- 首页扩展页面 -->
    <div class="extend-bg" v-show="isShowExtendBg" @click="isShowExtend = false">
      <div class="extentd-box" :class="{ 'slide-in-from-bottom': isShowExtend, 'slide-out-to-bottom': !isShowExtend }">
        <div class="extend-content">
          <div class="operator" @click="isShowExtend = false"></div>
          <div class="row">
            <!-- 官方频道 -->
            <div class="item" @click.stop="handleOfTelegram">
              <div class="item-content">
                <img src="@/assets/extend/icon1.png" alt="" />
                <div>{{ $t('eaglegroup_official') }}</div>
              </div>
            </div>
            <!-- 官方视频 -->
            <div class="item" @click.stop="handleOfInstagram">
              <div class="item-content">
                <img src="@/assets/extend/icon2.png" alt="" />
                <div>{{ $t('eaglegroup_official') }}</div>
              </div>
            </div>
          </div>
          <div class="row">
            <!-- 成为合作伙伴 -->
            <div class="item" @click.stop="handleOfPartner">
              <div class="item-content">
                <img src="@/assets/extend/icon3.png" alt="" />
                <div>{{ $t('become_a_partner') }}</div>
              </div>
            </div>
            <!-- 邀请好友 -->
            <div class="item" @click="handleOfInvite">
              <div class="item-content">
                <img src="@/assets/extend/icon4.png" alt="" />
                <div>{{ $t('invite_friends') }}</div>
              </div>
            </div>
          </div>
          <div class="row">
            <!-- 下载APP -->
            <div class="item" @click="handleOfDownload">
              <div class="item-content">
                <img src="@/assets/extend/icon5.png" alt="" />
                <div>{{ $t('download_app') }}</div>
              </div>
            </div>
            <!-- 官方客服 -->
            <div class="item" @click="handleOfService">
              <div class="item-content">
                <img src="@/assets/extend/icon6.png" alt="" />
                <div>{{ $t('customer_service02') }}</div>
              </div>
            </div>
          </div>
          <!-- 会员俱乐部 -->
          <div class="club" @click="handleOfClub">
            <div class="club-des">
              <div>{{ $t('members_club') }}</div>
              <div>{{ $t('daily_draw_Bet_Refund_Weekly_Bonus') }}</div>
            </div>
            <img class="club-img" src="@/assets/extend/club.png" alt="" />
          </div>
          <!-- 幸运抽奖 -->
          <div class="draw" @click="handleOfDraw">
            <img src="@/assets/extend/draw1.png" alt="" />
            <div>{{ $t('luck_draw') }}</div>
            <img src="@/assets/extend/draw2.png" alt="" />
          </div>
          <div class="row">
            <!-- 奖品 -->
            <div class="item" @click="handleOfAward">
              <div class="item-content">
                <img src="@/assets/extend/icon7.png" alt="" />
                <div>{{ $t('prizes') }}</div>
              </div>
            </div>
            <!-- 消息 -->
            <div class="item" @click="handleOfMessage">
              <div class="item-content">
                <img src="@/assets/extend/icon8.png" alt="" />
                <div>{{ $t('message') }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- apple install -->
    <AppleInstall v-if="isShowAppleInstall" @close="isShowAppleInstall = false"></AppleInstall>

    <!-- 注册成功奖励 -->
    <RegistrationRewardsComp v-if="isShowRegistrationRewards" @closeEvent="handleOfCloseRegistrationRewards"> </RegistrationRewardsComp>
  </div>
</template>

<script>
import PrivacyPolicy from "@/pages/containers/PrivacyPolicy.vue";
import ServiceTerms from "@/pages/containers/ServiceTerms.vue";
import ForgetPassword from "./containers/ForgetPassword.vue";
import TabbarView from "@/pages/TabbarView.vue";
import AppleInstall from "@/pages/containers/AppleInstall.vue";
import clipboard from "clipboard";
import LoginComp from "@/components/LoginComp.vue";
import ModalPopupComp from "@/components/ModalPopupComp.vue";
import RegistrationRewardsComp from "@/components/RegistrationRewardsComp.vue";
import FbPixel from "@/utils/facebookPixel";
import KwaiPixel from "@/utils/kwaiPixel";

export default {
  components: {
    ForgetPassword,
    TabbarView,
    PrivacyPolicy,
    ServiceTerms,
    AppleInstall,
    LoginComp,
    ModalPopupComp,
    RegistrationRewardsComp,
  },
  data() {
    return {
      isShowLoginModal: false,
      isShowPrivacyPolicy: false,
      isShowService: false,
      // 是否登录
      isLoggedIn: false,
      // 是否展示登录页面
      isShowLoginPage: false,
      // 是否展示忘记密码页面
      isShowForgetPW: false,
      // 忘记密码页面状态是否是手机
      isForgetOfPhone: true,
      // 登录页面是否展示注册状态
      isRegisterForLoginPage: false,
      // 显示个人中心menu
      isShowMineMenu: false,
      list: [
        {
          title: this.$t("my_wallet"),
          icon: require("@/assets/user/wallet_icon.png"),
          path: "wallet",
        },
        {
          title: this.$t("my_account"),
          icon: require("@/assets/user/account_icon.png"),
          path: "general",
        },
        // {
        //   title: this.$t("my_prizes"),
        //   icon: require("@/assets/user/award_icon.png"),
        //   path: "award",
        // },
        {
          title: this.$t("my_invitations"),
          icon: require("@/assets/user/invite_icon.png"),
          path: "commission",
        },
        {
          title: this.$t("transaction_records"),
          icon: require("@/assets/user/transcation_icon.png"),
          path: "flow",
        },
        {
          title: this.$t("game_history"),
          icon: require("@/assets/user/history_icon.png"),
          path: "history",
        },
        // {
        //   title: this.$t("settings"),
        //   icon: require("@/assets/user/setting_icon.png"),
        //   path: "setting",
        // },
        // {
        //   title: this.$t("messages"),
        //   icon: require("@/assets/user/message_icon.png"),
        //   path: "email",
        // },
        {
          title: this.$t("cs"),
          icon: require("@/assets/user/service_icon.png"),
          path: "service",
        },
        {
          title: this.$t("log_out"),
          icon: require("@/assets/user/exit_icon.png"),
          path: "",
        },
      ],
      scrollPosition: 0,
      isShowAppleInstall: false,
      userCode: "",
      isShowCopiado: false,
      isShowExtend: false,
      isShowExtendBg: false,
      isShowRegistrationRewards: false,
    };
  },
  props: {
    registerGiveCoins: {
      type: Number,
      default: 0,
    },
  },
  watch: {
    isShowExtend(newVal) {
      if (newVal) {
        this.isShowExtendBg = true;
      } else {
        setTimeout(() => {
          this.isShowExtendBg = false;
        }, 500);
      }
    },
  },
  mounted() {
    this.userCode = this.$store.getters.ucode;

    this.$eventBus.on("emitOfShowExtend", (isShow) => {
      this.isShowExtend = isShow;
    });
    this.$eventBus.on("eventOfDownload", this.handleOfDownload);

    this.$eventBus.on("showLogin", this.showLogin);
    this.$eventBus.on("showRegister", this.showRegister);
    this.$eventBus.on("isNeedLogin", () => {
      const registerInfo = this.$store.getters.registerInfo;
      // console.log('registerInfo :>> ', registerInfo);

      if (registerInfo !== null) {
        // alert(1) 本地谷歌浏览器走这里
        this.showLogin();
      } else {
        // alert(2) 真机安卓手机走这里 就很奇怪
        this.showRegister(2, 0);
      }
    });
    this.$eventBus.on("closeLogin", (isLoggedIn) => {
      this.isShowLoginPage = false;
      this.isLoggedIn = isLoggedIn;
      this.isShowLoginModal = false;
    });

    this.$eventBus.on("logoutSuccessHandle", this.logoutSuccessHandle);
    this.isLoggedIn = this.$store.getters.isLoggedIn;

    if (this.isLoggedIn && sessionStorage.getItem("registerSuccess") === "1") {
      this.handleOfRegisterSuccess();
      sessionStorage.setItem("registerSuccess", 0);
    }

    if (this.isLoggedIn) {
      const hasShow = sessionStorage.getItem("redemption_code_has_show");
      if ("rc" in this.$route.query && !hasShow) {
        setTimeout(() => {
          this.$router.push({ name: "redemption-code", query: { rc: this.$route.query.rc } });
          sessionStorage.setItem("redemption_code_has_show", true);
        }, 1000);
      }
    } else {
      if ("code" in this.$route.query) {
        // google login
        // this.googleLogin(this.$route.query.code);
      } else {
        if ("s" in this.$route.query) {
          // session storage 推广值，给注册的时候使用
          localStorage.setItem("source_channel", this.$route.query.s);
        }

        if ("pa" in this.$route.query) {
          localStorage.setItem("pa", this.$route.query.pa);
          this.promoteAd(this.$route.query.pa);
        }
      }
    }

    if ("fb" in this.$route.query) {
      FbPixel.setupFacebookPixel(this.$route.query.fb);
    }
    if ("ks" in this.$route.query) {
      KwaiPixel.loadKwaiPixelScript(this.$route.query.ks);
    }

    document.addEventListener("DOMContentLoaded", this.adjustMainHeight());
    window.addEventListener("resize", this.adjustMainHeight());
    this.adjustMainHeight();
  },
  methods: {
    adjustMainHeight() {
      const root = document.documentElement; // 获取 :root 元素
      const tabBarHeight = parseInt(getComputedStyle(root).getPropertyValue("--tab-bar-height").trim(), 10); // 读取变量
      const element = document.querySelector(".main");
      element.style.height = window.innerHeight - tabBarHeight + "px";
    },
    handleOfCloseRegistrationRewards() {
      this.isShowRegistrationRewards = false;
      this.$refs.newerDialog.showModal();
    },
    handleOfRegisterReward() {
      this.showRegister();
    },
    handleOfClub() {
      if (this.isLoggedIn) {
        this.$router.push({ name: "m-vip" });
      } else {
        this.showRegister();
      }
    },
    handleOfDraw() {
      if (this.isLoggedIn) {
        this.$router.push({ name: "daily-lottery-draw" });
      } else {
        this.showRegister();
      }
    },
    handleOfAward() {
      if (this.isLoggedIn) {
        this.$router.push({ name: "m-award" });
      } else {
        this.showRegister();
      }
    },
    handleOfMessage() {
      if (this.isLoggedIn) {
        this.$router.push({ name: "m-message" });
      } else {
        this.showRegister();
      }
    },
    handleOfService() {
      if (this.isLoggedIn) {
        this.$router.push("m-service");
      } else {
        this.showRegister();
      }
    },
    handleOfDownload() {
      // 首页下载桌面点击
      this.$tools.userTrackReport({ track_id: "35" });
      this.$router.push({ name: "app-download-page" });
    },
    handleOfInvite() {
      this.$eventBus.emit("emitOfChangeTab", "commission");
    },
    handleOfPartner() {
      window.open(this.$global.data.cooperate_link, "_self");
    },
    handleOfInstagram() {
      window.open(this.$global.data.ins_link, "_self");
    },
    handleOfTelegram() {
      this.$tools.openTelegram();
    },
    handleOfRegisterSuccess() {
      if (this.registerGiveCoins > 0) {
        this.isShowRegistrationRewards = true;
      } else {
        this.$refs.newerDialog.showModal();
      }
    },
    copyHandle() {
      clipboard.copy(this.userCode);
      this.isShowCopiado = true;
      setTimeout(() => {
        this.isShowCopiado = false;
      }, 2000);
    },
    endChangedPasswordEvent() {
      this.isShowForgetPW = false;
      this.isShowLoginPage = true;
    },
    /**
     * 展示登录页面
     */
    showLogin() {
      if (this.$global.isDialogModelShowLogin) {
        this.isRegisterForLoginPage = false;
        this.isShowLoginModal = true;
      } else {
        this.$router.push({ name: "login-page", query: { isRegister: 0 } });
      }
    },
    /**
     * 展示注册页面
     * trackId: 埋点id； "1"点击注册按钮 "2"其他注册（默认）
     */
    showRegister(trackId = "2", isRegister = 1) {
      this.$tools.userTrackReport({ track_id: trackId });
      if (this.$global.isDialogModelShowLogin) {
        this.isRegisterForLoginPage = true;
        this.isShowLoginModal = true;
      } else {
        // 动态传参，这里如果isRegister固定传1，登录页面会获取到这个参数，并自动展示注册
        this.$router.push({ name: "login-page", query: { isRegister: isRegister } });
      }
    },
    /**
     * 关闭登录页面
     * @param {Boolean} isLoggedIn 是否登录成功后的关闭事件。true：登录后的关闭 false：直接关闭按钮
     */
    closeLogin(isLoggedIn) {
      this.isShowLoginPage = false;
      this.isLoggedIn = isLoggedIn;
      this.isShowLoginModal = false;
    },
    /**
     * 个人中心menu列表中的点击事件
     * @param {Int} index 列表中点击的选项下标
     */
    menuItemHandle(index) {
      const item = this.list[index];
      if (item.title === this.$t("log_out")) {
        this.logout();
      } else {
        if (item.path != "") {
          this.$router.push({ name: item.path });
        }
      }
      this.isShowMineMenu = false;
    },
    logout() {
      const data = {
        action: "logout",
        data: {},
      };
      this.$http
        .post("userCenter", data)
        .then()
        .catch()
        .finally(() => {
          this.localLogout();
        });
    },
    localLogout() {
      this.$store.commit("clearInfo");
      this.$eventBus.emit("logoutSuccessHandle");
      this.$router.push({ name: "casino" });
    },
    logoutSuccessHandle() {
      this.isLoggedIn = false;
    },
    async promoteAd(id) {
      const data = {
        action: "promote_ad_open",
        data: {
          promote_ad_id: id,
        },
      };
      try {
        await this.$http.post("promote", data);
      } catch (error) {
        console.log("error", error);
      }
    },
  },
  updated() {
    this.isLoggedIn = this.$store.getters.isLoggedIn;
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  .main {
    background: transparent;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
  }

  .m-tabbar {
    display: flex;
    position: fixed;
    left: 0;
    bottom: 0;
  }

  @keyframes slideInFromBottom {
    0% {
      transform: translateY(100%);
    }

    100% {
      transform: translateY(0);
    }
  }

  .slide-in-from-bottom {
    animation: slideInFromBottom 0.5s ease forwards;
  }

  @keyframes slideOutToBottom {
    0% {
      transform: translateY(0);
    }

    100% {
      transform: translateY(100%);
    }
  }

  .slide-out-to-bottom {
    animation: slideOutToBottom 0.5s ease forwards;
  }

  .extend-bg {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 30;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;

    .extentd-box {
      max-width: var(--max-width);
      width: 100%;
      background: #015545;
      border-radius: 10px 10px 0px 0px;
      display: flex;
      transform: translateY(100%);

      .extend-content {
        width: 100%;
        padding: 15px;
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;

        .operator {
          margin-top: 3px;
          background: #e5ecf2;
          width: 40px;
          border-radius: 5px;
          height: 5px;
        }

        .row {
          width: 100%;
          display: flex;
          margin-top: 18px;

          .item {
            flex: 1;
            display: flex;
            justify-content: space-around;

            .item-content {
              width: 100%;
              height: 45px;
              background: #006a56;
              border-radius: 10px;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              color: #ffffff;
              font-size: 12px;
              font-weight: 500;
            }

            img {
              margin: auto 10px;
              width: 30px;
              height: 30px;
            }
          }

          .item:last-child {
            margin-left: 10px;
          }
        }

        .club {
          margin-top: 18px;
          background: #006a56;
          border-radius: 10px;
          width: 100%;
          height: 90px;
          display: flex;
          align-items: center;

          .club-des {
            margin: auto 12px;
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            div:nth-child(1) {
              color: #ffd400;
              font-size: 18px;
              font-weight: 500;
            }

            div:nth-child(2) {
              color: #ffffff;
              font-size: 8px;
              font-weight: 500;
            }
          }

          .club-img {
            margin-right: 7px;
            width: 92px;
            height: 82px;
          }
        }

        .draw {
          margin-top: 18px;
          width: 100%;
          height: 60px;
          background: #006a56;
          border-radius: 10px;
          color: #ffffff;
          font-size: 18px;
          font-weight: 500;
          display: flex;
          justify-content: space-around;
          align-items: center;

          img:first-of-type {
            margin-top: auto;
            width: 69.5px;
            height: 58px;
          }

          img:last-of-type {
            margin-top: auto;
            width: 55px;
            height: 43.5px;
          }
        }
      }
    }
  }
}

// @media (max-width: 768px) {
//   .wrapper {
//     position: relative;
//     height: 100vh;
//     width: 100vw;
//   }

//   .wrapper .main {
//     margin-bottom: 0;
//     width: 100%;
//     position: fixed;
//     top: 0;
//     bottom: var(--tab-bar-height);
//     left: 0;
//     margin-top: 0;
//   }
// }
</style>
