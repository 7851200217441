<template>
  <div class="container" v-cloak>
    <div class="img-box">
      <img src="@/assets/redemption/icon1.png" alt="" />
    </div>
    <div class="des">
      {{ $t('redemption_code_des1') }} <br />
      {{ $t('redemption_code_des2') }} <br />
      {{ $t('redemption_code_des3') }} <br />
      {{ $t('redemption_code_des4') }} <br />
      {{ $t('redemption_code_des5') }}
      {{ $t('redemption_code_des6') }}.
    </div>
    <div class="input-box">
      <input type="text" :placeholder="$t('redemption_code_des7')" v-model="inputValue" />
    </div>
    <div class="input-box1">
      <input type="text" :placeholder="$t('redemption_code_des8')" v-model="cpfInputValue" :readonly="customer_cert_lock" />
      <!-- <button class="binding-btn">Vincular</button> -->
    </div>
    <button class="btn1" @click="handleOfOK">{{ $t('confirm') }}</button>
  </div>
  <dialog ref="resultDialog">
    <CommonDialogTipContent
      :title="$t('prompt')"
      :des="resultDes"
      :value="resultValue"
      :buttonTitle="$t('bom')"
      @confirmEvent="closeDialog"
      @closeEvent="closeDialog"
    ></CommonDialogTipContent>
  </dialog>
</template>
<script>
import CommonDialogTipContent from "@/components/CommonDialogTipContent.vue";
export default {
  components: {
    CommonDialogTipContent,
  },
  data() {
    return {
      inputValue: "",
      resultDes: "",
      resultValue: "",
      cpfInputValue: "",
      customer_cert_lock: 0,
      readonly: false,
    };
  },
  mounted() {
    this.$emit("update:title", "código de resgate");
    this.$emit("update:isShowHeaderRightBtn", true);
    this.fetchUserInfo();
    this.inputValue = this.$route.query?.rc;
  },
  unmounted() {
    this.$emit("update:isShowHeaderRightBtn", false);
  },
  methods: {
    closeDialog() {
      this.$refs.resultDialog.close();
      this.inputValue = "";
      if (this.customer_cert_lock == 0) {
        this.cpfInputValue = "";
      }
    },
    async fetchUserInfo() {
      const data = {
        action: "my_account",
        data: {},
      };
      try {
        const response = await this.$http.post("userCenter", data);
        this.cpfInputValue = response.data.data.customer_cert || "";
        this.customer_cert_lock = response.data.data.customer_cert_lock;
      } catch (error) {
        console.log("error", error);
      }
    },
    async redeem() {
      const data = {
        action: "use_redeem_code",
        data: {
          redeem_code: this.inputValue,
          customer_cert: this.cpfInputValue,
        },
      };
      try {
        const response = await this.$http.post("activity", data);
        this.resultDes = "A troca foi bem-sucedida.";
        this.resultValue = "R$ " + this.$tools.formatCurrency(response.data.redeem_amount);
        this.$refs.resultDialog.showModal();
        this.fetchUserInfo();
      } catch (error) {
        console.log("error", error);
        this.resultDes = error.msg;
        this.resultValue = "";
        this.$refs.resultDialog.showModal();
      }
    },
    handleOfOK() {
      if (this.inputValue === "") {
        this.resultDes = this.$t('incorrect_redemption_code');
        this.resultValue = "";
        this.$refs.resultDialog.showModal();
        return;
      }

      this.redeem();
    },
  },
};
</script>
<style lang="scss" scoped>
[v-cloak] {
  display: none !important;
}
.container {
  display: flex;
  flex-direction: column;

  .img-box {
    display: flex;
    flex-direction: column;
    padding: 6px 15px 0;

    & > img {
      width: 100%;
      height: auto;
    }
  }

  .des {
    margin: 50px 30px 0;
    color: #fff;
    font-size: 12px;
    font-weight: 500;
    text-align: left;
  }

  .input-box {
    margin: 20px 15px 0;
    background: #004134;
    border-radius: 10px;
    height: 46px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    & > input {
      margin: 10px 15px;
      background: transparent;
      border: none;
      border-radius: 10px;
      color: #ffffff;
      font-size: 14px;
      font-weight: 500;
      text-align: left;
    }

    & > input::placeholder {
      color: #029479;
    }
  }

  .input-box1 {
    margin: 10px 15px 0;
    background: #004134;
    border-radius: 10px;
    height: 46px;
    display: flex;
    align-items: center;

    & > input {
      flex: 1;
      margin: 10px 15px;
      background: transparent;
      border: none;
      border-radius: 10px;
      color: #ffffff;
      font-size: 14px;
      font-weight: 500;
      text-align: left;
    }

    & > input::placeholder {
      color: #029479;
    }

    & > .binding-btn {
      margin-right: 5px;
      background: #33439e;
      border-radius: 6px;
      height: 28px;
      border: none;
      color: #ffffff;
      font-size: 12px;
      font-weight: 500;
    }
  }

  .btn1 {
    border: none;
    margin: 20px 15px 0;
    background: #ffd400;
    border-radius: 8px;
    height: 46px;
    color: #000000;
    font-size: 18px;
    font-weight: 800;
    text-align: center;
  }
}
</style>
