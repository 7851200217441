<template>
  <div class="container">
    <div class="temp" v-if="true">
      <div class="tip1">Atualização recente</div>
      <div class="tip2">Jogabilidade de membros</div>
      <div class="des">
        Para fornecer aos usuários uma experiência melhor e melhor, em breve atualizaremos a jogabilidade dos membros.
        Se queridos usuários tiverem boas sugestões, você poderá entrar em contato diretamente com o atendimento oficial
        ao cliente para feedback sugestões valiosas. Vamos ouvir o feedback de cada usuário!
      </div>
      <div class="vip">
        <div class="vipTitle">Nível de membro</div>
        <img src="@/assets/vip/vip0.png" alt="" />
        <img src="@/assets/vip/vip1.png" alt="" />
        <img src="@/assets/vip/vip2.png" alt="" />
        <img src="@/assets/vip/vip3.png" alt="" />
        <img src="@/assets/vip/vip4.png" alt="" />
        <img src="@/assets/vip/vip5.png" alt="" />
        <img src="@/assets/vip/vip6.png" alt="" />
      </div>
    </div>
    <div class="wrapper" v-else>
      <div class="title">{{ $t("membership_privileges") }}</div>
      <category-comp class="cate" :list="cateList" v-model:index="index"></category-comp>
      <router-view></router-view>
    </div>
  </div>
</template>
<script>
import CategoryComp from "@/components/CategoryComp.vue";
export default {
  components: { CategoryComp },
  data() {
    return {
      index: 0,
      cateList: [
        { title: this.$t("vip"), path: "vip" },
        { title: this.$t("immunities"), path: "immunities" },
        { title: this.$t("club"), path: "club" },
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;

  .temp {
    display: flex;
    flex-direction: column;
    align-items: center;

    .tip1 {
      font-size: 21px;
      font-weight: bold;
      color: #15ad51;
    }

    .tip2 {
      margin-top: 24px;
      font-size: 12px;
      font-weight: 500;
      color: #bcbcbc;
    }

    .des {
      margin: 12px 33px 0;
      font-size: 9px;
      font-weight: 250;
      color: #ffffff;
      opacity: 0.8;
      text-align: left;
    }

    .vip {
      margin-top: 56px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .vipTitle {
        margin-bottom: 18px;
        font-size: 12px;
        font-weight: 500;
        color: #9e9e9e;
      }

      img {
        margin-bottom: 12px;
        width: 164px;
        height: auto;
      }
    }
  }

  .wrapper {
    margin: 40px 140px 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    .title {
      margin-bottom: 18px;
      font-size: 24px;
      font-weight: 600;
      color: #ffffff;
    }
  }

  // @media (max-width: 768px) {
  //   .wrapper {
  //     margin: 0px 30px 0;
  //   }

  //   .wrapper .title {
  //     display: none;
  //   }

  //   .wrapper .cate {
  //     width: 100%;
  //   }
  // }
}
</style>
