<template>
  <div class="download-app-tips-container" v-if="isShow">
    <img src="@/assets/download-app-tips/close.webp" alt="" @click="isShow = false" />
    <div class="content">
      {{ $t('download_the_application_to_receive_a_bonus') }} <span>R$ {{ $tools.formatCurrency(5) }}</span>
    </div>
    <div class="button" @click="$router.push({ name: 'app-download-page' })">
      <img src="@/assets/download-app-tips/phone.webp" alt="" />
      <div>{{ $t('download') }}</div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isShow: true,
    };
  },
};
</script>
<style lang="scss" scoped>
.download-app-tips-container {
  display: flex;
  align-items: center;
  background: linear-gradient(270deg, #637cfa 0%, #009dff 100%);
  width: 100%;
  min-height: 45px;
  max-height: 45px;

  & > img {
    margin-left: 10px;
    width: 16px;
    height: 16px;
  }

  & > .content {
    margin-left: 10px;
    display: flex;
    align-items: center;
    color: #ffffff;
    font-size: 10px;
    font-weight: 500;

    & > span {
      margin-left: 5px;
      color: #00ff59;
      font-size: 16px;
      font-weight: bold;
    }
  }

  & > .button {
    margin-left: auto;
    margin-right: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ffffff;
    border-radius: 5px;
    width: 80px;
    height: 30px;

    & > img {
      width: 11px;
      height: 15px;
    }

    & > div {
      margin-left: 10px;
      color: #3d89fc;
      font-size: 12px;
      font-weight: 500;
    }
  }
}
</style>
