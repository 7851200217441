<template>
  <div class="tips-container" :class="type === 'success' ? 'success' : type === 'error' ? 'error' : 'info'">
    <div class="msg">{{ message }}</div>
    <div class="timer">{{ countDownStr }}</div>
  </div>
</template>
<script>
export default {
  props: {
    message: String,
    type: String,
    countDownStr: String,
  },
};
</script>
<style lang="scss" scoped>
.success {
  background: #a7ddb7;
  color: #00771c;
}

.error {
  background: #dda7a7;
  color: #700000;
}

.info {
  background: #e9e9be;
  color: #000000;
}

.tips-container {
  display: flex;
  align-items: center;
  width: 350px;
  // max-width: var(--max-width);
  // min-width: var(--max-width);
  min-height: 45px;
  margin-top: 10px;
  border-radius: 7px;

  .msg {
    flex: 1;
    padding: 10px;
    font-size: 12px;
    font-weight: 500;
    word-break: break-word;
  }

  .timer {
    width: 13px;
    padding: 0 10px;
    font-size: 12px;
    font-weight: 500;
    text-align: center;
  }
}
</style>
