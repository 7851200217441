<template>
  <div class="container">
    <div class="wrapper">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 30;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .wrapper {
    width: 100%;
    max-height: 100vh;
    border-radius: 8px;
    background: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
</style>
