<template>
  <div class="container">
    <!-- <div class="context">{{ messageInfo }}</div> -->
    <div class="cover">
      <img :src="$global.data.cdn_url+messageInfo.cover_images" alt="">
    </div>
    <div class="title">
      {{ messageInfo.title }}
    </div>
    <div class="desc">
      {{ messageInfo.details }}
    </div>
    <div class="btns">
      <button>juntar</button>
      <!-- <button>联系客服</button> -->
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      id: "",
      messageInfo: "",
    };
  },
  mounted() {
    this.$emit("update:title", this.$t("messages"));
    this.id = this.$route.params.id;
    this.fetchMessageDetail();
  },
  methods: {
    fetchMessageDetail() {
      const data = {
        action: "view_platform_message",
        data: {
          id: this.id,
        },
      };
      this.$http
        .post("userCenter", data)
        .then((response) => {
          this.messageInfo = response.data.message_info;
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;

  .context {
    flex: 1;
    margin: 25px;
    color: #ffffff;
    font-size: 14px;
    font-weight: 500;
    text-align: left;
  }

  .cover {
    height: 140px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .title {
    width: 100%;
    color: #fff;
    font-size: 12px;
    font-weight: bold;
    text-align: left;
    padding: 12px;
  }

  .desc {
    color: #fff;
    width: 100%;
    font-size: 12px;
    opacity: 0.8;
    text-align: left;
    padding: 0 12px;
  }

  .btns {
    padding: 0 20px;
    margin-top: 90px;

    button {
      background: #FFD400;
      outline: none;
      border: none;
      font-size: 12px;
      width: 100%;
      height: 40px;
      border-radius: 8px;
      font-size: 14px;
      font-weight: bold;
    }
  }
}
</style>
