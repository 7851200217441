<template>
  <div class="transaction">
    <div class="wrapper">
      <CategoryComp class="cate" :list="cateList" v-model:index="index"></CategoryComp>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import CategoryComp from "@/components/CategoryComp.vue";
export default {
  components: { CategoryComp },
  props: {
    title: {
      type: String,
      default: "",
    },
  },
  emits: ["update:title"],
  data() {
    return {
      index: 0,
      cateList: [
        {
          title: this.$t("bet"),
          path: "m-flow",
        },
        {
          title: this.$t("deposit"),
          path: "m-deposit",
        },
        {
          title: this.$t("drawing"),
          path: "m-withdrawals",
        },
        {
          title: this.$t('bonus'),
          path: "m-bonus",
        },
      ],
    };
  },
  mounted() {
    this.$emit("update:title", this.$t("transaction_records"));
  },
  methods: {
    onTapCateItem(item) {
      this.index = item.index;
    },
  },
};
</script>

<style lang="scss" scoped>
.transaction {
  display: flex;
  flex-direction: row;

  .wrapper {
    padding: 0 15px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    width: 100%;

    .cate {
      margin-top: 18px;
      margin-bottom: 30px;
    }
  }
}
</style>
