<template>
  <div class="container">
    <div class="box1">
      <div class="item" v-if="goldenClubInfo?.this_week !== null">
        <div class="title">Bônus da semana</div>
        <div class="money">R$:{{ $tools.formatCurrency(goldenClubInfo?.this_week?.prize) }}</div>
        <div class="count-down-title">Iniciar Contagem Regressiva</div>
        <div class="count-down-value">{{ goldenCountDown }}</div>
      </div>
      <div class="item" v-if="goldenClubInfo?.this_week !== null">
        <div class="title">Missão da Semana</div>
        <div class="des">
          Apostas qualificadas da semana:R$
          {{ $tools.formatCurrency(goldenClubInfo?.this_week?.user_effective_bet) }}
        </div>
        <div class="progress-current-value">{{ goldThisWeekTaskValue }}%</div>
        <div class="progress">
          <div class="progress-current" :style="{ width: goldThisWeekTaskValue + '%' }"></div>
        </div>
        <div class="progress-des">
          <div>{{ userInfo?.vip_level_name }}</div>
          <div>apostas válidas:{{ $tools.formatCurrency(goldenClubInfo?.this_week?.vip_level_need_effective_bet) }}</div>
        </div>
      </div>
    </div>
    <div class="box2">
      <div>O último ciclo recebeu bônus</div>
      <div>Bônus Pendente para Receber</div>
      <div>R$:{{ goldenWinPrize }}</div>
    </div>
    <div class="btn">Receber</div>
    <div class="winner-box">
      <div class="title-box">
        <div>Lista de Vencedores</div>
        <div>Valor do Prêmio</div>
      </div>
      <div class="value-box">
        <div>{{ monthAndDay(goldenClubInfo?.last_week?.start_date) }}~{{ monthAndDay(goldenClubInfo?.last_week?.end_date) }}</div>
        <div>R$:{{ $tools.formatCurrency(goldenClubInfo?.last_week?.prize) }}</div>
      </div>
    </div>
    <div class="btn2" @click="fetchGoldenUserList">Ver Lista</div>
    <div class="rule-box">
      <div class="title">Explicação</div>
      <div class="content">
        1. Membros Gold desbloqueiam benefícios do clube;<br />
        2. O período de recebimento é semanal, apostas feitas esta semana podem ser recebidas na próxima segunda-feira;<br />
        3. Requisitos da tarefa: Apostas válidas esta semana ≥ 1000 para participar das atividades do clube;<br />
        4. Os benefícios do clube são divididos em: 3 prêmios de primeira classe, 30 prêmios de segunda classe e número ilimitado de prêmios de terceira classe
        (completar a tarefa para receber);<br />
        5. A lista de premiação do clube é gerada aleatoriamente entre os usuários que completaram a tarefa esta semana. Quanto mais apostas válidas feitas esta
        semana, maior a chance de ganhar o primeiro e segundo prêmios;<br />
        6. Horário de recebimento: Você pode receber dentro de 7 dias após o anúncio da lista de premiação. Se perder a data de recebimento, não poderá mais
        receber;<br />
        7. O prêmio recebido será automaticamente transferido para sua carteira de conta.<br />
      </div>
    </div>
    <div class="add-up-box">
      <div class="title">Reembolso de Apostas Recebido Acumulado</div>
      <div class="money-box">
        <img src="@/assets/level/coin.webp" alt="" />
        <div class="money">&nbsp;{{ $tools.formatCurrency(goldenClubInfo?.total_amount) }}</div>
      </div>
      <div class="img-box">
        <img src="@/assets/level/icon6.webp" alt="" />
      </div>
    </div>
    <div class="btn3" @click="$tools.openTelegram($global.data.cooperate_link)">Feedback e Sugestões</div>

    <dialog ref="listDialog" v-if="goldenUserList">
      <DialogContentComp :headerImgPath="require('@/assets/level/dialog_icon.webp')" @closeEvent="$refs.listDialog.close()">
        <div class="list-wrapper">
          <div class="title">
            lista de vencedores（{{ monthAndDay(goldenClubInfo?.last_week?.start_date) + "~" + monthAndDay(goldenClubInfo?.last_week?.end_date) }}）
          </div>
          <div class="list">
            <div class="item">
              <div class="sub-title">primeiro prêmio（R$ {{ $tools.formatCurrency(goldenUserList["1"]?.prize) }}）</div>
              <div class="list">
                <div v-for="(item, index) in goldenUserList['1']?.user_list" :key="index">ID：{{ item }}</div>
              </div>
            </div>
            <div class="item">
              <div class="sub-title">segundo prêmio（R$ {{ $tools.formatCurrency(goldenUserList["2"]?.prize) }}）</div>
              <div class="list">
                <div v-for="(item, index) in goldenUserList['2']?.user_list" :key="index">ID：{{ item }}</div>
              </div>
            </div>
            <div class="item">
              <div class="sub-title">terceiro prêmio（R$ {{ $tools.formatCurrency(goldenUserList["3"]?.prize) }}）</div>
              <div class="list">lista omitida</div>
            </div>
          </div>
        </div>
      </DialogContentComp>
    </dialog>
  </div>
</template>
<script>
import DialogContentComp from "@/components/DialogContentComp.vue";
export default {
  components: {
    DialogContentComp,
  },
  data() {
    return {
      goldenClubInfo: null,
      goldenCountDown: "",
      goldenUserList: null,
    };
  },
  mounted() {
    this.$emit("update:title", "Clube de Ouro");
    this.fetchGoldenClubInfo();
  },
  computed: {
    goldThisWeekTaskValue() {
      if (this.goldenClubInfo?.this_week?.vip_level_need_effective_bet === 0) {
        return 0;
      }

      const value = (this.goldenClubInfo?.this_week?.user_effective_bet / this.goldenClubInfo?.this_week?.vip_level_need_effective_bet) * 100;
      if (value > 100) {
        return 100;
      }

      return value;
    },
    goldenWinPrize() {
      if (this.goldenClubInfo?.last_week?.have_drawn === 0) {
        return "0.00";
      } else {
        return this.$tools.formatCurrency(this.goldenClubInfo?.last_week.win_prize);
      }
    },
  },
  methods: {
    fetchGoldenClubInfo() {
      const data = {
        action: "golden_club_info",
        data: {},
      };

      this.$http
        .post("userVip", data)
        .then((response) => {
          this.goldenClubInfo = response.data;
          this.startGoldenCountDown();
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    fetchGoldenUserList() {
      const data = {
        action: "golden_club_user_list",
        data: {
          golden_club_id: this.goldenClubInfo.last_week.golden_club_id,
        },
      };

      this.$http
        .post("userVip", data)
        .then((response) => {
          this.goldenUserList = response.data;
          this.$nextTick(() => {
            this.$refs.listDialog.showModal();
          });
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    startGoldenCountDown() {
      const dateString = this.goldenClubInfo?.this_week?.end_date + " 23:59:59";
      const dateTimestamp = new Date(dateString).getTime();
      const currentTimestamp = Date.now();
      const difference = dateTimestamp - currentTimestamp;
      let seconds = Math.floor(difference / 1000);
      setInterval(() => {
        seconds--;
        const hours = Math.floor(seconds / 3600)
          .toString()
          .padStart(2, "0");
        const minutes = Math.floor((seconds % 3600) / 60)
          .toString()
          .padStart(2, "0");
        const remainingSeconds = (seconds % 60).toString().padStart(2, "0");
        this.goldenCountDown = `${hours}:${minutes}:${remainingSeconds}`;
      }, 1000);
    },
    monthAndDay(dateStr) {
      if (dateStr === null || dateStr === undefined) {
        return "";
      }
      return dateStr.split("-")[1] + dateStr.split("-")[2];
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  padding: 0 10px;

  .box1 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;

    .item {
      flex: 1;
      background: var(--mine-card-bg-color);
      height: 120px;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0 10px;

      .title {
        margin-top: 9px;
        color: #ffffff;
        font-size: 12px;
        font-weight: 500;
      }

      .money {
        color: #10b349;
        font-weight: bold;
        font-size: 21px;
      }

      .count-down-title {
        margin-top: 17px;
        color: #96a7c6;
        font-size: 9px;
        font-weight: 300;
      }

      .count-down-value {
        margin: 0;
        margin-bottom: 6px;
        color: #0ac325;
        font-size: 10px;
        font-weight: 500;
      }

      .des {
        text-align: left;
        color: #96a7c6;
        font-size: 10px;
        font-weight: 300;
      }

      .progress-current-value {
        margin-top: 18px;
        width: 100%;
        color: #96a7c6;
        font-size: 9px;
        font-weight: 500;
      }

      .progress {
        background: #141414;
        border-radius: 5px;
        height: 7px;
        width: 100%;

        .progress-current {
          background: #11793b;
          border-radius: 5px;
          height: 7px;
          width: 10%;
        }
      }
      .progress-des {
        margin-top: 10px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        color: #96a7c6;
        font-size: 9px;
        font-weight: 300;
      }
    }
  }

  .box2 {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    background: #0a1f32;
    height: 120px;
    border-radius: 10px;

    & > div:nth-child(1) {
      margin-top: 10px;
      color: #96a7c6;
      font-size: 9px;
      font-weight: 500;
    }

    & > div:nth-child(2) {
      color: #ffffff;
      font-size: 12px;
      font-weight: 500;
    }

    & > div:nth-child(3) {
      margin-top: 20px;
      color: #10b349;
      font-size: 21px;
      font-weight: bold;
    }
  }

  .btn {
    margin-top: 10px;
    background: #ffd400;
    height: 40px;
    line-height: 40px;
    border-radius: 8px;
    color: #06131c;
    font-size: 13px;
    font-weight: 500;
  }

  .winner-box {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #0a1f32;
    height: 50px;
    border-radius: 10px;
    padding: 10px 14px;

    .title-box {
      display: flex;

      & > div {
        flex: 1;
        color: #96a7c6;
        font-size: 12px;
        font-weight: 500;
        text-align: left;
      }
    }

    .value-box {
      display: flex;

      & > div:nth-child(1) {
        flex: 1;
        color: #ffffff;
        font-size: 12px;
        font-weight: 500;
        text-align: left;
      }
      & > div:nth-child(2) {
        flex: 1;
        color: #10b349;
        font-size: 16px;
        font-weight: bold;
        text-align: left;
      }
    }
  }

  .btn2 {
    margin-top: 10px;
    background: #33439e;
    height: 34px;
    line-height: 34px;
    border-radius: 8px;
    color: #ffffff;
    font-size: 12px;
    font-weight: 500;
  }

  .rule-box {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    & > .title {
      color: #ffffff;
      font-size: 12px;
      font-weight: bold;
    }

    & > .content {
      margin-top: 10px;
      text-align: left;
      color: #96a7c6;
      font-size: 10px;
      font-weight: 500;
    }
  }

  .add-up-box {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    background: linear-gradient(0deg, #0a1f32 0%, #293577 100%);
    height: 50px;
    border-radius: 10px;
    padding: 10px 16px;
    position: relative;

    & > .title {
      color: #96a7c6;
      font-size: 12px;
      font-weight: 500;
    }

    & > .money-box {
      display: flex;
      align-items: center;

      & > img {
        width: 15px;
        height: 15px;
      }

      & > .money {
        color: #ffd400;
        font-size: 15px;
        font-weight: 500;
      }
    }

    & > .img-box {
      position: absolute;
      right: 0;
      bottom: 0;
      display: flex;

      & > img {
        width: 54.5px;
        height: auto;
      }
    }
  }

  .btn3 {
    margin-top: 10px;
    background: #0a1f32;
    height: 34px;
    line-height: 34px;
    border-radius: 8px;
    color: #96a7c6;
    font-size: 12px;
    font-weight: 500;
  }

  .list-wrapper {
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    background: #0a1f32;
    width: 345px;

    & > .title {
      margin: 20px 20px 0;
      color: #ffffff;
      font-size: 12px;
      font-weight: 500;
      text-align: left;
    }

    & > .list {
      display: flex;
      flex-direction: column;
      padding: 0 20px;
      margin-top: 10px;
      margin-bottom: 28px;

      & > .item {
        margin-top: 10px;
        display: flex;
        flex-direction: column;

        & > .sub-title {
          text-align: left;
          color: #ffffff;
          font-size: 10px;
          font-weight: 500;
        }

        & > .list {
          margin-top: 5px;
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          column-gap: 10px;
          color: #96a7c6;
          font-size: 10px;
          font-weight: 500;
          text-align: left;
        }
      }
    }
  }
}
</style>
