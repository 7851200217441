<template>
  <div class="providersSetWithTitleCompWrapper">
    <div class="titleBox">
      <div class="title">
        <img class="icon" :src="$global.data.cdn_url + icon" alt="" />
        <div>{{ title }}</div>
      </div>
      <img src="@/assets/casino/tag_title_bg.webp" alt="" />
      <div class="arrow" @click="handleOfScroll('left')">
        <img src="@/assets/common/arrow_left.webp" alt="" />
      </div>
      <div class="arrow" @click="handleOfScroll('right')">
        <img src="@/assets/common/arrow_right.webp" alt="" />
      </div>
    </div>
    <div class="providers" v-if="providers != null" ref="scrollContainer" :style="{
      display: 'grid',
      'grid-template-columns': `repeat(${numColumns}, 1fr)`,
    }">
      <div class="card" v-for="(item, index) in providers" :key="index" @click="handleOfProvider(item)">
        <img :src="$global.data.cdn_url + item.provider_logo" alt="" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    icon: {
      type: String,
      default: "",
    },
    title: String,
    providers: null,
  },
  data() {
    return {
      numColumns: 10,
    };
  },
  mounted() { },
  methods: {
    handleOfProvider(providerInfo) {
      if (providerInfo.id === 1001) {
        // PG
        this.$tools.userTrackReport({ track_id: "27" });
      } else if (providerInfo.id === 1003) {
        // JDB
        this.$tools.userTrackReport({ track_id: "29" });
      } else if (providerInfo.id === 1004) {
        // PP
        this.$tools.userTrackReport({ track_id: "28" });
      }
      this.$router.push({
        path: "/m-game-list/" + providerInfo.provider_name,
        query: { id: providerInfo.id, type: "provider" },
      });
    },
    handleOfScroll(direction) {
      const container = this.$refs.scrollContainer;
      const scrollAmount = 345; // 滚动量可以根据需要调整

      if (direction === "left") {
        container.scrollTo({
          left: container.scrollLeft - scrollAmount,
          behavior: "smooth",
        });
      } else {
        container.scrollTo({
          left: container.scrollLeft + scrollAmount,
          behavior: "smooth",
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.providersSetWithTitleCompWrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;

  .titleBox {
    width: 100%;
    height: 34px;
    display: flex;
    align-items: center;

    &>.title {
      min-width: 180px;
      height: 100%;
      background-color: #006a56;
      display: flex;
      align-items: center;

      color: #81d6ba;
      font-size: 14px;
      font-weight: 500;

      &>.icon {
        margin-left: 15px;
        margin-right: 5px;
        width: 16px;
        height: 16px;
      }
    }

    &>img {
      width: auto;
      height: 100%;
      margin-right: auto;
    }

    .arrow {
      margin-left: 3px;
      background: #006a56;
      border-radius: 4px;
      width: 23px;
      height: 25px;
      display: flex;
      justify-content: center;
      align-items: center;

      &>img {
        width: 5px;
        height: 9px;
      }
    }

    .arrow:last-child {
      margin-right: 5px;
    }
  }

  .providers {
    background: #006a56;
    overflow-x: scroll;
    scroll-behavior: smooth;
    display: grid;
    row-gap: 10px;
    column-gap: 10px;
    min-height: 45px;
    padding: 10px 0 10px 15px;

    .card {
      display: flex;
      justify-content: center;
      align-items: center;

      &>img {
        width: 105px;
        height: 45px;
      }
    }
  }
}
</style>
