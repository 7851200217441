<template>
  <ModalPopupComp @close="closeHandler">
    <div class="box">
      <div class="title">Saldo Retirável</div>
      <div class="rules">
        <div>
          Cada depósito ,Todos precisam passar por uma aposta válida antes que ela possa ser convertida em uma Saldo
          Retirável.
        </div>
        <div>Apenas apostas que geram ganhos e perdas podem obter a Saldo Retirável.</div>
        <div>Todas as apostas canceladas, rejeitadas e empatadas não podem obter a Saldo Retirável.</div>
        <div>O valor máximo que pode ser sacado não utrapassar o Saldo Da Conta.</div>
      </div>
      <div class="calculation">
        <div class="subTitle">Método de cálculo</div>
        <div class="form">
          <div class="column" v-for="(col, index) in withdrawalCalList" :key="index">
            <div class="col-titel">{{ col.title }}</div>
            <div class="row-list">
              <div
                class="row"
                v-for="(row, index) in col.list"
                :key="index"
                :style="{
                  color:
                    col.title === 'clarification'
                      ? row === 'Failure'
                        ? '#D9796B'
                        : row === 'win'
                        ? '#42F388'
                        : '#989898'
                      : '#ffffff',
                }"
              >
                {{ row }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ModalPopupComp>
</template>

<script>
import ModalPopupComp from "@/components/ModalPopupComp.vue";
export default {
  data() {
    return {
      withdrawalCalList: [
        {
          title: "Aposta",
          list: ["100.00", "100.00", "100.00", "100.00", "100.00", "100.00", "100.00", "100.00"],
        },
        {
          title: "Ganhar",
          list: ["40.00", "80.00", "100.00", "120.00", "160.00", "200.00", "1000.00", "8000.00"],
        },
        {
          title: "Saldo Retirável",
          list: ["60.00", "20.00", "0.00", "20.00", "60.00", "100.00", "900.00", "7900.00"],
        },
      ],
    };
  },
  components: { ModalPopupComp },
  methods: {
    closeHandler() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
.box {
  display: flex;
  flex-direction: column;
}
.title {
  margin: 30px 40px 0;
  font-size: 18px;
  font-weight: bold;
  color: var(--color-popup-title);
}
.rules {
  margin: 20px 40px 0;
  font-size: 12px;
  font-weight: 300;
  line-height: 22px;
  color: #ffffff;
  opacity: 0.8;
  display: flex;
  flex-direction: column;

  div {
    margin-left: 0;
    text-align: left;
  }
}
.calculation {
  margin: 31px 40px 20px;
  display: flex;
  flex-direction: column;
  .subTitle {
    font-size: 12px;
    font-weight: 500;
    color: #bcbcbc;
    text-align: left;
  }

  .form {
    margin-top: 12px;
    display: flex;
    border-radius: 8px;
    overflow: hidden;
    color: #ffffff;
    font-size: 12px;
    .column {
      flex: 1;
      display: flex;
      flex-direction: column;
      background: #0c411f;
      .col-titel {
        font-weight: 500;
        line-height: 36px;
        background: rgba(233, 201, 126, 0.08);
        height: 36px;
      }

      .row-list {
        display: flex;
        flex-direction: column;
        margin: 14px auto;
        .row {
          line-height: 28px;
        }
      }
    }
  }
}

// @media (max-width: 768px) {
//   .box .title {
//     margin-left: 15px;
//     margin-right: 15px;
//   }
//   .box .rules {
//     margin-left: 15px;
//     margin-right: 15px;
//   }
//   .box .calculation {
//     margin-left: 15px;
//     margin-right: 15px;
//   }
// }
</style>
