<template>
  <div class="dialogCollectWrapper">
    <!-- 未登录欢迎弹窗 -->
    <dialog ref="welcomeDialog">
      <div class="welcome-dialog-wrapper">
        <div class="welcome-dialog-box">
          <div class="info-box">
            <div>{{ $t('welcome_to') }}</div>
            <div style="width: 75%;margin:0 auto;">
              {{ $t('minimum_recharge_10') }}<br />
              {{ $t('daily_login_for_free_lottery_draw') }}<br />
              {{ $t('daily_bonus_weekly_salary_monthly_salary_for_members') }}<br />
              {{ $t('follow_the_channel_for_more_information') }}<br />
            </div>
          </div>
          <img src="@/assets/welcome/img.webp" alt="" />
          <div class="button-box">
            <div class="button" @click="handleOfFollow">{{ $t('follow') }}</div>
          </div>
        </div>
        <div class="close_wrap" @click="handleOfCloseDialog">
          <img src="@/assets/login/close_icon.png" alt="" />
        </div>
      </div>
    </dialog>

    <!-- 首充活动弹窗 -->
    <dialog ref="dialogFirstChargeActivity">
      <div class="first-charge-activity-box">
        <img src="@/assets/dialog-collect/first-deposit.webp" alt="" @click="handleOfShowFirstChargeActivity" />
        <div class="dialogFirstChargeActivity_des1">{{ $t('first_deposit') }}</div>
        <div class="dialogFirstChargeActivity_des2">{{ $t('gift_bonus2') }}</div>
        <div class="dialogFirstChargeActivity_des3">{{ $t('receive_25%_per_week') }}</div>
        <div class="dialogFirstChargeActivity_des4">{{ $t('deposit') }}</div>
        <div class="close_wrap" @click="handleOfCloseDialog">
          <img class="close" src="@/assets/dialog-collect/close.webp" alt="" />
        </div>
      </div>
    </dialog>
    <!-- 每日抽奖 -->
    <dialog ref="dialogDailyDraw">
      <div class="dialogDailyDrawWrapper">
        <img src="@/assets/dialog-collect/daily-lottery-draw.webp" alt="" @click="handleOfDailyLotteryDraw" />
        <div class="dialogDailyDrawWrapper_des1">{{ $t('winning') }}</div>
        <div class="dialogDailyDrawWrapper_des2">{{ $t('go_to_the_lottery') }}</div>
        <div class="close_wrap" @click="handleOfCloseDialog">
          <img class="close" src="@/assets/dialog-collect/close.webp" alt="" />
        </div>
      </div>
    </dialog>
    <!-- 红包雨dialog -->
    <dialog ref="dialogRedBaoYuTips">
      <div class="dialogRedBaoYuTipsWrap">
        <div class="tiger">
          <img class="tiger-img" src="@/assets/tiger.webp" alt="" />
          <div class="close_wrap" @click="handleOfCloseDialog">
            <img class="close-img" src="@/assets/daily-login/close.png" alt="" />
          </div>
        </div>
        <div class="box">
          <div class="h1">{{ $t('red_envelope_rain') }}</div>
          <div class="h2">{{ $t('red_des2') }}</div>
          <div class="flex">
            <span>00:00~00:59</span>
            <span>13:00~13:59</span>
            <span>20:00~20:59</span>
          </div>
          <div class="ewai">
            <div class="h1">{{ $t('red_des6') }}</div>
            <div class="flex">
              <span>11:00~11:59</span>
              <span>22:00~22:59</span>
            </div>
          </div>
          <div class="pre">
            <div>
              {{ $t('pre_des1') }}
            </div>
          </div>
          <div class="hongbao">
            <img src="@/assets/hongbao.webp" alt="" />
          </div>
          <div class="btn" @click="handleOfShowRedBaoYu">Ver</div>
        </div>
      </div>
    </dialog>
    <!-- 新手任务弹窗 -->
    <dialog ref="newerDialog">
      <div class="newer-dialog-wrapper">
        <img src="@/assets/dialog-collect/newer-task.webp" alt="" @click="handleOfNewerDialogBtn" />
        <div class="newerDialog_des1">{{ $t('newbie_task') }}</div>
        <div class="newerDialog_des2">{{ $t('newbie_task_des1') }}</div>
        <div class="newerDialog_des3">{{ $t('newbie_task_des2') }}</div>
        <div class="newerDialog_des4">{{ $t('newbie_task_des3') }}</div>
        <div class="close_wrap" @click="handleOfCloseDialog">
          <img class="close" src="@/assets/dialog-collect/close.webp" alt="" />
        </div>
      </div>
    </dialog>
    <!-- 每日签到 -->
    <dialog ref="dialogCheckIn">
      <div class="check-in-wrapper">
        <img src="@/assets/dialog-collect/check-in.webp" alt="" @click="handleOfCheckIn" />
        <div class="dialogCheckIn_des1 dialogCheckIn_des">{{ $t('daily_check-in') }}</div>
        <div class="dialogCheckIn_des2 dialogCheckIn_des">{{ $t('login_for_7_consecutive_days_to_receive_the_highest_reward') }}
        </div>
        <div class="dialogCheckIn_des3 dialogCheckIn_des">{{ $t('day') }} 1</div>
        <div class="dialogCheckIn_des4 dialogCheckIn_des">{{ $t('day') }} 2</div>
        <div class="dialogCheckIn_des5 dialogCheckIn_des">{{ $t('day') }} 3</div>
        <div class="dialogCheckIn_des6 dialogCheckIn_des">{{ $t('day') }} 4</div>
        <div class="dialogCheckIn_des7 dialogCheckIn_des">{{ $t('day') }} 5</div>
        <div class="dialogCheckIn_des8 dialogCheckIn_des">{{ $t('day') }} 6</div>
        <div class="dialogCheckIn_des9 dialogCheckIn_des">{{ $t('day_7_luxury_gift_pack') }}</div>
        <div class="dialogCheckIn_des10 dialogCheckIn_des">{{ $t('login') }}</div>
        <div class="close_wrap" @click="handleOfCloseDialog">
          <img class="close" src="@/assets/dialog-collect/close.webp" alt="" />
        </div>
      </div>
    </dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      waitingPopupList: null,
    };
  },
  mounted() {
    this.queryPopupList();
  },
  methods: {
    async queryPopupList() {
      const data = {
        action: "popup_list",
        data: {},
      };
      try {
        const response = await this.$http.post("activity", data);
        this.waitingPopupList = response.data.popup_list;
        this.showNext();
      } catch (error) {
        console.log("error", error);
      }
    },
    showNext() {
      if (this.waitingPopupList.length === 0) {
        return;
      }

      const today = this.$tools.today();

      const obj = this.waitingPopupList.shift();
      switch (obj.type) {
        case "welcome_pack": // 欢迎弹窗
          this.$refs.welcomeDialog.showModal();
          break;
        case "user_first_pay_activity": // 首充活动
          this.$refs.dialogFirstChargeActivity.showModal();
          break;
        case "user_daily_draw":
          {
            // 每日抽奖
            const storageDate = localStorage.getItem(obj.type) || "";
            if (storageDate !== today) {
              localStorage.setItem(obj.type, today);
              this.$refs.dialogDailyDraw.showModal();
            } else {
              this.showNext();
            }
          }
          break;
        case "user_red_packet_rain": // 红包雨
          {
            const showDialogAndStorageBlock = () => {
              this.$refs.dialogRedBaoYuTips.showModal();
              localStorage.setItem(obj.type, Date.now());
            };

            const timestamp = localStorage.getItem(obj.type) || "";
            if (timestamp === 0) {
              showDialogAndStorageBlock();
              return;
            }

            const date = new Date(+timestamp);
            const dateStr = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;
            if (dateStr !== today) {
              // 年月日不同，显示弹窗
              showDialogAndStorageBlock();
            } else {
              const recordHour = date.getHours();
              const nowHour = new Date().getHours();
              if (nowHour >= 0 && nowHour < 12 && recordHour >= 12) {
                // 每日00:00~11:59,记录的小时不在此区间内，显示弹窗
                showDialogAndStorageBlock();
              } else if (nowHour >= 12 && nowHour < 20 && recordHour < 12 && recordHour >= 20) {
                // 每日12:00~19:59,记录的小时不在此区间内，显示弹窗
                showDialogAndStorageBlock();
              } else if (nowHour >= 20 && recordHour < 20) {
                // 每日20:00~23:59,记录的小时不在此区间内，显示弹窗
                showDialogAndStorageBlock();
              } else {
                this.showNext();
              }
            }
          }
          break;
        case "new_user_activity":
          {
            // 新手任务
            const date = localStorage.getItem(obj.type) || "";
            if (date !== today) {
              localStorage.setItem(obj.type, today);
              this.$refs.newerDialog.showModal();
            } else {
              this.showNext();
            }
          }
          break;
        case "user_daily_check_in":
          {
            // 每日签到
            const date = localStorage.getItem(obj.type) || "";
            if (date !== today) {
              localStorage.setItem(obj.type, today);
              this.$refs.dialogCheckIn.showModal();
            } else {
              this.showNext();
            }
          }
          break;
        default:
          break;
      }
    },
    handleOfCloseDialog() {
      this.$refs.welcomeDialog.close();
      this.$refs.dialogRedBaoYuTips.close();
      this.$refs.dialogFirstChargeActivity.close();
      this.$refs.dialogDailyDraw.close();
      this.$refs.newerDialog.close();
      this.$refs.dialogCheckIn.close();
      this.showNext();
    },
    handleOfFollow() {
      this.$tools.openTelegram();
      this.$refs.welcomeDialog.close();
    },
    handleOfShowRedBaoYu() {
      this.$refs.dialogRedBaoYuTips.close();
      this.$router.push({ name: "m-redbaoyu" });
    },
    handleOfShowFirstChargeActivity() {
      this.$refs.dialogFirstChargeActivity.close();
      this.$router.push({ name: "first-deposit" });
    },
    handleOfDailyLotteryDraw() {
      this.$refs.dialogDailyDraw.close();
      this.$router.push({ name: "daily-lottery-draw" });
    },
    handleOfNewerDialogBtn() {
      this.$refs.newerDialog.close();
      this.$router.push({ name: "newer-task" });
    },
    handleOfCheckIn() {
      this.$refs.dialogCheckIn.close();
      this.$router.push({ name: "daily-check-in" });
    },
  },
};
</script>
<style lang="scss" scoped>
.dialogCollectWrapper {
  display: flex;
  flex-direction: column;

  .welcome-dialog-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 320px;
    position: relative;

    .welcome-dialog-box {
      width: 100%;
      height: 392px;
      display: flex;
      flex-direction: column;
      align-items: center;
      background: url("@/assets/welcome/bg1.webp") no-repeat;
      background-size: 281.5px 392px;

      .info-box {
        margin-top: 113px;
        margin-left: 20px;
        margin-right: 20px;
        width: 280px;
        height: 163px;
        background: #015545;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        position: relative;

        &>div:nth-child(1) {
          color: #ffffff;
          font-size: 16px;
          font-weight: 800;
          margin-top: 16px;
        }

        &>div:nth-child(2) {
          color: #ffffff;
          font-size: 12px;
          font-weight: normal;
        }
      }

      &>img {
        position: absolute;
        width: 320px;
        height: 73.5px;
        top: 203px;
      }

      .button-box {
        background: #015545;
        height: 84px;
        width: 100%;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;

        &>.button {
          background: #ffd400;
          width: 280px;
          height: 45px;
          line-height: 45px;
          border-radius: 10px;
          color: #000000;
          font-size: 18px;
          font-weight: bold;
        }
      }
    }

    .close_wrap {
      position: absolute;
      width: 40px;
      height: 40px;
      top: 59px;
      right: 12px;
      display: flex;
      align-items: center;
      justify-content: center;

      &>img {
        width: 20px;
        height: 20px;

      }
    }

  }

  .first-charge-activity-box {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 320px;
    color: #fff;
    font-weight: bold;
    font-size: 12px;

    .dialogFirstChargeActivity_des1 {
      position: absolute;
      top: 27%;
      left: 14%;
      width: 73px;
    }

    .dialogFirstChargeActivity_des2 {
      position: absolute;
      top: 27%;
      right: 19%;
      width: 73px;
    }

    .dialogFirstChargeActivity_des3 {
      position: absolute;
      top: 54%;
      left: 28%;
      color: #C5ADFF;
      font-weight: 500;
    }

    .dialogFirstChargeActivity_des4 {
      position: absolute;
      bottom: 14%;
      left: 45%;
      font-size: 18px;
    }

    .close_wrap {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 60px;
      height: 60px;
      top: 20px;
      right: 35px;

      .close {
        width: 20px;
        height: 20px;

      }
    }

    &>img {
      width: 100%;
      height: auto;
    }


  }

  .dialogDailyDrawWrapper {
    display: flex;
    flex-direction: column;
    position: relative;

    .dialogDailyDrawWrapper_des1 {
      position: absolute;
      bottom: 13.3%;
      left: 53%;
      width: 200px;
      color: #FFD400;
      font-weight: 900;
      margin-left: -100px;
      text-align: center;
    }

    .dialogDailyDrawWrapper_des2 {
      position: absolute;
      bottom: 3.2%;
      left: 28.5%;
      color: #004033;
      font-weight: 800;
      font-size: 20px;
    }

    &>img {
      width: 315.5px;
      height: auto;
    }

    .close_wrap {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 60px;
      height: 60px;
      top: 0px;
      right: 10px;

      .close {
        width: 20px;
        height: 20px;

      }
    }
  }

  .newer-dialog-wrapper {
    position: relative;

    .newerDialog_des1 {
      position: absolute;
      top: 20%;
      width: 95%;
      color: #004236;
      font-size: 20px;
      font-weight: 800;
    }

    .newerDialog_des2 {
      position: absolute;
      top: 35%;
      width: 95%;
      padding: 0 30%;
      box-sizing: border-box;
      color: #fff;
      font-size: 11px;
      font-weight: 500;
    }

    .newerDialog_des3 {
      position: absolute;
      top: 62%;
      width: 95%;
      box-sizing: border-box;
      color: #81D6BA;
      font-size: 11px;
      font-weight: 500;
    }

    .newerDialog_des4 {
      position: absolute;
      bottom: 11.5%;
      width: 95%;
      box-sizing: border-box;
      color: #fff;
      font-size: 18px;
      font-weight: 500;
    }

    &>img {
      width: 316px;
      height: auto;
    }

    .close_wrap {
      position: absolute;
      top: 0px;
      right: 25px;
      width: 60px;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;

      .close {
        width: 20px;
        height: 20px;

      }
    }

  }

  .check-in-wrapper {
    position: relative;

    .dialogCheckIn_des {
      position: absolute;
      top: 0;
      color: #7CF4A0;
    }

    .dialogCheckIn_des1 {
      position: absolute;
      top: 23%;
      left: 17%;
      font-size: 18px;
      font-weight: bold;
      color: #FFD400;
    }
    .dialogCheckIn_des2 {
      top: 29%;
      left: 7.5%;
      padding: 0 10%;
      box-sizing: border-box;
      font-size: 10px;
      font-weight: 500;
      color: #81D6BA;
      text-align: left;
    }
    .dialogCheckIn_des3 {
      top: 39%;
      left: 22.5%;
      color: #7CF4A0;
      font-size: 10px;
      font-weight: bold;
    }
    .dialogCheckIn_des4 {
      top: 39%;
      left: 48.5%;
      color: #7CF4A0;
      font-size: 10px;
      font-weight: bold;
    }
    .dialogCheckIn_des5 {
      top: 39%;
      left: 74%;
      color: #7CF4A0;
      font-size: 10px;
      font-weight: bold;
    }
    .dialogCheckIn_des6 {
      top: 55.5%;
      left: 22.5%;
      color: #7CF4A0;
      font-size: 10px;
      font-weight: bold;
    }
    .dialogCheckIn_des7 {
      top: 55.5%;
      left: 48.5%;
      color: #7CF4A0;
      font-size: 10px;
      font-weight: bold;
    }
    .dialogCheckIn_des8 {
      top: 55.5%;
      left: 74%;
      color: #7CF4A0;
      font-size: 10px;
      font-weight: bold;
    }
    .dialogCheckIn_des9 {
      top: 73.5%;
      left: 2%;
      width: 100%;
      color: #fff;
      font-size: 15px;
      font-weight: bold;
    }
    .dialogCheckIn_des10 {
      top: 88.5%;
      left: 2%;
      width: 100%;
      color: #fff;
      font-size: 18px;
      font-weight: bold;
    }

    &>img {
      width: 283px;
      height: auto;
    }

    .close_wrap {
      position: absolute;
      top: 0px;
      right: 0px;
      width: 60px;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;

      .close {

        width: 20px;
        height: 20px;

      }
    }

  }

  .dialogRedBaoYuTipsWrap {
    width: 320px;
    height: 390px;
    // background: #fff;
    color: red;
    text-align: left;

    .tiger {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 20px 0 20px;

      .tiger-img {
        width: 144px;
        height: 93px;
      }

      .close_wrap {
        position: absolute;
        top: 30px;
        right: 0;
        width: 60px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .close-img {
        width: 20px;
        height: 20px;
      }
    }

    .box {
      height: 278px;
      border-radius: 40px;
      background: #015545;
      border: 5px solid #ffbe3c;
      padding: 15px 15px;
      box-sizing: border-box;

      .h1 {
        font-size: 18px;
        font-weight: bold;
        color: #ffd400;
      }

      .h2 {
        font-size: 10px;
        color: #fff;
        line-height: 2;
        font-weight: 500;
      }

      .flex {
        display: flex;
        justify-content: space-between;
        margin-top: 6px;

        span {
          // width: 90px;
          padding: 0 8px;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 5px;
          background: #006a56;
          box-sizing: border-box;
          border: 1px solid #007a63;
          font-size: 12px;
          font-weight: bold;
          text-align: center;
          color: #7cf4a0;
        }
      }

      .ewai {
        margin-top: 8px;

        .h1 {
          font-size: 10px;
          font-weight: 500;
          color: #ffffff;
        }

        .flex {
          display: flex;
          justify-content: flex-start;

          span {
            &:first-child {
              margin-right: 5.5px;
            }
          }
        }
      }

      .pre {
        font-size: 11px;
        line-height: 20px;
        color: #81d6ba;
        margin-top: 8px;
        width: 60%;
      }

      .hongbao {
        width: 58.5px;
        height: 71px;
        position: absolute;
        right: 15px;
        bottom: 50px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .btn {
        width: 280px;
        height: 45px;
        border-radius: 40px;
        background: linear-gradient(180deg, #00a5d1 0%, #673bcd 100%);
        box-shadow: inset -2px 2px 5px 0px #00f1e9;
        max-width: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        font-weight: 500;
        color: #ffffff;
        position: absolute;
        bottom: 0;
      }
    }
  }
}
</style>
