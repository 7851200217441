<template>
  <div class="container">
    <div class="waining">{{ $t('add_pix_des0') }}</div>
    <div class="item-box">
      <img class="icon" src="@/assets/pix/name_icon.webp" alt="" />
      <input type="text" :placeholder="$t('add_pix_des2')" v-model="name" />
    </div>
    <div class="item-box" @click="isShowPixList = true">
      <img class="icon" src="@/assets/pix/type_icon.webp" alt="" />
      <div>{{ list[index] }}</div>
      <img class="ext" src="@/assets/pix/arrow_down.webp" alt="" />
    </div>
    <div class="pix-list" v-if="isShowPixList">
      <div class="item" v-for="(item, i) in list" :key="i" @click="handleOfSelectedType(i)">{{ item }}</div>
    </div>
    <div class="item-box" v-if="index !== 0">
      <img class="icon" src="@/assets/pix/type_icon.webp" alt="" />
      <input type="text" :placeholder="$t('add_pix_enter_your')" v-model="id" />
    </div>
    <div class="item-box">
      <img class="icon" src="@/assets/pix/cpf_icon.webp" alt="" />
      <input type="text" :placeholder="$t('add_pix_des3')" v-model="cpf" />
    </div>

    <div class="mind">
      {{ $t('add_pix_des1') }}
    </div>

    <div class="btn" @click="handleOfAdd">{{ $t('submit') }}</div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      list: ["CPF", "PHONE", "EMAIL"],
      index: 0,
      isShowPixList: false,
      name: "",
      id: "",
      cpf: "",
    };
  },
  mounted() {
    this.$emit("update:title", this.$t('add_pix_title'));
  },
  methods: {
    async handleOfAdd() {
      const data = {
        action: "add_pix_info",
        data: {
          real_name: this.name, // 真实姓名
          account_type: this.list[this.index], // PIX类型
          account_num: this.id, // PIX-ID
          customer_cert: this.cpf, // CPF
        },
      };
      try {
        const response = await this.$http.post("userWallet", data);
        console.log("response", response);
        this.$router.back();
      } catch (error) {
        console.log("error", error);
      }
    },
    handleOfSelectedType(i) {
      this.isShowPixList = false;
      this.index = i;
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  padding: 0px 15px;
  .waining{
    color: #700000;
    background: #DDA7A7;
    border-radius: 10px;
    font-size: 12px;
    padding: 10px 16px;
    font-weight: 500;
    text-align: left;
    margin: 20px 0 10px 0;
  }
  .item-box {
    margin-top: 10px;
    display: flex;
    align-items: center;
    background: #004134;
    height: 45px;
    border-radius: 10px;
    padding: 0px 15px;
    border: 1px solid #007A63;
    .icon {
      width: 15px;
      height: 15px;
    }

    & > input {
      flex: 1;
      margin-left: 10px;
      &::placeholder{
        color: #029479;
      }
    }

    & > div {
      flex: 1;
      margin-left: 10px;
      text-align: left;
      color: #029479;
      font-size: 12px;
      font-weight: 500;
    }

    .ext {
      width: 22px;
      height: 22px;
    }
  }

  .pix-list {
    display: flex;
    flex-direction: column;

    & > .item {
      background: #004134;
      color: #029479;
      font-size: 12px;
      font-weight: 500;
      line-height: 45px;
      margin-top: 10px;
    }
  }

  .mind {
    margin-top: 15px;
    color: #ff5000;
    font-size: 12px;
    font-weight: 500;
    text-align: left;
  }

  & > .btn {
    margin-top: 50px;
    background: #FFD400;
    border-radius: 5px;
    height: 45px;
    line-height: 45px;
    color: #000000;
    font-size: 18px;
    font-weight: 500;
  }
}
</style>
