<template>
  <div class="dialog-container">
    <div class="header">
      <div>{{ prompt }}</div>
      <img class="icon" v-if="verifyType === 2 && !cpf" src="@/assets/account/verify_dialog_phone_icon.webp" alt="" />
      <img class="icon" v-else-if="verifyType === 2 && cpf" src="@/assets/account/verify_dialog_cps_icon.webp" alt="" />
      <img class="icon" v-else-if="verifyType === 3 && !cpf" src="@/assets/verify_dialog_flow_icon.webp" alt="" />
      <img class="icon" v-else src="@/assets/account/verify_dialog_email_icon.webp" alt="" />
      <img class="close" src="@/assets/account/close.webp" alt="" @click="handleOfCloseDialog" />
    </div>
    <div class="title">{{ showTitle() }}</div>
    <div class="item-box" v-if="verifyType === 2">
      <img src="@/assets/account/phone_icon.webp" alt="" />
      <input type="number" :placeholder="enter_your_phone_number" v-model="dialogPhone" :disabled="isLockInput" />
    </div>
    <div class="item-box" v-if="verifyType === 1">
      <img src="@/assets/account/email_icon.webp" alt="" />
      <input type="text" :placeholder="enter_email_address" v-model="dialogEmail" :disabled="isLockInput" />
    </div>
    <div class="transactionFlow" v-if="verifyType === 3">
      <slot name="transactionFlow"></slot>
      <slot name="depositRecords"></slot>
    </div>
    <div class="item-box" v-if="verifyType === 1 || verifyType === 2">
      <img src="@/assets/account/verify_icon.webp" alt="" />
      <input type="number" :placeholder="enter_verification_code" v-model="msgCode" />
      <div class="btn" @click="handleOfMsgCode">{{ timer !== null ? countDown + "s" : obter }}</div>
    </div>
    <div class="btn" v-if="verifyType === 1 || verifyType === 2" @click="handleOfVerifyOk">{{ cpf ? binding : binding }}</div>
  </div>
</template>
<script>
import tools from "@/utils/tools";
import http from "@/utils/http";
import { useToast } from "vue-toast-notification";
import "vue-toast-notification/dist/theme-sugar.css";

export default {
  props: {
    cpf: {
      type: Boolean,
      default: false,
    },
    verifyType: {
      type: Number,
      default: 1, // 1邮箱 2手机号
    },
    phone: {
      type: String,
      default: "",
    },
    email: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    prompt:{
      type: String,
      default: "",
    },
    enter_your_phone_number:{
      type: String,
      default: "",
    },
    enter_email_address:{
      type: String,
      default: "",
    },
    enter_verification_code:{
      type: String,
      default: "",
    },
    obter:{
      type: String,
      default: "",
    },
    binding:{
      type: String,
      default: "",
    },
  },
  data() {
    const toast = useToast();
    const toastConfig = {
      position: "top",
      duration: 3000,
    };
    return {
      timer: null,
      captcha: "",
      countDown: 60,
      dialogEmail: this.email,
      dialogEmail2: "",
      dialogPhone: this.phone,
      dialogPhone2: "",
      msgCode: "",
      isLockInput: false,
      toast,
      toastConfig,
    };
  },
  watch: {
    email: {
      handler(val) {
        this.dialogEmail = val;
        if (this.verifyType === 1 && val !== "" && val !== null) {
          this.isLockInput = true;
        }
      },
      immediate: true,
    },
    phone: {
      handler(val) {
        if (val) this.dialogPhone = val.length > 11 ? val.substr(val.length - 11, 11) : val;
        if (this.verifyType === 2 && val !== "" && val !== null) {
          this.isLockInput = true;
        }
      },
      immediate: true,
    },
    verifyType(val) {
      if (val === 1 && this.email !== "" && this.email !== null) {
        this.isLockInput = true;
      } else if (val === 2 && this.phone !== "" && this.phone !== null) {
        this.isLockInput = true;
      } else {
        this.isLockInput = false;
      }
    },
  },
  methods: {
    showTitle() {
      if (this.title !== "") {
        return this.title;
      }
      return this.verifyType === 2 ? this.$t('bind_phone') : this.$t('bind_email');
    },
    async handleOfVerifyOk() {
      if (this.msgCode === "") {
        this.toast.error("Código de Verificação", this.toastConfig);
        this.$emit("closeEvent");
        return;
      }

      const params = {
        type: this.verifyType,
        captcha: this.msgCode,
      };

      if (this.verifyType === 1) {
        if (this.dialogEmail !== this.dialogEmail2) {
          this.toast.error("Falha na verificação do email", this.toastConfig);
          this.$emit("closeEvent");
          return;
        } else {
          params.email = this.dialogEmail;
        }
      } else if (this.verifyType === 2) {
        if (this.dialogPhone !== this.dialogPhone2) {
          this.toast.error("Falha na verificação do número de telefone", this.toastConfig);
          this.$emit("closeEvent");
          return;
        } else {
          params.phone = "55" + this.dialogPhone;
        }
      }

      const data = {
        action: "verify_user_info",
        data: params,
      };
      try {
        await http.post("userCenter", data);
        this.$emit("endVerifyEvent");
        this.handleOfCloseDialog();
      } catch (error) {
        console.log("error", error);
      }
    },
    async handleOfMsgCode() {
      if (this.timer !== null) {
        this.toast.error("Código de verificação enviado", this.toastConfig);
        return;
      }

      const params = {
        type: this.verifyType,
      };

      if (this.verifyType === 1) {
        if (tools.isValidEmail(this.dialogEmail) === false) {
          this.toast.error("Email", this.toastConfig);
          return;
        } else {
          params.email = this.dialogEmail;
        }
      } else {
        if (tools.isNumeric(this.dialogPhone) === false) {
          this.toast.error("Numero de telefone", this.toastConfig);
          return;
        } else {
          params.phone = "55" + this.dialogPhone;
        }
      }

      const data = {
        action: "sendCaptcha",
        data: params,
      };
      try {
        await http.post("userCenter", data);
        if (this.verifyType === 1) {
          this.dialogEmail2 = this.dialogEmail;
        } else {
          this.dialogPhone2 = this.dialogPhone;
        }
        this.timer = setInterval(() => {
          this.countDown--;
          if (this.countDown <= 0) {
            this.countDown = 60;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
      } catch (error) {
        console.log("error", error);
      }
    },
    handleOfCloseDialog() {
      this.$emit("closeEvent");
      this.dialogEmail = "";
      this.dialogEmail2 = "";
      this.dialogPhone = "";
      this.dialogPhone2 = "";
      this.msgCode = "";
    },
  },
};
</script>
<style lang="scss" scoped>
.dialog-container {
  display: flex;
  flex-direction: column;
  background: #015545;
  border-radius: 10px;
  overflow: hidden;

  & > .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #012c23;
    height: 48px;

    & > div {
      margin-left: 23px;
      color: #81d6ba;
      font-size: 12px;
      font-weight: 500;
    }

    & > .icon {
      width: 67px;
      height: 47px;
    }

    & > .close {
      margin-right: 22px;
      width: 20px;
      height: 20px;
    }
  }

  & > .title {
    margin: 30px 70px 0;
    color: #fff;
    font-size: 12px;
    font-weight: 500;
    text-align: center;
  }

  & > .item-box {
    align-self: center;
    min-width: 282px;
    max-width: 282px;
    margin: 20px 16px 0;
    background: #004134;
    border-radius: 10px;
    height: 45px;
    display: flex;
    align-items: center;
    padding: 0 15px;

    & > img {
      width: 15px;
      height: 15px;
    }

    & > input {
      flex: 1;
      min-width: 0;
      margin: auto 10px;

      &::placeholder {
        color: #00886e;
        font-size: 12px;
      }
    }

    & > .btn {
      border-radius: 6px;
      min-width: 58px;
      height: 28px;
      line-height: 28px;
      background: #029479;
      text-align: center;
    }
  }
  .transactionFlow {
    min-width: 282px;
    max-width: 282px;
    margin: 20px 16px 0;
  }

  & > .btn {
    margin: 37px 16px 16px;
    height: 40px;
    line-height: 40px;
    border-radius: 8px;
    background: #ffd400;
    color: #06131c;
    font-size: 15px;
    text-align: center;
  }
}
</style>
