<template>
  <div class="container">
    <div class="header">
      <div>R$</div>
      <div>&nbsp;{{ showAmount }}</div>
    </div>
    <div class="des1">
      {{ $t('can_be_extracted') }} <span>R${{ cofrinho_active_info?.factor }}</span>
    </div>
    <img src="@/assets/piggy-bank/logo.webp" alt="" />
    <div class="ratio-box">
      <div>20%</div>
      <div>{{ $t('reembolso') }}</div>
    </div>
    <div class="des2">{{ $t('refund_des') }}</div>
    <div class="money-box">
      <div class="item">
        <div>{{ $t('savings_bank_limit') }}</div>
        <div>{{ $tools.formatCurrency(cofrinho_active_info?.size) }}</div>
      </div>
      <div class="item">
        <div>{{ $t('withdrawn_credit_limit') }}</div>
        <div>{{ $tools.formatCurrency(cofrinho_active_info?.extract_amount) }}</div>
      </div>
    </div>
    <div class="btn" :class="cofrinho_active_info?.status !== 0 ? 'highlight' : ''" @click="handleOfButton">
      {{ cofrinho_active_info?.status === -1 ? $t('deposit') : cofrinho_active_info?.status === 0 ? $t('go_place_a_bet') : $t('extract') }}
    </div>
    <div class="rules">
      <div class="title">{{ $t('piggy_bank_page_des1') }}</div>
      <div>{{ $t('piggy_bank_page_des2') }}</div>
      <div class="title">{{ $t('piggy_bank_page_des3') }}</div>
      <div class="value">
        {{ $t('piggy_bank_page_des4') }}
        <span>{{ parseFloat(cofrinho_active_info?.ratio * 1000) }}‰</span> {{ $t('piggy_bank_page_des5') }}<br />
        {{ $t('piggy_bank_page_des6') }} <span>R${{ cofrinho_active_info?.factor }}</span> {{ $t('piggy_bank_page_des7') }}
        {{ cofrinho_active_info?.factor }}.<br />
        {{ $t('piggy_bank_page_des8') }}<br />
        {{ $t('piggy_bank_page_des9') }}<br />
        {{ $t('piggy_bank_page_des10') }}<br />
        {{ $t('piggy_bank_page_des11') }}<br />
      </div>
    </div>
    <div class="logs-wrapper">
      <div>{{ $t('piggy_bank_page_des12') }}</div>
      <div>{{ $t('piggy_bank_page_des13') }}</div>
      <div class="over-box" v-if="isOver">
        <div>{{ $t('piggy_bank_page_des15') }}</div>
        <div>{{ $t('piggy_bank_page_des16') }}</div>
      </div>
      <div v-else class="running-box">
        <div class="empty-box" v-if="cofrinho_active_info?.deposit_log.length === 0">
          <img src="../../assets/dice.png" alt="" />
          <div>Vazio</div>
        </div>
        <div v-else class="log-box">
          <div class="log-item" v-for="(item, index) in cofrinho_active_info?.deposit_log" :key="index">
            <div>{{ $tools.formatIsoDate(item.time) }}</div>
            <div>R$-{{ parseFloat(item.lose_amount).toFixed(4) }}</div>
            <div>+R${{ parseFloat(item.amount).toFixed(4) }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="logBtn" @click="hanldeOfShowRecords">{{ $t('piggy_bank_page_des14') }}</div>
    <!-- <div class="award-task-box" @click="handleOfAwardTask">
      <img src="@/assets/piggy-bank/icon.webp" alt="" />
      <div class="des-box">
        <div>Como utilizar o bônus?</div>
        <div>
          1. O bônus pode ser usado para apostar e experimentar diferentes jogos (não pode ser sacado)<br />
          2. Ao completar tarefas de bônus, ele pode ser convertido em dinheiro (pode ser sacado após apostas)<br />
        </div>
        <div>Converter agora 》</div>
      </div>
    </div> -->

    <dialog ref="successDialog">
      <div class="success-wrapper">
        <div class="header">
          <div>{{ $t('congratulations') }}</div>
          <img src="@/assets/award-task/coin.webp" alt="" />
          <img src="@/assets/newer-task/close.webp" alt="" @click="handleOfCloseSuccessDialog" />
        </div>
        <div class="title">{{ $t('success_in_withdrawal') }}</div>
        <div class="money">
          <div>R$</div>
          <div>&nbsp;{{ $tools.formatCurrency(extract_amount) }}&nbsp;</div>
        </div>
        <div class="des">{{ $t('it_has_already_been_automatically_deposited_into_the_money_wallet') }}</div>
        <div class="btn" @click="handleOfCloseSuccessDialog">{{ $t('understand') }}</div>
      </div>
    </dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      extract_amount: 0,
      cofrinho_active_info: null,
      isOver: false,
      showAmount: "0.00",
    };
  },
  computed: {
    progress() {
      if (this.cofrinho_active_info === null) {
        return 0;
      }

      if (this.cofrinho_active_info?.amount >= this.cofrinho_active_info?.size) {
        return 100;
      }

      return parseFloat((this.cofrinho_active_info?.amount / this.cofrinho_active_info?.size) * 100).toFixed(0);
    },
    minValue() {
      return this.$tools.formatCurrency(
        this.cofrinho_active_info?.cycle > 1 ? this.cofrinho_active_info?.cycle * this.cofrinho_active_info.size - this.cofrinho_active_info.size : 0
      );
    },
    maxValue() {
      return parseFloat(this.cofrinho_active_info?.size * this.cofrinho_active_info?.cycle).toFixed(4);
    },
  },
  mounted() {
    this.$emit("update:title", this.$t('piggy_bank'));
    this.fetchInfo();
    this.$tools.newerActivityFinish({ view_cofrinho_finish: 1 });
  },
  methods: {
    handleOfAwardTask() {
      this.$router.push({ name: "award-task-page" });
    },
    hanldeOfShowRecords() {
      this.$router.push({ name: "m-bonus" });
      // const isMobile = this.$tools.isMobile();
      // if (isMobile) {
      //   this.$router.push({ name: "m-bonus" });
      // } else {
      //   this.$router.push({ name: "bonus" });
      // }
    },
    handleOfCloseSuccessDialog() {
      this.$refs.successDialog.close();
      this.fetchInfo();
    },
    async extractBounty() {
      const data = {
        action: "extract_cofrinho",
        data: {},
      };
      try {
        const response = await this.$http.post("activity", data);
        this.extract_amount = response.data.extract_amount;
        this.$refs.successDialog.showModal();
      } catch (error) {
        console.log("error", error);
      }
    },
    handleOfButton() {
      const status = this.cofrinho_active_info.status;
      switch (status) {
        case -1:
          this.$router.push({ name: "m-deposit-page" });
          break;
        case 0:
          this.$router.push({ name: "casino" });
          break;
        case 1:
          this.extractBounty();
          break;
      }
    },
    async fetchInfo() {
      const data = {
        action: "cofrinho_active_info",
        data: {},
      };
      try {
        const response = await this.$http.post("activity", data);
        this.cofrinho_active_info = response.data.cofrinho_active_info;
        this.isOver = this.cofrinho_active_info.extract_amount >= this.cofrinho_active_info.size;
        this.showAmount = parseFloat(this.cofrinho_active_info.amount - this.cofrinho_active_info.extract_amount).toFixed(4);
      } catch (error) {
        console.log("error", error);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  padding: 0 16px;
  padding-bottom: 40px;
  & > .header {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 14px;
    & > div:nth-child(1) {
      color: #71c6ac;
      font-size: 18px;
      font-weight: bold;
    }

    & > div:nth-child(2) {
      color: #ffd400;
      font-size: 32px;
      font-weight: bold;
    }
  }

  & > .des1 {
    color: #71c6ac;
    font-size: 9px;
    font-weight: 500;

    & > span {
      color: #ffd400;
    }
  }

  & > img {
    margin: 30px auto 0;
    width: 248.5px;
    height: 213px;
  }

  .ratio-box {
    margin-left: auto;
    margin-top: -102px;
    background: linear-gradient(180deg, #e8669c 0%, #c82f6f 100%);
    border-radius: 10px 0px 10px 0px;
    width: 76px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 3px 0;

    & > div:nth-child(1) {
      color: #ffffff;
      font-size: 18px;
      font-weight: bold;
    }

    & > div:nth-child(2) {
      color: #fff;
      font-size: 10px;
      font-weight: bold;
    }
  }

  .des2 {
    color: #ffffff;
    font-size: 10px;
    font-weight: 500;
  }

  & > .money-box {
    margin-top: 6px;
    display: flex;
    justify-content: space-between;

    & > .item {
      flex: 0 1 43%;
      display: flex;
      flex-direction: column;
      background: #004134;
      border-radius: 10px;
      height: 40px;
      padding: 2px 10px 5px;

      & > div:nth-child(1) {
        text-align: left;
        color: #71c6ac;
        font-size: 10px;
        font-weight: 500;
        line-height: 21px;
      }

      & > div:nth-child(2) {
        text-align: right;
        color: #ffffff;
        font-size: 18px;
        font-weight: bold;
        line-height: 21px;
      }
    }
  }

  .btn {
    margin-top: 10px;
    background: #029479;
    height: 40px;
    line-height: 40px;
    border-radius: 8px;
    color: #ffffff;
    font-size: 15px;
    font-weight: 500;
  }

  .highlight {
    background: #ffd400;
    color: #06131c;
  }

  .logs-wrapper {
    margin-top: 20px;
    background: #006a56;
    min-height: 237.5px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 5px 15px;

    & > div:nth-child(1) {
      color: #ffffff;
      font-size: 10px;
      font-weight: 500;
    }

    & > div:nth-child(2) {
      color: #029479;
      font-size: 9px;
      font-weight: normal;
      margin-bottom: 5px;
    }

    .over-box {
      width: 100%;
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      & > div {
        color: #029479;
        font-size: 10px;
        font-weight: 500;
      }
    }

    .running-box {
      flex: 1;
      display: flex;
      width: 100%;

      .empty-box {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        & > img {
          width: 40px;
          height: 40px;
        }

        & > div {
          color: #526382;
          font-size: 12px;
          font-weight: 500;
        }
      }
    }

    .log-box {
      display: flex;
      flex-direction: column;
      width: 100%;
      .log-item {
        width: 100%;
        height: 21px;
        display: flex;
        justify-content: space-between;

        & > div:nth-child(1) {
          color: #00cda6;
          font-size: 10px;
          font-weight: 500;
        }

        & > div:nth-child(2) {
          color: #00cda6;
          font-size: 10px;
          font-weight: 500;
        }

        & > div:nth-child(3) {
          color: #ffd400;
          font-size: 10px;
          font-weight: bold;
        }
      }
    }
  }

  .logBtn {
    margin-top: 10px;
    height: 40px;
    line-height: 40px;
    border-radius: 8px;
    background: #006a56;
    color: #ffffff;
    font-size: 15px;
    font-weight: 500;
  }

  .award-task-box {
    margin-top: 10px;
    display: flex;
    background: linear-gradient(90deg, #0c2133 0%, #293577 100%);
    border-radius: 10px;
    min-height: 131.5px;

    & > img {
      width: 50px;
      height: 50px;
      margin-top: 30px;
      margin-left: 15px;
      margin-right: 15px;
    }

    & > .des-box {
      margin-right: 15px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      text-align: left;

      & > div:nth-child(1) {
        color: #ffffff;
        font-size: 12px;
        font-weight: 500;
      }

      & > div:nth-child(2) {
        color: #96a7c6;
        font-size: 9px;
        font-weight: 500;
      }

      & > div:nth-child(3) {
        color: #ffd400;
        font-size: 12px;
        font-weight: 500;
      }
    }
  }

  .rules {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: #029479;
    font-size: 10px;
    font-weight: 500;
    text-align: left;

    & > .title {
      color: #fff;
      font-size: 12px;
      font-weight: bold;
      margin: 6px 0;
    }

    & > .value {
      & > span {
        color: #ffd400;
      }
    }
  }

  .success-wrapper {
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    background: #015545;
    height: 247px;

    & > .header {
      border-radius: 10px 10px 0px 0px;
      background: #012c23;
      height: 48px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 13px 0 23px;

      & > div:first-child {
        color: #ffffff;
        font-size: 12px;
        font-weight: bold;
      }

      & > img:first-of-type {
        width: 70px;
        height: 48px;
      }

      & > img:last-of-type {
        width: 20px;
        height: 20px;
      }
    }

    & > .title {
      margin-top: 15px;
      color: #ffffff;
      font-size: 12px;
      font-weight: 500;
    }

    & > .money {
      margin-top: 15px;
      display: flex;
      justify-content: center;

      & > div:first-child {
        color: #96a7c6;
        font-size: 21px;
        font-weight: bold;
      }

      & > div:last-child {
        color: #ffd400;
        font-size: 21px;
        font-weight: bold;
      }
    }

    & > .des {
      margin: 25px 80px 0;
      color: #81d6ba;
      font-size: 11px;
      font-weight: 500;
      margin-bottom: 6px;
    }

    & > .btn {
      margin: 5px 20px 20px;
      background: #0085a9;
      height: 40px;
      line-height: 40px;
      border-radius: 10px;
      font-size: 15px;
      font-weight: 500;
      color: #d8d8d8;
    }
  }
}
</style>
