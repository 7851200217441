<template>
  <div class="login-wrap">
    <input type="password" autocomplete="new-password" v-model="form.username" style="display: none" id="usename" placeholder="Número de Telefone" />
    <div class="topbg">
      <img src="@/assets/login/back.webp" @click="backFun" alt="" />
      <div class="btn-box">
        <span :class="islogin ? 'active' : ''" @click="changeLoginOrReg(1)">{{ $t("login") }}</span>
        <span :class="!islogin ? 'active' : ''" @click="changeLoginOrReg(2)">{{ $t("register") }}</span>
      </div>
      <div class="changtype" v-if="islogin">
        <span :class="isPhone ? 'typeactive' : ''" @click="changeLoginType(1)">{{ $t("mobile_login") }}</span>
        <span :class="!isPhone ? 'typeactive' : ''" @click="changeLoginType(2)">{{ $t("email_login") }}</span>
      </div>
      <div class="changtype" v-if="!islogin">
        <span :class="isPhone ? 'typeactive' : ''" @click="changeLoginType(1)">{{ $t('mobile_registration') }}</span>
        <span :class="!isPhone ? 'typeactive' : ''" @click="changeLoginType(2)">{{ $t('email_registration') }}</span>
      </div>
    </div>
    <div class="form" v-if="islogin">
      <div style="position: relative">
        <input
          type="text"
          :style="{ borderColor: borderColorSuccess ? '#00CB47' : borderColorError ? '#FF5000' : '' }"
          v-if="isPhone"
          v-model="form.username"
          id="usename"
          :placeholder="$t('phone_number')"
        />
        <img class="username55" v-if="isPhone" src="@/assets/login/55.webp" alt="" />
        <img class="username55" v-if="!isPhone" src="@/assets/login/email.webp" alt="" />
      </div>
      <input
        type="text"
        :style="{ borderColor: borderColorSuccess ? '#00CB47' : borderColorError ? '#FF5000' : '' }"
        v-if="!isPhone"
        v-model="form.email"
        id="email"
        :placeholder="$t('email')"
      />
      <div class="pwd">
        <input :type="type" v-model="form.password" id="password" :placeholder="$t('password')" />
        <img class="username55" src="@/assets/login/lock.webp" alt="" />
        <div class="eye-box">
          <img v-if="type == 'password'" @click="changeType(1)" src="@/assets/password/invisible_icon.webp" alt="" />
          <img v-else @click="changeType(2)" src="@/assets/password/visible_icon.webp" alt="" />
        </div>
      </div>
      <p class="tip" @click="forgetPwdPage()">{{ $t("forgot_password") }}</p>
      <!-- 登录按钮触发 -->
      <div class="button" @click="handleOfLogin">{{ $t('sign_in') }}</div>
    </div>
    <div class="form" v-if="!islogin">
      <div style="position: relative">
        <input
          type="text"
          :style="{ borderColor: borderColorSuccess ? '#00CB47' : borderColorError ? '#FF5000' : '' }"
          v-if="isPhone"
          v-model="form.username"
          id="usename"
          :placeholder="$t('phone_number')"
        />
        <input
          type="text"
          :style="{ borderColor: borderColorSuccess ? '#00CB47' : borderColorError ? '#FF5000' : '' }"
          v-if="!isPhone"
          v-model="form.email"
          id="email"
          :placeholder="$t('email')"
        />
        <img class="username55" v-if="isPhone" src="@/assets/login/55.webp" alt="" />
        <img class="username55" v-if="!isPhone" src="@/assets/login/email.webp" alt="" />
        <!-- 遮罩 -->
        <div class="zhezhao" v-if="closeXflag">
          {{ $t('tips_03') }}
          <div class="close" @click="closeXFun()">
            <img src="@/assets/closex.webp" alt="" />
          </div>
        </div>
      </div>
      <div class="pwd">
        <input :type="type" v-model="form.password" id="password" :placeholder="$t('password')" />
        <img class="username55" src="@/assets/login/lock.webp" alt="" />
        <div class="eye-box">
          <img v-if="type == 'password'" @click="changeType(1)" src="@/assets/password/invisible_icon.webp" alt="" />
          <img v-else @click="changeType(2)" src="@/assets/password/visible_icon.webp" alt="" />
        </div>
      </div>
      <p class="tip" @click="hiddenInvitationCode">{{ $t('add_invitation_code') }} <img src="@/assets/login/arrow.webp" alt="" /></p>
      <input type="text" v-if="!isHiddenInvitationCode" v-model="form.invitationCode" id="qr" :placeholder="$t('invitation_code')" />
      <div class="checked">
        <van-checkbox shape="square" icon-size="13px" checked-color="#15AD51" v-model="checked"
          >{{ $t('tips_01') }} <span style="color: #fff" @click.stop="handleOfAgreement(1)">{{ $t('tips_02') }}</span>
        </van-checkbox>
      </div>
      <div class="button" @click="handleOfregister">{{ $t('register_and_login') }}</div>
    </div>
    <!-- <dialog ref="agreement">
      <AgreementComp :aId="agreementId" @closeEvent="agreementCloseEvent"></AgreementComp>
    </dialog> -->
  </div>
</template>

<script>
import { Checkbox } from "vant";
// import AgreementComp from "@/components/AgreementComp.vue";
import FbPixel from "@/utils/facebookPixel";
import KwaiPixel from "@/utils/kwaiPixel";

export default {
  components: {
    [Checkbox.name]: Checkbox,
    // AgreementComp
  },
  data() {
    return {
      borderColorSuccess: false,
      borderColorError: false,
      agreementId: "",
      isRegister: "",
      isHiddenInvitationCode: true,
      islogin: true,
      isPhone: true,
      checked: true,
      invitationCode: "",
      type: "password",
      form: {
        username: "",
        email: "",
        password: "",
        invitationCode: "",
      },
      areaCode: "55",
      usernameErr: "",
      emailErr: "",
      passwordErr: "",
      invitationCodeErr: "",
      codeErr: "",
      storedData: null,
      closeXflag: true,
    };
  },
  methods: {
    closeXFun() {
      this.closeXflag = false;
    },
    updateUrl(index) {
      // 获取当前URL
      var url = window.location.href;
      let newQuery;
      // 修改query参数
      if (index == 0) newQuery = "isRegister=0";
      if (index == 1) newQuery = "isRegister=1";
      // 拼接新的URL
      var newUrl = url.split("?")[0] + "?" + newQuery;
      // 修改地址栏URL
      history.pushState(null, null, newUrl);
      sessionStorage.setItem("isRegister", index);
    },
    agreementCloseEvent() {
      this.$refs.agreement.close();
    },
    handleOfAgreement() {
      // this.agreementId = id + "";
      // this.$refs.agreement.showModal();

      this.$router.push("/m-privacyPolicy");
    },
    // 跳转到忘记密码页面
    forgetPwdPage() {
      this.$router.push(`/forgetpwd-page?phone=${this.form.username}&email=${this.form.email}`);
    },
    hiddenInvitationCode() {
      this.isHiddenInvitationCode = !this.isHiddenInvitationCode;
      // if (this.isHiddenInvitationCode) this.form.invitationCode = ''
    },
    backFun() {
      this.$router.push("/casino");
      sessionStorage.removeItem("isRegister");
    },
    changeType(index) {
      if (index == 1) this.type = "text";
      if (index == 2) this.type = "password";
    },
    changeLoginOrReg(index) {
      this.form.password = "";
      this.closeXflag = true;
      if (index == 1) {
        this.islogin = true;
        this.updateUrl(0);
        const isValid = this.form.username.length === 11;
        this.borderColorSuccess = isValid;
        this.borderColorError = !isValid && this.form.username.length > 11;

        if (this.storedData && this.storedData.password) {
          this.form.password = this.storedData.password;
        }
        if (this.storedData && this.storedData.username) {
          this.form.username = this.storedData.username;
        }
        if (!this.isPhone && !this.form.email) {
          setTimeout(() => {
            this.borderColorError = false;
            this.borderColorSuccess = false;
          }, 0);
        }
      }
      if (index == 2) {
        this.islogin = false;
        this.updateUrl(1);
        this.form.username = "";
        this.form.password = "";
      }
    },
    changeLoginType(index) {
      this.form.password = "";
      this.borderColorError = false;
      this.borderColorSuccess = false;
      if (index == 1) {
        this.isPhone = true;
        const isValid = this.form.username.length === 11;
        this.borderColorSuccess = isValid;
        this.borderColorError = !isValid && this.form.username.length > 11;
        if (this.storedData && this.islogin) {
          this.form.password = this.storedData.password;
        }
      }
      if (index == 2) {
        this.isPhone = false;
      }
    },
    /*** 登录事件 */
    handleOfLogin() {
      let action = "login";
      if (this.isPhone) {
        const value = this.$tools.isNumeric(this.form.username);
        if (value === false) {
          this.usernameErr = this.$t("phone_verification_failed");
          this.$toast.error(this.$t(this.usernameErr));
          return;
        }
      } else {
        const value = this.$tools.isValidEmail(this.form.email);
        if (value === false) {
          this.emailErr = this.$t("email_verification_failed");
          this.$toast.error(this.$t(this.emailErr));
          return;
        }
      }

      if (this.form.password === "") {
        this.passwordErr = this.$t("enter_password");
        this.$toast.error(this.$t(this.passwordErr));
        return;
      }

      const source_channel = localStorage.getItem("source_channel") || "";
      localStorage.removeItem("source_channel");
      const promote_ad_id = localStorage.getItem("pa") || "";
      localStorage.removeItem("pa");

      const data = {
        action,
        data: {
          login_type: this.isPhone ? 4 : 5,
          login_name: this.isPhone ? this.areaCode + this.form.username : this.form.email,
          login_pwd: this.form.password,
          source_user: this.invitationCode,
          source_channel,
          promote_ad_id,
        },
      };

      this.$http
        .post("login", data)
        .then((response) => {
          const loginInfo = {
            sessionid: response.sessionid,
            ucode: response.ucode,
          };
          this.$store.commit("setLoginInfo", loginInfo);
          this.$store.commit("setChannel", response.data.channel);
          this.$store.commit("setActivityInfo", {
            activityId: response.data.activity_id,
            isJoinActivity: response.data.is_join_activity,
          });
          this.initiateData(response.data.temp_event_code, response.data.temp_event_amount);
          this.$tools.userTrackReport({ track_id: "8" });
          // 存储账号密码到本地缓存
          this.handleSubmit();
        })
        .catch((error) => {
          // 请求失败后的处理
          console.error("login:", error);
          this.showInputErr(error);
        });
    },
    handleSubmit() {
      // 存储到 localStorage
      localStorage.setItem("username", this.form.username);
      localStorage.setItem("password", this.form.password);
    },
    retrieveData() {
      // 从 localStorage 中读取数据
      const username = localStorage.getItem("username");
      const password = localStorage.getItem("password");
      if (username && password) {
        this.storedData = { username, password };
      } else {
        this.storedData = null;
      }
    },
    /*** 注册事件 */
    handleOfregister() {
      if (!this.checked) {
        this.$toast.error(this.$t('please_read_and_agree_to_the_privacy_policy'));
        return;
      }
      if (this.isPhone && this.form.username === "") {
        this.$toast.error(this.$t('the_phone_number_cannot_be_empty'));
        return;
      }
      if (!this.isPhone && this.form.email === "") {
        this.$toast.error(this.$t('the_email_cannot_be_empty'));
        return;
      }
      if (this.form.password === "") {
        this.$toast.error(this.$t('the_password_cannot_be_empty'));
        return;
      }
      let action = "register";
      this.$tools.userTrackReport({ track_id: "22" });
      const source_channel = localStorage.getItem("source_channel") || "";
      const promote_ad_id = localStorage.getItem("pa") || "";

      const data = {
        action,
        data: {
          login_type: this.isPhone ? 4 : 5,
          login_name: this.isPhone ? this.areaCode + this.form.username : this.form.email,
          login_pwd: this.form.password,
          source_user: this.form.invitationCode,
          source_channel,
          promote_ad_id,
        },
      };
      this.$http
        .post("login", data)
        .then((response) => {
          const loginInfo = {
            sessionid: response.sessionid,
            ucode: response.ucode,
          };
          this.$store.commit("setLoginInfo", loginInfo);
          this.$store.commit("setChannel", response.data.channel);
          this.$store.commit("setActivityInfo", {
            activityId: response.data.activity_id,
            isJoinActivity: response.data.is_join_activity,
          });
          this.initiateData(response.data.temp_event_code, response.data.temp_event_amount);
          this.$tools.userTrackReport({ track_id: "4" });
          // this.$fbq("track", "CompleteRegistration");
          FbPixel.trackEvent("CompleteRegistration");
          // this.$kwaiq.instance(this.$global.kwaiPixelId).track("completeRegistration");
          KwaiPixel.trackEvent("completeRegistration");

          // this.$emit("registerSuccess");
          this.$store.commit("setUserInfo", loginInfo);
          localStorage.removeItem("source_channel");
          localStorage.removeItem("pa");
        })
        .catch((error) => {
          // 请求失败后的处理
          console.error("login:", error);
          this.showInputErr(error);
          this.$tools.userTrackReport({ track_id: "5" });
        });
    },
    /**
     * 登录成功后初始化平台的一些用户数据
     */
    initiateData(code, amount) {
      this.$http
        .post("initiate", {})
        .then((response) => {
          // 请求成功后的处理
          let pgparam = response.data.pgparam;

          const user = {
            username: this.form.username,
            pgparam,
          };

          this.$store.commit("setLastLoginType", this.isPhone ? 4 : 5);
          this.$store.commit("setUserInfo", user);
          this.$eventBus.emit("loginSuccessHandle", {
            code,
            amount,
          });
          this.closeHandle(true);
        })
        .catch((error) => {
          // 请求失败后的处理
          console.error("error: ", error);
        });
    },
    /**
     * 关闭窗口,跳转首页大厅
     */
    closeHandle(isLoggedIn = false) {
      // this.$emit("closeLogin", isLoggedIn);
      this.$eventBus.emit("closeLogin", isLoggedIn);
      this.$router.push("/casino");
      localStorage.setItem("isJump", 0);
    },
    showInputErr(error) {
      if ("code" in error) {
        const code = error.code;
        switch (code) {
          case 10001: //loginName null
          case 10011: //loginName ERROR
          case 10003: //Email ERROR
          case 10004: //PHONE ERROR
          case 10009: //USER ALREADY EXIST
          case 10010: //USER NOT EXIST
            if (this.isPhone) {
              this.usernameErr = "Por favor insira o número de telefone correto";
            } else {
              this.emailErr = "Por favor, introduz o endereço correto de correio";
            }
            break;

          case 10002: //loginPwd NULL
          case 10012: //loginPwd ERROR
          case 10005: //PWD LENGTH ERROR
            this.passwordErr = "8-16 caracteres";
            break;

          case 20010: //CAPTCHA ERROR
            this.codeErr = "Erro do código de verificação";
            break;

          case 11001: //USERCODE ERROR
            this.invitationCodeErr = "Código de convite inválido";
            break;

          default:
            break;
        }
      }
    },
  },
  watch: {
    invitationCode(newValue) {
      if (newValue) {
        this.form.invitationCode = this.invitationCode;
        this.isHiddenInvitationCode = false;
      }
    },
    storedData(newValue) {
      console.log(newValue.username);
      if (newValue) {
        this.form.username = newValue.username;
        this.form.password = newValue.password;
      }
    },
    "form.username"(newValue) {
      const isValid = newValue.length === 11;
      this.borderColorSuccess = isValid;
      this.borderColorError = !isValid && newValue.length > 11;
    },
    "form.email"(newValue) {
      if (newValue == "") {
        this.borderColorSuccess = false;
        this.borderColorError = false;
      } else {
        const isValid = this.$tools.isValidEmail(newValue);
        this.borderColorSuccess = isValid;
        this.borderColorError = !isValid;
      }
    },
  },
  mounted() {
    this.retrieveData();
    const uc = sessionStorage.getItem("uc");
    this.isRegister = this.$route.query.isRegister;
    if (uc || this.isRegister == 1) {
      this.islogin = false;
      setTimeout(() => {
        this.form.username = "";
        this.form.password = "";
      }, 0);
    }

    if (uc !== null && uc !== undefined && uc !== "") {
      this.invitationCode = uc;
      this.hiddenInvitationCode = false;
      sessionStorage.removeItem("uc");
      this.form.username = "";
      this.form.password = "";
      this.form.email = "";
    }
  },
};
</script>

<style lang="scss" scoped>
.login-wrap {
  color: #fff;
  width: 100vw;
  max-width: var(--max-width);

  .topbg {
    width: 100%;
    height: 236px;
    background-image: url("@/assets/login/login-bg.webp");
    background-size: 100%;
    position: relative;

    img {
      position: absolute;
      left: 10px;
      top: 17px;
      width: 22px;
      height: 22px;
    }

    .btn-box {
      position: absolute;
      bottom: 19%;
      left: 0;
      right: 0;
      height: 40px;
      color: #007962;
      display: flex;
      justify-content: center;

      span {
        width: 150px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #001e05;
        border-radius: 20px;
        font-weight: bold;

        &:first-child {
          margin-right: 6px;
        }
      }

      span.active {
        background: #ffd400;
        color: #000;
      }
    }

    .changtype {
      position: absolute;
      bottom: -24px;
      right: 0;
      left: 0;
      background: #004134;
      height: 54px;
      border-radius: 30px 30px 0px 0px;
      font-size: 14px;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 24px;
      box-sizing: border-box;
      color: #007962;

      span.typeactive {
        color: #ffd400;
      }
    }
  }

  .form {
    color: #fff;
    padding: 40px 34px 0 34px;
    box-sizing: border-box;

    .username55 {
      position: absolute;
      left: 15px;
      top: 12px;
      width: 15px;
      height: 14px;
    }

    input {
      height: 40px;
      width: 100%;
      border-radius: 10px;
      background: #004134;
      border: 1px solid #007a63;
      padding-left: 40px;
      font-size: 12px;
      box-sizing: border-box;

      &:focus {
        border: 1px solid #92ffea;
      }

      &::placeholder {
        color: #029479;
        font-weight: 500;
      }
    }
    .zhezhao {
      position: absolute;
      top: 0px;
      left: 0;
      height: 45px;
      right: 0;
      bottom: 4px;
      background: #dda7a7;
      border-radius: 7px;
      font-size: 10px;
      color: #700000;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      text-align: left;
      padding: 4px 30px 4px 10px;
      box-sizing: border-box;
      .close {
        position: absolute;
        right: 0;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 15px;
          height: 15px;
        }
      }
    }

    #usename {
      padding-left: 40px;
      /* 为图标预留空间 */
      // background-image: url('@/assets/login/55.webp') !important;
      background-repeat: no-repeat;
      /* 假设图标是 svg 格式的 */
      background-position: 14px center;
      /* 图标的位置 */
      background-size: 15px;
      /* 图标的大小 */
      margin-bottom: 12px;
    }

    #email {
      padding-left: 40px;
      /* 为图标预留空间 */
      // background-image: url('@/assets/login/email.webp') !important;
      background-repeat: no-repeat;
      /* 假设图标是 svg 格式的 */
      background-position: 14px center;
      /* 图标的位置 */
      background-size: 15px;
      /* 图标的大小 */
      margin-bottom: 12px;
    }

    #password {
      padding-left: 40px;
      /* 为图标预留空间 */
      // background-image: url('@/assets/login/lock.webp') !important;
      background-repeat: no-repeat;
      /* 假设图标是 svg 格式的 */
      background-position: 14px center;
      /* 图标的位置 */
      background-size: 15px;
      /* 图标的大小 */
      margin-bottom: 12px;
    }

    #qr {
      padding-left: 40px;
      /* 为图标预留空间 */
      background-image: url("@/assets/login/qr.webp") !important;
      background-repeat: no-repeat;
      /* 假设图标是 svg 格式的 */
      background-position: 14px center;
      /* 图标的位置 */
      background-size: 15px;
      /* 图标的大小 */
      margin-bottom: 12px;
    }

    .checked {
      font-size: 10px;
      display: flex;

      :deep(.van-checkbox__label) {
        color: #67b89f;
        font-weight: bold;
      }

      :deep(.van-checkbox__icon .van-icon) {
        border-color: #67b89f;
        border-radius: 2px;
      }
    }

    .pwd {
      position: relative;

      .eye-box {
        // background: red;
        position: absolute;
        right: 6px;
        top: 2px;
        width: 30px;
        height: 37px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 15px;
          height: 12px;
        }
      }
    }

    .tip {
      font-size: 12px;
      color: #67b89f;
      font-weight: 500;
      margin-top: 4px;
      display: flex;
      align-items: center;

      img {
        width: 10px;
        margin-left: 5px;
      }
    }

    .button {
      width: 100%;
      height: 40px;
      background-color: #ffd400;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;
      color: #0a1f32;
      margin-top: 24px;
    }
  }
}
</style>
