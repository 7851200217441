<template>
  <div class="freeze">
    <div class="wrapper">
      <div class="desc">
        <div class="desc-item">{{ $t("freeze_account_notice") }}</div>
        <div class="desc-item">{{ $t("freeze_warning") }}</div>
      </div>
      <div class="options-mobile">
        <div>{{ $t("freeze_days_title") }}</div>
        <div class="input-box">
          <input class="input" :placeholder="$t('freeze_input')" type="text" v-model="inputValue" />
        </div>
        <div></div>
      </div>
      <div class="buttons">
        <div @click="submitHandle">{{ $t("submit") }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      inputValue: "",
    };
  },
  methods: {
    submitHandle() {
      if (this.inputValue === "" || parseInt(this.inputValue) === 0) {
        this.$toast.error(this.$t("freeze_days"));
        return;
      }

      const data = {
        action: "frozen",
        data: {
          days: parseInt(this.inputValue),
        },
      };

      this.$http
        .post("userCenter", data)
        .then((response) => {
          // 请求成功后的处理
          console.log(response.data);
          this.$toast.success(this.$t('success'));
        })
        .catch((error) => {
          // 请求失败后的处理
          console.error(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.freeze {
  display: flex;
  .wrapper {
    display: flex;
    flex-direction: column;
    .desc {
      margin-top: 12px;
      display: flex;
      flex-direction: column;

      .desc-item {
        font-size: 12px;
        font-weight: 500;
        color: #81D6BA;
        text-align: left;
      }
    }

    .options-mobile {
      margin-top: 20px;
      display: block;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      & > div:first-of-type {
        font-size: 14px;
        font-weight: 500;
        color: #ffffff;
        padding-left: 12px;
      }

      & > div:last-of-type {
        font-size: 12px;
        font-weight: normal;
        color: #ffffff;
        opacity: 0.4;
      }

      .input-box {
        margin: 10px auto;
        background: #006A56;
        width: 100%;
        height: 70px;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
      }

      .input {
        margin: 12px 16px;
        font-size: 12px;
        line-height: 16px;
        background-color: #004134;
        border: 0;
        height: 45px;
        outline-style: none;
        border-radius: 10px;
        padding-left: 12px;
        color: #ffffff;
        &::placeholder{
          color: #00886E;
        }
      }
    }

    .buttons {
      height: 40px;
      display: flex;
      justify-content: space-between;
      margin-top: 100px;
      max-width: 768px;
      flex-wrap: wrap-reverse;

      div:first-of-type {
        flex: 1;
        border-radius: 6px;
        box-sizing: border-box;
        border: 1px solid #e9c97e;
        background: transparent;
        font-size: 14px;
        font-weight: 600;
        text-align: center;
        color: #e9c97e;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;
        height: 40px;
      }

      div:last-of-type {
        border: 0;
        flex: 1;
        border-radius: 8px;
        background: #FFD400;
        font-size: 18px;
        font-weight: 500;
        text-align: center;
        color: #000000;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
      }
    }
  }
}
</style>
