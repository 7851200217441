<template>
  <div class="container">
    <div class="wrapper">
      <mine-empty v-if="tableData.length === 0"></mine-empty>
      <div v-else-if="isMobile" class="m-table">
        <ul>
          <li v-for="(item, index) in tableData" :key="index" @click="showDetailDialog(item)">
            <div class="li-top">
              <span :class="item.status == 1 ? 'm-value-positive' : item.status == 2 ? 'm-value-nagative' : 'm-value-wait'"
                >R${{ $tools.formatCurrency(parseFloat(item.disbursement_amount)) }}
              </span>
              <span>{{ item.create_time }}</span>
            </div>
            <div class="li-bottom">
              <span>{{ item.merchantorderid }} 
                <img src="@/assets/verify_dialog_slot_copy.webp" alt="" />
              </span>
              <span :class="item.status == 1 ? 'm-value-positive' : item.status == 2 ? 'm-value-nagative' : 'm-value-wait'">{{
                item.status == 1 ? $t('pending_processing') : item.status == 2 ? $t('success') : $t('fail')
              }}</span>
            </div>
          </li>
        </ul>
        <LoadMoreComp :curCount="tableData.length" :totalCount="total" @loadMoreEvent="fetchWithdrawalList(page + 1)"> </LoadMoreComp>
      </div>
      <div v-else class="table">
        <div class="header">
          <div>{{ $t("date") }}</div>
          <div>{{ $t("sn") }}</div>
          <div>{{ $t("type") }}</div>
          <div>{{ $t("amount") }}</div>
          <div>{{ $t("status") }}</div>
        </div>
        <div class="cell" v-for="(item, index) in tableData" :key="index">
          <div>{{ item.create_time }}</div>
          <div>{{ item.id }}</div>
          <div>{{ item.pix }}</div>
          <div :class="item.disbursement_amount >= 0 ? 'value-positive' : 'mvalue-negative'">R${{ $tools.formatCurrency(item.disbursement_amount) }}</div>
          <div>{{ withdrawalStatus(index) }}</div>
        </div>
      </div>
      <el-pagination
        v-if="isMobile === false && tableData.length !== 0"
        style="justify-content: right"
        background
        layout="prev, pager, next"
        :page-size="pageSize"
        :total="total"
        @current-change="changePage"
      ></el-pagination>
    </div>
    <!-- 验证手机or邮箱弹窗 -->
    <dialog ref="verifyDialog">
      <VerifyDialogForPhoneOrEmail :verifyType="3" title="Registro de retiradas" @closeEvent="handleOfVerifyDialogClose">
        <template v-slot:depositRecords>
          <h1 :class="info.status == 1 ? 'm-value-positive dialog-title' : info.status == 2 ? 'm-value-nagative dialog-title' : 'm-value-wait dialog-title'">
            R${{ $tools.formatCurrency(parseFloat(info.disbursement_amount)) }}
          </h1>
          <div class="row">
            <p>
              <span>{{ $t('serial_number') }}</span>
              <span style="font-size: 9px">{{ info.merchantorderid }}</span>
              <span style="position: relative">
                <img src="@/assets/verify_dialog_slot_copy.webp" @click="copyHandle(info.merchantorderid)" alt="" />
                <img class="copiado" v-show="isShowCopiado" src="@/assets/user/copiado.png" alt="" />
              </span>
            </p>
            <p>
              <span>{{ $t('creation_time') }}</span>
              <span>{{ info.create_time }}</span>
            </p>
            <p>
              <span>{{ $t('trading_time') }}</span>
              <span>{{ info.create_time }}</span>
            </p>
            <p>
              <span>{{ $t('order_status') }}</span>
              <span :class="info.status == 1 ? 'm-value-positive win' : info.status == 2 ? 'm-value-nagative win' : 'm-value-wait win'">{{
                info.status == 1 ? $t('pending_processing') : info.status == 2 ? $t('success') : $t('fail')
              }}</span>
            </p>
            <!-- <p>
              <span>Observações</span>
              <span>我是备注</span>
            </p> -->
          </div>
          <div class="row last">
            <p>
              <span>{{ $t('balance') }}</span>
              <span>R${{ $tools.formatCurrency(parseFloat(info.new_coins)) + " Real" }}</span>
            </p>
          </div>
        </template>
      </VerifyDialogForPhoneOrEmail>
    </dialog>
  </div>
</template>

<script>
import MineEmpty from "@/components/MineEmpty.vue";
import LoadMoreComp from "@/components/LoadMoreComp.vue";
import VerifyDialogForPhoneOrEmail from "@/components/VerifyDialogForPhoneOrEmail.vue";
import clipboard from "clipboard";
export default {
  components: { MineEmpty, LoadMoreComp, VerifyDialogForPhoneOrEmail },
  data() {
    return {
      tableData: [],
      isMobile: false,
      pageSize: 10,
      page: 0,
      total: 0,
      info: {},
      isShowCopiado: false,
    };
  },
  mounted() {
    this.isMobile = this.$tools.isMobile();
    this.fetchWithdrawalList(0);
  },
  methods: {
    copyHandle(bet_id) {
      clipboard.copy(bet_id);
      this.isShowCopiado = true;
      setTimeout(() => {
        this.isShowCopiado = false;
      }, 2000);
    },
    handleOfVerifyDialogClose() {
      this.$refs.verifyDialog.close();
    },
    showDetailDialog(item) {
      this.info = item;
      console.log(item);

      this.$refs.verifyDialog.showModal();
    },
    changePage(index) {
      this.fetchWithdrawalList(index - 1);
    },
    fetchWithdrawalList(page) {
      const data = {
        action: "user_withdrawal_list",
        data: {
          amount_type: 1,
          page,
          page_size: this.pageSize,
        },
      };
      this.$http
        .post("userWallet", data)
        .then((response) => {
          if (this.isMobile) {
            this.tableData.push(...response.data.data_list);
          } else {
            this.tableData = response.data.data_list;
          }

          this.page = response.data.page;
          this.total = response.data.count;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    withdrawalStatus(index) {
      const st = this.tableData[index].status;
      let str = "";
      switch (st) {
        case 1:
          // 待处理
          str = this.$t('pending_processing');
          break;
        case 2:
          // 代付失败
          str =this.$t('fail');
          break;
        case 3:
          // 锁定中
          str = "Trancar";
          break;
        case 4:
          // 代付中
          str = "Pagamento esperando";
          break;
        case 5:
          // Completado
          str = "Completado";
          break;
        case 6:
          // 已拒绝
          str = "Rejeitado";
          break;
        case 7:
          // 已退单
          str = "Pedido de devolução";
          break;
        case 8:
          // 已冻结
          str = "Congelar";
          break;
        case 9:
          // 已关闭
          str = "Fecho";
          break;
        case 10:
          // 拒绝并挂起
          str = "Recusar e desligar";
          break;
        case 11:
          // 退单并挂起
          str = "Voltar e desligar";
          break;
        case 12:
          // 待审核
          str = "A ser revisado";
          break;
        default:
          // 未知
          str = "Desconhecido";
          break;
      }

      return str;
    },
  },
};
</script>

<style lang="scss" scoped>
:deep(.el-pagination.is-background .btn-prev) {
  background-color: #1f1e1e;
  color: #fff;
}

:deep(.el-pagination.is-background .btn-prev:disabled) {
  background-color: #1f1e1e;
  color: #fff;
}

:deep(.el-pagination.is-background .el-pager li:not(.disabled)) {
  background-color: #1f1e1e;
  color: #fff;
}

:deep(.el-pagination.is-background .el-pager li:not(.disabled).is-active) {
  background-color: #e9c97e;
  color: #070704;
}

:deep(.el-pagination.is-background .btn-next) {
  background-color: #1f1e1e;
  color: #fff;
}

:deep(.el-pagination.is-background .btn-next:disabled) {
  background-color: #1f1e1e;
  color: #fff;
}

.container {
  display: flex;

  .wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;

    .m-table {
      margin-bottom: 10px;
      border-radius: 4px;
      display: flex;
      flex-direction: column;
      padding-bottom: 10px;

      ul {
        li {
          height: 80px;
          background: #006a56;
          border: 1px solid #007a63;
          border-radius: 10px;
          display: flex;
          flex-direction: column;
          padding: 12px;
          box-sizing: border-box;
          margin-bottom: 12px;

          .li-top {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-bottom: 10px;

            span {
              font-size: 18px;
              color: #7bffa9;
              font-weight: bold;

              &:last-child {
                font-size: 12px;
                color: #81d6ba;
              }
            }
          }

          .li-bottom {
            display: flex;
            justify-content: space-between;
            font-size: 10px;
            color: #81d6ba;
            font-weight: 500;

            span {
              display: flex;
              align-items: center;
              img {
                width: 15px;
                height: 15px;
                margin-left: 4px;
              }
              &:last-child{
              width: 50px;
              display: flex;
              justify-content: center;
            }
            }
            
          }
        }
      }
    }

    .table {
      background: #1a1919;
      border-radius: 8px;
      display: flex;
      flex-direction: column;

      .header {
        margin: 0 30px;
        font-size: 14px;
        color: #ffffff;
        display: flex;
        height: 56px;
        justify-content: center;
        align-items: center;

        div {
          flex: 1;
          display: flex;
          justify-content: center;
        }
      }

      .cell {
        margin: 0 10px 8px;
        padding: 0 20px;
        display: flex;
        font-size: 12px;
        color: rgba($color: #ffffff, $alpha: 0.8);
        height: 40px;
        border-radius: 8px;
        background: #1f1e1e;
        box-sizing: border-box;
        border: 1px solid #2b2b2b;
        justify-content: space-between;
        align-items: center;

        div {
          flex: 1;
          display: flex;
          justify-content: center;
        }

        .value-positive {
          flex: 1;
          font-size: 12px;
          color: #42f388;
          opacity: 0.8;
          text-align: left;
        }

        .value-negative {
          flex: 1;
          font-size: 12px;
          color: #d04835;
          opacity: 0.8;
          text-align: left;
        }
      }
    }
  }

  .dialog-title {
    margin: 0;
    font-size: 32px;
    font-weight: bold;
    color: #7bffa9;
    line-height: 1;
    border-bottom: 1px solid #007760;
    margin-bottom: 20px;
    padding-bottom: 14px;
  }

  .row {
    border-bottom: 1px solid #007760;
    margin-bottom: 20px;

    p {
      display: flex;
      align-items: center;
      width: 100%;
      line-height: 1;
      justify-content: space-between;

      span {
        font-size: 11px;

        &:first-child {
          // width: 60px;
          color: #00c19c;
          font-weight: 500;
          text-align: left;
          margin-right: 16px;
        }

        &:nth-child(2) {
          flex: 1;
          text-align: left;
          color: #fff;
          font-weight: 500;
        }

        &:last-child {
          display: flex;

          img {
            width: 15px;
            height: 16px;
          }

          .copiado {
            position: absolute;
            top: -28px;
            left: -20px;
            width: 50px;
            height: auto;
          }
        }
      }

      span.win {
        color: #7bffa9;
      }
    }
  }

  .last {
    border: none;

    p {
      margin: 0;
      line-height: 1.7;
    }
  }
  .m-value-nagative {
    color: #82baff !important;
  }

  .m-value-positive {
    color: #7bffa9 !important;
  }

  .m-value-wait {
    color: #ff9d70 !important;
  }
}
</style>
