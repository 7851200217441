<template>
  <div class="gamesSetWithTitleCompWrapper" v-if="games?.length > 0">
    <div class="titleBox">
      <div class="title">
        <img class="icon" :src="iconUrl" alt="" />
        <div>{{ title }}</div>
      </div>
      <img src="@/assets/casino/tag_title_bg.webp" alt="" />
      <div class="totalBox" v-if="type !== 'recent'" @click="handleOfShowMore">
        {{ $t('more') }} <span>{{ totalCount }}</span>
      </div>
      <div class="arrow" @click="handleOfScroll('left')">
        <img src="@/assets/common/arrow_left.webp" alt="" />
      </div>
      <div class="arrow" @click="handleOfScroll('right')">
        <img src="@/assets/common/arrow_right.webp" alt="" />
      </div>
    </div>
    <div class="games" v-if="games != null" ref="scrollContainer" :style="styleObject">
      <div class="card" v-for="(item, index) in games" :key="index">
        <GameCard :game="item"></GameCard>
      </div>
    </div>
  </div>
</template>
<script>
import GameCard from "./GameCard.vue";
export default {
  components: { GameCard },
  props: {
    type: {
      type: String,
      default: "tag", // tag, recent, provider
    },
    id: {
      type: Number,
      default: 0,
    },
    icon: {
      type: String,
      default: "",
    },
    title: String,
  },
  data() {
    return {
      games: null,
      totalCount: 0,
      numColumns: 10,
    };
  },
  computed: {
    styleObject() {
      return {
        display: "grid",
        "grid-template-columns": `repeat(${this.numColumns}, 1fr)`,
      };
    },
    iconUrl() {
      if (this.icon === "") {
        return require("@/assets/tag_icon.png");
      } else {
        return this.$global.data.cdn_url + this.icon;
      }
    },
  },
  mounted() {
    if (this.type === "recent") {
      this.fetchRecentGames();
    } else if (this.type === "tag") {
      this.queryTagGames();
    } else if (this.type === "provider") {
      this.fetchProviderList();
    }
  },
  methods: {
    async fetchProviderList() {
      const data = {
        action: "game_list",
        data: {
          game_provider: this.id,
        },
      };
      try {
        const response = await this.$http.post("gameCenter", data);
        this.totalCount = response.data.count;
        this.games = response.data.game_list;
        this.numColumns = response.data.page_size;
      } catch (error) {
        console.log("error", error);
      }
    },
    async fetchRecentGames() {
      const data = {
        action: "game_list_recent",
        data: {},
      };
      try {
        const response = await this.$http.post("gameRecent", data);
        this.games = response.data.game_list_recent;
      } catch (error) {
        console.log("error", error);
      }
    },
    async queryTagGames() {
      const data = {
        action: "game_list",
        data: {
          game_tag: this.id,
          page_size: 20,
        },
      };
      try {
        const response = await this.$http.post("gameCenter", data);
        this.totalCount = response.data.count;
        this.games = response.data.game_list;
        this.numColumns = parseInt(this.games.length > 10 ? (this.games.length + 1) / 2 : this.games.length);
      } catch (error) {
        console.log("error", error);
      }
    },
    handleOfShowMore() {
      if (this.type === "tag") {
        this.$router.push({ path: "/m-tag-list/" + this.id });
      } else if (this.type === "provider") {
        this.$router.push({
          path: "/m-game-list/" + this.title,
          query: { id: this.id, type: "provider" },
        });
      }
    },
    handleOfScroll(direction) {
      const container = this.$refs.scrollContainer;
      const scrollAmount = 345; // 滚动量可以根据需要调整

      if (direction === "left") {
        container.scrollTo({
          left: container.scrollLeft - scrollAmount,
          behavior: "smooth",
        });
      } else {
        container.scrollTo({
          left: container.scrollLeft + scrollAmount,
          behavior: "smooth",
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.gamesSetWithTitleCompWrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;

  .titleBox {
    width: 100%;
    height: 34px;
    display: flex;
    align-items: center;

    & > .title {
      min-width: 180px;
      height: 100%;
      background-color: #006a56;
      display: flex;
      align-items: center;

      color: #81d6ba;
      font-size: 14px;
      font-weight: 500;

      & > .icon {
        margin-left: 15px;
        margin-right: 5px;
        width: 16px;
        height: 16px;
      }
    }

    & > img {
      width: auto;
      height: 100%;
      margin-right: auto;
    }

    & > .totalBox {
      padding: 0 10px;
      background: #006a56;
      border-radius: 4px;
      height: 25px;
      line-height: 25px;
      color: #81d6ba;
      font-size: 10px;
      font-weight: 500;
      white-space: nowrap;
      & > span {
        color: #ffffff;
      }
    }

    .arrow {
      margin-left: 3px;
      background: #006a56;
      border-radius: 4px;
      width: 23px;
      height: 25px;
      display: flex;
      justify-content: center;
      align-items: center;

      & > img {
        width: 5px;
        height: 9px;
      }
    }

    .arrow:last-child {
      margin-right: 5px;
    }
  }

  .games {
    background: #006a56;
    overflow-x: scroll;
    scroll-behavior: smooth;
    display: grid;
    row-gap: 10px;
    column-gap: 10px;
    min-height: 105px;
    padding: 10px 0 10px 15px;

    .card {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 105px;
      height: auto;
    }
  }
}
</style>
