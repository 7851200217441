<template>
  <div class="content">
    <div>
      You hereby acknowledge and accept that if we deem necessary, we are able to collect and otherwise use your personal data in order to allow you access and
      use of the Websites and in order to allow you to participate in the Games.
    </div>
    <div>
      We hereby acknowledge that in collecting your personal details as stated in the previous provision, we are bound by the Data Protection Act. We will
      protect your personal information and respect your privacy in accordance with best business practices and applicable laws.
    </div>
    <div>
      We will use your personal data to allow you to participate in the Games and to carry out operations relevant to your participation in the Games. We may
      also use your personal data to inform you of changes, new services and promotions that we think you may find interesting. If you do not wish to receive
      such direct marketing correspondences, you may opt out of the service.
    </div>
    <div>
      Your personal data will not be disclosed to third parties, unless it is required by law. As Bet mace business partners or suppliers or service providers
      may be responsible for certain parts of the overall functioning or operation of the Website, personal data may be disclosed to them. The employees of
      eg-wintop have access to your personal data for the purpose of executing their duties and providing you with the best possible assistance and service. You
      hereby consent to such disclosures.
    </div>
    <div>
      We shall keep all information provided as personal data. You have the right to access personal data held by us about you. No data shall be destroyed
      unless required by law, or unless the information held is no longer required to be kept for the purpose of the relationship.
    </div>
    <div>
      In order to make your visit to the Websites more user-friendly, to keep track of visits to the Websites and to improve the service, we collect a small
      piece of information sent from your browser, called a cookie. You can, if you wish, turn off the collection of cookies. You must note, however, that
      turning off cookies may severely restrict or completely hinder your use of the Websites.
    </div>
    <div>Cookies Policy</div>
    <div>1.What are cookies?</div>
    <div>
      A cookie is a piece of information in the form of a very small text file that is placed on an internet user's computer. It is generated by a web page
      server (which is basically the computer that operates the website) and can be used by that server whenever the user visits the site. A cookie can be
      thought of as an internet user's identification card, which tells a website when the user has returned. Cookies can't harm your computer and we don't
      store any personally identifiable information about you on any of our cookies.
    </div>
    <div>2.Why do we use cookies on eg-wintop?</div>
    <div>
      BC.GAME uses two types of cookies: cookies set by us and cookies set by third parties (i.e. other websites or services).eg-wintop cookies enable us to
      keep you signed in to your account throughout your visit and to tailor the information displayed on the site to your preferences.
    </div>
    <div>3.What cookies do we use on eg-wintop?</div>
    <div>Below is a list of the main cookies set by eg-wintop, and what each is used for:</div>
    <div>_fp - stores browser's fingerprint. Lifetime: forever.</div>
    <div>_t - stores timestamp when user firstly visited site in current browsing session. Needed for unique visits statistic. Lifetime: browsing session.</div>
    <div>_r - stores http referrer for current browsing session. Needed in order to external track traffic sources. Lifetime: browsing session.</div>
    <div>_c - stores identifier of affiliate campaign. Needed for affiliate statistic. Lifetime: forever.</div>
    <div>Cookies set by third parties for wildcard domain: *.BC.GAME</div>
    <div>Google analytics: _ga, _gat, _gid</div>
    <div>Zendesk：__ zlcmid</div>
    <div>Cloudflare：__ cfuid</div>
    <div>
      Please keep in mind that some browsers (i.e. chrome on mac) keep background processes running even if no tabs opened due to this session cookies may left
      set between sessions.
    </div>
    <div>There are also cookies set by third party scripts to their domains.</div>
    <div>4.How can I manage my cookies on eg-wintop?</div>
    <div>If you wish to stop accepting cookies, you can do so through the Privacy Settings option in your browser.</div>
    <div>5.Personal Data Protection Policy</div>
    <div>
      eg-wintop’s mission is to keep your Data safe and for this matter we protect your data in various ways. We provide our customers with high security
      standards, such as encryption of data in motion over public networks, encryption of data in database, auditing standards, Distributed Denial of Service
      mitigations, and a Live Chat available on-site.
    </div>
    <div>6.Server Protection Policy</div>
    <div>All servers have full encryption;</div>
    <div>All backups have encryption;</div>
    <div>Firewalls, VPN Access;</div>
    <div>Access to servers allowed only over VPN;</div>
    <div>All http/s services work over Cloudflare;</div>
    <div>Connections to nodes over VPN;</div>
    <div>SSH port forwarding tunnels;</div>
    <div>Services allowed only over VPN;</div>
    <div>Server have firewall and allowed only SSH port;</div>
    <div>Alerts on critical services.</div>
    <div>Data Breach Notification</div>
    <div>Wheneg-wintop will be made aware of personal data breaches we will notify relevant users in accordance with GDPR timeframes.</div>
    <div>7.Data International Transfer</div>
    <div>
      We only disclose personal data to third parties where it is necessary to provide the high-quality service or in order to respond lawful requests from
      authorities.
    </div>
    <div>We share the following data to third party systems:</div>
    <div>Zendesk Inc. – username and e-mail information is transferred if user sends a message to live-chat or sends an e-mail to support mailbox.</div>
    <div>Technical issues</div>
    <div>
      Although we try to do our best, problems could occur now and then. Our team will do everything we could to solve your problems as soon as possible. To
      assist you quicker, You can join us by clicking the button above to join the telegram group.
    </div>
    <div>If an error occurs, please provide the following information:</div>
    <div>Username</div>
    <div>Date and time of the problem</div>
    <div>Game ID or table name, if any</div>
    <div>Screenshot of the error, if possible</div>
    <div>We really appreciate your help and the error report you provided because your information report could help us improve.</div>
    <div>Collecting and Using Your Personal Data</div>
    <div>Types of Data Collected</div>
    <div>Personal Data</div>
    <div>
      While using Our Service, We may ask You to provide Us with certain personally identifiable information that can be used to contact or identify You.
      Personally identifiable information may include, but is not limited to:
    </div>
    <div>Email address</div>
    <div>First name and last name</div>
    <div>Usage Data</div>
    <div>Usage Data</div>
    <div>Usage Data is collected automatically when using the Service.</div>
    <div>
      Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our
      Service that You visit, the time and date of Your visit, the time spent on those pages, unique device identifiers and other diagnostic data.
    </div>
    <div>
      When You access the Service by or through a mobile device, We may collect certain information automatically, including, but not limited to, the type of
      mobile device You use, Your mobile device unique ID, the IP address of Your mobile device, Your mobile operating system, the type of mobile Internet
      browser You use, unique device identifiers and other diagnostic data.
    </div>
    <div>
      We may also collect information that Your browser sends whenever You visit our Service or when You access the Service by or through a mobile device.
    </div>
    <div>Information from Third-Party Social Media Services</div>
    <div>eg-wintop allows You to create an account and log in to use the Service through the following Third-party Social Media Services:</div>
    <div>Google</div>
    <div>Facebook</div>
    <div>Telegram</div>
    <div>Metamask</div>
    <div>Web3.0</div>
    <div>
      If You decide to register through or otherwise grant us access to a Third-Party Social Media Service, We may collect Personal data that is already
      associated with Your Third-Party Social Media Service's account, such as Your name, Your email address, Your activities or Your contact list associated
      with that account.
    </div>
    <div>
      You may also have the option of sharing additional information with the eg-wintop through Your Third-Party Social Media Service's account. If You choose
      to provide such information and Personal Data, during registration or otherwise, You are giving eg-wintop permission to use, share, and store it in a
      manner consistent with this Privacy Policy.
    </div>
    <div>Delete Personal Data</div>
    <div>
      You can request to have your personal data deleted if eg-wintop no longer have a legal reason to continue to process or store it. Please note that this
      right is not guaranteed - in the sense that eg-wintop do not have the ability to comply with your request if it is subject to a legal obligation to store
      your data. You can request the deletion of your personal data by sending an email to csbragiua@gmail.com.
    </div>
  </div>
</template>
<script>
export default {
  mounted() {
    this.$emit("update:title", "Privacy Policy");
  },
};
</script>
<style lang="scss" scoped>
.content {
  margin: 20px;
  font-size: 10px;
  font-weight: 250;
  color: #ffffff;
  overflow: auto;
  text-align: left;
  display: flex;
  flex-direction: column;
}
</style>
