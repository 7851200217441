<template>
  <div class="container">
    <div class="wrapper">
      <MineEmpty v-if="isShowEmpty"></MineEmpty>
      <div class="table" v-else>
        <ul>
          <li v-for="(item, index) in data_list" :key="index">
            <div class="li-top">
              <span :class="item.type === 2 && item.amount > 0 ? 'yellow' : 'green'">R${{ $tools.formatCurrency(parseFloat(item.amount)) }}</span>
              <span>{{ item.create_time }}</span>
            </div>
            <div class="li-bottom">
              <span>{{ $t('type') }}: {{ typeStr(item.type) }}</span>
              <span>{{ statusStr(item.status) }}</span>
            </div>
          </li>
        </ul>
        <LoadMoreComp :curCount="data_list.length" :totalCount="total" @loadMoreEvent="fetchDataList(page + 1)"> </LoadMoreComp>
      </div>
    </div>
  </div>
</template>
<script>
import MineEmpty from "@/components/MineEmpty.vue";
import LoadMoreComp from "@/components/LoadMoreComp.vue";
export default {
  data() {
    return {
      isShowEmpty: true,
      data_list: [],
      pageSize: 10,
      page: 0,
      total: 0,
    };
  },
  components: {
    MineEmpty,
    LoadMoreComp,
  },
  mounted() {
    this.fetchDataList(this.page);
  },
  methods: {
    statusStr(obj) {
      if (obj.type !== 2) {
        return this.$t('success');
      } else if (obj.status === 1) {
        return this.$t('transfer_money');
      } else if (obj.status === -1) {
        return this.$t('deduct_prize');
      }
    },
    typeStr(type) {
      switch (type) {
        case 1:
          return this.$t('first_promotional_recharge');
        case 2:
          return this.$t('reward_missions');
        case 3:
          return this.$t('item_money_coupon');
        case 4:
          return this.$t('free_item_game');
        case 5:
          return this.$t('bonus_item_rewards');
        case 6:
          return this.$t('deposit_item_coupon');
        case 7:
          return this.$t('vip_betting_cashback');
        case 8:
          return this.$t('vip_gold_club');
        case 9:
          return this.$t('operational_activities');
        case 10:
          return this.$t('daily_discounted_recharge_activity');
        case 11:
          return this.$t('gift_registration');
        case 12:
          return this.$t('registration_deposit_activity');
        case 13:
          return this.$t('recharge_reward');
        case 14:
          return this.$t('manual_deposit');
        case 15:
          return this.$t('daily_challenge_activity');
        case 16:
          return this.$t('piggy_bank_withdrawal');
        case 17:
          return this.$t('missions_for_newbies');
        case 18:
          return this.$t('daily_check-in');
        case 22:
          return this.$t('bonus_withdrawal_task');
        case 23:
          return this.$t('promotional_code');
        case 24:
          return this.$t('daily_deposit_task');
        case 25:
          return this.$t('cumulative_betting_task');
        case 26:
          return this.$t('daily_betting_task');
        case 27:
          return this.$t('first_deposit');
        case 67:
          return this.$t('red_packet_rain'); //红包雨
        case 1323:
          return this.$t('blevel_bonus'); //会员升级奖励
        case 1321:
          return this.$t('weekly_bonus');//会员周返奖励
        case 1322:
          return this.$t('monthly_bonus'); //会员月返奖励

        default:
          return "";
      }
    },
    async fetchDataList(page) {
      const data = {
        action: "user_gift_list",
        data: {
          page,
          page_size: this.pageSize,
        },
      };
      try {
        const response = await this.$http.post("userWallet", data);
        this.data_list.push(...response.data.data_list);
        this.total = response.data.count;
        this.isShowEmpty = this.data_list.length === 0;
      } catch (error) {
        console.log("error", error);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: row;

  .wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;

    .table {
      flex: 1;
      display: flex;
      flex-direction: column;

      // .cell {
      //   display: flex;
      //   flex-direction: column;
      //   justify-content: space-around;
      //   background: #0a1f32;
      //   border-radius: 4px;
      //   height: 150px;
      //   padding: 10px 16px;
      //   margin-bottom: 10px;

      //   .row {
      //     display: flex;
      //     justify-content: space-between;
      //     text-align: left;
      //     line-height: 20px;

      //     .title {
      //       width: 150px;
      //       font-size: 14px;
      //       color: #90acdd;
      //       font-size: 11px;
      //       font-weight: 500;
      //     }

      //     .value {
      //       flex: 1;
      //       color: #ffffff;
      //       font-size: 11px;
      //       font-weight: 500;
      //     }
      //   }
      // }
      ul {
        li {
          height: 80px;
          background: #006a56;
          border: 1px solid #007a63;
          border-radius: 10px;
          display: flex;
          flex-direction: column;
          padding: 12px;
          box-sizing: border-box;
          margin-bottom: 12px;

          .yellow {
            color: #ffd400;
          }

          .green {
            color: #15ad51;
          }

          .li-top {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-bottom: 10px;

            span {
              font-size: 18px;
              color: #7bffa9;
              font-weight: bold;

              &:last-child {
                font-size: 12px;
                color: #81d6ba;
              }
            }
          }

          .li-bottom {
            display: flex;
            justify-content: space-between;
            font-size: 12px;
            color: #81d6ba;
            font-weight: 500;

            span {
              display: flex;
              align-items: center;
              color: #7bffa9;
            }
          }
        }
      }
    }
  }
}
</style>
