<template>
  <div class="container">
    <HeaderView class="m-header" :isLoggedIn="isLoggedIn" :isShowExtend="isShowExtend"></HeaderView>
    <div class="list">
      <div class="item" v-for="(item, index) in activityList" :key="index" @click="toPage(item.type)">
        <div class="l">
          <img :src="$global.data.cdn_url + item.icon" alt="" />
        </div>
        <div class="r">
          <div class="t">
            <div class="h1" v-if="item.type != 7">
              {{ item.title }} <span v-if="item.tips && item.tips > 0" class="tips">{{ item.tips }}</span>
            </div>
            <div class="h1" v-if="item.type == 7">
              <div style="display: flex; width: 80%">{{ item.title }}</div>
              <span v-if="item.tips && item.tips > 0" style="margin-left: -18px; margin-top: 2px" class="tips">{{ item.tips }}</span>
            </div>
            <div class="sub">{{ item.desc }}</div>
          </div>
          <div v-if="item.type == 1" class="b">
            {{ $t('bonus') }}: <span class="gre">{{ $tools.formatCurrency(item.bounty) }}</span>
          </div>
          <div v-if="item.type == 4" class="b">
            {{ $t('number_of_lucky_draws_today') }}: <span class="fff">{{ item.draw_number }}</span>
          </div>
          <div v-if="item.type == 5" class="b">
            {{ $t('completing_will_result_in') }}: <span class="yelo">{{ $tools.formatCurrency(item.cash) }}</span> +
            <span class="gre">{{ $tools.formatCurrency(item.bounty) }}</span>
          </div>
          <div v-if="item.type == 6" class="b">
            {{ $t('completing_will_result_in') }}: <span class="gre">{{ $tools.formatCurrency(item.bounty) }}</span>
          </div>
          <div v-if="item.type == 7" class="b">
            {{ $t('completing_will_result_in') }}: <span class="fff">{{ item.daily_deals_pay_number }}</span>
          </div>
          <div v-if="item.type == 8" class="b">
            {{ $t('tentativas_Restantes') }}: <span class="fff">{{ item.daily_pay_give_number }}</span>
          </div>
          <div v-if="item.type == 9" class="b">
            {{ $t('next_opening_time') }}: <span class="fff">{{ item.red_begin_time }}-{{ item.red_end_time }}</span>
          </div>
          <div v-if="item.type == 10" class="b">
            {{ $t('savings_bank_balance') }}: <span class="yelo">{{ $tools.formatCurrency(item.bounty) }}</span>
          </div>
          <div v-if="item.type == 11" class="b agent_commission">
            <span class="yelo"
              >{{ $tools.formatCurrency(item.agent_commission.substring(0, item.agent_commission.indexOf("~"))) }}~{{
                $tools.formatCurrency(item.agent_commission.substring(item.agent_commission.indexOf("~") + 1))
              }}</span
            >
            +
            <span class="gre"
              >{{ $tools.formatCurrency(item.agent_target_reward.substring(0, item.agent_target_reward.indexOf("~"))) }}~{{
                $tools.formatCurrency(item.agent_target_reward.substring(item.agent_target_reward.indexOf("~") + 1))
              }}</span
            >
          </div>
          <div v-if="item.type == 12" class="b normal"><span class="yelo">Bônus diário com limite de distribuição, disponível por ordem de chegada</span></div>
        </div>
        <div class="arrowIcon">
          <img src="@/assets/activity/arrow.webp" alt="" />
        </div>
      </div>
      <template v-if="activityList.length == 0">
        <div class="placeholder" v-for="(item, index) in 10" :key="index">
          <img src="@/assets/game_default.webp" />
          <div class="animate"></div>
        </div>
      </template>
    </div>
    <div class="feedback" @click="$tools.openTelegram($global.data.cooperate_link)">
      {{ $t('feedback_and_suggestions') }}
      <img src="@/assets/activity/feedback_icon.webp" alt="" />
    </div>
    <div class="ext">{{ $t('feedback_des') }}</div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import HeaderView from "@/pages/HeaderView.vue";

export default {
  components: {
    HeaderView,
  },
  props: {
    isShowExtend: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activityList: [],
    };
  },

  computed: {
    ...mapGetters(["isLoggedIn"]),
  },
  mounted() {
    this.fetchListData();
    this.$tools.newerActivityFinish({ view_active_finish: 1 });
  },
  methods: {
    async fetchListData() {
      const data = {
        action: "promotion_activity_list",
        cmd: "activity",
        data: {},
      };
      try {
        const response = await this.$http.post("/gateway", data);
        this.activityList = response.data.list;
      } catch (error) {
        console.log("error", error);
      }
    },
    toPage(type) {
      console.log(type);

      switch (type) {
        case 1: // 注册赠送
          // this.$router.push("/m-raffle")
          break;
        case 2: // 首次充值
          this.$router.push("/first-deposit");
          break;
        case 3: // 新手任务
          this.$router.push("/newer-task");
          break;
        case 4: // 首次充值
          this.$router.push("/daily-lottery-draw");
          break;
        case 5: // 每日签到
          this.$router.push("/daily-check-in");
          break;
        case 6: // 每日任务
          this.$router.push("/m-dailyTasks");
          break;
        case 7: // 每日存款特惠
          this.$router.push({ name: "m-deposit-page", query: { tabIndex: 0 } });
          break;
        case 8: // 每日存款赠送
          this.$router.push({ name: "m-deposit-page", query: { tabIndex: 1 } });
          break;
        case 9: // 红包雨
          this.$router.push("/m-redbaoyu");
          break;
        case 10: // 存钱罐
          this.$router.push("/piggy-bank-page");
          break;
        case 11: // 全民代理
          this.$eventBus.emit("emitOfChangeTab", "commission");
          break;
        case 12: // 频道号
          this.$router.push("/m-socialMedia");
          break;

        default:
          break;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@keyframes lazy-loading {
  0% {
    background-position-x: 150%;
  }

  100% {
    background-position-x: -150%;
  }
}

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  .m-header {
    position: fixed;
    top: 0;
    z-index: 10;
  }
  .list {
    margin-top: 50px;
    padding: 20px 18px;
    box-sizing: border-box;
    position: relative;
    z-index: 1;
    .item {
      width: 100%;
      height: 92px;
      background: #006a56;
      border-radius: 15px;
      display: flex;
      text-align: left;
      padding: 12px 10px;
      box-sizing: border-box;
      position: relative;
      margin-bottom: 6px;

      .arrowIcon {
        position: absolute;
        right: 20px;
        top: 10px;

        img {
          height: 15px;
          height: 15px;
        }
      }

      .l {
        width: 60px;
        height: 60px;
        margin-right: 33px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .r {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 1;

        .t {
          .h1 {
            display: flex;
            color: #fff;
            font-weight: bold;
            font-size: 12px;

            .tips {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 15px;
              height: 15px;
              background: #f04770;
              border-radius: 100px;
              margin-left: 6px;
              font-size: 10px;
            }
          }

          .sub {
            color: #81d6ba;
            font-size: 10px;
            font-weight: 500;
            padding-right: 20px;
          }
        }

        .b {
          font-size: 10px;
          font-weight: bold;
          color: #81d6ba;

          span.gre {
            color: #0ac325;
          }

          span.jia {
            color: #81d6ba;
          }

          span.yelo {
            color: #ffd400;
          }

          span.fff {
            color: #fff;
          }
        }

        .agent_commission {
          font-size: 15px;
          margin-top: 8px;
        }

        .normal {
          font-size: 10px;
          font-weight: normal;
          line-height: 1;
          margin-top: 8px;
          padding-right: 4px;
        }
      }
    }

    .placeholder {
      width: 100%;
      height: 92px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      border-radius: 15px;
      position: relative;
      background: #004134;
      margin-bottom: 6px;

      & > img {
        position: absolute;
        width: 80px;
        height: 80px;
        left: 20px;
      }

      .animate {
        width: 100%;
        height: 100%;
        background: url("@/assets/game_default2.png") transparent no-repeat;
        background-size: 200%;
        background-position-y: 40%;
        animation: lazy-loading 1.2s linear infinite;
      }
    }
  }

  .feedback {
    margin: 20px 15px 15px;
    background: #ffd400;
    height: 40px;
    line-height: 40px;
    border-radius: 8px;
    color: #062017;
    font-size: 12px;
    font-weight: 500;
    position: relative;

    & > img {
      position: absolute;
      width: 28.5px;
      height: 24px;
      top: 8px;
      right: 12px;
    }
  }

  .ext {
    margin: auto;
    margin-bottom: 30px;
    color: #3fb48c;
    font-size: 10px;
    font-weight: 500;
    width: 185px;
  }
}
</style>
