<template>
  <div class="container">
    <div class="wrapper">
      <div class="img-box">
        <img :src="$global.data.cdn_url + selAvatarUrl" alt="" />
      </div>
      <div class="des">{{ $t('avatar_des') }}</div>
      <div class="collection">
        <div v-for="(item, index) in avatar_list" :key="index" class="item" @click="selAvatarUrl = item.icon_url">
          <img :src="$global.data.cdn_url + item.icon_url" alt="" />
        </div>
      </div>
    </div>
    <div class="btn" @click="changeAvatar(selAvatarUrl)">{{ $t('save') }}</div>

  </div>
</template>
<script>
export default {
  data() {
    return {
      avatar_list: [],
      selAvatarUrl: "",
    };
  },
  mounted() {
    this.$emit("update:title", "Avatar");
    this.fetchUserInfo();
    this.fetchAvatarList();
  },
  methods: {
    async fetchUserInfo() {
      const data = {
        action: "my_account",
        data: {},
      };
      try {
        const response = await this.$http.post("userCenter", data);
        console.log("response", response);
        this.selAvatarUrl = response.data.data.icon;
      } catch (error) {
        console.log("error", error);
      }
    },
    async fetchAvatarList() {
      const data = {
        action: "user_avatar_list",
        data: {},
      };
      try {
        const response = await this.$http.post("userCenter", data);
        this.avatar_list = response.data.avatar_list;
      } catch (error) {
        console.log("error", error);
      }
    },
    async changeAvatar(icon_url) {
      const data = {
        action: "change_avatar",
        data: {
          icon_url,
        },
      };
      try {
        await this.$http.post("userCenter", data);
        this.$router.back();
      } catch (error) {
        console.log("error", error);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  display: flex;
  width: 100vw;
  max-width: var(--max-width);
  .wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    background: #004134;
    // height: 100vh;
    padding-bottom: 23px;
    .img-box {
      display: flex;
      justify-content: center;
      align-items: center;

      & > img {
        width: 70px;
        height: 70px;
      }
    }

    .des {
      margin-top: 10px;
      color: #81D6BA;
      font-size: 12px;
      font-weight: 500;
    }

    .collection {
      margin: 10px 15px 0;
      padding: 22px;
      background: #006A56;
      border-radius: 8px;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      row-gap: 13px;
      column-gap: 20px;

      .item {
        display: flex;
        justify-content: center;
        align-items: center;

        & > img {
          width: 50px;
          height: auto;
        }
      }
    }

    
  }
  .btn {
      margin: 20px 15px;
      background: #FFD400;
      height: 45px;
      line-height: 45px;
      border-radius: 8px;
      color: #000000;
      font-size: 18px;
      font-weight: 500;
    }
}
</style>
