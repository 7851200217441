<template>
  <div class="container">
    <!-- 分类 -->
    <div class="cate">
      <div class="item" :class="cateIndex === index ? 'highlight' : ''" v-for="(item, index) in cateList" :key="index" @click="handleOfCate(index)">
        <div>{{ item.name }}</div>
        <div class="point" v-show="drawsTips[index] === 1"></div>
      </div>
    </div>
    <div class="cate-des" v-if="cateIndex === 0">Participar de mais atividades pode garantir mais oportunidades de sorteio.</div>
    <!-- 小分类 -->
    <div class="sub-cate" v-else>
      <div class="item" :class="subCateIndex === index ? 'highlight' : ''" v-for="(item, index) in raffles" :key="index" @click="handleOfSubCate(index)">
        <div>{{ item.raffle_vip_level_name }}</div>
        <div class="point" v-show="item.draws_numbers > 0"></div>
      </div>
    </div>
    <!-- 游戏 -->
    <div v-show="awards.length !== 0" class="game" :style="{ 'min-height': itemWidth * 4 + 30 + 'px' }">
      <div v-for="(item, index) in awards" :key="index" :style="getImageStyle(index)">
        <div class="box" :class="index === highlightIndex ? 'highlight' : ''" :style="{ width: itemWidth + 'px', height: itemWidth + 'px' }">
          <img :src="$global.data.cdn_url + item.prize_info.prize_image_url" alt="Image" />
        </div>
      </div>
      <div class="center">
        <div class="level" v-if="cateIndex !== 0">{{ userInfo.vip_level_name }}: {{ userInfo.vip_small_level }}</div>
        <div class="count">Número da sorte {{ "(" + raffles[subCateIndex]?.draws_numbers + ")" }}</div>
        <div class="button" :class="raffles[subCateIndex]?.draws_numbers === 0 ? 'disabled' : ''" @click="handleOfPlaying">começar</div>
      </div>
    </div>
    <div class="list" v-show="awards.length !== 0">
      <div class="title">Prêmio</div>
      <div class="item" v-for="(item, index) in awards" :key="index">
        <img :src="$global.data.cdn_url + item.prize_info.prize_image_url" alt="" />
        <div class="text">
          <div class="name">{{ item.prize_info.prize_name }}</div>
          <div class="des">{{ item.prize_info.describe }}</div>
        </div>
      </div>
    </div>
    <dialog ref="rewardTips">
      <div class="reward-tips-box">
        <div>Parabéns</div>
        <div>{{ winningPrizeInfo?.prize_info?.prize_name }}</div>
        <div>Já armazenado na lista de prémios</div>
        <div class="buttons">
          <div @click="handleOfRewardTipsClose">Bom</div>
          <div @click="handleOfUsingPrizes">Usar</div>
        </div>
      </div>
    </dialog>
    <dialog ref="prizeAmountTips">
      <div class="reward-tips-box">
        <!-- Prompt -->
        <div>Prompt</div>
        <div>R$:{{ $tools.formatCurrency(prizeAmountTipsValue) }}</div>
        <div>A recompensa foi depositada na carteira</div>
        <div class="button" @click="handleOfPrizeAmountTipsOk">OK</div>
      </div>
    </dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      cateList: [{ name: "Sorteio de Evento" }, { name: "Sorteio de Membros" }, { name: "Sorteio de Promoção" }],
      itemWidth: 0,
      highlightIndex: -1,
      stopIndex: -1,
      timeout: 100,
      loopCount: 3,
      curIndex: -1,
      cateIndex: 0,
      subCateIndex: -1,
      awards: [],
      drawsTips: [0, 0, 0],
      // drawsCount: [0, 0, 0],
      raffles: [],
      winningPrizeInfo: {},
      usePrizeInfo: {},
      userInfo: {},
      prizeAmountTipsValue: 0,
      isPlaying: false,
    };
  },
  mounted() {
    this.$emit("update:title", this.$t("Roda da Fortuna"));
    this.fetchUserInfo();
    // 获取根元素（即 :root）上的计算样式
    const rootStyles = getComputedStyle(document.documentElement);

    // 获取 --max-width 变量的值
    const maxWidth = rootStyles.getPropertyValue("--max-width").trim();

    // 将 maxWidth 转换为整数（如果需要的话）
    const maxWidthValue = parseInt(maxWidth, 10);
    const screenWidth = Math.min(screen.width, maxWidthValue);
    this.itemWidth = (screenWidth - 30 - 10 * 3) / 4;
    this.cateIndex = parseInt(this.$route.query.index || 0);
    this.resetAllData();
  },
  methods: {
    resetAllData() {
      this.fetchDrawsTips();
      // this.fetchCount();
      this.fetchPrizeList();
    },
    fetchUserInfo() {
      const data = {
        action: "my_account",
        data: {},
      };

      this.$http
        .post("userCenter", data)
        .then((response) => {
          this.userInfo = response.data.data;
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    handleOfPrizeAmountTipsOk() {
      this.$refs.prizeAmountTips.close();
      this.nextOfUsedPrize();
    },
    nextOfUsedPrize() {
      const followPage = this.usePrizeInfo.prize_info.follow_page;
      switch (followPage) {
        case 0: // 不跳转
          break;
        case 1: // 首页
          this.$eventBus.emit("emitOfChangeTab", "casino");
          break;
        case 2: // 充值页
          this.$router.push({ name: "m-deposit-page" });
          break;
        case 3: // 提款页面
          this.$router.push({ name: "m-withdrawal-page" });
          break;
        case 4: // 抽奖页面
          break;

        default:
          break;
      }
    },
    async usePrize() {
      const data = {
        action: "use_raffle_prize",
        data: {
          raffle_prize_id: this.winningPrizeInfo.id,
        },
      };
      try {
        const response = await this.$http.post("userVip", data);
        this.usePrizeInfo = response.data.user_raffle_prize_info;
        if ("prize_amount" in response.data && response.data.prize_amount > 0) {
          this.prizeAmountTipsValue = response.data.prize_amount;
          this.$refs.prizeAmountTips.showModal();
        } else {
          this.nextOfUsedPrize();
        }
        this.resetAllData();
      } catch (error) {
        console.log("error", error);
        this.resetAllData();
      }
    },
    handleOfRewardTipsClose() {
      this.$refs.rewardTips.close();
      this.resetAllData();
    },
    handleOfUsingPrizes() {
      this.$refs.rewardTips.close();
      this.usePrize();
    },
    async handleOfPlaying() {
      if (this.isPlaying) {
        return;
      }
      if (this.raffles[this.subCateIndex]?.draws_numbers === 0) {
        return;
      }
      this.raffles[this.subCateIndex].draws_numbers--;

      const data = {
        action: "user_ticket_lottery",
        data: {
          raffle_id: this.raffles[this.subCateIndex]?.id, // 活动id
        },
      };
      try {
        const response = await this.$http.post("userVip", data);
        this.winningPrizeInfo = response.data.user_raffle_prize_info;
        this.stopIndex = this.awards.findIndex((item) => {
          return item.prize_id === this.winningPrizeInfo.prize_id;
        });
        this.isPlaying = true;
        this.selecteNextItem();
      } catch (error) {
        console.log("error", error);
      }
    },
    async fetchDrawsTips() {
      const data = {
        action: "awaiting_draws_tips",
        data: {},
      };

      try {
        const response = await this.$http.post("userVip", data);
        this.drawsTips[0] = response.data.activity_draws_tips;
        this.drawsTips[1] = response.data.today_draws_tips;
        this.drawsTips[2] = response.data.promotion_draws_tips;
      } catch (error) {
        console.log("error", error);
      }
    },
    async fetchPrizeList() {
      const raffle_type = this.cateIndex === 0 ? 3 : this.cateIndex; //1，每日抽奖。2，升级抽奖，3普通抽奖
      const data = {
        action: "raffle_prize_list",
        data: {
          raffle_type,
          raffle_vip_level_min: 1, //最低适配VIP等级}
        },
      };

      try {
        const response = await this.$http.post("userVip", data);
        this.raffles = response.data.raffle_list.filter((item) => {
          return item.raffle_type === raffle_type;
        });

        if (raffle_type === 3) {
          // 普通抽奖
          this.subCateIndex = 0;
        } else {
          if (this.subCateIndex === -1) {
            this.subCateIndex = this.raffles.findIndex((item) => {
              return item.draws_numbers > 0;
            });

            if (this.subCateIndex === -1) {
              this.subCateIndex = 0;
            }
          }
        }
        this.handleOfSubCate(this.subCateIndex);
      } catch (error) {
        console.log("error", error);
      }
    },
    handleOfCate(index) {
      this.cateIndex = index;
      this.fetchPrizeList();
    },
    handleOfSubCate(index) {
      this.subCateIndex = index;
      this.awards = this.raffles[index].raffle_prize_list;
    },
    getImageStyle(index) {
      switch (index) {
        case 0:
          return { transform: "translate(0, 0)" };
        case 1:
          return { transform: `translate(${this.itemWidth + 10}px, 0)` };
        case 2:
          return { transform: `translate(${(this.itemWidth + 10) * 2}px, 0)` };
        case 3:
          return { transform: `translate(${(this.itemWidth + 10) * 3}px, 0)` };
        case 4:
          return { transform: `translate(${(this.itemWidth + 10) * 3}px, ${(this.itemWidth + 10) * 1}px)` };
        case 5:
          return { transform: `translate(${(this.itemWidth + 10) * 3}px, ${(this.itemWidth + 10) * 2}px)` };
        case 6:
          return { transform: `translate(${(this.itemWidth + 10) * 3}px, ${(this.itemWidth + 10) * 3}px)` };
        case 7:
          return { transform: `translate(${(this.itemWidth + 10) * 2}px, ${(this.itemWidth + 10) * 3}px)` };
        case 8:
          return { transform: `translate(${(this.itemWidth + 10) * 1}px, ${(this.itemWidth + 10) * 3}px)` };
        case 9:
          return { transform: `translate(0, ${(this.itemWidth + 10) * 3}px)` };
        case 10:
          return { transform: `translate(0, ${(this.itemWidth + 10) * 2}px)` };
        case 11:
          return { transform: `translate(0, ${(this.itemWidth + 10) * 1}px)` };

        default:
          break;
      }
    },
    selecteNextItem() {
      const totleIndex = this.stopIndex + this.loopCount * this.awards.length;
      if (this.curIndex === totleIndex) {
        this.isPlaying = false;
        this.$refs.rewardTips.showModal();
        this.timeout = 100;
        this.highlightIndex = -1;
        this.stopIndex = -1;
        this.curIndex = -1;
        return;
      }

      this.curIndex++;
      this.highlightIndex = this.curIndex % 12;
      if (totleIndex - this.curIndex < 8) {
        this.timeout *= 1.3;
      }

      setTimeout(() => {
        this.selecteNextItem();
      }, this.timeout);
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;

  .cate {
    margin: 10px 15px;
    border-radius: 8px;
    display: flex;
    background: var(--cate-normal-bg);

    & > .item {
      flex: 1;
      height: 35px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      font-size: 12px;
      font-weight: 500;
      color: var(--cate-text-normal-color);
      padding: 5px 27px;

      & > .point {
        position: absolute;
        right: 30px;
        width: 6px;
        height: 6px;
        border-radius: 3px;
        background: #ff5000;
      }
    }

    & > .highlight {
      background: var(--cate-selected-bg);
      color: var(--cate-text-selected-color);
      border-radius: 6px;
    }
  }

  .cate-des {
    margin: 30px 35px 32px;
    color: #96a7c6;
    font-size: 12px;
    font-weight: 300;
  }

  .sub-cate {
    margin: 0 15px 32px;
    display: flex;
    background: var(--cate-normal-bg);
    border-radius: 8px;

    & > .item {
      flex: 1;
      height: 40px;
      color: #ffffff;
      font-size: 12px;
      font-weight: 300;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;

      & > .point {
        position: absolute;
        top: 5px;
        width: 6px;
        height: 6px;
        border-radius: 3px;
        background: #ff5000;
      }
    }

    & > .highlight {
      font-weight: 600;
      color: #ffd400;
    }
  }

  .game {
    position: relative;
    padding: 0 15px;
    display: flex;

    .center {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .level {
        color: #3e7bb0;
        font-size: 9px;
        font-weight: bold;
        margin-bottom: 14px;
      }

      .count {
        color: #ffd400;
        font-size: 12px;
        font-weight: 500;
        margin-bottom: 18px;
      }

      .button {
        width: 100px;
        height: 100px;
        background: #ffd400;
        border-radius: 50px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 18px;
        font-weight: bold;
        color: #06131c;
      }

      .disabled {
        background: #194164;
        color: #3e7bb0;
      }
    }

    .box {
      position: absolute;
      left: 0;
      top: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #194164;
      border-radius: 10px;

      img {
        width: 60px;
        height: 40px;
      }
    }

    .highlight {
      background: #a6dc36;
    }
  }

  .list {
    margin: 20px 15px;
    display: flex;
    flex-direction: column;

    .title {
      color: #ffffff;
      font-size: 14px;
      font-weight: 500;
      text-align: left;
      margin-bottom: 20px;
    }

    & > .item {
      background: var(--mine-card-bg-color);
      border-radius: 10px;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      height: 80px;

      img {
        width: 60px;
        height: 40px;
        margin: auto 18px;
      }

      .text {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .name {
          color: #ffffff;
          font-size: 12px;
          font-weight: 500;
        }

        .des {
          margin-top: 7px;
          color: #ffffff;
          font-size: 9px;
          font-weight: 300;
          text-align: left;
        }
      }
    }
  }

  .reward-tips-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 345px;
    height: 220px;
    background: var(--color-popup-bg);
    border-radius: 10px;

    div:nth-child(1) {
      color: #ffffff;
      font-size: 18px;
      font-weight: bold;
    }

    div:nth-child(2) {
      color: #ffd400;
      font-size: 32px;
      font-weight: 500;
    }

    div:nth-child(3) {
      color: #ffffff;
      font-size: 18px;
      font-weight: normal;
    }

    .buttons {
      width: 90%;
      display: flex;
      justify-content: space-between;

      div {
        flex: 0 1 48%;
        background: var(--color-btn-bg);
        height: 40px;
        border-radius: 8px;
        color: var(--color-btn-text);
        font-size: 18px;
        font-weight: 500;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .button {
      background: var(--color-btn-bg);
      color: var(--color-btn-text);
      border-radius: 8px;
      width: 80%;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
