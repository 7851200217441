<template>
  <div class="history">
    <div class="wrapper">
      <mine-empty type="kong" v-if="isShowEmpty"></mine-empty>
      <div class="m-table" v-if="isShowEmpty === false">
        <ul>
          <li v-for="(item, index) in list" :key="index">
            <div class="li-bottom">Lucro</div>
            <div class="li-top">
              <span :class="item.win_amount > 0 ? 'win' : 'lose'">R${{ $tools.formatCurrency(item.win_amount) }}</span>
              <span>{{ $t('bet_amount') }}:R${{ $tools.formatCurrency(item.bet_amount) }} Real</span>
            </div>
            <div class="li-center">
              <span>{{ item.provider_name }} | {{ item.game_name_en }}</span>
              <span>{{ getGameStatusStr(item.status) }}</span>
            </div>
            <div class="li-bottom">
              <span>{{ item.parent_bet_id }}</span>
              <span>odds:{{ item.multiple }}</span>
            </div>
          </li>
        </ul>
        <LoadMoreComp :curCount="list.length" :totalCount="totalPage" @loadMoreEvent="fetchGameHistory(page + 1)"></LoadMoreComp>
      </div>
    </div>
  </div>
</template>

<script>
import MineEmpty from "@/components/MineEmpty.vue";
import LoadMoreComp from "@/components/LoadMoreComp.vue";
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
  },
  emits: ["update:title"],
  data() {
    return {
      list: [],
      page: 0,
      totalPage: 0,
      pageSize: 10,
      isShowEmpty: true,
    };
  },
  components: {
    MineEmpty,
    LoadMoreComp,
  },
  mounted() {
    this.$emit("update:title", this.$t("game_history"));
    this.fetchGameHistory(0);
  },
  methods: {
    getGameStatusStr(status) {
      if (status < 0) {
        return "Bet";
      } else if (status > 0) {
        return "Win";
      } else {
        return "Igual";
      }
    },
    checkGame(game) {
      this.$router.push({
        path: "/m-game-details/" + game.id,
      });
    },
    fetchGameHistory(page) {
      const data = {
        action: "game_history",
        data: {
          page,
          page_size: this.pageSize,
        },
      };
      this.$http
        .post("gameCenter", data)
        .then((response) => {
          this.list.push(...response.data.list);

          this.page = page;
          this.isShowEmpty = this.list.length === 0;
          this.totalPage = response.data.count;
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.history {
  display: flex;
  .wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;

    .m-table {
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 12px;

      ul {
        width: 100%;
        .win {
          color: #21d567;
          opacity: 0.8;
        }
        .lose {
          color: #fff !important;
          // opacity: 0.8;
        }
        li {
          background: #006a56;
          border: 1px solid #007a63;
          border-radius: 10px;
          display: flex;
          flex-direction: column;
          padding: 12px;
          box-sizing: border-box;
          margin-bottom: 12px;

          .m-value-positive {
            color: #7bffa9 !important;
          }

          .m-value-negative {
            color: #ff9d70 !important;
          }

          .li-top {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-bottom: 3px;

            span {
              font-size: 18px;
              color: #7bffa9;
              font-weight: bold;

              &:last-child {
                font-size: 12px;
                color: #81d6ba;
              }
            }
          }

          .li-center {
            display: flex;
            justify-content: space-between;
            font-size: 12px;
            line-height: 2;
            color: #81d6ba;

            span {
              font-weight: 500;
            }
          }

          .li-bottom {
            display: flex;
            justify-content: space-between;
            font-size: 11px;
            color: #81d6ba;
            font-weight: 500;
            &:first-child {
              margin-bottom: 4px;
            }
            span {
              display: flex;
              align-items: center;

              img {
                width: 15px;
                height: 15px;
                margin-left: 12px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
