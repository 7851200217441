<template>
  <div class="content">
    <div class="title">Privacy</div>
    <div class="desc">
      Policy You hereby acknowledge and accept that if we deem necessary, we are able to collect and otherwise use your
      personal data in order to allow you access and use of the Websites and in order to allow you to participate in the
      Games.
      <br> We hereby acknowledge that in collecting your personal details as stated in the previous provision, we are
      bound by the Data Protection Act. We will protect your personal information and respect your privacy in accordance
      with best business practices and applicable laws. We will use your personal data to allow you to participate in
      the Games and to carry out operations relevant to your participation in the Games. We may also use your personal
      data to inform you of changes, new services and promotions that we think you may find interesting. If you do not
      wish to receive such direct marketing correspondences, you may opt out of the service. <br> Your personal data will not
      be disclosed to third parties, unless it is required by law. As Bet mace business partners or suppliers or service
      providers may be responsible for certain parts of the overall Privacy Policy <br> You hereby acknowledge and accept
      that if we deem necessary, we are able to collect and otherwise use your personal data in order to allow you
      access and use of the Websites and in order to allow you to participate in the Games. <br>Privacy Policy <br> You hereby
      acknowledge and accept that if we deem necessary, we are able to collect and otherwise use your personal data in
      order to allow you access and use of the Websites and in order to allow you to participate in the Games. We hereby
      acknowledge that in collecting your personal details as stated in the previous provision, we are bound by the Data
      Protection Act. We will protect your personal information and respect your privacy in accordance with best
      business practices and applicable laws.<br> We will use your personal data to allow you to participate in the Games
      and to carry out operations relevant to your participation in the Games.<br> We may also use your personal data to
      inform you of changes, new services and promotions that we think you may find interesting. If you do not wish to
      receive such direct marketing correspondences, you may opt out of the service.<br> Your personal data will not be
      disclosed to third parties, unless it is required by law. As Bet mace business partners or suppliers or service
      providers may be responsible for certain parts of the overall Privacy Policy
    </div>
  </div>
</template>
<script>
export default {
  mounted() {
    this.$emit("update:title", "Privacy Policy");
  }
};
</script>
<style lang="scss" scoped>
.content {
  padding: 20px;
  font-size: 10px;
  font-weight: normal;
  line-height: 14px;
  color: #FFFFFF;
  text-align: left;
  line-height: 1.5;
}
</style>
