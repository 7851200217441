// Meta Pixel Code
function loadFacebookPixelScript(callback) {
  (function (f, b, e, v, n, t, s) {
    if (f.fbq) return;
    n = f.fbq = function () {
      n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
    };
    if (!f._fbq) f._fbq = n;
    n.push = n;
    n.loaded = !0;
    n.version = "2.0";
    n.queue = [];
    t = b.createElement(e);
    t.async = !0;
    t.src = v;
    s = b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t, s);
    if (callback) callback();
  })(window, document, "script", "https://connect.facebook.net/en_US/fbevents.js");
}

function initializeFacebookPixel(pixelId) {
  if (window.fbq) {
    window.fbq("init", pixelId);
    window.fbq("track", "PageView");
  }
}

function setupFacebookPixel(pixelId) {
  if (!pixelId) return;
  if (!window.fbq) {
    loadFacebookPixelScript(() => initializeFacebookPixel(pixelId));
  } else {
    initializeFacebookPixel(pixelId);
  }
}

function trackEvent(eventName, params = {}) {
  if (window.fbq) {
    window.fbq("track", eventName, params);
  }
}

export default {
  setupFacebookPixel,
  trackEvent,
};
