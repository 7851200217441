<template>
  <div class="dailyTasks">
    <!-- 每日任务 -->
    <div class="wrap">
      <div class="title">{{ $t('today_deposit') }}</div>
      <div class="autoWrap">
        <div class="item" v-for="(item, index) in daily_task_list.pay_task" :key="index">
          <div class="num">{{ item.target_amount }}</div>
          <div class="dot"></div>
          <div :class="item.status == 1 ? 'price collect' : item.status == 2 ? 'price overCollect' : 'price '">
            <img src="@/assets/price.webp" v-if="!item.status == 1" alt="">
            <span :class="item.status == 2 ? 'fff' : ''">{{ $tools.formatCurrency(item.bounty_amount) }}</span>
            <span v-if="item.status == 1" @click="handleLing(item)">{{ $t('receive') }}</span>
            <span class="overCollect" v-if="item.status == 2">{{ $t('recebido') }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="daytou">
      <div class="title">{{ $t('today_betting') }}</div>
      <ul>
        <li v-for="(item, index) in daily_task_list.bet_task" :key="index">
          <div class="left">
            <div v-if="item.type == 5">
              <div class="h1">{{ $t('bet_bonus') }}</div>
              <div class="desc">{{ $t('m_dailyTasks_des1') }}</div>
            </div>
            <div v-if="item.type == 6">
              <div class="h1">{{$t('single_bet')}}</div>
              <div class="desc">{{$t('single_bet_amount')}}: {{ item.target_amount }}</div>
              <div class="desc">{{$t('all_games_are_open_for_participation')}}</div>
            </div>
            <div class="progress">
              <div class="ji">
                <div><span class="first">{{ item.done_amount }}</span>/{{ item.type == 5 ? item.target_amount : 1 }}
                </div>
                <span v-if="item.type == 5">100%</span>
              </div>
              <div class="bar" v-if="item.type == 5">
                <span :style="{ width: (item.done_amount / item.target_amount) * 100 + '%' }"></span>
              </div>
            </div>
          </div>
          <div class="right">
            <div class="jiangjin">
              <img src="@/assets/dao.webp" alt="">
              {{ $t('bonus') }}
            </div>
            <div class="dao">{{ $tools.formatCurrency(item.bounty_amount) }}</div>
            <div class="btn no" v-if="item.status == 0">{{ $t('receive') }}</div>
            <div class="btn" v-if="item.status == 1" @click="handleLingJiang(item)">{{ $t('receive') }}</div>
            <div class="btn over" v-if="item.status == 2">{{ $t('recebido') }}</div>
          </div>
        </li>
      </ul>
    </div>
    <dialog ref="taskDialog">
      <div class="task-dialog-wrapper">
        <div class="header">
          <div>{{$t('prompt')}}</div>
          <img src="@/assets/daole.webp" alt="" />
          <img src="@/assets/account/close.webp" alt="" @click="handleOk()" />
        </div>
        <div class="sucesso">{{ $t('success_in_withdrawal') }}</div>
        <div class="addPrice">+{{ $tools.formatCurrency(bounty_amount) }}</div>
        <div class="desc">
          {{ $t('successfully_des') }}
        </div>
        <div class="btns" @click="handleOk()">{{ $t('bom') }}</div>
      </div>
    </dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [
        {
          num: 10,
          price: 0.10,
          isCollect: true
        },
        {
          num: 30,
          price: 0.50,
          isCollect: false
        },
        {
          num: 60,
          price: 1.00,
          isCollect: false
        },
        {
          num: 120,
          price: 1.50,
          isCollect: false
        },
        {
          num: 240,
          price: 2.00,
          isCollect: false
        },
        {
          num: 480,
          price: 2.50,
          isCollect: false
        },
        {
          num: 960,
          price: 3.00,
          isCollect: false
        },
      ],
      daily_task_list: {},
      bounty_amount: 0
    }
  },
  methods: {
    getInfo() {
      const data = {
        action: "daily_task_info",
        data: {},
        cmd: "activity",
      };
      this.$http
        .post("/gateway?cmd=/activity", data)
        .then((res) => {
          console.log(res);
          this.daily_task_list = res.data.daily_task_list
        })
        .catch((error) => {
          // 请求失败后的处理
          console.error(error);
        });
    },
    // 领取奖金
    handleLing(item) {
      const data = {
        action: "receive_daily_task_bounty",
        data: {
          type: 4,
          task_id: item.task_id
        },
        cmd: "activity",
      };
      this.$http
        .post("/gateway?cmd=/activity", data)
        .then((res) => {
          console.log(res);
          this.$refs.taskDialog.showModal();
          this.bounty_amount = item.bounty_amount
        })
        .catch((error) => {
          // 请求失败后的处理
          console.error(error);
        });
    },
    handleOk() {
      this.$refs.taskDialog.close()
      this.getInfo()
    },
    handleLingJiang(item) {
      const data = {
        action: "receive_daily_task_bounty",
        data: {
          type: 5,
          task_id: item.task_id
        },
        cmd: "activity",
      };
      this.$http
        .post("/gateway?cmd=/activity", data)
        .then((res) => {
          console.log(res);
          this.$refs.taskDialog.showModal();
          this.bounty_amount = item.bounty_amount
        })
        .catch((error) => {
          // 请求失败后的处理
          console.error(error);
        });
    }
  },
  mounted() {
    this.$emit("update:title", this.$t('daily_tasks'));
    this.getInfo()
  },
} 
</script>

<style lang="scss" scoped>
.dailyTasks {
  color: #fff;
  display: flex;
  align-items: flex-start;

  .title {
    font-family: Roboto;
    font-size: 12px;
    font-weight: bold;
    line-height: 14px;
    color: #FFFFFF;
    margin-bottom: 13px;
    text-align: left;
  }

  .wrap {
    width: 100%;
    background: #004134;
    text-align: left;
    box-sizing: border-box;
    padding: 0 0 0 20px;



    .autoWrap {
      width: 100%;
      height: 90px;
      margin-bottom: 10px;
      overflow: auto;
      display: flex;
      flex-wrap: nowrap;
    }

    .item {
      font-size: 12px;
      color: #00CB47;
      font-weight: bold;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-right: 15px;

      &:last-child {
        margin-right: 20px;

        .dot {
          &::after {
            width: 0 !important;
          }
        }
      }

      .num {
        color: #029479;
        font-weight: bold;
        font-size: 10px;
      }

      .dot {
        width: 10px;
        height: 10px;
        background: #015444;
        border-radius: 100px;
        margin: 4px 0 10px 0;
        position: relative;

        &::after {
          display: inline-block;
          content: '';
          width: 66px;
          height: 1px;
          background: #015444;
          position: absolute;
          top: 50%;
          left: 10px;
        }

      }

      .price {
        width: 60px;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        img {
          width: 30px;
          height: 25px;
          margin-bottom: 4px;
        }
      }

      .collect {
        background: #FFE600;
        width: 60px;
        height: 45px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        border-radius: 10px;
        margin-top: 0px;
        color: #0B0B0B;
        font-size: 10px;
        position: relative;
        top: -1px;
        padding: 4px 0;
        box-sizing: border-box;
      }

      .overCollect {
        width: 60px;
        height: 45px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        border-radius: 10px;
        margin-top: 0px;
        color: #10b349;
        font-size: 10px;
        position: relative;
        top: 1px;
        padding: 4px 0;
        box-sizing: border-box;
      }

      .fff {
        color: #fff !important;
      }
    }
  }

  .daytou {
    padding: 10px 20px;
    box-sizing: border-box;
    width: 100%;

    ul {
      li {
        height: 90px;
        border-radius: 10px;
        background: #006A56;
        color: #81D6BA;
        display: flex;
        border: 1px solid #007A63;
        padding: 6px 10px;
        box-sizing: border-box;
        margin-bottom: 5px;

        .left {
          font-size: 12px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          margin-right: 10px;
          position: relative;
          width: 100%;

          .desc {
            text-align: left;
            font-size: 10px;
            padding-right: 20px;
            box-sizing: border-box;
            line-height: 16px;
            font-weight: 500;
          }

          .h1 {
            font-weight: bold;
            color: #FFFFFF;
            text-align: left;
          }

          .progress {
            display: flex;
            flex-direction: column;
            color: #81D6BA;
            font-size: 10px;
            font-weight: 500;
            position: absolute;
            bottom: 2px;
            width: 100%;

            .ji {
              display: flex;
              justify-content: space-between;

              .first {
                color: #FFFFFF;
              }
            }

            .bar {
              height: 6px;
              border-radius: 10px;
              background: #015444;
              margin-top: 2px;
              display: flex;
              align-items: center;

              span {
                display: inline-block;
                width: 100%;
                height: 100%;
                background: #00CB47;
                border-radius: 100px;
              }
            }
          }
        }

        .right {
          width: 80px;
          font-size: 12px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-around;

          img {
            width: 15px;
            height: 15px;
            margin-right: 4px;
          }

          .jiangjin {
            color: #81D6BA;
            font-weight: 500;
            display: flex;
            align-items: center;
            font-weight: 500;
          }

          .dao {
            color: #00CB47;
            font-size: 18px;
            font-weight: bold;
          }

          .btn {
            width: 80px;
            height: 30px;
            border-radius: 8px;
            background: #FFE600;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 500;
            color: #0B0B0B;
            font-weight: bold;
          }

          .no {
            background: #676767;
            color: #A5A5A5;
          }

          .over {
            background: transparent;
            color: #00CB47;
          }
        }
      }
    }
  }
}

.task-dialog-wrapper {
  display: flex;
  flex-direction: column;
  width: 320px;
  height: 247px;
  background: #015545;
  border-radius: 10px;

  &>.header {
    border-radius: 10px 10px 0px 0px;
    background: #012c23;
    height: 48px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 13px 0 23px;

    &>div:first-child {
      color: #81d6ba;
      font-size: 12px;
      font-weight: 500;
    }

    &>img:first-of-type {
      width: 70px;
      height: 48px;
    }

    &>img:last-of-type {
      width: 20px;
      height: 20px;
    }
  }

  .sucesso {
    text-align: center;
    font-size: 12px;
    font-weight: bold;
    color: #FFFFFF;
    margin: 15px 0;
  }

  .addPrice {
    text-align: center;
    font-size: 21px;
    color: #00E550;
    font-weight: bold;
  }

  .desc {
    color: #54E6B4;
    font-size: 11px;
    text-align: center;
    padding: 0 55px;
    font-weight: 500;
    margin-top: 20px;
  }

  .btns {
    width: 90%;
    margin: auto;
    height: 40px;
    background: #029479;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    color: #D8D8D8;
    font-weight: 500;
  }
}
</style>