<template>
  <div class="hongbao" v-if="info.show">
    <div class="wrapper" @click="handleOfClick">
      <img class="bg" src="@/assets/hb/hongbao.png" alt="" />
      <div class="info">
        <div class="title">Bem-vindo ao eg-wintop</div>
        <img class="coin" src="@/assets/hb/big_coin.png" alt="" />
        <div class="amount-box">
          <div class="unit">R$</div>
          <div class="amount">{{ info.amount }}</div>
        </div>
        <div class="des">Clique para receber R${{ info.amount }}</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    info: null,
  },
  methods: {
    handleOfClick() {
      this.info.clickCallback();
    },
  },
};
</script>
<style lang="scss" scoped>
.hongbao {
  z-index: 98; // z-index低于loadding(99)
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .wrapper {
    position: relative;
    width: 340px;
    display: flex;
    flex-direction: column;
    .bg {
      width: inherit;
      height: auto;
    }

    .info {
      position: absolute;
      width: inherit;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;

      .title {
        font-size: 18px;
        font-weight: 500;
        color: #d0b57c;
      }

      .coin {
        width: 44px;
        height: auto;
      }

      .amount-box {
        display: flex;
        align-items: center;

        .unit {
          font-size: 18px;
          font-weight: 500;
          color: #ffffff;
        }

        .amount {
          margin-left: 10px;
          font-size: 50px;
          font-weight: bold;
          color: #ffffff;
        }
      }

      .des {
        font-size: 12px;
        font-weight: 500;
        color: #e9d498;
      }
    }
  }
}
</style>
