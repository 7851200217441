<template>
  <div class="tab-bar-container">
    <div class="tabbar">
      <div class="item" v-for="(item, i) in list" :key="i" @click="tabItemHandle(item)">
        <div class="img_box">
          <img :src="index === i ? item.icon_h : item.icon" alt="" />
          <div :class="index === i ? 'txt_h' : 'txt'">{{ item.title }}</div>
        </div>
        <!-- <div v-if="tips[i] > 0" class="tip">{{ tips[i] }}</div> -->
        <div v-if="i == 1 && activityTipsCount > 0" class="tip">{{ activityTipsCount }}</div>
        <div v-if="i == 3 && vipTipsCount > 0" class="tip">{{ vipTipsCount }}</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      index: 0,
      tips: [0, 0, 0, 0, 0],
      activityTipsCount: 0,
      vipTipsCount:0,
      list: this.$global.tabs,
    };
  },
  mounted() {
    this.$eventBus.on("emitOfChangeTab", this.changeTab);
    this.$eventBus.on("refreshCount", this.fetchListData);
    this.$eventBus.on("resetTabbarIndex", (index=0) => {
      this.index = index;
    });
    var obj = this.list.find((item) => {
      return item.path === window.location.pathname;
    });
    if (window.location.pathname.includes("/wallet2")) {
      this.index = 2;
    } else if (obj !== undefined) {
      this.index = obj.index;
    }
    if (this.$store.getters.isLoggedIn) {
      this.fetchListData();
    }
  },
  methods: {
    async fetchListData() {
      const data = {
        action: "promotion_activity_list",
        data: {},
      };
      const data2 = {
        action: "vip_info",
        data: {},
      };
      try {
        const response = await this.$http.post("activity", data);
        const response2 = await this.$http.post("userVip", data2);
        this.activityTipsCount = response.data.tips;
        this.vipTipsCount = response2.data.total_tips;
      } catch (error) {
        console.log("error", error);
      }
    },
    changeTab(val) {
      const obj = this.list.find((item) => {
        return item.name === val;
      });
      if (obj) {
        this.tabItemHandle(obj);
      }
    },
    tabItemHandle(item) {
      const isLoggedIn = this.$store.getters.isLoggedIn;
      if (isLoggedIn) {
        this.index = item.index;
        this.$router.push({ name: item.name });

        if (this.index === 4) {
          // 我的埋点
          this.$tools.userTrackReport({ track_id: 23 });
        } else {
          // 其他埋点
          this.$tools.userTrackReport({ track_id: 15 + this.index + "" });
        }
      } else {
        this.$eventBus.emit("isNeedLogin");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.tab-bar-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .tabbar {
    background: var(--tab-bg-color);
    width: 100%;
    min-height: var(--tab-bar-height);
    max-width: var(--max-width);
    // margin-top: 12px;
    display: flex;

    .item {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;

      & > .tip {
        position: absolute;
        top: 6px;
        left: 60%;
        width: 15px;
        height: 15px;
        box-sizing: border-box;
        // border: 0.5px solid #ff0000;
        border-radius: 50%;
        background: #f04770;
        color: #fff;
        font-size: 9px;
        font-weight: 900;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .img_box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 60px;
        height: 53px;

        img {
          width: 20px;
          height: 20px;
        }

        .txt {
          margin-top: 5px;
          color: var(--tab-text-normal-color);
          font-size: 11px;
          font-weight: 300;
        }

        .txt_h {
          margin-top: 5px;
          color: var(--primary-text-color);
          font-size: 11px;
          font-weight: 500;
        }
      }
    }
  }

  // @media (max-width: 768px) {
  //   .tabbar {
  //     width: 100%;
  //   }
  // }
}
</style>
