<template>
  <div class="common-dialog-tip-content">
    <div class="header">
      <div>{{ title }}</div>
      <img src="@/assets/tanhao.webp" alt="" />
      <img src="@/assets/newer-task/close.webp" alt="" @click="handleOfCloseSuccessDialog" />
    </div>
    <div class="content-box">
      <div style="opacity: 0;">{{ title }}</div>
      <div>{{ des }}</div>
      <div>{{ value }}</div>
      <div @click="handleOfConfirm">{{ buttonTitle }}</div>
    </div>
    <img class="img" src="@/assets/login/close_icon.png" alt="" @click="handleOfClose" />
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: "Dicas",
    },
    des: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
    buttonTitle: {
      type: String,
      default: "",
    },
  },
  methods: {
    handleOfCloseSuccessDialog() {
      this.$emit("closeEvent");
    },
    handleOfConfirm() {
      this.$emit("confirmEvent");
    },
    handleOfClose() {
      this.$emit("closeEvent");
    },
  },
};
</script>
<style lang="scss" scoped>
.common-dialog-tip-content {
  width: 90vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  overflow: hidden;

  &>.header {
    border-radius: 10px 10px 0px 0px;
    background: #012C23;
    height: 48px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 13px 0 23px;
    width: 100%;
    box-sizing: border-box;
    &>div:first-child {
      color: #ffffff;
      font-size: 12px;
      font-weight: 500;
    }

    &>img:first-of-type {
      width: 70px;
      height: 48px;
    }

    &>img:last-of-type {
      width: 20px;
      height: 20px;
    }
  }

  .content-box {
    width: 90%;
    padding: 20px;
    margin: auto 10px;
    background: #015545;
    border-radius:0 0 10px 10px;
    display: flex;
    flex-direction: column;

    div:nth-child(1) {
      font-size: 18px;
      font-weight: bold;
      color: #ffffff;
    }

    div:nth-child(2) {
      margin-top: 20px;
      color: #ffffff;
      font-size: 18px;
      font-weight: normal;
    }

    div:nth-child(3) {
      margin-top: 20px;
      color: #ffd400;
      font-size: 25px;
      font-weight: bold;
    }

    div:nth-child(4) {
      margin-top: 40px;
      height: 40px;
      background: #FFD400;
      border-radius: 8px;
      color: #ffffff;
      font-size: 18px;
      font-weight: 500;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  img.img {
    margin-top: 22px;
    width: 32px;
    height: 32px;
  }
}
</style>
