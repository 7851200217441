<template>
  <div class="deposit-wrapper">
    <div class="title">{{ $t("select_deposit_amount") }}</div>
    <div class="collection">
      <div
        class="item"
        :class="index === selIndex ? 'sel-item' : ''"
        v-for="(item, index) in depositAmountList"
        :key="index"
        @click="onTapRechargeItem(index)"
      >
        <div class="deposit">
          <div class="unit">R$&nbsp;</div>
          <div class="name">{{ item.deposit_amount }}</div>
        </div>
        <div class="give" v-if="item.is_give === 1 && item.give_amount !== null">
          {{ item.give_amount }}
        </div>
        <img class="gift" v-if="item.is_hot === 1" src="@/assets/gift.png" alt="" />
      </div>
    </div>
    <div class="give-des">
      {{ $t('deposit_page_des2') }}
    </div>
  </div>
</template>
<script>
export default {
  props: {
    depositAmountList: {
      type: Array,
      default() {
        return [];
      },
    },
    selIndex: {
      type: Number,
      default() {
        return 0;
      },
    },
  },
  methods: {
    onTapRechargeItem(index) {
      this.$emit("selectEvent", index);
    },
  },
};
</script>
<style lang="scss" scoped>
.deposit-wrapper {
  display: flex;
  flex-direction: column;

  & > .title {
    text-align: left;
    font-size: 12px;
    font-weight: 500;
    color: #ffffff;
  }

  .collection {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 5px; /* 设置适当的间距 */
    margin-top: 10px;

    & > .item {
      position: relative;
      border-radius: 10px;
      height: 45px;
      background: #006a56;
      box-sizing: border-box;
      border: 1px solid #007a63;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .gift {
        position: absolute;
        width: 20px;
        height: 12px;
        top: 0px;
        right: 0px;
      }

      .deposit {
        display: flex;
        align-items: center;

        .name {
          line-height: 16px;
          font-size: 18px;
          font-weight: 500;
          color: #ffffff;
        }

        .unit {
          font-size: 12px;
          font-weight: 500;
          color: #ffffff;
        }
      }

      .give {
        margin-top: 3px;
        line-height: 10px;
        font-size: 12px;
        font-weight: 500;
        color: #ffd400;
      }
    }

    .sel-item {
      background: #ffd400;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .deposit {
        display: flex;
        align-items: center;

        .name {
          line-height: 16px;
          font-size: 18px;
          font-weight: bold;
          color: #06131c;
        }

        .unit {
          font-size: 12px;
          font-weight: bold;
          color: #06131c;
        }
      }
      .give {
        line-height: 10px;
        font-size: 10px;
        font-weight: bold;
        color: #06131c;
      }
    }
  }

  .give-des {
    margin-top: 12px;
    color: #81d6ba;
    font-size: 10px;
    font-weight: 500;
    text-align: left;
  }
}
</style>
