<template>
  <div class="login-wrap">
    <div class="topbg">
      <img src="@/assets/login/back.webp" @click="backFun" alt="">
      <div class="btn-box">
        <span :class="Telefone ? 'active' : ''" @click="changeLoginOrReg(1)">{{ $t('telefone') }}</span>
        <span :class="!Telefone ? 'active' : ''" @click="changeLoginOrReg(2)">{{ $t('email') }}</span>
      </div>
    </div>
    <div class="box">
      <div class="form">
        <div style="position: relative;">
          <input type="text" :style="{ borderColor: borderColorSuccess ? '#00CB47' : borderColorError ? '#FF5000' : '' }" maxlength="11" autocomplete="new-password" v-if="Telefone" v-model="form.phone"
            id="usename" :placeholder="$t('phone_number')">
          <input type="text" :style="{ borderColor: borderColorSuccess ? '#00CB47' : borderColorError ? '#FF5000' : '' }" autocomplete="new-password" v-if="!Telefone" v-model="form.email" id="email"
            :placeholder="$t('email')">
          <img class="username55" v-if="Telefone" src="@/assets/login/55.webp" alt="">
          <img class="username55" v-if="!Telefone" src="@/assets/login/email.webp" alt="">
        </div>
        <div class="safity" style="position: relative;">
          <input type="text" autocomplete="new-password" v-model="form.code" id="safity"
            :placeholder="$t('verification_code')">
          <img class="username55" src="@/assets/login/safity.webp" alt="">
          <button @click="getCodeHandle">{{ codeTitle }}</button>
        </div>
        <div class="pwd">
          <div style="position: relative;">
            <input :type="type" autocomplete="new-password" v-model="form.password" id="password"
              :placeholder="$t('newpassword')">
            <img class="username55" src="@/assets/login/lock.webp" alt="">
          </div>
          <div class="eye-box">
            <img v-if="type == 'password'" @click="changeType(1)" src="@/assets/password/invisible_icon.webp" alt="">
            <img v-else @click="changeType(2)" src="@/assets/password/visible_icon.webp" alt="">
          </div>
        </div>
        <!-- 登录按钮触发 -->
        <div class="button" @click="handleOfForgetPW">{{ $t('sign_in') }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

  data() {
    return {
      Telefone: true,
      type: "password",
      form: {
        phone: "",
        code: "",
        email: "",
        password: "",
        invitationCode: ""
      },
      areaCode: "55",
      time: "",
      timer: null,
      codeTitle: this.$t("obter")
    }
  },
  methods: {
    getCodeHandle() {
      if (this.codeTitle !== this.$t("verification_code")) {
        this.$toast.error(this.$t("verification_code_sent"));
        return;
      }
      if (this.Telefone) {
        const value = this.$tools.isNumeric(this.form.phone);
        if (value === false) {
          this.$toast.error(this.$t("enter_phone_number"));
          return;
        }
      } else {
        const value = this.$tools.isValidEmail(this.form.email);
        if (value === false) {
          this.$toast.error(this.$t("email_verification_failed"));
          return;
        }
      }
      const data = {
        action: "find_password_captcha",
        data: {
          login_type: this.Telefone ? 4 : 5,
          login_name: this.Telefone ? this.areaCode + this.form.phone : this.form.email,
        },
      };
      this.$http
        .post("login", data)
        .then(() => {
          this.startTime();
        })
        .catch((error) => {
          // 请求失败后的处理
          console.error(error);
          this.showInputErr(error);
        });

    },
    startTime() {
      this.time = 120;
      this.timer = setInterval(() => {
        this.time--;
        this.codeTitle = this.time;
        if (this.time <= 0) {
          clearInterval(this.timer);
          this.codeTitle = this.$t("verification_code");
        }
      }, 1000);
    },
    handleOfForgetPW() {
      if (this.Telefone && this.form.phone == '') {
        this.$toast.error(this.$t('the_phone_number_cannot_be_empty'));
        return
      }
      if (this.Telefone && this.form.code == '') {
        this.$toast.error(this.$t('the_verification_code_cannot_be_empty'));
        return
      }
      if (this.Telefone && this.form.password == '') {
        this.$toast.error(this.$t('the_password_cannot_be_empty'));
        return
      }
      const data = {
        action: "find_password",
        data: {
          login_type: this.Telefone ? 4 : 5,
          login_name: this.Telefone ? this.areaCode + this.form.phone : this.form.email,
          captcha: this.form.code,
          login_pwd: this.form.password,
        },
      };
      this.$http
        .post("login", data)
        .then(() => {
          const registerInfo = this.$store.getters.registerInfo;
          if (registerInfo) {
            if (this.isPhone) {
              registerInfo.phonePw = this.new1;
            } else {
              registerInfo.emailPw = this.new1;
            }
            this.$store.commit("setRegisterInfo", registerInfo);
          }
          // this.$toast.success("Reiniciar com sucesso, por favor faça login novamente");
          // this.$router.push("/login-page")
          this.handleOfLogin()
        })
        .catch((error) => {
          // 请求失败后的处理
          console.error(error);
          this.showInputErr(error);
        });
    },
    /**
    * 登录事件
    */
    handleOfLogin() {
      let action = "login";
      const source_channel = localStorage.getItem("source_channel") || "";
      localStorage.removeItem("source_channel");
      const promote_ad_id = localStorage.getItem("pa") || "";
      localStorage.removeItem("pa");
      const data = {
        action,
        data: {
          login_type: this.Telefone ? 4 : 5,
          login_name: this.Telefone ? this.areaCode + this.form.phone : this.form.email,
          login_pwd: this.form.password,
          source_user: this.form.code,
          source_channel,
          promote_ad_id,
        },
      };

      this.$http
        .post("login", data)
        .then((response) => {
          const loginInfo = {
            sessionid: response.sessionid,
            ucode: response.ucode,
          };
          this.$store.commit("setLoginInfo", loginInfo);
          this.$store.commit("setChannel", response.data.channel);
          this.$store.commit("setActivityInfo", {
            activityId: response.data.activity_id,
            isJoinActivity: response.data.is_join_activity,
          });
          this.initiateData(response.data.temp_event_code, response.data.temp_event_amount);
          this.$tools.userTrackReport({ track_id: "8" });
        })
        .catch((error) => {
          // 请求失败后的处理
          console.error("login:", error);
          this.showInputErr(error);
          this.$tools.userTrackReport({ track_id: "9" });
        });
    },
    /**
    * 登录成功后初始化平台的一些用户数据
    */
    initiateData(code, amount) {
      this.$http
        .post("initiate", {})
        .then((response) => {
          // 请求成功后的处理
          let pgparam = response.data.pgparam;

          const user = {
            username: this.form.phone,
            pgparam,
          };

          this.$store.commit("setLastLoginType", this.Telefone ? 4 : 5);
          this.$store.commit("setUserInfo", user);
          this.$eventBus.emit("loginSuccessHandle", {
            code,
            amount,
          });
          this.$router.push('/casino')
          this.$store.commit("setUserInfo", user);
        })
        .catch((error) => {
          // 请求失败后的处理
          console.error("error: ", error);
        });
    },
    showInputErr(error) {
      if ("code" in error) {
        const code = error.code;
        switch (code) {
          case 10001: //loginName null
          case 10011: //loginName ERROR
          case 10003: //Email ERROR
          case 10004: //PHONE ERROR
          case 10009: //USER ALREADY EXIST
          case 10010: //USER NOT EXIST
            if (this.isPhone) {
              this.usernameErr = "Por favor insira o número de telefone correto";
            } else {
              this.emailErr = "Por favor, introduz o endereço correto de correio";
            }
            break;

          case 10002: //loginPwd NULL
          case 10012: //loginPwd ERROR
          case 10005: //PWD LENGTH ERROR
            this.passwordErr = "8-16 caracteres";
            break;

          case 20010: //CAPTCHA ERROR
            this.codeErr = "Erro do código de verificação";
            break;

          case 11001: //USERCODE ERROR
            this.invitationCodeErr = "Código de convite inválido";
            break;

          default:
            break;
        }
      }
    },
    backFun() {
      this.$router.go(-1)
    },
    changeType(index) {
      if (index == 1) this.type = 'text'
      if (index == 2) this.type = 'password'
    },
    changeLoginOrReg(index) {
      if (index == 1) this.Telefone = true
      if (index == 2) this.Telefone = false
    },
  },
  mounted() {
    if (this.$route.query.phone) this.form.phone = this.$route.query.phone;
    if (this.$route.query.email) this.form.email = this.$route.query.email;
  },
  watch: {
    'form.phone'(newValue) {
      const isValid = newValue.length === 11;
      this.borderColorSuccess = isValid;
      this.borderColorError = !isValid && newValue.length > 11;
    },
    'form.email'(newValue) {
      if (newValue === '') {
        this.borderColorSuccess = false;
        this.borderColorError = false;
      } else {
        const isValid = this.$tools.isValidEmail(newValue);
        this.borderColorSuccess = isValid;
        this.borderColorError = !isValid;
      }
    }
  },

}
</script>

<style lang="scss" scoped>
.login-wrap {
  color: #fff;
  width: 100%;
  max-width: var(--max-width);

  .topbg {
    width: 100%;
    height: 236px;
    background-image: url("@/assets/login/login-bg.webp");
    background-size: 100%;
    position: relative;

    img {
      position: absolute;
      left: 10px;
      top: 17px;
      width: 22px;
      height: 22px;
    }

    .btn-box {
      position: absolute;
      bottom: 19%;
      left: 0;
      right: 0;
      height: 40px;
      color: #007962;
      display: flex;
      justify-content: center;

      span {
        width: 150px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #001E05;
        border-radius: 20px;
        font-weight: bold;

        &:first-child {
          margin-right: 6px;
        }

      }

      span.active {
        background: #FFD400;
        color: #000;
      }
    }



  }

  .box {
    background: #006A56;
    height: 100vh;
    border-radius: 30px 30px 0px 0px;
    position: absolute;
    top: 205px;
    right: 0;
    left: 0;

    .form {
      color: #fff;
      padding: 20px 34px 0 34px;
      box-sizing: border-box;

      .username55 {
        position: absolute;
        left: 15px;
        top: 12px;
        width: 15px;
        // height: 14px;
      }

      input {
        height: 40px;
        width: 100%;
        border-radius: 10px;
        background: #004134;
        border: 1px solid #007A63;
        padding-left: 40px;
        font-size: 12px;
        box-sizing: border-box;

        &:focus {
          border: 1px solid #92FFEA;
        }

        &::placeholder {
          color: #029479;
          font-weight: 500;
        }
      }

      .safity {
        position: relative;

        button {
          position: absolute;
          right: 10px;
          top: 5.5px;
          background: #029479;
          outline: none;
          border: none;
          border-radius: 6px;
          width: 58px;
          height: 28px;
          font-weight: 500;
          font-size: 12px;

          &:active {
            transform: scale(0.9);
          }
        }
      }

      #usename {
        padding-left: 40px;
        /* 为图标预留空间 */
        // background-image: url('@/assets/login/55.webp');
        background-repeat: no-repeat;
        /* 假设图标是 svg 格式的 */
        background-position: 14px center;
        /* 图标的位置 */
        background-size: 15px;
        /* 图标的大小 */
        margin-bottom: 12px;
      }

      #email {
        padding-left: 40px;
        /* 为图标预留空间 */
        // background-image: url('@/assets/login/email.webp');
        background-repeat: no-repeat;
        /* 假设图标是 svg 格式的 */
        background-position: 14px center;
        /* 图标的位置 */
        background-size: 15px;
        /* 图标的大小 */
        margin-bottom: 12px;
      }

      #safity {
        padding-left: 40px;
        padding-right: 100px;
        /* 为图标预留空间 */
        // background-image: url('@/assets/login/safity.webp');
        background-repeat: no-repeat;
        /* 假设图标是 svg 格式的 */
        background-position: 14px center;
        /* 图标的位置 */
        background-size: 15px;
        /* 图标的大小 */
        margin-bottom: 12px;
      }

      #password {
        padding-left: 40px;
        /* 为图标预留空间 */
        // background-image: url('@/assets/login/lock.webp');
        background-repeat: no-repeat;
        /* 假设图标是 svg 格式的 */
        background-position: 14px center;
        /* 图标的位置 */
        background-size: 15px;
        /* 图标的大小 */
        margin-bottom: 12px;
      }

      .pwd {
        position: relative;

        .eye-box {
          // background: red;
          position: absolute;
          right: 6px;
          top: 2px;
          width: 40px;
          height: 37px;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            width: 15px;
            height: 12px;
          }
        }

        // img {
        //   position: absolute;
        //   right: 12px;
        //   top: 11px;
        //   width: 15px;
        //   height: 12px;
        // }
      }

      .button {
        width: 100%;
        height: 40px;
        background-color: #FFD400;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        color: #0A1F32;
        margin-top: 24px;
      }
    }
  }
}
</style>