<template>
  <div class="piggy-banner-wrapper" @click="handleClick()">
    <img class="top" src="@/assets/piggy-bank/top.webp" alt="" />
    <div class="main">
      <div class="money">{{ $tools.formatCurrency(outputValue) }}</div>
    </div>
    <img class="left" src="@/assets/piggy-bank/left.webp" alt="" />
    <img class="right" src="@/assets/piggy-bank/right.webp" alt="" />
  </div>
</template>
<script>
import { ref } from "vue";
import { useTransition } from "@vueuse/core";

export default {
  setup() {
    const source = ref(0);
    const outputValue = useTransition(source, {
      duration: 3000,
    });

    return {
      source,
      outputValue,
    };
  },
  data() {
    return {
      isPolling: false,
    };
  },
  mounted() {
    document.addEventListener("visibilitychange", () => {
      if (document.hidden) {
        this.isPolling = false;
      } else {
        this.isPolling = true;
        this.fetchData();
      }
    });
    this.isPolling = true;
    this.fetchData();
  },
  unmounted() {
    this.isPolling = false;
    document.removeEventListener("visibilitychange", () => {});
  },
  methods: {
    handleClick(){
      this.$router.push("/m-game-list/PG?id=1001&type=provider")
    },
    async fetchData() {
      const data = {
        action: "aggregate_data",
        data: {},
      };
      try {
        const response = await this.$http.post("aggregateData", data);
        // console.log("response", response);
        // this.source += 1234;
        this.source = response.data.jackpot_amount_show;
        if (this.isPolling) {
          setTimeout(() => {
            this.fetchData();
          }, 3000);
        }
      } catch (error) {
        console.log("error", error);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.piggy-banner-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 7px 0;
  position: relative;

  .top {
    width: 193px;
    height: 36.5px;
  }

  .main {
    width: 90%;
    min-height: 41px;
    background: url("@/assets/piggy-bank/bg.webp");
    background-size: contain;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    & > .money {
      color: #ffde3a;
      font-size: 25px;
      font-weight: 1000;
    }
  }

  & > .left {
    position: absolute;
    left: 7px;
    bottom: 0px;
    width: 92px;
    height: 56.5px;
  }

  & > .right {
    position: absolute;
    right: 7px;
    bottom: 0px;
    width: 84px;
    height: 65.5px;
  }
}
</style>
