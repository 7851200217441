<template>
  <div class="conatiner">
    <div class="tips">Para garantir a segurança dos seus fundos, você precisa definir uma senha de saque primeiro!</div>
    <div class="title">Definir senha de saque</div>
    <div class="pw-father">
      <div class="pw-box">
        <div class="header">
          <div>Nova senha de saque</div>
          <img :src="!isVisiblePw
            ? require('@/assets/password/invisible_icon.webp')
            : require('@/assets/password/visible_icon.webp')
            " alt="" @click="isVisiblePw = !isVisiblePw" />
        </div>
        <Password6Input ref="pwd6-1" @click="handlclickPwdInput(2)" v-model:inputValue="password" :isVisible="isVisiblePw" />
        <!-- <van-password-input :value="password" :mask="isInVisiblePw" gutter="18" :focused="showKeyboard1" @focus="handleFocus('password')" />
        <van-number-keyboard v-model="password" :show="showKeyboard1" @blur="showKeyboard1 = false" /> -->
      </div>

      <div class="pw-box">
        <div class="header">
          <div class="marginTop12">Confirmar nova senha de saque</div>
        </div>
        <Password6Input ref="pwd6-2"  @click="handlclickPwdInput(1)" v-model:inputValue="password2" :isVisible="isVisiblePw" />
        <!-- <van-password-input :value="password2" :mask="isInVisiblePw" gutter="18" :focused="showKeyboard2" @focus="handleFocus('password2')" />
        <van-number-keyboard v-model="password2" :show="showKeyboard2" @blur="showKeyboard2 = false" /> -->
      </div>
    </div>
    <div class="mind">
      Atenção: A senha de saque protege seus fundos, é muito importante. Mantenha-a em segredo para evitar qualquer
      perda financeira.
    </div>
    <div class="btn" @click="handleOfOk">Confirmar</div>
  </div>
</template>
<script>
import Password6Input from "@/components/Password6Input.vue";
import { PasswordInput, NumberKeyboard } from "vant";
import "vant/lib/index.css";
export default {
  components: {
    Password6Input,
    [PasswordInput.name]: PasswordInput,
    [NumberKeyboard.name]: NumberKeyboard,
  },
  data() {
    return {
      password: "",
      isVisiblePw:false,
      isInVisiblePw: true,
      password2: "",
      type: "0",
      isFromMine: false,
      showKeyboard1: false,
      showKeyboard2: false,
    };
  },
  watch: {
    // password(val) {
    //   console.log("password", val);
    // },
    // password2(val) {
    //   console.log("password2", val);
    // },
  },
  mounted() {
    this.$emit("update:title", "Senha de saque");
    this.type = this.$route.query.type;
    this.isFromMine = this.$route.query.from === "mine";
  },
  methods: {
    handlclickPwdInput(index){
      this.$refs['pwd6-'+index].i = -1
    },
    handleFocus(inputType) {
      if (inputType === 'password') {
        this.showKeyboard1 = true;
        this.showKeyboard2 = false;
      } else if (inputType === 'password2') {
        this.showKeyboard1 = false;
        this.showKeyboard2 = true;
      }
    },
    handleOfInputBlur() {
      console.log("this.inputValue", this.inputValue);
    },
    async handleOfOk() {
      const data = {
        action: "set_pay_out_pwd",
        data: {
          type: this.type, // 1修改提款密码;其他值为设置提款密码;
          pay_out_pwd: this.password,
          repeat_pay_out_pwd: this.password2,
        },
      };
      try {
        const response = await this.$http.post("userCenter", data);
        console.log("response", response);
        if (this.isFromMine) {
          sessionStorage.setItem("endOfSetPwFromMine", true);
        }
        this.$router.back();
      } catch (error) {
        console.log("error", error);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.conatiner {
  height: 100vh;
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 10px 15px;

  .tips {
    color: #700000;
    font-size: 12px;
    font-weight: 500;
    background-color: #DDA7A7;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8.5px 12px;
    box-sizing: border-box;
  }

  .title {
    margin-top: 20px;
    color: #ffffff;
    font-size: 12px;
    font-weight: 500;
  }

  .pw-father {
    background: #006A56;
    padding: 12px 12px 14px 12px;
    box-sizing: border-box;
    border-radius: 8px;
    margin-top: 10px;

    .pw-box {
      display: flex;
      flex-direction: column;

      

      :deep(.van-password-input) {
        margin: 8px 0 0 0;

        .van-password-input__item {
          border-radius: 10px;
          height: 45px;
        }
      }

      :deep(.van-number-keyboard) {
        background-color: #d6d7dc;
        padding-top: 18px;

        .van-key {
          background: #fff;
        }
      }

      &>.header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 12px;
        &>div {
          color: #81D6BA;
          font-size: 12px;
          font-weight: 500;
        }

        .marginTop12 {
          margin-top: 12px;
        }

        &>img {
          width: 15px;
          height: 12px;
        }
      }
    }
  }

  .mind {
    margin-top: 10px;
    color: #81D6BA;
    font-size: 12px;
    font-weight: 500;
  }

  .btn {
    margin-top: 20px;
    background: #FFD400;
    border-radius: 5px;
    height: 45px;
    line-height: 45px;
    color: #000000;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
  }
}
</style>
