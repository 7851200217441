<template>
  <div class="container">
    <div class="tab-box">
      <div
        class="tab"
        :class="tabIndex === index ? 'selected' : 'normal'"
        v-for="(tab, index) in tabs"
        :key="index"
        @click="tabIndex = index"
      >
        {{ tab }}
      </div>
    </div>
    <div class="tableView">
      <div class="sectionHeader">
        <div>{{ $t('rules_grade') }}</div>
        <div>{{ $t('required_experience') }}</div>
        <div>{{ tabIndex === 0 ? $t('upgrade_bonus') : $t('percentage_of_return') }}</div>
      </div>
      <div class="cell" v-for="(cell, index) in tableData" :key="index">
        <div class="vip-box">
          <img :src="$global.data.cdn_url + cell.small_vip_icon" alt="" />
          <div>VIP {{ cell.vip_small_level }}</div>
        </div>
        <div class="level">{{ cell.vip_small_level_up_need_exp }}</div>
        <div class="other">{{ showOtherInfo(cell) }}</div>
      </div>
    </div>
    <div class="des-title">{{ $t('piggy_bank_page_des3') }}</div>
    <div class="des" v-if="tabIndex === 0">
      {{ $t('vip_benefits_des1') }}<br />
      {{ $t('vip_benefits_des2') }}.<br />
      {{ $t('vip_benefits_des3') }}<br />
    </div>
    <div class="des" v-else-if="tabIndex === 1">
      {{ $t('vip_benefits_des4') }}<br />
      {{ $t('vip_benefits_des5') }}<br />
      {{ $t('vip_benefits_des6') }}<br />
    </div>
    <div class="des" v-else-if="tabIndex === 2">
      {{ $t('vip_benefits_des7') }}<br />
      {{ $t('vip_benefits_des8') }}<br />
      {{ $t('vip_benefits_des9') }}<br />
    </div>
    <div class="des" v-else>
      {{ $t('vip_benefits_des10') }}<br />
      {{ $t('vip_benefits_des11') }}<br />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tabs: [this.$t('upgrade_bonus'), this.$t('weekly_bonus'), this.$t('monthly_bonus'), this.$t('piggy_bank')],
      tabIndex: 0,
      tableData: null,
    };
  },
  mounted() {
    this.$emit("update:title", this.$t('membership_benefits2'));
    this.tabIndex = parseInt(this.$route.query.tabIndex || "0");
    this.benefitsData();
  },
  methods: {
    async benefitsData() {
      const data = {
        action: "vip_benefits",
        data: {},
      };
      try {
        const response = await this.$http.post("userVip", data);
        this.tableData = response.data.vip_benefits;
      } catch (error) {
        console.log("error", error);
      }
    },
    showOtherInfo(item) {
      switch (this.tabIndex) {
        case 0:
          return item.level_up_bounty;
        case 1:
          return item.week_bounty_bet_return_rate * 1000 + "‰";
        case 2:
          return item.month_bounty_bet_return_rate * 1000 + "‰";
        default:
          return item.cofrinho_bet_lose_return_rate * 1000 + "‰";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  padding: 10px 15px;

  .tab-box {
    display: flex;
    align-items: center;
    background: #004134;
    height: 40px;
    border-radius: 5px;
    padding: 5px;

    .tab {
      flex: 1;
      font-size: 12px;
      text-align: center;
      border-radius: 5px;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 15px;
    }

    & > .selected {
      color: #ffffff;
      font-weight: bold;
      background: #029479;
    }

    & > .normal {
      color: #81d6ba;
      font-weight: 500;
      background: transparent;
    }
  }

  .tableView {
    margin-top: 17px;
    display: flex;
    flex-direction: column;

    .sectionHeader {
      margin-bottom: 5px;
      display: flex;
      justify-content: space-between;
      padding: 0 10px;

      & > div {
        flex: 1;
        color: #029479;
        font-size: 10px;
        font-weight: 500;
      }

      & > div:nth-child(1) {
        text-align: left;
      }
    }

    .cell {
      margin-top: 5px;
      background: #015545;
      border: 1px solid #006a56;
      border-radius: 5px;
      height: 30px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #ffffff;
      font-weight: 500;
      font-size: 12px;
      padding: 0 10px;

      .vip-box {
        flex: 1;
        display: flex;

        & > img {
          width: 13.5px;
          height: 15px;
          margin-right: 5px;
        }
      }

      .level {
        flex: 1;
      }

      .other {
        flex: 1;
      }
    }
  }

  .des-title {
    margin-top: 10px;
    color: #ffffff;
    font-size: 12px;
    font-weight: 500;
    text-align: left;
  }

  .des {
    margin-top: 5px;
    color: #029479;
    font-size: 10px;
    font-weight: 500;
    text-align: left;
  }
}
</style>
