<template>
  <div class="container">
    <DepositCollectComp :isCanCustomInput="true" :tabIndex="tabIndex" @depositEvent="handleOfDeposit"></DepositCollectComp>
  </div>
</template>
<script>
import DepositCollectComp from "@/components/DepositCollectComp.vue";
export default {
  components: {
    DepositCollectComp,
  },
  data() {
    return {
      tabIndex: 0,
    };
  },
  mounted() {
    this.$emit("update:title", "Depósito");
    this.$emit("update:isShowHeaderRightBtn", true);
    this.tabIndex = parseInt(this.$route.query.tabIndex) || 0;
    console.log("this.$route.query.tabIndex", this.$route.query.tabIndex);
  },
  unmounted() {
    this.$emit("update:isShowHeaderRightBtn", false);
  },
  methods: {
    handleOfDeposit(order,userOrder) {
      sessionStorage.setItem("order", JSON.stringify(order));
      sessionStorage.setItem("userOrder", JSON.stringify(userOrder));
      this.$router.push({ name: "m-pay-page" });
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  margin: 0 15px;
}
</style>
