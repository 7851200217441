<template>
  <div class="container">
    <div class="cate-box">
      <div class="cate-item-box" v-for="(item, index) in raffles" :key="index" @click="handleOfSubCate(index)">
        <div class="cate-item" :class="index === subCateIndex ? 'highlight' : ''">{{ item.raffle_vip_level_name }}</div>
        <div class="tips" v-if="item.draws_numbers > 0">{{ item.draws_numbers }}</div>
      </div>
    </div>
    <!-- 游戏 -->
    <div v-show="awards.length !== 0" class="game" :style="{ 'min-height': itemWidth * 4 + 30 + 'px' }">
      <div v-for="(item, index) in awards" :key="index" :style="getImageStyle(index)">
        <div class="box" :class="index === highlightIndex ? 'highlight' : ''" :style="{ width: itemWidth + 'px', height: itemWidth + 'px' }">
          <div class="bg" :style="{ width: itemWidth - 8 + 'px', height: itemWidth - 8 + 'px' }">
            <img :src="$global.data.cdn_url + item.prize_info.prize_image_url" alt="Image" />
          </div>
        </div>
      </div>
      <div class="center">
        <div class="center-bg">
          <img src="@/assets/daily-lottery-draw/100bai.webp" alt="" />
          <div>{{ $t('winning_opportunities') }}</div>
          <img src="@/assets/daily-lottery-draw/emoji-icon.webp" alt="" />
          <div class="count-box">
            <div>{{ $t('number_of_lucky_draws') }}</div>
            <div>{{ showDrawsNumber }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="play-btn" v-if="showDrawsNumber > 0" @click="handleOfPlaying">{{ $t('luck_draw') }}</div>
    <div class="play-btn dis" v-else>{{ $t('luck_draw') }}</div>
    <div class="list" v-show="awards.length !== 0">
      <div class="title">{{ $t('prize_description') }}</div>
      <div class="item" v-for="(item, index) in awards" :key="index">
        <img :src="$global.data.cdn_url + item.prize_info.prize_image_url" alt="" />
        <div class="text">
          <div class="name">{{ item.prize_info.prize_name }}</div>
          <div class="des">{{ item.prize_info.describe }}</div>
        </div>
      </div>
    </div>
    <dialog ref="rewardTips">
      <div class="reward-tips-box">
        <div>{{ $t('congratulations') }}</div>
        <div>{{ winningPrizeInfo?.prize_info?.prize_name }}</div>
        <div>{{ $t('saved_in_the_prize_list') }}</div>
        <div class="buttons">
          <div @click="handleOfRewardTipsClose">{{ $t('bom') }}</div>
          <div @click="handleOfUsingPrizes">{{ $t('use') }}</div>
        </div>
      </div>
    </dialog>
    <dialog ref="prizeAmountTips">
      <div class="reward-tips-box">
        <!-- Prompt -->
        <div>Prompt</div>
        <div>R$:{{ $tools.formatCurrency(prizeAmountTipsValue) }}</div>
        <div>{{ $t('the_reward_was_deposited_into_the_wallet') }}</div>
        <div class="button" @click="handleOfPrizeAmountTipsOk">{{ $t('bom') }}</div>
      </div>
    </dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      itemWidth: 0,
      highlightIndex: -1,
      stopIndex: -1,
      timeout: 100,
      loopCount: 3,
      curIndex: -1,
      subCateIndex: 0,
      awards: [],
      raffles: null,
      winningPrizeInfo: {},
      usePrizeInfo: {},
      prizeAmountTipsValue: 0,
      isPlaying: false,
      showDrawsNumber: 0,
    };
  },
  mounted() {
    this.$emit("update:title", this.$t("daily_lottery_draw"));
    // 获取根元素（即 :root）上的计算样式
    const rootStyles = getComputedStyle(document.documentElement);

    // 获取 --max-width 变量的值
    const maxWidth = rootStyles.getPropertyValue("--max-width").trim();

    // 将 maxWidth 转换为整数（如果需要的话）
    const maxWidthValue = parseInt(maxWidth, 10);
    const screenWidth = Math.min(screen.width, maxWidthValue);
    this.itemWidth = (screenWidth - 30 - 10 * 3) / 4;
    this.resetAllData();
  },
  methods: {
    resetAllData() {
      this.fetchPrizeList();
    },
    handleOfPrizeAmountTipsOk() {
      this.$refs.prizeAmountTips.close();
      this.nextOfUsedPrize();
    },
    nextOfUsedPrize() {
      const followPage = this.usePrizeInfo.prize_info.follow_page;
      switch (followPage) {
        case 0: // 不跳转
          break;
        case 1: // 首页
          this.$eventBus.emit("emitOfChangeTab", "casino");
          break;
        case 2: // 充值页
          this.$router.push({ name: "m-deposit-page" });
          break;
        case 3: // 提款页面
          this.$router.push({ name: "m-withdrawal-page" });
          break;
        case 4: // 抽奖页面
          break;

        default:
          break;
      }
    },
    async usePrize() {
      const data = {
        action: "use_raffle_prize",
        data: {
          raffle_prize_id: this.winningPrizeInfo.id,
        },
      };
      try {
        const response = await this.$http.post("userVip", data);
        this.usePrizeInfo = response.data.user_raffle_prize_info;
        if ("prize_amount" in response.data && response.data.prize_amount > 0) {
          this.prizeAmountTipsValue = response.data.prize_amount;
          this.$refs.prizeAmountTips.showModal();
        } else {
          this.nextOfUsedPrize();
        }
        this.resetAllData();
      } catch (error) {
        console.log("error", error);
        this.resetAllData();
      }
    },
    handleOfRewardTipsClose() {
      this.$refs.rewardTips.close();
      this.resetAllData();
    },
    handleOfUsingPrizes() {
      this.$refs.rewardTips.close();
      this.usePrize();
    },
    async handleOfPlaying() {
      if (this.isPlaying) {
        return;
      }
      if (this.raffles[this.subCateIndex]?.draws_numbers === 0) {
        return;
      }
      this.raffles[this.subCateIndex].draws_numbers--;

      const data = {
        action: "user_ticket_lottery",
        data: {
          raffle_id: this.raffles[this.subCateIndex]?.id, // 活动id
        },
      };
      try {
        const response = await this.$http.post("userVip", data);
        this.winningPrizeInfo = response.data.user_raffle_prize_info;
        this.stopIndex = this.awards.findIndex((item) => {
          return item.prize_id === this.winningPrizeInfo.prize_id;
        });
        this.isPlaying = true;
        this.selectedNextItem();
      } catch (error) {
        console.log("error", error);
      }
    },
    async fetchPrizeList() {
      const data = {
        action: "raffle_prize_list",
        data: {},
      };

      try {
        const response = await this.$http.post("userVip", data);
        this.raffles = response.data.raffle_list;

        if (this.subCateIndex === -1) {
          this.subCateIndex = this.raffles.findIndex((item) => {
            return item.draws_numbers > 0;
          });

          if (this.subCateIndex === -1) {
            this.subCateIndex = 0;
          }
        }
        this.handleOfSubCate(this.subCateIndex);
      } catch (error) {
        console.log("error", error);
      }
    },
    handleOfSubCate(index) {
      this.subCateIndex = index;
      this.awards = this.raffles[index].raffle_prize_list;
      this.showDrawsNumber = this.raffles[index].draws_numbers;
    },
    getImageStyle(index) {
      switch (index) {
        case 0:
          return { transform: "translate(0, 0)" };
        case 1:
          return { transform: `translate(${this.itemWidth + 10}px, 0)` };
        case 2:
          return { transform: `translate(${(this.itemWidth + 10) * 2}px, 0)` };
        case 3:
          return { transform: `translate(${(this.itemWidth + 10) * 3}px, 0)` };
        case 4:
          return { transform: `translate(${(this.itemWidth + 10) * 3}px, ${(this.itemWidth + 10) * 1}px)` };
        case 5:
          return { transform: `translate(${(this.itemWidth + 10) * 3}px, ${(this.itemWidth + 10) * 2}px)` };
        case 6:
          return { transform: `translate(${(this.itemWidth + 10) * 3}px, ${(this.itemWidth + 10) * 3}px)` };
        case 7:
          return { transform: `translate(${(this.itemWidth + 10) * 2}px, ${(this.itemWidth + 10) * 3}px)` };
        case 8:
          return { transform: `translate(${(this.itemWidth + 10) * 1}px, ${(this.itemWidth + 10) * 3}px)` };
        case 9:
          return { transform: `translate(0, ${(this.itemWidth + 10) * 3}px)` };
        case 10:
          return { transform: `translate(0, ${(this.itemWidth + 10) * 2}px)` };
        case 11:
          return { transform: `translate(0, ${(this.itemWidth + 10) * 1}px)` };

        default:
          break;
      }
    },
    selectedNextItem() {
      const totleIndex = this.stopIndex + this.loopCount * this.awards.length;
      if (this.curIndex === totleIndex) {
        this.isPlaying = false;
        this.$refs.rewardTips.showModal();
        this.timeout = 100;
        this.highlightIndex = -1;
        this.stopIndex = -1;
        this.curIndex = -1;
        return;
      }

      this.curIndex++;
      this.highlightIndex = this.curIndex % 12;
      if (totleIndex - this.curIndex < 8) {
        this.timeout *= 1.3;
      }

      setTimeout(() => {
        this.selectedNextItem();
      }, this.timeout);
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;

  .cate-box {
    display: flex;
    align-items: center;
    margin-top: 15px;
    min-height: 35px;
    overflow-x: scroll;

    .cate-item-box {
      margin-right: 5px;
      text-wrap: nowrap;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      .cate-item {
        border-radius: 10px;
        padding: 9px 0;
        min-width: 60px;
        font-size: 12px;
        font-weight: bold;
        background: #004134;
        color: #029479;
      }
      .tips {
        position: absolute;
        top: 0;
        right: 0;
        width: 15px;
        height: 15px;
        background: #f04770;
        border-radius: 100px;
        font-size: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
      }

      .highlight {
        background: #029479;
        color: #ffffff;
      }
    }

    .cate-item-box:first-child {
      margin-left: 22px;
    }

    .cate-item-box:last-child {
      margin-right: 22px;
    }
  }

  .game {
    margin-top: 22px;
    position: relative;
    padding: 0 15px;
    display: flex;

    .center {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .center-bg {
        border-radius: 10px;
        background: #01382d;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 10px 13px;

        & > img:nth-of-type(1) {
          width: 79.105px;
          height: 22.85px;
        }

        & > div:nth-of-type(1) {
          margin-top: 3px;
          color: #10b349;
          font-size: 10px;
          font-weight: bold;
        }

        & > img:nth-of-type(2) {
          margin-top: 5px;
          width: 87.5px;
          height: 47px;
        }

        & > .count-box {
          margin-top: 15px;
          width: 136px;
          height: 29px;
          background: #006955;
          border-radius: 10px;
          font-size: 10px;

          & > div:nth-child(1) {
            color: #00cda6;
            font-weight: 500;
          }

          & > div:nth-child(2) {
            color: #ffffff;
            font-weight: 900;
          }
        }
      }
    }

    .box {
      position: absolute;
      left: 0;
      top: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 4px solid #006955;
      box-sizing: border-box;
      background: #004033;
      border-radius: 10px;

      .bg {
        background: url("@/assets/daily-lottery-draw/bg.webp") no-repeat;
        background-size: 100% 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 60px;
          height: 40px;
        }
      }
    }

    .highlight {
      background: #a6dc36;
    }
  }

  .play-btn {
    margin: 11px 15px 0;
    background: #ffd400;
    height: 40px;
    line-height: 40px;
    border-radius: 10px;
    color: #000000;
    font-size: 15px;
    font-weight: bold;
  }
  .dis {
    background: #676767;
    color: #a5a5a5;
  }

  .list {
    margin: 20px 15px;
    display: flex;
    flex-direction: column;

    .title {
      color: #ffffff;
      font-size: 14px;
      font-weight: 500;
      text-align: left;
      margin-bottom: 20px;
    }

    & > .item {
      background: #006a56;
      border-radius: 10px;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      height: 80px;

      img {
        width: 60px;
        height: 40px;
        margin: auto 18px;
      }

      .text {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .name {
          color: #ffffff;
          font-size: 12px;
          font-weight: 500;
        }

        .des {
          margin-top: 7px;
          color: #ffffff;
          font-size: 9px;
          font-weight: 300;
          text-align: left;
        }
      }
    }
  }

  .reward-tips-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 345px;
    height: 220px;
    background: var(--color-popup-bg);
    border-radius: 10px;

    div:nth-child(1) {
      color: #ffffff;
      font-size: 18px;
      font-weight: bold;
    }

    div:nth-child(2) {
      color: #ffd400;
      font-size: 32px;
      font-weight: 500;
    }

    div:nth-child(3) {
      color: #ffffff;
      font-size: 18px;
      font-weight: normal;
    }

    .buttons {
      width: 90%;
      display: flex;
      justify-content: space-between;

      div {
        flex: 0 1 48%;
        background: var(--color-btn-bg);
        height: 40px;
        border-radius: 8px;
        color: var(--color-btn-text);
        font-size: 18px;
        font-weight: 500;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .button {
      background: var(--color-btn-bg);
      color: var(--color-btn-text);
      border-radius: 8px;
      width: 80%;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
