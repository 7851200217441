<template>
  <div class="commission">
    <div class="top-box">
      <img v-if="icon !== ''" :src="$global.data.cdn_url + icon" alt="" />
      <div class="item">
        <div class="title">{{ $t('proxy_level') }}</div>
        <div class="level">{{ commission.agent_level }}</div>
      </div>
      <div class="item">
        <div class="title">{{ $t('first_deposit_commission') }}</div>
        <div class="money">{{ $tools.formatCurrency(commission.invite_bonus_level1) }}</div>
      </div>
    </div>
    <div class="collection">
      <div class="item1">
        <div class="title">{{ $t('commission_wallet_balance') }}</div>
        <div class="money">
          <div>R$</div>
          <div>{{ $tools.formatCurrency(commission.withdrawal_commission + commission.freeze_coins) }}</div>
        </div>
        <div class="buttons">
          <div @click="withdrawalHandle">{{ $t("drawing") }}</div>
          <div @click="isShowTransferBalance = true">{{ $t("transfer_out") }}</div>
        </div>
      </div>
      <!-- 签约合作费未写 -->
      <div class="item2">
        <img class="info" src="@/assets/info.webp" alt="" @click="isShowInviteRules = true" />
        <div class="title">{{ $t("your_invitation_link") }}</div>
        <div class="linker">
          <div>{{ linker }}</div>
          <img src="@/assets/user/copy-invite.webp" alt="" @click="copyHandle" />
          <img class="copiado" v-show="isShowCopiado" src="@/assets/user/copiado.png" alt="" />
        </div>
      </div>
    </div>

    <div class="progress-title-box">
      <div>{{ $t('invitation_rewards') }}</div>
    </div>

    <div class="invitation">
      <div class="table">
        <div class="cell" v-for="(item, index) in invite" :key="index">
          <div class="item">
            <div class="title">{{ $t('cell_item_title1') }}</div>
            <div class="value">{{ item.first_pay_level1 }}</div>
          </div>
          <div class="item">
            <div class="title">{{ $t('cell_item_title2') }}</div>
            <div class="value">{{ $tools.formatCurrency(item.add_bonus) }}</div>
          </div>
          <div class="item">
            <div class="title"></div>
            <div :class="item.reach === 1 ? 'achieved-st' : 'not-achieved-st'">
              {{ item.reach === 0 ?  $t('cell_item_title4')  : $t('cell_item_title3')  }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="partner-title">{{ $t('partner') }}</div>
    <div class="partner-box">
      <div>{{ $t('commission_des') }}</div>
      <div class="btn" @click="$tools.openTelegram($global.data.cooperate_link)">{{ $t('contact_us') }}</div>
    </div>

    <!-- 奖金提现弹窗 -->
    <ModalPopupComp v-show="isShowTransferBalance" class="to-balance"
      :style="{ 'margin-bottom': modalPopupMarginBottom }" :contextWidth="360" @close="isShowTransferBalance = false">
      <div class="to-balance-title">{{ $t('transfer_to_account_balance') }}</div>
      <div class="to-balance-input">
        <input type="number" v-model="inputValue2Balance" :placeholder="$t('amount')" />
      </div>
      <button class="to-balance-modal" @click="toBalanceHandle">{{ $t('transfer_out') }}</button>
    </ModalPopupComp>
    <!-- 提现身份信息弹窗 -->
    <DrawMoney v-if="isShowDrawMoney" :userInfo="userInfo" @close="isShowDrawMoney = false"
      @endSaveEvent="userInfoEndSaveEvent"></DrawMoney>
    <!-- 提现信息弹窗 -->
    <DrawMoney2 v-if="isShowDrawMoney2" :userInfo="userInfo" :withdrawable="commission.withdrawal_commission"
      :amountType="2" @close="isShowDrawMoney2 = false" @endDrawMoney2Event="endDrawMoney2Event"></DrawMoney2>
    <!-- 邀请规则弹窗 -->
    <InviteRules v-if="isShowInviteRules" :style="{ 'margin-bottom': modalPopupMarginBottom }"
      :formData="inviteRulesData" :contextWidth="380" @close="isShowInviteRules = false"></InviteRules>
    <!-- 邀请任务规则弹窗 -->
    <CommissionRules v-if="isShowRules" :contextWidth="380" :style="{ 'margin-bottom': modalPopupMarginBottom }"
      :formData="inviteBonusData" @close="isShowRules = false"></CommissionRules>
  </div>
</template>

<script>
import ModalPopupComp from "@/components/ModalPopupComp.vue";
import DrawMoney from "../wallet/DrawMoney.vue";
import DrawMoney2 from "../wallet/DrawMoney2.vue";
import CommissionRules from "./CommissionRules.vue";
import clipboard from "clipboard";
import InviteRules from "./InviteRules.vue";
import { withdrawTools } from "@/utils/withdraw.js";

export default {
  components: { ModalPopupComp, DrawMoney, DrawMoney2, CommissionRules, InviteRules },
  data() {
    let urlDomain = location.origin + "/";
    if (urlDomain.indexOf("localhost") > -1 || urlDomain.indexOf("127.0.0.1") > -1 || urlDomain.indexOf("192.168") > -1) {
      urlDomain = "https://developr.corp.betmace.com/";
    }
    const ucode = this.$store.getters.ucode;

    let modalPopupMarginBottom = "0px";
    if (location.pathname.includes("m-") === false) {
      const tabBarHeight = getComputedStyle(document.documentElement).getPropertyValue("--tab-bar-height");
      modalPopupMarginBottom = tabBarHeight;
    }

    return {
      linker: urlDomain + "?uc=" + ucode,
      isShowTransferBalance: false,
      isShowRules: false,
      commission: {
        withdrawal_commission: 0,
        freeze_coins: 0,
      },
      invite: [],
      inputValue2Balance: "",
      isShowDrawMoney: false,
      isShowDrawMoney2: false,
      userInfo: null,
      isShowCopiado: false,
      isShowInviteRules: false,
      inviteRulesData: [],
      inviteBonusData: [],
      modalPopupMarginBottom,
      icon: "",
    };
  },
  mounted() {
    
    this.fetchCommisionInfo();
  },
  methods: {
    fetchCommisionInfo() {
      const data = {
        action: "agent_commission_info",
        data: {},
      };
      this.$http
        .post("agentCenter", data)
        .then((response) => {
          this.commission = response.data.commission_data;
          this.invite = response.data.invite_progress;
          this.inviteRulesData = response.data.agent_level_and_service_list;
          this.inviteBonusData = response.data.agent_invite_pay_bonus_list;
          this.icon = response.data.icon;
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    endDrawMoney2Event() {
      this.isShowDrawMoney2 = false;
      this.fetchCommisionInfo();
      this.$eventBus.emit("refreshHeaderCoins");
    },
    withdrawalHandle() {
      withdrawTools.withdrawEvent(2);
    },
    userInfoEndSaveEvent() {
      this.isShowDrawMoney = false;
      this.withdrawalHandle();
    },
    toBalanceHandle() {
      if (this.inputValue2Balance == "") {
        this.$toast.error(this.$t('the_amount_cannot_be_empty'));
        return;
      }

      const data = {
        action: "agent_commission_transfer",
        data: {
          amount: parseInt(this.inputValue2Balance),
        },
      };
      this.$http
        .post("userWallet", data)
        .then(() => {
          this.isShowTransferBalance = false;
          this.fetchCommisionInfo();
          this.$eventBus.emit("refreshHeaderCoins");
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    copyHandle() {
      clipboard.copy(this.linker);
      this.isShowCopiado = true;
      setTimeout(() => {
        this.isShowCopiado = false;
      }, 2000);
    },
  },
};
</script>

<style lang="scss" scoped>
.commission {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding-bottom: 40px;

  &>.top-box {
    background: #006a56;
    height: 70px;
    border-radius: 8px;
    display: flex;
    justify-content: space-around;
    align-items: center;

    &>img {
      width: 50px;
      height: 50px;
    }

    &>.item {
      display: flex;
      flex-direction: column;
      text-align: left;

      &>.title {
        color: #81d6ba;
        font-size: 10px;
        font-weight: 500;
      }

      &>.level {
        margin-top: 10px;
        color: #ffffff;
        font-size: 18px;
        font-weight: bold;
      }

      &>.money {
        margin-top: 10px;
        color: #ffd400;
        font-size: 18px;
        font-weight: bold;
      }
    }
  }

  .collection {
    margin-top: 10px;
    display: flex;
    flex-direction: column;

    .item1 {
      padding: 15px 0;
      border-radius: 8px;
      background: #006a56;
      display: flex;
      flex-direction: column;
      align-items: center;

      &>.title {
        font-size: 12px;
        font-weight: 500;
        color: #81d6ba;
      }

      &>.money {
        display: flex;
        align-items: center;

        &>div:first-of-type {
          font-size: 18px;
          font-weight: bold;
          line-height: 30px;
          color: #ffd400;
          margin-bottom: -8px;
        }

        &>div:last-of-type {
          font-size: 32px;
          font-weight: bold;
          line-height: 56px;
          color: #ffd400;
        }
      }

      &>.buttons {
        margin-top: 16px;
        display: flex;
        justify-content: center;

        &>div {
          margin-right: 20px;
          width: 140px;
          height: 40px;
          line-height: 40px;
          border-radius: 6px;
          background: #ffd400;
          color: #000000;
          font-size: 14px;
          font-weight: 800;
        }

        &>div:last-of-type {
          margin-right: 0;
        }
      }
    }

    .item2 {
      position: relative;
      margin-top: 15px;
      border-radius: 16px;
      background: #006a56;
      display: flex;
      flex-direction: column;
      align-items: center;

      &>.info {
        position: absolute;
        top: 16px;
        right: 12px;
        width: 20px;
        height: 20px;
      }

      &>.title {
        align-self: flex-start;
        margin: 15px;
        font-size: 12px;
        font-weight: 500;
        color: #ffffff;
      }

      &>.linker {
        margin: 15px 8px 12px;
        border-radius: 6px;
        background: #004134;
        display: flex;
        height: 40px;
        justify-content: space-between;
        align-items: center;
        padding: 0 5px 0 14px;
        width: 90%;

        &>div:first-of-type {
          margin: 5px;
          font-size: 12px;
          font-weight: 500;
          color: #ffffff;
          text-align: left;
          width: 80%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        &>img {
          margin-right: 10px;
          width: 30px;
          height: 30px;
        }

        &>.copiado {
          position: absolute;
          top: 45px;
          right: 0px;
          width: 60px;
          height: auto;
        }
      }
    }
  }

  .progress-title-box {
    margin-top: 22px;
    display: flex;
    justify-content: space-between;

    &>div {
      color: #ffffff;
      font-size: 14px;
      font-weight: 500;
    }

    &>img {
      margin-right: 14px;
      width: 20px;
      height: 20px;
    }
  }

  .invitation {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    position: relative;

    .table {
      border-radius: 8px;
      display: flex;
      flex-direction: column;

      .cell {
        height: 45px;
        display: flex;
        border-radius: 8px;
        background: #006a56;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 8px;
        padding: 10px 15px;

        &>.item {
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          text-align: left;

          &>.title {
            color: #81d6ba;
            font-size: 10px;
            font-weight: 500;
          }

          &>.value {
            color: #ecc603;
            font-size: 18px;
            font-weight: bold;
          }

          &>.achieved-st {
            color: #00cb47;
            font-size: 12px;
            font-weight: 500;
          }

          &>.not-achieved-st {
            color: #a5a5a5;
            font-size: 12px;
            font-weight: 500;
          }
        }
      }
    }
  }

  &>.partner-title {
    margin-top: 20px;
    text-align: left;
    color: #fff;
    font-size: 12px;
    font-weight: 500;
  }

  &>.partner-box {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    background: #006a56;
    border-radius: 10px;
    padding: 15px;

    &>div:nth-child(1) {
      color: #81d6ba;
      font-size: 12px;
      font-weight: 500;
      text-align: left;
    }

    &>div:nth-child(3) {
      margin-top: 8px;
      color: #81d6ba;
      font-size: 12px;
      font-weight: 500;
      text-align: left;
    }

    &>.btn {
      margin-top: 14px;
      background: #0085a9;
      height: 40px;
      line-height: 40px;
      border-radius: 6px;
      color: #ffffff;
      font-size: 14px;
      font-weight: 500;
    }
  }

  &>.title {
    margin-top: 25px;
    font-size: 14px;
    font-weight: 500;
    color: #bcbcbc;
    text-align: left;
  }

  .to-balance {
    .to-balance-title {
      margin: 30px auto 0;
      font-size: 14px;
      font-weight: 500;
      color: var(--color-popup-title);
    }

    .to-balance-input {
      margin: 30px auto 0;
      background: #004134;
      border-radius: 8px;
      width: 320px;
      height: 46px;
      display: flex;

      input {
        flex: 1;
        margin: auto 20px;
        font-size: 14px;
        font-weight: 500;
        line-height: 16px;
        color: #ffffff;
        background: transparent;
        border: 0;
        outline-style: none;
      }

      input::placeholder {
        color: #00886e;
      }
    }

    .to-balance-modal {
      margin: 40px auto;
      width: 320px;
      height: 40px;
      border-radius: 8px;
      background: #ffd400;
      font-size: 14px;
      font-weight: 800;
      color: #0a1f32;
      border: none;
    }
  }
}
</style>
