function loadKwaiPixelScript(pixelId) {
  const scriptUrl = "https://s1.kwai.net/kos/s101/nlav11187/pixel/events.js"; // 快手像素脚本的URL
  let script = document.createElement("script");
  script.src = scriptUrl;
  script.async = true;
  document.head.appendChild(script);

  script.onload = () => {
    console.log("Kwai Pixel script loaded successfully.");
    initializeKwaiPixel(pixelId);
  };

  script.onerror = () => {
    console.error("Failed to load Kwai Pixel script.");
  };
}

function initializeKwaiPixel(pixelId) {
  window.kwaiq =
    window.kwaiq ||
    function () {
      (window.kwaiq.q = window.kwaiq.q || []).push(arguments);
    };
  window.kwaiq("init", { token: pixelId });
  window.kwaiq("page");
}

function trackEvent(eventType, eventData) {
  if (window.kwaiq) {
    window.kwaiq("track", eventType, eventData);
  } else {
    console.error("Kwai Pixel is not initialized yet.");
  }
}

export default {
  loadKwaiPixelScript,
  trackEvent,
};
