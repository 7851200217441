<template>
  <div class="record">
    <div class="wrapper">
      <div class="params">
        <div class="categories">
          <div :class="categoryIndex === index ? 'selected' : ''" v-for="(cate, index) in categories" :key="index"
            @click="onTapCateHandle(index)">
            {{ cate }}
          </div>
        </div>
      </div>

      <div v-if="categoryIndex === 0" class="box">
        <MineEmpty v-if="inviteRecord.length === 0"></MineEmpty>
        <div class="table" v-else>
          <div class="cell1" v-for="(item, index) in inviteRecord" :key="index">
            <div class="content">
              <div>{{ $t("you_invited_a_user") }}&nbsp;</div>
              <div class="userid">{{ item.userid }}</div>
            </div>
            <div class="date">{{ item.create_time }}</div>
            <div class="content" v-if="item.amount !== null">
              <div>{{ $t('invite_log_des1') }}&nbsp;</div>
              <div class="money">{{ item.amount }}</div>
            </div>
            <div class="date" v-if="item.pay_time !== null">{{ item.pay_time }}</div>
          </div>
          <LoadMoreComp :curCount="inviteRecord.length" :totalCount="totalPage0" :pageSize="pageSize"
            @loadMoreEvent="searchHandle"></LoadMoreComp>
        </div>
      </div>
      <div v-else-if="categoryIndex === 1" class="box">
        <MineEmpty v-if="grantRecord.length === 0"></MineEmpty>
        <div class="table" v-else>
          <div class="cell2" v-for="(item, index) in grantRecord" :key="index">
            <div class="des">{{ item.desc }}</div>
            <div class="content">
              <div>{{ $tools.formatCurrency(item.amount) }}&nbsp;</div>
              <div v-if="item.userid !== null">({{ item.userid }})</div>
            </div>
            <div class="date">{{ item.create_time }}</div>
          </div>
          <LoadMoreComp :curCount="grantRecord.length" :totalCount="totalPage1" :pageSize="pageSize"
            @loadMoreEvent="searchHandle"></LoadMoreComp>
        </div>
      </div>
      <div v-else class="box">
        <MineEmpty v-if="withdrawRecord.length === 0"></MineEmpty>
        <div class="table" v-else>
          <div class="cell3" v-for="(item, index) in withdrawRecord" :key="index">
            <div class="content">
              <div>R$ {{ $tools.formatCurrency(item.disbursement_amount) }}</div>
              <div>{{ item.create_time }}</div>
            </div>
            <div class="des" v-if="item.disbursement_type === 6">{{ $t('invite_log_des2') }}</div>
            <div class="des" v-if="item.disbursement_type === 7">{{ $t('invite_log_des3') }}</div>
            <div class="info">
              <div>Id:{{ item.order_id }}</div>
              <div :class="item.status === 1 ? 'success' : 'fail'">{{ item.status === 1 ? $t('success'): $t('fail') }}</div>
            </div>
          </div>
          <LoadMoreComp :curCount="withdrawRecord.length" :totalCount="totalPage2" :pageSize="pageSize"
            @loadMoreEvent="searchHandle"></LoadMoreComp>
        </div>
      </div>
    </div>
    <van-calendar v-model:show="isShowMobileDateModal" type="range" :min-date="minDate" :max-date="maxDate"
      allow-same-day @confirm="calendarConfirmHandle" />
  </div>
</template>

<script>
import MineEmpty from "@/components/MineEmpty.vue";
import "@vuepic/vue-datepicker/dist/main.css";
import LoadMoreComp from "@/components/LoadMoreComp.vue";
import { Calendar } from "vant";
export default {
  components: { MineEmpty, LoadMoreComp, [Calendar.name]: Calendar },
  data() {
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth() + 1;
    const day = today.getDate();
    const currentDate = `${year}-${month < 10 ? "0" + month : month}-${day < 10 ? "0" + day : day}`;

    return {
      categoryIndex: 0,
      categories: [this.$t("invite-record-invite"), this.$t("invite-record-grant"), this.$t("invite-record-withdraw")],
      inviteRecord: [],
      grantRecord: [],
      withdrawRecord: [],
      dateRange: [currentDate, currentDate],
      isShowMobileDateModal: false,
      pageSize: 20,
      page0: -1,
      totalPage0: 0,
      page1: -1,
      totalPage1: 0,
      page2: -1,
      totalPage2: 0,
      minDate: new Date(1900, 0, 1),
      maxDate: today,
    };
  },
  mounted() {
    this.onTapCateHandle(0);
  },
  methods: {
    calendarConfirmHandle(values) {
      this.isShowMobileDateModal = false;
      const [start, end] = values;
      this.dateRange = [this.dateStringFormat(start), this.dateStringFormat(end)];
      this.searchHandle();
    },
    dateFormat() {
      if (this.dateRange === null) {
        return;
      }
      const start = this.dateStringFormat(new Date(this.dateRange[0]));
      const end = this.dateStringFormat(new Date(this.dateRange[1]));
      return start + " - " + end;
    },
    dateStringFormat(date) {
      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const year = date.getFullYear();
      return `${year}-${month}-${day}`;
    },
    searchHandle() {
      this.onTapCateHandle(this.categoryIndex);
    },
    onTapCateHandle(index) {
      this.categoryIndex = index;
      switch (index) {
        case 0:
          this.fetchInviteRecordData();
          break;

        case 1:
          this.fetchGrantRecordData();
          break;

        default:
          this.fetchWithdrawRecordData();
          break;
      }
    },
    fetchInviteRecordData() {
      const data = {
        action: "agent_item_list",
        data: {
          // start_time: this.dateStringFormat(new Date(this.dateRange[0])),
          // end_time: this.dateStringFormat(new Date(this.dateRange[1])),
          page: this.page0 + 1,
          page_size: this.pageSize,
        },
      };
      this.$http
        .post("agentCenter", data)
        .then((response) => {
          this.inviteRecord.push(...response.data.data_list);
          this.page0 = response.data.page;
          this.totalPage0 = response.data.total;
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    fetchGrantRecordData() {
      const data = {
        action: "agent_commission_changes",
        page: this.page1 + 1,
        page_size: this.pageSize,
        data: {
          // start_time: this.dateStringFormat(new Date(this.dateRange[0])),
          // end_time: this.dateStringFormat(new Date(this.dateRange[1])),
          page: this.page1 + 1,
          page_size: this.pageSize,
        },
      };
      this.$http
        .post("agentCenter", data)
        .then((response) => {
          this.grantRecord.push(...response.data.data_list);
          this.page1 = response.data.page;
          this.totalPage1 = response.data.total;
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    fetchWithdrawRecordData() {
      const data = {
        action: "agent_disbursement_order",
        data: {
          // start_time: this.dateStringFormat(new Date(this.dateRange[0])),
          // end_time: this.dateStringFormat(new Date(this.dateRange[1])),
          page: this.page2 + 1,
          page_size: this.pageSize,
        },
      };
      this.$http
        .post("agentCenter", data)
        .then((response) => {
          this.withdrawRecord.push(...response.data.data_list);
          this.page2 = response.data.page;
          this.totalPage2 = response.data.total;
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.record {
  position: relative;
  display: flex;

  .wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;

    .params {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      .categories {
        flex: 1;
        margin-bottom: 10px;
        display: flex;
        height: 50px;
        background: var(--cate-normal-bg);
        border-radius: 8px;
        align-items: center;
        min-width: auto;

        div {
          flex: 1;
          font-size: 14px;
          font-weight: 500;
          color: #029479;
        }

        .selected {
          color: #fff;
        }
      }
    }

    .box {
      margin-top: 10px;
      display: flex;
      flex-direction: column;

      .table {
        display: flex;
        flex-direction: column;
        border-radius: 4px;
        padding-bottom: 10px;

        .cell1 {
          display: flex;
          flex-direction: column;
          border-radius: 4px;
          background: #006a56;
          padding: 0px 16px 5px;
          margin-bottom: 10px;
          justify-content: space-between;

          .content {
            margin-top: 5px;
            display: flex;
            font-size: 11px;
            font-weight: 500;

            &>div:nth-child(1) {
              color: #81d6ba;
            }

            &>.userid {
              color: #ffffff;
            }

            &>.money {
              color: #ecc603;
            }
          }

          .date {
            margin-top: 5px;
            font-size: 11px;
            font-weight: normal;
            color: #004134;
            text-align: left;
          }
        }

        .cell2 {
          display: flex;
          flex-direction: column;
          height: 90px;
          border-radius: 4px;
          background: #006a56;
          padding: 10px 16px;
          margin-bottom: 8px;
          justify-content: space-between;

          .des {
            color: #81d6ba;
            font-size: 11px;
            font-weight: 250;
            text-align: left;
          }

          .content {
            display: flex;
            align-items: center;
            font-size: 12px;
            font-weight: 600;
            color: #ffd400;

            &>div:last-of-type {
              color: #81d6ba;
              font-size: 11px;
              font-weight: 500;
            }
          }

          .date {
            font-size: 11px;
            font-weight: 250;
            color: #004134;
            text-align: left;
          }
        }

        .cell3 {
          display: flex;
          flex-direction: column;
          height: 90px;
          border-radius: 4px;
          background: #006a56;
          padding: 10px 16px;
          margin-bottom: 8px;
          justify-content: space-around;

          .content {
            display: flex;
            justify-content: space-between;

            div:first-of-type {
              font-size: 14px;
              font-weight: 600;
              color: #ffd400;
            }

            div:last-of-type {
              font-size: 11px;
              font-weight: 250;
              color: #004134;
            }
          }

          .des {
            font-size: 14px;
            font-weight: 250;
            color: #ffffff;
            opacity: 0.8;
            text-align: left;
          }

          .info {
            display: flex;
            justify-content: space-between;

            div:first-of-type {
              font-size: 11px;
              font-weight: 250;
              color: #ffffff;
              text-align: left;
            }

            .success {
              font-size: 14px;
              font-weight: 500;
              color: #00cb47;
              opacity: 0.6;
              display: flex;
              flex-direction: column;
              justify-content: flex-end;
            }

            .fail {
              font-size: 14px;
              font-weight: 500;
              color: #f04770;
              opacity: 1;
              display: flex;
              flex-direction: column;
              justify-content: flex-end;
            }
          }
        }
      }
    }
  }
}
</style>
