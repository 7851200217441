<template>
  <div class="container">
    <img class="top-image" src="@/assets/newer-task/top.webp" alt="" />
    <div class="totle-progress-box">
      <div class="totle-progress">
        <div class="title">Concluir tarefas e receber um bônus</div>
        <div class="progress-box">
          <div class="progress-bg">
            <div class="progress" :style="{ width: (finishCount / tableData.length) * 100 + '%' }"></div>
            <div class="progress-text">{{ finishCount }} /{{ tableData.length }}</div>
          </div>
          <img class="start" src="@/assets/newer-task/progress-start.webp" alt="" />
          <img class="end" src="@/assets/newer-task/progress-end.webp" alt="" />
        </div>
      </div>
      <div class="money-box">
        <div class="title">Recebido</div>
        <div class="money">R$:{{ $tools.formatCurrency(totalPrize) }}</div>
      </div>
    </div>
    <div class="list">
      <div class="cell" v-for="(item, index) in tableData" :key="index">
        <div class="main">
          <div class="img-box">
            <img :src="require('@/assets/newer-task/icon' + (index + 1) + '.webp')" alt="" />
          </div>
          <div class="text-box">
            <div class="title">{{ item.name }}</div>
            <div class="bonus">
              {{ index === tableData.length - 1 ? "Dinheiro" : "Bônus" }} :<span :class="index === tableData.length - 1 ? 'yellow' : 'green'"
                >+{{ item.bonus }}</span
              >
            </div>
          </div>
          <div class="button" :class="item.isFinish ? 'disable' : 'enable'" @click="handleOfFinishTask(index)">
            {{ item.isFinish ? "Concluído" : "Ir completar" }}
          </div>
        </div>
        <div v-if="index === tableData.length - 1 && new_user_active_info !== null" class="progress-box">
          <div class="min-max">0</div>
          <div class="progress-bg">
            <div class="progress" :style="{ width: rechargeTaskProgress + '%' }"></div>
            <div class="progress-text">{{ rechargeTaskProgress }}%</div>
          </div>
          <div class="min-max">{{ new_user_active_info?.recharge_need }}</div>
        </div>
      </div>
    </div>
    <div class="button" :class="new_user_active_info?.receive_state === 1 ? 'enable' : 'disable'" @click="handleOfFinishAll">
      {{ new_user_active_info?.receive_state === 1 ? "Receber" : "Completar todas as tarefas para reivindicar" }}
    </div>
    <div class="rule">
      <div class="title">Condições de participação:</div>
      <div class="value">1. Novos usuários registrados.</div>
      <div class="title">Explicação:</div>
      <div class="value">
        Novos usuários, vantagens exclusivas!<br />
        1. Siga o canal para receber informações oficiais e mais promoções.<br />
        2. Baixe o aplicativo e adicione-o à tela inicial do seu celular para maior segurança e conveniência.<br />
        3. Verifique a página de eventos e participe de mais promoções para ganhar bônus generosos.<br />
        4. Confira a página de membros para desfrutar de mais privilégios exclusivos após a atualização, não perca.<br />
        5. Visite a página de convites para ganhar comissões e bônus extras ao convidar amigos.<br />
        6. Cumpra a missão de recarga, com recargas acumuladas ≥ 20. Oferta exclusiva para novos usuários.<br />
        7. O período de validade da promoção é de 7 dias após o registro bem-sucedido.<br />
        8. Aqueles que forem pegos trapaceando ou violando as regras podem ter sua elegibilidade e recompensas canceladas.<br />
      </div>
    </div>
    <dialog ref="completeDialog">
      <div class="dialog-box">
        <div class="header">
          <div>Parabéns</div>
          <img src="@/assets/award-task/coin.webp" alt="" />
          <img src="@/assets/newer-task/close.webp" alt="" @click="handleOfCloseDialog" />
        </div>
        <div class="title">Sucesso na retirada</div>
        <div class="money">
          R$&nbsp;<span class="green">{{ $tools.formatCurrency(receiveData?.coins_give) }}</span> +
          <span class="yellow">{{ $tools.formatCurrency(receiveData?.coins) }}</span>
        </div>
        <div class="des">O bônus é automaticamente depositado na carteira da sua conta</div>
        <div class="btn" @click="handleOfCloseDialog">Entendido</div>
      </div>
    </dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      new_user_active_info: null,
      tableData: [
        {
          key: "join_channel_",
          name: "Siga o canal",
          bonus: "",
          isFinish: false,
        },
        {
          key: "download_app_",
          name: "Download do aplicativo",
          bonus: "",
          isFinish: false,
        },
        {
          key: "view_cofrinho_",
          name: "Ver página do cofrinho",
          bonus: "",
          isFinish: false,
        },
        {
          key: "view_active_",
          name: "Ver página de eventos",
          bonus: "",
          isFinish: false,
        },
        {
          key: "view_member_",
          name: "Ver página de membros",
          bonus: "",
          isFinish: false,
        },
        {
          key: "view_invite_",
          name: "Ver página de convite",
          bonus: "",
          isFinish: false,
        },
        {
          key: "recharge_",
          name: "Tarefas de recarga",
          bonus: "",
          isFinish: false,
        },
      ],
      totalPrize: 0,
      finishCount: 0,
      receiveData: null,
    };
  },
  computed: {
    rechargeTaskProgress() {
      if (this.new_user_active_info?.recharge_need === 0) {
        return 0;
      }
      let progress = parseInt(this.new_user_active_info?.recharge_amount / this.new_user_active_info?.recharge_need) * 100;
      if (progress >= 100) {
        progress = 100;
      }
      return progress;
    },
  },
  mounted() {
    this.$emit("update:title", "Missões para novatos");
    this.fetchNewerTaskInfo();
  },
  methods: {
    handleOfFinishTask(index) {
      this.tableData[index].isFinish = true;
      // if (index < 7) {
      //   this.completeTask(index);
      // } else {
      //   this.nextStep(index);
      // }
      this.nextStep(index);
    },
    handleOfCloseDialog() {
      this.$refs.completeDialog.close();
      this.$router.back();
    },
    handleOfFinishAll() {
      if (this.new_user_active_info && this.new_user_active_info.receive_state === 1) {
        this.receiveTask();
      }
    },
    async fetchNewerTaskInfo() {
      const data = {
        action: "new_user_active_info",
        data: {},
      };
      const response = await this.$http.post("activity", data);
      const info = response.data.new_user_active_info;
      if (info === null) {
        return;
      }
      this.new_user_active_info = info;
      for (const key of Object.keys(info)) {
        if (key.endsWith("finish")) {
          const prizeKey = key.replace("finish", "prize");
          if (Object.keys(info).includes(prizeKey)) {
            this.finishCount += info[key];
            if (info[key] === 1) {
              this.totalPrize += info[prizeKey];
            }
          }

          const preKey = key.replace("finish", "");
          this.tableData.find((item) => {
            if (item.key === preKey) {
              item.bonus = this.$tools.formatCurrency(info[prizeKey]);
              item.isFinish = info[key] === 1;
            }
          });
        }
      }
    },
    // async completeTask(index) {
    //   const obj = this.tableData[index];
    //   const params = {};
    //   params[obj.key + "finish"] = 1;
    //   const data = {
    //     action: "new_user_active_finish",
    //     data: params,
    //   };
    //   try {
    //     await this.$http.post("activity", data);
    //     this.nextStep(index);
    //   } catch (error) {
    //     console.log("error", error);
    //   }
    // },
    nextStep(index) {
      switch (index) {
        case 0:
          {
            this.$tools.openTelegram();
          }
          break;

        case 1:
          {
            this.$router.push({ name: "app-download-page" });
          }
          break;
        case 2:
          {
            this.$router.push({ name: "piggy-bank-page" });
          }
          break;
        case 3:
          {
            this.$eventBus.emit("emitOfChangeTab", "activity");
          }
          break;
        case 4:
          {
            this.$eventBus.emit("emitOfChangeTab", "vip");
          }
          break;
        case 5:
          {
            this.$router.push({ name: "m-commission" });
          }
          break;
        case 6:
          {
            this.$router.push({ name: "m-deposit-page" });
          }
          break;

        default:
          break;
      }
    },
    async receiveTask() {
      const data = {
        action: "new_user_active_receive",
        data: {},
      };
      try {
        const response = await this.$http.post("activity", data);
        this.receiveData = response.data;
        this.$refs.completeDialog.showModal();
      } catch (error) {
        console.log("error", error);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  margin-top: 12px;
  .top-image {
    width: 100%;
    height: auto;
  }

  .totle-progress-box {
    background: #006a56;
    display: flex;
    padding: 3.5px 26px 7px;

    .totle-progress {
      flex: 1;
      display: flex;
      flex-direction: column;

      & > .title {
        line-height: 21px;
        text-align: left;
        color: #00e2b8;
        font-size: 10px;
        font-weight: 500;
      }

      .progress-box {
        margin-top: 6px;
        display: flex;
        flex-direction: column;
        height: 25px;
        justify-content: center;
        position: relative;

        .progress-bg {
          background: #004f3f;
          border-radius: 10px;
          height: 17px;
          display: flex;
          flex-direction: column;
          position: relative;

          .progress {
            background: #00cb47;
            border-radius: 10px;
            height: 100%;
            width: 50%;
          }

          .progress-text {
            position: absolute;
            color: #ffffff;
            font-size: 10px;
            font-weight: bold;
            width: 100%;
            line-height: 17px;
          }
        }

        .start {
          position: absolute;
          width: 25px;
          height: 25px;
          left: 0;
          top: 0;
        }

        .end {
          position: absolute;
          width: 25px;
          height: 25px;
          right: 0;
          top: 0;
        }
      }
    }

    .money-box {
      margin-left: 34px;
      display: flex;
      flex-direction: column;

      & > .title {
        line-height: 21px;
        text-align: left;
        color: #00e2b8;
        font-size: 10px;
        font-weight: 500;
      }

      & > .money {
        margin-top: 9px;
        color: #ffffff;
        font-size: 14px;
        font-weight: bold;
        line-height: 20px;
      }
    }
  }

  .list {
    margin: 16px 16px 0;
    display: flex;
    flex-direction: column;

    .cell {
      padding: 14px 9px 12px;
      margin-bottom: 10px;
      background: #004033;
      border-radius: 10px;
      display: flex;
      flex-direction: column;

      .main {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .img-box {
          display: flex;
          justify-content: center;
          align-items: center;

          & > img {
            width: 30px;
            height: auto;
          }
        }

        .text-box {
          margin: 0 15px;
          flex: 1;
          display: flex;
          flex-direction: column;

          & > .title {
            text-align: left;
            color: #ffffff;
            font-size: 12px;
            font-weight: bold;
          }

          .bonus {
            margin-top: 5px;
            text-align: left;
            color: #fff;
            font-size: 10px;
            font-weight: 500;

            & > span {
              font-size: 10px;
              font-weight: 700;
            }

            .yellow {
              color: #ecc603;
            }

            .green {
              color: #21b300;
            }
          }
        }

        .button {
          padding: 0 9px;
          width: 82px;
          box-sizing: border-box;
          border-radius: 8px;
          height: 34px;
          color: #ffffff;
          font-size: 11px;
          font-weight: 500;
          line-height: 34px;
        }

        .enable {
          background: #006955;
        }

        .disable {
          background: transparent;
          color: #00cb47;
          font-weight: bold;
        }
      }

      .progress-box {
        margin: 9px 67px 0 35px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .min-max {
          color: #fff;
          font-size: 9px;
          font-weight: 500;
        }

        & > .progress-bg {
          margin: 0 5px;
          flex: 1;
          background: #06131c;
          border-radius: 5px;
          height: 10px;
          display: flex;
          align-items: center;
          position: relative;

          & > .progress {
            background: #00cb47;
            border-radius: 5px;
            height: 100%;
          }

          & > .progress-text {
            position: absolute;
            color: #ffffff;
            font-size: 9px;
            font-weight: normal;
            width: 100%;
            line-height: 10px;
          }
        }
      }
    }
  }

  & > .button {
    margin: 10px 16px 0;
    border-radius: 8px;
    height: 40px;
    line-height: 40px;
    font-size: 12px;
    font-weight: 500;
  }

  & > .enable {
    background: #ffd400;
    color: #06131c;
    font-size: 15px;
  }

  & > .disable {
    background: #676767;
    color: #a5a5a5;
  }

  .rule {
    margin: 8px 15px 10px;
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    color: #029479;
    text-align: left;

    & > .title {
      margin-top: 10px;
      font-size: 12px;
      font-weight: bold;
      color: #fff;
    }

    & > .value {
      margin-top: 5px;
      font-size: 10px;
      font-weight: normal;
    }
  }

  .dialog-box {
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    background: #015545;
    height: 247px;

    & > .header {
      border-radius: 10px 10px 0px 0px;
      background: #012c23;
      height: 48px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 20px 0 20px;

      & > div:first-child {
        color: #ffffff;
        font-size: 12px;
        font-weight: 500;
      }

      & > img:first-of-type {
        width: 70px;
        height: 48px;
      }

      & > img:last-of-type {
        width: 20px;
        height: 20px;
      }
    }

    & > .title {
      margin-top: 15px;
      color: #ffffff;
      font-size: 12px;
      font-weight: 500;
    }

    & > .money {
      margin-top: 15px;
      display: flex;
      justify-content: center;
      color: #fff;
      font-size: 21px;
      font-weight: bold;

      & > .green {
        color: #15ad51;
      }

      & > .yellow {
        color: #ecc603;
      }
    }

    & > .des {
      margin: 25px 80px 0;
      color: #54e6b4;
      font-size: 11px;
      font-weight: 500;
      margin-bottom: 6px;
    }

    & > .btn {
      margin: 5px 20px 20px;
      background: #029479;
      height: 40px;
      line-height: 40px;
      border-radius: 10px;
      font-size: 15px;
      font-weight: 500;
      color: #d8d8d8;
    }
  }
}
</style>
