<template>
  <div class="container">
    <div class="box1">
      <div class="item">
        <div class="title">apostas válidas hoje</div>
        <div class="money-grey">R$:{{ $tools.formatCurrency(rebate?.today_bet_amount) }}</div>
      </div>
      <div class="item">
        <div class="title">apostas válidas hoje</div>
        <div class="money">R$:{{ $tools.formatCurrency(rebate?.rebate_mount) }}</div>
        <div class="count-down">
          contagem regressiva <span>{{ rebateCountDownTimeStr }}</span>
        </div>
      </div>
    </div>
    <div class="box2">
      <div class="title">Reembolso de Apostas Pendente para Receber</div>
      <div class="money">R$:{{ $tools.formatCurrency(rebate?.receive_amount) }}</div>
    </div>
    <div class="btn1" @click="handleOfClaimRebate">Receber</div>
    <div class="rule-box">
      <div class="title">Explicação</div>
      <div class="content">
        1. Membros Silver ativam reembolsos de apostas;<br />
        2. O reembolso das apostas é em dinheiro (valor maior que bônus);<br />
        3. Quanto mais alto o nível de membro, maior o percentual de reembolso;<br />
        4. O valor do reembolso = valor da aposta efetiva x percentual de reembolso;<br />
        5. O período de reembolso é de 1 dia. Apostas válidas feitas hoje, recebem reembolso de apostas no dia seguinte;<br />
        6. Após recebimento, o valor do reembolso será automaticamente transferido para a carteira da conta;<br />
        7. O valor do reembolso pode ser usado para apostar em jogos na plataforma ou para saque.<br />
      </div>
    </div>
    <div class="add-up-box">
      <div class="title">Reembolso de Apostas Recebido Acumulado</div>
      <div class="money-box">
        <img src="@/assets/level/coin.webp" alt="" />
        <div class="money">&nbsp;{{ rebate?.total_amount }}</div>
      </div>
      <div class="img-box">
        <img src="@/assets/level/icon6.webp" alt="" />
      </div>
    </div>
    <div class="btn2" @click="$tools.openTelegram($global.data.cooperate_link)">Feedback e Sugestões</div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      rebate: null,
      rebateTimer: null,
      rebateCountDownTimeStr: "00:00:00",
    };
  },
  mounted() {
    this.$emit("update:title", "Reembolso de apostas");
    this.fetchUserBetRebateInfo();
  },
  methods: {
    async handleOfClaimRebate() {
      if (this.rebate?.state === 2) {
        const data = {
          action: "receive_bet_rebate",
          data: {},
        };
        try {
          await this.$http.post("userVip", data);
          this.fetchUserBetRebateInfo();
        } catch (error) {
          console.log("error", error);
        }
      }
    },
    async fetchUserBetRebateInfo() {
      const data = {
        action: "user_bet_rebate_info",
        data: {},
      };
      try {
        const response = await this.$http.post("userVip", data);
        this.rebate = response.data;
        this.startRebateCountDown();
      } catch (error) {
        console.log("error", error);
      }
    },
    startRebateCountDown() {
      if (this.rebate === null) {
        return;
      }

      let diff = this.rebate.countdown_time;
      this.rebateTimer = setInterval(() => {
        if (diff <= 0) {
          clearInterval(this.rebateTimer);
          this.rebateCountDownTimeStr = "00:00:00";
          this.fetchUserBetRebateInfo();
          return;
        }
        const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
          .toString()
          .padStart(2, "0");
        const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60))
          .toString()
          .padStart(2, "0");
        const seconds = Math.floor((diff % (1000 * 60)) / 1000)
          .toString()
          .padStart(2, "0");
        this.rebateCountDownTimeStr = `${hours}:${minutes}:${seconds}`;
        diff -= 1000;
      }, 1000);
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  padding: 0 10px;

  .box1 {
    display: flex;

    & > .item {
      margin-right: 10px;
      background: #0a1f32;
      height: 77px;
      border-radius: 10px;
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 10px 9px;

      & > .title {
        color: #96a7c6;
        font-size: 12px;
        font-weight: 500;
      }

      & > .money-grey {
        color: #96a7c6;
        font-size: 21px;
        font-weight: bold;
      }

      & > .money {
        color: #ffd400;
        font-size: 21px;
        font-weight: bold;
      }

      & > .count-down {
        margin-top: auto;
        color: #96a7c6;
        font-size: 10px;
        font-weight: 500;

        & > span {
          color: #0ac325;
          font-size: 10px;
          font-weight: 500;
        }
      }
    }

    & > .item:last-child {
      margin-right: 0;
    }
  }

  .box2 {
    margin-top: 10px;
    background: #0a1f32;
    height: 120px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;

    & > .title {
      margin-top: 14px;
      color: #96a7c6;
      font-size: 12px;
      font-weight: 500;
    }

    & > .money {
      margin-top: 24px;
      color: #ffd400;
      font-size: 21px;
      font-weight: bold;
    }
  }

  & > .btn1 {
    margin-top: 10px;
    background: #ffd400;
    border-radius: 8px;
    height: 40px;
    line-height: 40px;
    color: #06131c;
    font-size: 13px;
    font-weight: 500;
  }

  .rule-box {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    & > .title {
      color: #ffffff;
      font-size: 12px;
      font-weight: bold;
    }

    & > .content {
      margin-top: 7px;
      text-align: left;
      color: #96a7c6;
      font-size: 10px;
      font-weight: 500;
    }
  }

  .add-up-box {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    background: linear-gradient(0deg, #0a1f32 0%, #293577 100%);
    height: 50px;
    border-radius: 10px;
    padding: 10px 16px;
    position: relative;

    & > .title {
      color: #96a7c6;
      font-size: 12px;
      font-weight: 500;
    }

    & > .money-box {
      display: flex;
      align-items: center;

      & > img {
        width: 15px;
        height: 15px;
      }

      & > .money {
        color: #ffd400;
        font-size: 15px;
        font-weight: 500;
      }
    }

    & > .img-box {
      position: absolute;
      right: 0;
      bottom: 0;
      display: flex;

      & > img {
        width: 54.5px;
        height: auto;
      }
    }
  }

  .btn2 {
    margin-top: 10px;
    background: #0a1f32;
    height: 34px;
    line-height: 34px;
    border-radius: 8px;
    color: #96a7c6;
    font-size: 12px;
    font-weight: 500;
  }
}
</style>
