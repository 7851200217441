<template>
  <div class="container" @click="resetPwdInput">
    <div class="top-banner">
      <div class="item">
        <div class="title-box">
          <img class="flag" src="@/assets/wallet/bra_flag.webp" alt="" />
          <div>{{ $t('balance') }} {{ amountType == 2 ? $t('commission_no') : "" }} R$</div>
          <img class="icon" src="@/assets/wallet/wallet_icon.webp" alt="" />
        </div>
        <div class="money">
          {{ $tools.formatCurrency((amountType === 2 ? walletData?.user_wallet?.withdrawal_commission :
            walletData?.user_wallet?.coins) || 0) }}
        </div>
        <div class="mind" v-if="amountType === 1">
          {{ $t('you_need_to_place_a_bet_of') }}
          <span @click="$router.push({ name: 'bet-task' })">{{
            $tools.formatCurrency(walletData?.pay_out_task?.task_amount - walletData?.pay_out_task?.task_amount_done ||
              0)
          }}</span>
          {{ $t('to_withdraw') }}
        </div>
        <div class="mind" v-if="amountType === 2">{{ $t('the_commission_has_no_withdrawal_requirements') }}</div>
      </div>
    </div>
    <div class="pix-empty" v-if="walletData?.user_pix_list.length === 0" @click="handleOfAddPix">
      <img src="@/assets/wallet/empty_icon.webp" alt="" />
      <div>{{ $t('add_withdrawal_account') }}</div>
      <img src="@/assets/wallet/arrow_right.webp" alt="" />
    </div>
    <div class="pix-box" v-else>
      <img class="pix-icon" src="@/assets/wallet/pix-icon.webp" alt="" />
      <div>PIX({{ walletData?.user_pix_list[0]?.pix_id }}) {{ walletData?.user_pix_list[0].pix }}</div>
      <img class="pix-lock" src="@/assets/wallet/pix-lock.webp" alt="" />
    </div>
    <div class="tips">{{ $t('enter_withdrawal_amount') }}</div>
    <div class="input-box" @click="resetPwdInput">
      <div class="unit">R$</div>
      <input type="number" :placeholder="showInputPlaceHolder" v-model="inputMoney" />
      <div class="btn" v-if="amountType === 1" @click="handleOfAll">{{ $t('whole') }}</div>
    </div>
    <div class="tip">
      {{ amountType === 1 ? $t('withdrawal_page_des1') : $t('withdrawal_page_des2') }}
    </div>
    <div class="pw-box">
      <div class="header">
        <div>{{ $t('enter_withdrawal_password') }}</div>
        <img
          :src="isVisiblePw ? require('@/assets/password/visible_icon.webp') : require('@/assets/password/invisible_icon.webp')"
          alt="" @click="changevisible" />
      </div>
      <!-- <Password6Input v-if="walletData?.user_status === 1" v-model:inputValue="password" :isVisible="isVisiblePw" /> -->
      <div class="password6" v-if="walletData?.user_status === 1">
        <Password6Input ref="pwdinput" @click="handleFocus" v-model:inputValue="password" :isVisible="isVisiblePw" />
        <!-- <van-password-input
          ref="passwordInput"
          :value="password"
          :mask="!isVisiblePw"
          gutter="10"
          :length="6"
          :focused="showKeyboard"
          @focus="handleFocus('password')"
        />
        <van-number-keyboard v-model="password" :show="showKeyboard" @blur="showKeyboard = false" /> -->
      </div>
      <div class="lock" v-else>
        <img src="@/assets/wallet/lock.webp" alt="" />
        <span>{{ $t('withdrawal_is_prohibited') }}</span>
      </div>
    </div>

    <div class="btn" :class="(amountType === 1 && walletData?.user_wallet?.coins > 0) || (amountType === 2 && walletData?.user_wallet?.withdrawal_commission > 0)
      ? 'btn-enable'
      : 'btn-disable'
      " v-if="walletData?.user_status === 1" @click="withdrawalHandle">
      {{ $t('withdrawals') }}
    </div>
    <div class="btn" v-else @click="$router.push({ name: 'm-service' })">{{ $t('contact_customer_service') }}</div>
    <div class="box">
      <div class="title">{{ $t('withdrawal_instructions') }}</div>
      <div class="rules">
        <div>
          {{ $t('withdrawal_instructions_des1') }}<br />
          {{ $t('withdrawal_instructions_des2') }}<br />
          {{ $t('withdrawal_instructions_des3') }}<br />
          {{ $t('withdrawal_instructions_des4') }} R$ {{ $global.data.payout_mini_amount }}, {{ $t('withdrawal_instructions_des5') }} R$ {{
            $global.data.payout_max_amount }}, {{ $t('withdrawal_instructions_des6') }}
        </div>
      </div>
    </div>
  </div>
  <dialog ref="taskDialog">
    <div class="task-dialog-wrapper">
      <div class="header">
        <div>{{ $t('prompt') }}</div>
        <img src="@/assets/wallet/coin_icon.webp" alt="" />
        <img src="@/assets/account/close.webp" alt="" @click="$refs.taskDialog.close()" />
      </div>
      <div class="des1">
        <i>{{ $t('withdrawal_instructions_dialog_des1') }}</i>
        <br />
        <i>{{ $t('withdrawal_instructions_dialog_des2') }}
          <span>{{ $tools.formatCurrency(walletData?.pay_out_task?.task_amount -
            walletData?.pay_out_task?.task_amount_done) }}</span></i>
        {{ $t('withdrawal_instructions_dialog_des3') }}
      </div>
      <div class="btns">
        <div class="btn see" @click="handleOfGoTask">{{ $t('withdrawal_instructions_dialog_btn_des1') }}</div>
        <div class="btn" @click="handleOfGoCasino">{{ $t('withdrawal_instructions_dialog_btn_des2') }}</div>
      </div>
    </div>
  </dialog>
</template>
<script>
import Password6Input from "@/components/Password6Input.vue";
import { PasswordInput, NumberKeyboard } from "vant";
export default {
  components: {
    Password6Input,
    [PasswordInput.name]: PasswordInput,
    [NumberKeyboard.name]: NumberKeyboard,
  },
  data() {
    return {
      walletData: null,
      inputMoney: "",
      password: "",
      isVisiblePw: false,
      showKeyboard: false,
      amountType: 1, // 提款类型 1现金 2佣金 3奖金提现任务
    };
  },
  mounted() {
    this.amountType = parseInt(this.$route.query.amountType) || 1;
    if (this.amountType === 1) {
      this.$emit("update:title", this.$t('wallet'));
    } else if (this.amountType === 2) {
      this.$emit("update:title", this.$t('commission_portfolio'));
    }
    this.getUserWallet();
  },
  watch: {
    password(newValue) {
      if (newValue.length > 6) {
        this.password = newValue.slice(0, 6);
      }
    },
  },
  computed: {
    showInputPlaceHolder() {
      if (this.amountType === 1) {
        return this.$t('minimum ') + this.$global.data.payout_mini_amount + ' , ' + this.$t('maximum') + ' ' + this.$global.data.payout_max_amount;
      } else if (this.amountType === 2) {
        return this.$t('inset_value');
      }
      return "";
    },
  },
  methods: {
    resetPwdInput() {
      if (this.$refs.pwdinput) {
        this.$refs.pwdinput.i = -1;
      }
    },
    changevisible() {
      this.isVisiblePw = !this.isVisiblePw;
      this.resetPwdInput();
    },
    adjustInputPosition() {
      // 这里你可以调整密码框的位置，以避免被自定义键盘遮挡
      const inputElement = this.$refs.pwdinput.$el;
      // 例如，将输入框滚动到视图中
      inputElement.scrollIntoView({ behavior: "smooth", block: "center" });
    },
    handleFocus() {
      // this.showKeyboard = true;
      setTimeout(() => {
        this.adjustInputPosition();
        this.$refs.pwdinput.i = 0;
      }, 200);
    },
    handleOfGoTask() {
      this.$refs.taskDialog.close();
      this.$router.push({ name: "bet-task" });
    },
    handleOfGoCasino() {
      this.$refs.taskDialog.close();
      this.$router.push({ name: "casino" });
    },
    handleOfAll() {
      if (this.walletData.pay_out_status) {
        if (this.amountType === 1) {
          this.inputMoney = Math.min(parseInt(this.walletData?.user_wallet?.coins / 10) * 10, this.$global.data.payout_max_amount);
        } else if (this.amountType === 2) {
          this.inputMoney = this.walletData?.user_wallet?.withdrawal_commission;
        }
      } else {
        this.$refs.taskDialog.showModal();
      }
    },
    handleOfAddPix() {
      if (this.$refs.pwdinput) {
        this.$refs.pwdinput.i = -1;
      }
      this.$router.push({ name: "add-pix" });
    },
    async withdrawalHandle() {
      if (
        (this.amountType === 1 && this.walletData.user_wallet.coins <= 0) ||
        (this.amountType === 2 && this.walletData.user_wallet.withdrawal_commission <= 0)
      ) {
        this.$toast.error(this.$t('insufficient_balance_for_withdrawal'));
        return;
      }

      if (this.walletData.pay_out_status === false) {
        this.$refs.taskDialog.showModal();
        return;
      }

      const data = {
        action: "user_withdrawal",
        data: {
          amount: this.inputMoney, // 提款金额
          amount_type: this.amountType, // 提款类型 1现金 2佣金 3奖金提现任务
          user_pix_id: this.walletData.user_pix_list[0].id, // 提款账户id
          pay_out_pwd: this.password, // 提款密码
          activity_id: 0, // 提款券奖品ID:user_pay_coupon_list接口id字段 无则传递0
        },
      };
      try {
        const response = await this.$http.post("userWallet", data);
        console.log("response", response);
        this.getUserWallet();
        window.location.reload();
      } catch (error) {
        console.log("error", error);
      }
    },
    getUserWallet() {
      const data = {
        action: "query_wallet",
        data: {},
      };

      this.$http
        .post("userWallet", data)
        .then((response) => {
          this.walletData = response.data;
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    checkUserInfo() {
      const data = {
        action: "my_account",
        data: {},
      };
      this.$http
        .post("userCenter", data)
        .then((response) => {
          const data = response.data.data;
          this.userInfo = data;
          if (data.real_name === null || data.customer_cert === null || data.email === null || data.phone_verify === 0) {
            this.$router.push({
              path: "/m-verification-info1",
              query: { withdrawable: this.wallet.withdrawable, amountType: 1 },
            });
          } else {
            this.$router.push({
              path: "/m-verification-info2",
              query: { withdrawable: this.wallet.withdrawable, amountType: 1 },
            });
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding-bottom: 40px;

  .top-banner {
    margin: 15px;
    background-image: url("@/assets/wallet/card_bg.webp");
    background-size: 100% 100%;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    padding: 12px 15px;
    box-sizing: border-box;
    height: 120px;
    margin-bottom: 0;

    &>.item {
      display: flex;
      flex-direction: column;

      .title-box {
        display: flex;
        align-items: center;

        &>.flag {
          width: 15px;
          height: 15px;
        }

        &>div {
          margin-left: 10px;
          color: #ffffff;
          font-size: 12px;
          font-weight: bold;
        }

        &>.icon {
          margin-left: auto;
          width: 15px;
          height: 15px;
        }
      }

      .money {
        text-align: left;
        margin-top: 5px;
        color: #ffd400;
        font-size: 36px;
        font-weight: bold;
      }

      .mind {
        text-align: left;
        margin-top: 10px;
        color: #ffffff;
        font-size: 12px;
        font-weight: 500;

        &>span {
          color: #ffd400;
        }
      }
    }
  }

  .pix-empty {
    margin: 15px 15px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #1c8277;
    border-radius: 10px;
    padding: 0 15px;

    &>img {
      width: 24.5px;
      height: 19px;
    }

    &>div {
      margin-left: 10px;
      margin-right: auto;
      color: #fff;
      font-size: 14px;
      font-weight: 500;
      height: 45px;
      line-height: 45px;
    }
  }

  .pix-box {
    margin: 10px 15px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #1c8277;
    border-radius: 10px;
    padding: 11px 10px 11px 15px;

    .pix-icon {
      width: 22px;
      height: 22px;
    }

    &>div {
      margin-left: 12px;
      margin-right: auto;
      color: #ffffff;
      font-size: 12px;
      font-weight: 500;
    }

    .pix-lock {
      width: 22px;
      height: 22px;
    }
  }

  .tips {
    margin: 20px 30px 0;
    margin-left: 18px;
    text-align: left;
    color: #fff;
    font-size: 12px;
    font-weight: 500;

    &>span {
      color: #ffd400;
    }
  }

  .input-box {
    margin: 10px 15px 0;
    background: #004134;
    border-radius: 10px;
    display: flex;
    align-items: center;
    padding: 0 10px 0 15px;
    border: 1px solid #007a63;

    .unit {
      color: #81d6ba;
      font-size: 14px;
      font-weight: 500;
    }

    input {
      flex: 1;
      margin: 0 10px;
      font-size: 12px;
      font-weight: 500;
      height: 45px;

      &::placeholder {
        color: #029479;
        font-size: 12px;
      }
    }

    &>.btn {
      background: transparent;
      color: #81d6ba;
      font-size: 12px;
      font-weight: 500;
    }
  }

  .tip {
    color: #81d6ba;
    font-size: 10px;
    text-align: left;
    padding: 4px 24px;
    box-sizing: border-box;
  }

  .pw-box {
    margin: 10px 15px 0;
    display: flex;
    flex-direction: column;

    &>.header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &>div {
        color: #ffffff;
        font-size: 12px;
        font-weight: 500;
      }

      &>img {
        width: 15px;
        height: 12px;
      }
    }

    .password6 {
      height: 70px;
      border-radius: 8px;
      background: #006a56;
      margin-top: 14px;
      padding: 0 12px;
      display: flex;
      align-items: center;

      :deep(.van-password-input) {
        margin: 12px 12px 0 12px;

        .van-password-input__item {
          border-radius: 10px;
          height: 45px;
        }
      }

      :deep(.van-number-keyboard) {
        background-color: #d6d7dc;
        padding-top: 18px;

        .van-key {
          background: #fff;
        }
      }
    }

    .lock {
      margin-top: 10px;
      display: flex;
      justify-content: space-around;
      flex-direction: column;
      align-items: center;
      background: #004134;
      height: 70px;
      border-radius: 10px;
      padding: 14px 0;
      box-sizing: border-box;
      color: #fff;
      font-size: 11px;

      &>img {
        width: 20px;
        height: 20px;
      }
    }
  }

  &>.btn {
    margin: 20px 15px;

    height: 45px;
    line-height: 45px;
    border-radius: 8px;

    font-size: 18px;
    font-weight: 500;
    background: #029479;
  }

  &>.btn-enable {
    color: #000000;
    background: #ffd400;
  }

  &>.btn-disable {
    background: #676767;
    color: #a5a5a5;
  }

  &>.box {
    display: flex;
    flex-direction: column;

    .title {
      margin: 10px 15px 0;
      text-align: left;
      font-size: 14px;
      font-weight: 500;
      color: #ffffff;
    }

    .rules {
      margin: 10px 15px 0;
      font-size: 12px;
      font-weight: 300;
      line-height: 22px;
      color: #029479;
      display: flex;
      flex-direction: column;

      div {
        text-align: left;
      }
    }
  }
}

.task-dialog-wrapper {
  display: flex;
  flex-direction: column;
  // min-width: 345px;
  height: 204px;
  background: #015545;
  border-radius: 10px;

  &>.header {
    border-radius: 10px 10px 0px 0px;
    background: #012c23;
    height: 48px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 13px 0 23px;

    &>div:first-child {
      color: #81d6ba;
      font-size: 14px;
      font-weight: 500;
    }

    &>img:first-of-type {
      width: 65.5px;
      height: 48px;
    }

    &>img:last-of-type {
      width: 20px;
      height: 20px;
    }
  }

  &>.des1 {
    margin-top: 30px;
    color: #fff;
    font-size: 12px;
    font-weight: 500;

    i {
      font-style: normal;
    }

    span {
      color: #ffd400;
    }
  }

  &>.btns {
    margin-top: 30px;
    display: flex;
    justify-content: space-around;
    padding: 0 50px;

    &>.btn {
      background: #ffd400;
      width: 135px;
      height: 40px;
      line-height: 40px;
      border-radius: 10px;
      font-size: 14px;
      font-weight: 500;
      color: #01221c;
    }

    &>.btn.see {
      background: #10b349;
      color: #fff;
      margin-right: 12px;
    }
  }
}
</style>
