<template>
  <div class="deposit">
    <div class="wrapper">
      <mine-empty v-if="tableData.length === 0"></mine-empty>
      <div v-else class="m-table">
        <ul>
          <li v-for="(item, index) in tableData" :key="index" @click="showDetailDialog(item)">
            <div class="li-top">
              <span :class="item.amount >= 0 ? 'm-value-positive' : 'm-value-nagative'">R${{
                $tools.formatCurrency(parseFloat(item.amount)) }} </span>
              <span>{{ item.pay_time }}</span>
            </div>
            <div class="li-bottom">
              <span>{{ item.orderid }} <img src="@/assets/verify_dialog_slot_copy.webp" alt="" /></span>
              <span :class="item.status == 1 ? 'ing' : item.status == 2 ? 'success' : 'cancel'">{{ showStaus(index) }}</span>
            </div>
          </li>
        </ul>
        <LoadMoreComp :curCount="tableData.length" :totalCount="total" @loadMoreEvent="getDeposit(page + 1)">
        </LoadMoreComp>
      </div>
    </div>
    <!-- 验证手机or邮箱弹窗 -->
    <dialog ref="verifyDialog">
      <VerifyDialogForPhoneOrEmail :verifyType="3" :title="$t('deposit_records')"
        @closeEvent="handleOfVerifyDialogClose">
        <template v-slot:depositRecords>
          <h1 class="dialog-title">R${{ $tools.formatCurrency(parseFloat(info.amount)) }}</h1>
          <div class="row">
            <p>
              <span>{{ $t('serial_number') }}</span>
              <span style="font-size: 11px">{{ info.orderid }}</span>
              <span style="position: relative">
                <img src="@/assets/verify_dialog_slot_copy.webp" @click="copyHandle(info.orderid)" alt="" />
                <img class="copiado" v-show="isShowCopiado" src="@/assets/user/copiado.png" alt="" />
              </span>
            </p>
            <p>
              <span>{{ $t('creation_time') }}</span>
              <span>{{ info.pay_time }}</span>
            </p>
            <p>
              <span>{{ $t('trading_time') }}</span>
              <span>{{ info.pay_time }}</span>
            </p>
            <p>
              <span>{{ $t('order_status') }}</span>
              <!-- 1 支付中 2 成功 4 取消 -->
              <span :class="info.status == 1 ? 'ing' : info.status == 2 ? 'success' : 'cancel'" class="win">{{ info.status == 1 ?
                $t('payment_in_progress') : info.status == 2 ? $t('success') : $t('cancel') }}</span>
            </p>
            <!-- <p>
              <span>Observações</span>
              <span>我是备注</span>
            </p> -->
          </div>
          <div class="row last">
            <p>
              <span>{{ $t('balance') }}</span>
              <span>R${{ $tools.formatCurrency(parseFloat(info.new_coins)) + " Real" }}</span>
            </p>
          </div>
        </template>
      </VerifyDialogForPhoneOrEmail>
    </dialog>
  </div>
</template>

<script>
import MineEmpty from "@/components/MineEmpty.vue";
import LoadMoreComp from "@/components/LoadMoreComp.vue";
import VerifyDialogForPhoneOrEmail from "@/components/VerifyDialogForPhoneOrEmail.vue";
import clipboard from "clipboard";
export default {
  components: { MineEmpty, LoadMoreComp, VerifyDialogForPhoneOrEmail },
  data() {
    return {
      tableData: [],
      isMobile: false,
      pageSize: 10,
      page: 0,
      total: 0,
      sid: "",
      info: {},
      isShowCopiado: false,
    };
  },
  mounted() {
    this.sid = this.$route.query?.sid || "";
    this.isMobile = this.$tools.isMobile();
    this.getDeposit(this.page);
  },
  methods: {
    copyHandle(bet_id) {
      clipboard.copy(bet_id);
      this.isShowCopiado = true;
      setTimeout(() => {
        this.isShowCopiado = false;
      }, 2000);
    },
    handleOfVerifyDialogClose() {
      this.$refs.verifyDialog.close();
    },
    showDetailDialog(item) {
      this.info = item;
      console.log(item);

      this.$refs.verifyDialog.showModal();
    },
    changePage(index) {
      this.getDeposit(index - 1);
    },
    getDeposit(page) {
      const data = {
        action: "user_payorder_list",
        data: {
          page,
          page_size: this.pageSize,
        },
      };
      this.$http
        .post("userWallet", data)
        .then((response) => {
          if (this.isMobile) {
            this.tableData.push(...response.data.data_list);
          } else {
            this.tableData = response.data.data_list;
          }

          // const index = this.tableData.findIndex((item) => {
          //   return item.orderid === this.sid && item.status === 2;
          // });
          // if (index !== -1) {
          //   console.log("index:", index);
          //   console.log("test sid:", this.sid);
          //   this.$fbq("track", "Purchase", { currency: "USD", value: 0 });
          //   this.$kwaiq.instance(this.$global.kwaiPixelId).track("purchase");
          // }

          this.page = response.data.page;
          this.total = response.data.count;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    showStaus(index) {
      const st = this.tableData[index].status;
      let ret = "";
      switch (st) {
        case 1:
          // 支付中
          ret = this.$t('payment_in_progress');
          break;
        case 2:
          // 已支付
          ret = this.$t('success');
          break;
        case 3:
          // 已补单
          ret = "Já suplementado";
          break;
        case 4:
          // 已取消
          ret = this.$t('cancel');
          break;
        default:
          // 未知
          ret = "Desconhecido";
          break;
      }

      return ret;
    },
  },
};
</script>

<style lang="scss" scoped>
:deep(.el-pagination.is-background .btn-prev) {
  background-color: #1f1e1e;
  color: #fff;
}

:deep(.el-pagination.is-background .btn-prev:disabled) {
  background-color: #1f1e1e;
  color: #fff;
}

:deep(.el-pagination.is-background .el-pager li:not(.disabled)) {
  background-color: #1f1e1e;
  color: #fff;
}

:deep(.el-pagination.is-background .el-pager li:not(.disabled).is-active) {
  background-color: #e9c97e;
  color: #070704;
}

:deep(.el-pagination.is-background .btn-next) {
  background-color: #1f1e1e;
  color: #fff;
}

:deep(.el-pagination.is-background .btn-next:disabled) {
  background-color: #1f1e1e;
  color: #fff;
}

.deposit {
  display: flex;

  .wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;

    .m-table {
      display: flex;
      flex-direction: column;
      border-radius: 4px;
      padding-bottom: 20px;
      margin-top: -15px;
      // .m-cell {
      //   margin-bottom: 8px;
      //   box-sizing: border-box;
      //   background: var(--mine-card-bg-color);
      //   border-radius: 4px;
      //   display: flex;
      //   flex-direction: column;
      //   padding: 10px 16px 7px;

      //   .m-item:nth-child(2) {
      //     .m-value {
      //       font-size: 12px;
      //     }
      //   }

      //   .m-item {
      //     display: flex;
      //     margin-bottom: 6px;
      //     .m-title {
      //       width: 30%;
      //       font-size: 14px;
      //       font-weight: 250;
      //       color: #3e7bb0;
      //       text-align: left;
      //     }
      //     .m-value {
      //       flex: 1;
      //       font-size: 14px;
      //       font-weight: 250;
      //       color: #ffffff;
      //       opacity: 0.8;
      //       text-align: left;
      //     }

      //     .m-value-positive {
      //       // flex: 1;
      //       font-size: 14px;
      //       font-weight: 500;
      //       color: #42f388;
      //       opacity: 0.8;
      //       text-align: left;
      //     }

      //     .m-value-negative {
      //       // flex: 1;
      //       font-size: 14px;
      //       font-weight: 500;
      //       color: #d04835;
      //       opacity: 0.8;
      //       text-align: left;
      //     }
      //   }
      // }
      ul {
        li {
          height: 80px;
          background: #006a56;
          border: 1px solid #007a63;
          border-radius: 10px;
          display: flex;
          flex-direction: column;
          padding: 12px;
          box-sizing: border-box;
          margin-bottom: 12px;

          .m-value-positive {
            color: #7bffa9 !important;
          }

          .m-value-negative {
            color: #ff9d70 !important;
          }

          .li-top {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-bottom: 10px;

            span {
              font-size: 18px;
              color: #7bffa9;
              font-weight: bold;

              &:last-child {
                font-size: 12px;
                color: #81d6ba;
              }
            }
          }

          .li-bottom {
            display: flex;
            justify-content: space-between;
            font-size: 10px;
            font-weight: 500;

            span {
              display: flex;
              align-items: center;
              color: #81d6ba;

              &:first-child {
                color: #fff;
                font-size: 10px;
              }

              img {
                width: 15px;
                height: 15px;
                margin-left: 8px;
              }
            }

          }
        }
      }
    }

    .table {
      background: #1a1919;
      border-radius: 8px;
      display: flex;
      flex-direction: column;

      .header {
        margin: 0 30px;
        font-size: 14px;
        color: #ffffff;
        display: flex;
        height: 56px;
        justify-content: center;
        align-items: center;

        div {
          flex: 1;
          display: flex;
          justify-content: center;
        }
      }

      .cell {
        margin: 0 10px 8px;
        // padding: 0 20px;
        font-size: 12px;
        color: rgba($color: #ffffff, $alpha: 0.8);
        height: 40px;
        border-radius: 8px;
        background: #1f1e1e;
        box-sizing: border-box;
        border: 1px solid #2b2b2b;
        display: flex;
        justify-content: center;
        align-items: center;

        div {
          flex: 1;
          display: flex;
          justify-content: center;
        }

        .value-positive {
          flex: 1;
          font-size: 12px;
          color: #42f388;
          opacity: 0.8;
          text-align: left;
        }

        .value-negative {
          flex: 1;
          font-size: 12px;
          color: #d04835;
          opacity: 0.8;
          text-align: left;
        }
      }
    }
  }

  .dialog-title {
    margin: 0;
    font-size: 32px;
    font-weight: bold;
    color: #7bffa9;
    line-height: 1;
    border-bottom: 1px solid #007760;
    margin-bottom: 20px;
    padding-bottom: 14px;
  }

  .row {
    border-bottom: 1px solid #007760;
    margin-bottom: 20px;

    p {
      display: flex;
      align-items: center;
      width: 100%;
      line-height: 1;
      justify-content: space-between;

      span {
        font-size: 11px;

        &:first-child {
          // width: 60px;
          color: #00c19c;
          font-weight: 500;
          text-align: left;
          margin-right: 16px;
        }

        &:nth-child(2) {
          flex: 1;
          text-align: left;
          color: #fff;
          font-weight: 500;
        }

        &:last-child {
          display: flex;

          img {
            width: 15px;
            height: 16px;
          }

          .copiado {
            position: absolute;
            top: -28px;
            left: -20px;
            width: 50px;
            height: auto;
          }
        }
      }

      span.win {
        color: #7bffa9;
      }
    }
  }

  .last {
    border: none;

    p {
      margin: 0;
      line-height: 1.7;
    }
  }

  .ing {
    color: #82BAFF !important;
  }

  .success {
    color: #7BFFA9 !important;
  }

  .cancel {
    color: #FF9D70 !important;
  }
}
</style>
