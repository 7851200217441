<template>
  <div class="container">
    <img src="@/assets/first-deposit/banner.webp" alt="" />
    <div class="title">{{ $t('first_deposit_des1') }}</div>
    <div class="rule">
      {{ $t('first_deposit_des2') }}<br />
      {{ $t('first_deposit_des3') }} {{ first_pay_info?.pay_amount }} {{ $t('first_deposit_des4') }} {{ first_pay_info?.give_amount }} {{ $t('first_deposit_des5') }}<br />
      {{ $t('first_deposit_des6') }} {{ first_pay_info?.receive_number }} {{ $t('first_deposit_des7') }} {{ first_pay_info?.receive_interval }} {{ $t('first_deposit_des8') }} {{ first_pay_info?.receive_rate * 100 }} {{ $t('first_deposit_des9') }}<br />
    </div>

    <div class="title">{{ $t('first_deposit_des10') }}</div>
    <div class="times-box" v-for="(item, index) in first_pay_info?.receive_list" :key="index">
      <div class="title">{{ showItemTitle(index) }}</div>
      <div class="money">R$ {{ item.receive_amount }}</div>
      <div class="des">{{ showItemDes(item) }}</div>
      <div
        v-if="first_pay_info?.status === 2"
        class="button"
        :class="item.receive_status === 0 ? 'disable' : item.receive_status === 1 ? 'enable' : item.receive_status === 2 ? 'end' : ''"
        @click="handleOfReceive(item)"
      >
        {{ showReceiveBtnTitle(item) }}
      </div>
    </div>
    <div class="button" :class="first_pay_info?.status === 1 ? 'button-ing' : ''" v-if="first_pay_info?.status !== 2" @click="handleOfDeposit">
      {{ first_pay_info?.status === 0 ? $t('deposit') : first_pay_info?.status === 1 ? $t('processing') : "" }}
    </div>

    <div class="title">{{ $t('first_deposit_des11') }}</div>
    <div class="example">
      {{ $t('first_deposit_des12') }}{{ first_pay_info?.pay_amount }} {{ $t('first_deposit_des13') }}<br />
      {{ $t('first_deposit_des14') }} {{ first_pay_info?.receive_rate * 100 }}{{ $t('first_deposit_des15') }} {{ first_pay_info?.pay_amount }} {{ $t('first_deposit_des16') }} {{ first_pay_info?.receive_amount * (1 + first_pay_info?.receive_rate) }} {{ $t('first_deposit_des17') }}<br />
      {{ $t('first_deposit_des18') }} {{ first_pay_info?.receive_rate }}{{ $t('first_deposit_des19') }}<br />
      {{ $t('first_deposit_des20') }} {{ first_pay_info?.receive_rate }}{{ $t('first_deposit_des21') }}<br />
      {{ $t('first_deposit_des22') }} {{ first_pay_info?.receive_rate }}{{ $t('first_deposit_des23') }}<br />
    </div>
    <dialog ref="receiveSuccessDialog">
      <div class="receive-wrapper" v-if="receiveData !== null">
        <div class="header-box">
          <div>{{ $t('prompt') }}</div>
          <img class="icon" src="@/assets/first-deposit/dialog_header_icon.webp" alt="" />
          <img class="close" src="@/assets/first-deposit/dialog_close.webp" alt="" @click="handleOfCloseDialog" />
        </div>
        <div class="title">{{ $t('first_deposit_des24') }}</div>
        <div class="money-box">
          R$ <span>{{ $tools.formatCurrency(receiveData?.amount) }}</span>
        </div>
        <div class="des">{{ $t('first_deposit_des25') }}</div>
        <div class="button" @click="handleOfCloseDialog">{{ $t('bom') }}</div>
      </div>
    </dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      first_pay_info: null,
      receiveData: null,
    };
  },
  mounted() {
    this.$emit("update:title", this.$t('first_deposit_title'));
    this.queryDetailData();
  },
  methods: {
    async queryDetailData() {
      const data = {
        action: "user_first_pay_activity_info",
        data: {},
      };
      try {
        const response = await this.$http.post("activity", data);
        this.first_pay_info = response.data.first_pay_info;
      } catch (error) {
        console.log("error", error);
      }
    },
    handleOfCloseDialog() {
      this.$refs.receiveSuccessDialog.close();
      this.receiveData = null;
    },
    async handleOfReceive(item) {
      const data = {
        action: "receive_user_first_pay_bounty",
        data: {
          receive_id: item.receive_id,
        },
      };

      this.$http
        .post("activity", data)
        .then((response) => {
          this.receiveData = response.data;
          this.$refs.receiveSuccessDialog.showModal();
          this.queryDetailData();
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    async handleOfDeposit() {
      this.$tools.userTrackReport({ track_id: "21" });

      if (this.first_pay_info?.status !== 0) {
        return;
      }

      const data = {
        action: "collection",
        data: {
          scene: "login",
          amount: 100 * 100,
          pay_method: "hkpPay",
          activity_type: 7,
          activity_id: 1,
        },
      };

      data.data.ks = this.$global.kwaiPixelId || "";
      data.data.fb = this.$global.fbPixelId || "";

      try {
        const response = await this.$http.post("pay", data);
        const orderInfo = response.data.order;
        sessionStorage.setItem("order", JSON.stringify(orderInfo));
        this.$router.push({ name: "m-pay-page", query: { give_amount: this.first_pay_info.give_amount } });
      } catch (error) {
        console.log("error", error);
      }
    },
    showItemTitle(index) {
      switch (index) {
        case 0:
          return this.$t('first_collection');

        case 1:
          return this.$t('second_collection');

        case 2:
          return this.$t('third_collection');

        case 3:
          return this.$t('4th_collection');

        default:
          return "错误";
      }
    },
    showItemDes(item) {
      if (this.first_pay_info?.status !== 2) {
        if (parseInt(item.receive_date) === 0) {
          return this.$t('on_the_date_of_the_deposit');
        } else {
          return item.receive_date + this.$t('days_after_the_deposit');
        }
      } else {
        return item.receive_date;
      }
    },
    showReceiveBtnTitle(item) {
      if (item.receive_status === 0 || item.receive_status === 1) {
        return this.$t('receber');
      } else if (item.receive_status === 2) {
        return this.$t('recebido');
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  padding: 15px 15px 0;
  text-align: left;
  padding-bottom: 20px;
  & > .title {
    margin-top: 20px;
    margin-bottom: 5px;
    color: #ffffff;
    font-size: 12px;
    font-weight: bold;
  }

  .rule {
    color: #81d6ba;
    font-size: 11px;
    font-weight: normal;
  }

  .times-box {
    margin-top: 5px;
    border: 1px solid #006a56;
    background: #015545;
    height: 40px;
    display: flex;
    align-items: center;
    border-radius: 5px;
    padding: 11.5px 10px;

    & > .title {
      color: #81d6ba;
      font-size: 11px;
      font-weight: normal;
      width: 105px;
    }

    & > .money {
      margin-right: 20px;
      color: #ffde3c;
      font-size: 11px;
      font-weight: bold;
    }

    & > .des {
      color: #ffffff;
      font-size: 11px;
      font-weight: normal;
    }

    & > .button {
      margin-left: auto;

      width: 60px;
      height: 30px;
      line-height: 30px;
      border-radius: 5px;

      font-size: 12px;
      font-weight: normal;
      text-align: center;
    }

    & > .disable {
      background: #676767;
      color: #a5a5a5;
    }

    & > .enable {
      background: #FFE600;
      color: #0B0B0B;
      font-weight: bold;
    }

    & > .end {
      background: transparent;
      color: #00cb47;
      font-weight: bold;
    }
  }

  & > .button {
    margin-top: 20px;
    background: #ffd400;
    height: 45px;
    line-height: 45px;
    border-radius: 10px;
    color: #000000;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
  }

  & > .button-ing {
    background: #029479;
    color: #ffffff;
  }

  .example {
    color: #81d6ba;
    font-size: 11px;
    font-weight: normal;
  }

  .receive-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #015545;
    border-radius: 10px;

    .header-box {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      background: #012c23;
      border-radius: 10px 10px 0px 0px;
      width: 100%;
      height: 48px;

      & > div {
        position: absolute;
        color: #ffffff;
        font-size: 12px;
        font-weight: 500;
        left: 18px;
      }

      & > .icon {
        width: 70.5px;
        height: 48px;
      }

      & > .close {
        position: absolute;
        width: 20px;
        height: 20px;
        right: 15px;
      }
    }

    & > .title {
      margin-top: 15px;
      color: #ffffff;
      font-size: 12px;
      font-weight: 500;
    }

    & > .money-box {
      margin-top: 15px;
      color: #ffffff;
      font-size: 21px;
      font-weight: bold;

      & > span {
        color: #00e550;
      }
    }

    & > .des {
      margin: 35px 60px 0;
      color: #ffffff;
      font-size: 11px;
      font-weight: 500;
      text-align: center;
    }

    & > .button {
      margin-top: 5px;
      margin-bottom: 10px;
      background: #029479;
      height: 40px;
      line-height: 40px;
      border-radius: 10px;
      width: 90%;
      color: #ffffff;
      font-size: 15px;
      font-weight: 500;
      text-align: center;
    }
  }
}
</style>
