<template>
  <div class="container">
    <MineEmpty v-if="messageList.length === 0"></MineEmpty>
    <div class="wrapper" v-for="item in messageList" :key="item.id" @click="handleOfGoDetail(item.id)">
      <van-cell is-link>
        <template #title>
          <span>{{ item.title }}</span>
          <div class="circle" :class="item.status === 0 ? 'unread' : 'read'"></div>
        </template>
      </van-cell>
      <div class="cover">
        <img :src="$global.data.cdn_url + item.cover_images" alt="">
      </div>
      <div class="desc">
        {{ item.details }}
      </div>
    </div>
    <LoadMoreComp :cur-count="curCount" :total-count="totalCount" @loadMoreEvent="fetchMessageListData"></LoadMoreComp>
  </div>
</template>

<script>
import MineEmpty from "@/components/MineEmpty.vue";
import LoadMoreComp from "@/components/LoadMoreComp.vue";
import { Cell } from 'vant';
export default {
  components: { MineEmpty, LoadMoreComp, [Cell.name]: Cell },
  data() {
    return {
      messageList: [],
      page: 0,
      curCount: 0,
      totalCount: 0,
    };
  },
  mounted() {
    this.$emit("update:title", this.$t("messages"));
    this.fetchMessageListData();
  },
  methods: {
    handleOfGoDetail(id) {
      this.$router.push("/m-message-detail/" + id);
    },
    fetchMessageListData() {
      const data = {
        action: "platform_messages",
        data: {
          page: this.page++,
        },
      };
      this.$http
        .post("userCenter", data)
        .then((response) => {
          this.messageList.push(...response.data.platform_messages);
          this.curCount = this.messageList.length;
          this.totalCount = response.data.count;
        })
        .catch((error) => {
          console.error(error);
          this.page--;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  margin: 10px 0;
  display: flex;
  flex-direction: column;

  .wrapper {
    display: flex;
    background: #006A56;
    margin: 5px 15px;
    border-radius: 10px;
    align-items: center;
    flex-direction: column;
    padding: 12px;
    box-sizing: border-box;
    color: #fff;
    font-size: 12px;

    :deep(.van-cell) {
      padding: 0;
      font-size: 12px;
      background: transparent;

      .van-cell__title {
        text-align: left;
        font-weight: bold;
        display: flex;
        align-items: center;
      }

      .van-cell__right-icon {
        color: #fff;
        font-weight: bold;
        font-size: 14px;
      }
    }

    // .icon {
    //   margin-left: 13px;
    //   margin-right: 13px;
    //   width: 21px;
    //   height: 20px;
    // }

    // .main {
    //   flex: 1;
    //   height: 70%;
    //   display: flex;
    //   flex-direction: column;
    //   justify-content: space-around;
    //   align-items: flex-start;

    //   .title {
    //     color: #ffffff;
    //     font-size: 14px;
    //     font-weight: 500;
    //   }

    //   .date {
    //     color: #3e7bb0;
    //     font-size: 10px;
    //     font-weight: 500;
    //   }
    // }
    .cover {
      height: 120px;
      margin-top: 6px;
      border-radius: 5px;
      img {
        width: 100%;
        height: 100%;
        border-radius: 5px;
      }
    }
    .desc{
      text-align: left;
      display: flex;
      width: 100%;
      margin-top: 8px;
      line-height: 1.5;
    }

    .circle {
      margin-left: 10px;
      width: 10px;
      height: 10px;
      border-radius: 5px;
    }

    .unread {
      background: #F04770;
    }

    .read {
      background: #15ad51;
    }

    // .state {
    //   margin-right: 20px;
    //   color: #ffffff;
    //   font-size: 12px;
    //   font-weight: 500;
    // }

    // .arrow {
    //   margin-right: 15px;
    //   width: 4px;
    //   height: 8px;
    // }
  }
}
</style>
