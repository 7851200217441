<template>
  <div class="container">
    <div class="wrapper">
      <div class="game-wrapper">
        <div class="game-info">
          <div class="img-box">
            <img v-show="!isImageLoading" :src="iconUrl" alt="" @load="onImageLoad" @error="onImageError" />
            <div v-show="isImageLoading" class="placeholder">
              <img src="@/assets/game_default.webp" />
              <div class="animate"></div>
            </div>
          </div>
          <div class="info-box">
            <div class="name">{{ game_info?.game_name_en }}</div>
            <div class="provider">{{ game_info?.provider_info?.provider_name }}</div>
            <div class="rtp">RTP:{{ game_info?.rtp }}%</div>
          </div>
        </div>
        <div class="btn-box">
          <div class="favorite-btn" :class="game_info?.game_collect === 1 ? 'favorite-state' : ''" @click="handleOfFavorite">
            <img src="@/assets/detail/start.webp" alt="" />
            <div>{{ $t('favorites') }}</div>
          </div>
          <div class="play-btn" @click="onTapRealHandel">{{ $t('start_game') }}</div> 
        </div>
      </div>

      <div class="recommend">
        <CardHeader
          :title="$t('recommended')"
          :icon="recommendIcon"
          :totalCount="recommendTotalCount"
          isShowMoreBtn
          isShowArrows
          @tap-left="movePrev"
          @tap-right="moveNext"
          @show-all="showAllCallBack"
        ></CardHeader>
        <HorizontalCard :current-index="recommendIndex" :game-list="recommendList"></HorizontalCard>
      </div>
      <div class="providers">
        <CardHeader
          :title="$t('game_providers')"
          isShowArrows
          :icon="providerIcon"
          @tap-left="providerListPreEvent"
          @tap-right="providerListNextEvent"
        ></CardHeader>
        <div class="list-container">
          <div class="list" v-for="(supplier, index) in supplierList" :key="index" :style="{ transform: 'translateX(' + supplierIndex * -392 + 'px)' }">
            <provider-card class="p-item" :title="supplier.provider_name" :log="supplier.provider_logo" @click="showSupplierGames(supplier)"></provider-card>
          </div>
        </div>
      </div>
    </div>
    <!-- 余额不足弹窗 -->
    <WalletEmptyModal v-show="isShowEmptyWalletTips" @close="isShowEmptyWalletTips = false" @depositEvent="handelOfDepositEvent"></WalletEmptyModal>
    <!-- 需要首次充值弹窗 -->
    <dialog ref="needFirstPayDialog">
      <div class="dialog-content">
        <div class="dialog-box">
          <div class="dialog-title">{{ $t('prompt') }}</div>
          <div class="dialog-des">{{ $t('please_complete_the_first_deposit_to_unlock_the_bonus') }}</div>
          <img class="dialog-icon" src="@/assets/user/drawing_icon.png" alt="" />
          <div class="dialog-btn" @click="handelOfDepositEvent">{{ $t('depositar') }}</div>
        </div>
        <img class="dialog-close" src="@/assets/login/close_icon.png" alt="" @click="$refs.needFirstPayDialog.close()" />
      </div>
    </dialog>
  </div>
</template>

<script>
import WalletEmptyModal from "@/components/WalletEmptyModal.vue";
import ProviderCard from "@/components/ProviderCard.vue";
import CardHeader from "@/components/CardHeader.vue";
import HorizontalCard from "@/components/HorizontalCard.vue";
export default {
  components: {
    ProviderCard,
    CardHeader,
    HorizontalCard,
    WalletEmptyModal,
  },
  data() {
    return {
      gameId: "",
      game_info: null,
      supplierList: [],
      supplierIndex: 0,
      providerIcon: "",
      recommendList: [],
      recommendIndex: 0,
      recommendIcon: "",
      recommendTotalCount: 0,
      isImageLoading: false,
      isShowEmptyWalletTips: false,
    };
  },
  beforeRouteUpdate(to, from, next) {
    this.game_info = null;
    this.isImageLoading = true;
    // 在每次路由到相同页面时执行的操作
    this.gameId = to.params.game;
    this.fetchRecommendGameListData();
    this.fetchProviderListData();
    this.fetchGameInfo();
    next();
  },
  mounted() {
    this.$emit("update:title", "Jogo");
    this.isImageLoading = true;
    this.gameId = this.$route.params.game;
    this.fetchRecommendGameListData();
    this.fetchProviderListData();
    this.fetchGameInfo();
  },
  computed: {
    iconUrl() {
      if (this.game_info === null || this.game_info?.image_url === null || this.game_info?.image_url === undefined) {
        return null;
      }
      return this.$global.data.cdn_url + this.game_info?.image_url;
    },
    userInfo() {
      return this.$store.state.userInfo;
    },
  },
  methods: {
    async handleOfFavorite() {
      const data = {
        action: "game_collect",
        data: {
          id: this.gameId,
        },
      };
      try {
        await this.$http.post("gameCenter", data);

        this.fetchGameInfo("1");
      } catch (error) {
        console.log("error", error);
      }
    },
    async fetchGameInfo(type) {
      const data = {
        action: "game_info",
        data: {
          id: this.gameId,
        },
      };
      try {
        const response = await this.$http.post("gameCenter", data);
        console.log("response", response);
        this.game_info = response.data.game_info;
        if (type == 1) {
          if (this.game_info.game_collect == 1) {
            this.$toast.success(this.$t('successful_collection'));
          } else {
            this.$toast.success(this.$t('not_subscribed_successfully'));
          }
        }
      } catch (error) {
        console.log("error", error);
      }
    },
    handelOfDepositEvent() {
      this.isShowEmptyWalletTips = false;
      this.$router.push({ name: "m-deposit-page" });
    },
    onImageLoad() {
      this.isImageLoading = false;
    },
    onImageError() {
      this.isImageLoading = false;
    },
    onTapRealHandel() {
      const data = {
        action: "query_wallet",
        data: {},
      };

      this.$http
        .post("userWallet", data)
        .then((response) => {
          const need_first_pay = response.data.need_first_pay;
          if (need_first_pay) {
            this.$refs.needFirstPayDialog.showModal();
            return;
          }
          const insufficient_balance = response.data.insufficient_balance;
          if (insufficient_balance) {
            // 余额不足
            this.isShowEmptyWalletTips = true;
          } else {
            this.$router.push({
              path: "/m-playing/" + this.gameId,
              query: { type: 1, pId: this.game_info.game_provider },
            });
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    /**
     * 供应商列表
     */
    fetchProviderListData() {
      const data = {
        action: "provider_list",
        data: {},
      };

      this.$http
        .post("gameCenter", data)
        .then((response) => {
          // 请求成功后的处理
          this.supplierList = response.data.provider_list;
          this.providerIcon = response.data.provider_icon;
        })
        .catch((error) => {
          // 请求失败后的处理
          console.error(error);
        });
    },
    /**
     * 推荐游戏列表
     */
    fetchRecommendGameListData() {
      const data = {
        action: "game_recommend",
      };

      this.$http
        .post("gameCenter", data)
        .then((response) => {
          // 请求成功后的处理
          this.recommendList = response.data.game_list;
          this.recommendIcon = response.data.recommend_icon;
          this.recommendTotalCount = response.data.count;
        })
        .catch((error) => {
          // 请求失败后的处理
          console.error(error);
        });
    },
    /**
     * 游戏供应商item点击事件
     * @param {*} providerInfo
     */
    showSupplierGames(providerInfo) {
      this.$router.push({
        path: "/m-game-list/" + providerInfo.provider_name,
        query: { id: providerInfo.id, type: "provider" },
      });
    },
    /**
     * see all按钮点击回调
     */
    showAllCallBack() {
      this.$router.push({
        path: "/m-game-list/recommend",
        query: { id: 500, type: "category" },
      });
    },
    /**
     * 右箭头事件
     */
    moveNext() {
      let gameIndex = this.recommendIndex;
      let gameCount = this.recommendList.length;

      if (gameIndex < gameCount - 1) {
        gameIndex++;
      } else {
        gameIndex = gameCount - 1;
      }
      this.recommendIndex = gameIndex;
    },
    /**
     * 左箭头事件
     */
    movePrev() {
      let gameIndex = this.recommendIndex;

      if (gameIndex > 0) {
        gameIndex--;
      } else {
        gameIndex = 0;
      }

      this.recommendIndex = gameIndex;
    },
    /**
     * 全屏显示
     */
    goFullScreen() {
      const iframe = this.$refs.myIframe;
      if (iframe.requestFullscreen) {
        iframe.requestFullscreen();
      } else if (iframe.mozRequestFullScreen) {
        // Firefox
        iframe.mozRequestFullScreen();
      } else if (iframe.webkitRequestFullscreen) {
        // Chrome, Safari and Opera
        iframe.webkitRequestFullscreen();
      } else if (iframe.msRequestFullscreen) {
        // IE/Edge
        iframe.msRequestFullscreen();
      }
    },
    goBack() {
      this.$router.back();
    },
    providerListPreEvent() {
      if (this.supplierIndex > 0) {
        this.supplierIndex--;
      } else {
        this.supplierIndex = 0;
      }
    },
    providerListNextEvent() {
      if (this.supplierIndex < this.supplierList.length - 1) {
        this.supplierIndex++;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@keyframes lazy-loading {
  0% {
    background-position-x: 150%;
  }

  100% {
    background-position-x: -150%;
  }
}

.container {
  background: transparent;
  display: flex;
  flex-direction: column;

  .wrapper {
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    margin: 0 15px;

    .game-wrapper {
      margin-top: 15px;
      background: #006a56;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      padding: 15px;

      .game-info {
        display: flex;

        .img-box {
          width: 105px;
          height: 105px;
          border-radius: 10px;
          overflow: hidden;
          display: flex;

          & > img {
            width: 100%;
            height: 100%;
          }

          .placeholder {
            width: 100%;
            height: 100%;
            background: url("@/assets/game_default.webp");
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;

            & > img {
              width: 100%;
              height: 100%;
            }

            .animate {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              background: url("@/assets/game_default2.png") transparent no-repeat;
              background-size: 200%;
              background-position-y: 40%;
              animation: lazy-loading 1.2s linear infinite;
            }
          }
        }

        .info-box {
          margin-left: 20px;
          display: flex;
          flex-direction: column;
          text-align: left;

          .name {
            color: #ffffff;
            font-size: 17px;
            font-weight: bold;
          }

          .provider {
            margin-top: 10px;
            color: #ffffff;
            font-size: 12px;
            font-weight: bold;
          }

          .rtp {
            margin-top: 35px;
            color: #00ffee;
            font-size: 12px;
            font-weight: 500;
          }
        }
      }

      .btn-box {
        margin-top: 30px;
        display: flex;

        .favorite-btn {
          display: flex;
          align-items: center;
          padding: 13px 11px;
          background: #015545;
          color: #ffffff;
          font-size: 14px;
          font-weight: bold;
          border-radius: 10px;

          & > img {
            margin-right: 5px;
            width: 15px;
            height: 15px;
          }
        }

        .favorite-state {
          background: #10b349;
        }

        .play-btn {
          margin-left: 20px;
          flex: 1;
          height: 45px;
          line-height: 45px;
          background: #ffd400;
          color: #000000;
          font-size: 14px;
          font-weight: bold;
          border-radius: 10px;
        }
      }
    }

    .recommend {
      margin-top: 30px;
      display: flex;
      flex-direction: column;
    }

    .providers {
      display: flex;
      flex-direction: column;
      margin-bottom: 80px;

      .list-container {
        margin-bottom: 18px;
        margin-top: 10px;
        overflow-x: scroll;
        display: flex;

        .list {
          display: flex;
          justify-content: flex-start;
          transition: transform 0.3s ease-in-out;
          flex-shrink: 0;

          .p-item {
            margin-right: 10px;
            width: 125px;
            height: auto;
          }
        }
      }
    }
  }

  .dialog-content {
    display: flex;
    flex-direction: column;
    align-items: center;

    .dialog-box {
      display: flex;
      flex-direction: column;

      background: #0a1f32;
      border-radius: 10px;
      padding: 20px 15px;

      .dialog-title {
        color: #ffffff;
        font-size: 18px;
        font-weight: bold;
      }

      .dialog-des {
        margin-top: 15px;
        color: #ffffff;
        font-size: 18px;
        font-weight: normal;
      }

      .dialog-icon {
        margin-top: 23px;
        align-self: center;
        width: 45px;
        height: 45px;
      }

      .dialog-btn {
        margin-top: 23px;
        background: #33439e;
        height: 40px;
        color: #ffffff;
        font-size: 18px;
        font-weight: 500;
        line-height: 40px;
        border-radius: 8px;
      }
    }

    .dialog-close {
      margin-top: 44px;
      width: 32px;
      height: 32px;
    }
  }
}

// @media (max-width: 768px) {
// }
</style>
