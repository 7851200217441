<template>
  <ModalPopupComp>
    <div class="modal-popup-content">
      <div class="invite-rules-title">
        {{ $t('rules_tips') }}
      </div>

      <div class="table">
        <div class="header">
          <div class="header-item" v-for="(x, i) in headerDataList" :key="i">
            <div class="title">{{ x.title }}</div>
            <div class="subTitles">
              <div class="subtitle" v-for="(y, j) in x.list" :key="j">
                {{ y }}
              </div>
            </div>
          </div>
        </div>
        <div class="cell-box">
          <div class="cell" v-for="(item, index) in formData" :key="index">
            <div>LV{{ item.agentLevel }}</div>
            <div>{{ item.firstPayLevel1 }}</div>
            <div>{{ item.add_bonus_first_pay_level1 }}</div>
            <div>{{ item.add_bonus_first_pay_level2 }}</div>
            <div>{{ item.add_bonus_first_pay_level3 }}</div>
          </div>
        </div>
      </div>
      <div class="invite-rules-des">
        <h1>{{ $t('rules_ex1') }}</h1>
        <div>
          {{ $t('rules_ex2') }} R$ {{ low }} a {{ high }}. {{ $t('rules_ex3') }}
        </div>
        <div>
          {{ $t('rules_ex4') }}
        </div>
      </div>
    </div>
  </ModalPopupComp>
</template>
<script>
import ModalPopupComp from "@/components/ModalPopupComp.vue";
export default {
  components: {
    ModalPopupComp,
  },
  props: {
    formData: {
      type: Array,
      default: new Array(),
    },
  },
  data() {
    return {
      headerDataList: [
        { title: this.$t('rules_grade'), list: [] },
        { title: this.$t('rules_number_of_recharges'), list: [] },
        { title: "", list: [this.$t('rules_level_1'), this.$t('rules_level_2'), this.$t('rules_level_3')] },
      ],
    };
  },
  computed: {
    low() {
      return this.formData[0].add_bonus_first_pay_level1;
    },
    high() {
      return this.formData[this.formData.length - 1].add_bonus_first_pay_level1;
    },
  },
};
</script>
<style lang="scss" scoped>
.modal-popup-content {
  display: flex;
  flex-direction: column;
  .invite-rules-title {
    margin-top: 22px;
    font-size: 12px;
    font-weight: 500;
    color: var(--color-popup-title);
    padding: 0 45px;
    margin-bottom: 14px;
  }
  .invite-rules-des {
    margin: 20px;
    font-size: 12px;
    font-weight: 300;
    color: #81d6ba;
    text-align: left;
    line-height: 2;
    h1 {
      color: #fff;
      font-weight: bold;
      margin: 0;
      line-height: 1;
      font-size: 12px;
      margin-bottom: 6px;
    }
  }
  .table {
    margin: 0 20px 0 16px;
    display: flex;
    flex-direction: column;
    .header {
      border-radius: 4px 4px 0px 0px;
      background: #004134;
      height: 50px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      margin-bottom: 6px;
      border-radius: 5px;
      .header-item:last-of-type {
        width: 60%;
      }
      .header-item {
        width: 20%;
        display: flex;
        flex-direction: column;

        .title {
          color: #81d6ba;
          font-weight: 300;
          font-size: 11px;
        }

        .subTitles {
          display: flex;
          justify-content: space-around;
          .subtitle {
            color: #81d6ba;
            font-weight: 300;
            font-size: 11px;
          }
        }
      }
    }

    .cell-box {
      border-radius: 0px 0px 4px 4px;
      padding: 10px 0;
      background: #004134;
      border-radius: 5px;
      .cell {
        height: 30px;
        display: flex;
        justify-content: space-around;
        div {
          flex: 1;
          font-size: 12px;
          font-weight: 300;
          color: #ffffff;
          opacity: 0.8;
        }
      }
    }
  }
}
</style>
