<template>
  <div class="general">
    <div class="form">
      <div class="section">
        <div class="title-box">
          <div class="title">{{ $t('routine') }}</div>
        </div>
        <div class="list">
          <div class="item">
            <img class="icon" src="@/assets/account/name_icon.webp" alt="" />
            <input type="text" :placeholder="$t('enter_real_name')" v-model="nickname"
              @blur="handleOfEndNickNameInput" />
          </div>
          <div class="item">
            <img class="icon" src="@/assets/account/gender_icon.webp" alt="" />
            <div class="gender">{{ $t('gender') }}</div>
            <img class="gender-mark" :src="sex === 1
                ? require('@/assets/account/gender_selected_icon.webp')
                : require('@/assets/account/juxing.webp')
              " alt="" @click="sex = 1" />
            <div class="gender-text" @click="sex = 1">
              <span :class="sex==1?'bai':''">{{ $t('man') }}</span>
            </div>
            <img class="gender-mark" :src="sex === 2
                ? require('@/assets/account/gender_selected_icon.webp')
                : require('@/assets/account/juxing.webp')
              " alt="" @click="sex = 2" />
            <div class="gender-text" @click="sex = 2">
              <span :class="sex==2?'bai':''">{{ $t('woman') }}</span>
            </div>
          </div>
          <div class="item">
            <img class="icon" src="@/assets/account/addr_icon.webp" alt="" />
            <input type="text" :placeholder="$t('enter_the_city_where_you_are_located')" v-model="address" @blur="handleOfEndAddressInput" />
          </div>
        </div>
      </div>
      <div class="section">
        <div class="title-box">
          <div class="title">{{ $t('e-mail') }}</div>
          <div class="tag" :class="userData?.email_verify === 1 ? 'green-tag' : 'red-tag'">
            {{ userData?.email_verify === 1 ? $t('Binding') :  $t('unbound') }}
          </div>
        </div>
        <div class="list">
          <div class="item" @click="handleOfVerify(1)">
            <img class="icon" src="@/assets/account/email_icon.webp" alt="" />
            <div class="placeholder" v-if="email === '' || email === null">{{ $t('enter_email_address') }}</div>
            <div class="value" v-else>{{ email }}</div>
            <div class="btn" v-if="userData?.email_verify !== 1">{{ $t('binding') }}</div>
          </div>
        </div>
      </div>
      <div class="section">
        <div class="title-box">
          <div class="title">{{ $t('telephone') }}</div>
          <div class="tag" :class="userData?.phone_verify === 1 ? 'green-tag' : 'red-tag'">
            {{ userData?.phone_verify === 1 ? $t('Binding') :  $t('unbound') }}
          </div>
        </div>
        <div class="list">
          <div class="item" @click="handleOfVerify(2)">
            <img class="icon" src="@/assets/account/phone_icon.webp" alt="" />
            <div class="placeholder" v-if="phone === '' || phone === null">{{ $t('enter_your_phone_number') }}</div>
            <div :class="phone ? 'value' : 'placeholder'" v-else>{{ phone || $t('enter_your_phone_number') }}</div>
            <div class="btn" v-if="userData?.phone_verify !== 1">{{ $t('binding') }}</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 验证手机or邮箱弹窗 -->
    <dialog ref="verifyDialog">
      <VerifyDialogForPhoneOrEmail :verifyType="verifyType" :phone="userData?.phone" :email="userData?.email"
        @closeEvent="handleOfVerifyDialogClose"></VerifyDialogForPhoneOrEmail>
    </dialog>
  </div>
</template>

<script>
import VerifyDialogForPhoneOrEmail from "@/components/VerifyDialogForPhoneOrEmail.vue";
export default {
  components: {
    VerifyDialogForPhoneOrEmail,
  },
  data() {
    return {
      userData: null,
      nickname: "",
      address: "",
      sex: 0, // 1男 2女 0未知
      email: "",
      phone: "",
      verifyType: 2, // 1邮箱 2手机号
    };
  },
  watch: {
    sex(val) {
      this.updateUserInfo({ sex: val });
    },
  },
  mounted() {
    this.getUserInfo();
  },
  methods: {
    handleOfVerifyDialogClose() {
      this.$refs.verifyDialog.close();
      this.userData = null;
      this.getUserInfo();
    },
    handleOfVerify(type) {
      if (type === 1 && this.userData.email_verify === 1) {
        return;
      }

      if (type === 2 && this.userData.phone_verify === 1) {
        return;
      }

      this.verifyType = type;
      this.$refs.verifyDialog.showModal();
    },
    handleOfEndNickNameInput() {
      this.updateUserInfo({ nickname: this.nickname });
    },
    handleOfEndAddressInput() {
      this.updateUserInfo({ address: this.address });
    },
    getUserInfo() {
      const data = {
        action: "my_account",
      };
      this.$http
        .post("userCenter", data)
        .then((response) => {
          const data = response.data.data;
          this.userData = data;
          this.nickname = data.nickname;
          this.sex = data.sex;
          this.address = data.address;
          this.email = data.email;
          // 手机号大于11位，保留后11位
          this.phone = data.phone.length > 11 ? data.phone.substr(data.phone.length - 11, 11) : data.phone;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    updateUserInfo(params) {
      const data = {
        action: "update_user",
        data: params,
        hiddenLoad: true //加上该字段，将不展示loading动画
      };
      this.$http
        .post("userCenter", data)
        .then(() => {
          this.getUserInfo();
        })
        .catch((error) => {
          // 请求失败后的处理
          console.error(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.general {
  display: flex;
  flex-direction: column;

  .form {
    display: flex;
    flex-direction: column;

    .section {
      margin-top: 20px;
      display: flex;
      flex-direction: column;

      .title-box {
        display: flex;
        align-items: center;

        .title {
          margin-left: 15px;
          font-size: 14px;
          font-weight: 500;
          color: #ffffff;
          text-align: left;
        }

        .tag {
          margin-left: 22px;
          height: 20px;
          line-height: 20px;
          border-radius: 4px;
          color: #ffffff;
          font-size: 12px;
          font-weight: 500;
          padding: 2px 5px;
        }

        .green-tag {
          background: #10b349;
        }

        .red-tag {
          background: #ff5000;
        }
      }

      .list {
        display: flex;
        flex-direction: column;
        margin-top: 10px;
        background: #006A56;
        padding: 12.5px;
        border-radius: 8px;

        .item {
          height: 40px;
          display: flex;
          align-items: center;
          background: #004134;
          border-radius: 8px;
          margin-top: 10px;
          font-size: 12px;
          font-weight: 300;
          padding: 0 15px;

          &:first-child {
            margin-top: 0;
          }

          .icon {
            width: 15px;
            height: auto;
          }

          &>input {
            margin: 0 11px;
            flex: 1;
            background: transparent;
            color: #ffffff;
            outline-style: none;
            border: 0;

            &::placeholder {
              color: #00886E;
            }
          }

          .gender {
            margin-left: 11px;
            margin-right: auto;
            color: #00886E;
            font-size: 12px;
            font-weight: 500;
          }

          .gender-mark {
            width: 15px;
            height: auto;
          }

          .gender-text {
            margin-left: 5px;
            margin-right: 7.5px;
            color: #00886E;
            font-size: 10px;
            font-weight: 500;
          }
          .bai{
            color: #fff;
          }

          &>.placeholder {
            margin: 0 11px;
            flex: 1;
            text-align: left;
            color: #00886E;
          }

          &>.value {
            margin: 0 11px;
            flex: 1;
            color: #ffffff;
            text-align: left;
          }

          &>.btn {
            background: #029479;
            border-radius: 6px;
            width: 74px;
            height: 28px;
            line-height: 28px;
            color: #ffffff;
            font-size: 12px;
            font-weight: 500;
          }
        }
      }
    }
  }
}
</style>
