<template>
  <div class="app-wrapper main-content" id="appContainer">
    <router-view :registerGiveCoins="register_give_coins"></router-view>
    <dialog ref="taskDialog">
      <div class="task-dialog-wrapper">
        <div class="header">
          <div>{{ $t('prompt') }}</div>
          <img src="@/assets/tanhao.webp" alt="" />
          <img src="@/assets/account/close.webp" alt="" @click="$refs.taskDialog.close()" />
        </div>
        <div class="desc">
          {{ $t('your_account_has_been_accessed_on_another_device') }}
          <br />
          {{ $t('if_it_wasn_t_you_please_log_in_again') }}
        </div>
        <div class="btns" @click="handleLog()">{{ $t('bom') }}</div>
      </div>
    </dialog>
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      globalTimer: null,
      countDown: 600,
      register_give_coins: 0,
      hbTimer: null,
    };
  },
  created() {
    this.fireHeartBeat();
    // this.getCountry();
    this.getGlobalData();
    this.$eventBus.on("fireGlobalTimer", () => {
      if (this.globalTimer === null) {
        this.globalTimer = setInterval(() => {
          this.countDown--;
          this.$eventBus.emit("globalCountDown", this.countDown);
          if (this.countDown === 0) {
            this.clearGlobalTimer();
          }
        }, 1000);
      }
    });
    this.$eventBus.on("clearGlobalTimer", () => {
      this.clearGlobalTimer();
    });
    this.$eventBus.on("logoutSuccessHandle", () => {
      // 打开单点登录提示弹窗
      this.$refs.taskDialog.showModal();
      this.clearGlobalTimer();
    });
    this.$eventBus.on("loginSuccessHandle", this.sendHeartBeat);
  },
  mounted() {
    // 初始化时根据屏幕大小设置 CSS 变量
    this.updateCssVariables();
    // 监听窗口大小变化
    window.addEventListener("resize", this.updateCssVariables);
    // 在页面即将刷新时执行的操作
    window.addEventListener("beforeunload", this.beforeUnloadHandle);
    // 阻止双指缩放
    document.addEventListener(
      "touchstart",
      function (event) {
        if (event.touches.length > 1) {
          event.preventDefault();
        }
      },
      { passive: false }
    );
  },
  beforeUnmount() {
    this.clearGlobalTimer();
    this.clearHbTimer();
  },
  methods: {
    updateCssVariables() {
      // const height = window.innerHeight;
      const root = document.documentElement;
      // 这里可以调整宽度的比例系数（例如 0.1）
      // const maxWidthValue = (height > 638 ? height : 638) * 0.6 + 'px';
      root.style.setProperty("--max-width", 480 + "px");
    },
    handleLog() {
      this.$refs.taskDialog.close();
      this.$router.push("/login-page");
    },
    clearHbTimer() {
      if (this.hbTimer) {
        clearInterval(this.hbTimer);
      }
    },
    fireHeartBeat() {
      this.hbTimer = setInterval(() => {
        const isLoggedIn = this.$store.getters.isLoggedIn;
        if (isLoggedIn) {
          this.sendHeartBeat();
        }
      }, 60000);
    },
    async sendHeartBeat() {
      const data = {
        action: "user_heartbeat_report",
        data: {},
      };
      this.$http.post("heartbeat", data);
    },
    clearGlobalTimer() {
      if (this.globalTimer) {
        clearInterval(this.globalTimer);
      }
      this.countDown = 600;
    },
    /**
     * 在页面即将刷新时执行的操作
     */
    beforeUnloadHandle() {
      console.log("Page is about to be refreshed!");
      this.$store.commit("clearTempCache");
      this.clearGlobalTimer();
      this.clearHbTimer();
    },
    /**
     * 获取全局数据
     */
    getGlobalData() {
      this.$http
        .post("load", {})
        .then((response) => {
          this.$store.commit("setChannel", response.data.resources.channel);
          this.register_give_coins = response.data.resources.register_give_coins | 0;
          this.$global.data = response.data.resources;
          this.$global.is_login = response.data.is_login;
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    getCountry() {
      const that = this;
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(function (position) {
          let lat = position.coords.latitude;
          let lon = position.coords.longitude;
          fetch(`https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${lat}&longitude=${lon}&localityLanguage=en`)
            .then((response) => response.json())
            .then((data) => {
              that.$global.country = data.countryName;
            })
            .catch((error) => console.log(error));
        });
      }
    },
  },
};
</script>

<style>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}
#app {
  touch-action: manipulation;
  /* 防止双击放大而不禁止缩放 */
  width: 100%;
  height: 100%;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.app-wrapper {
  background: #015545;
  max-width: var(--max-width);
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* @media (max-width: 768px) {
  #app {
    width: 100vw;
    height: 100vh;
  }
} */
</style>
<style>
body,
html {
  overflow: hidden;
}

#app {
  position: relative;
  margin: 0 auto;
  max-width: var(--max-width);
  min-height: 100vh;
  background: #015545;
  cursor: pointer;
}

.main-content {
  margin: 0 auto;
  max-width: var(--max-width);
  height: 100vh;
  overflow-y: scroll;
  position: relative;
}

/* 屏幕宽度大于 480 像素时的样式 */
@media (min-width: 480px) {
  .float-box,
  .float2-box {
    position: fixed;
    /* 或其他适合的大屏幕样式 */
    right: 50% !important;
    /* 根据需要调整 */
    margin-right: -234px !important;
    /* 或其他适合的大屏幕样式 */
  }
}
</style>
<style lang="scss" scoped>
.task-dialog-wrapper {
  display: flex;
  flex-direction: column;
  width: 320px;
  height: 204px;
  background: #015545;
  border-radius: 10px;

  & > .header {
    border-radius: 10px 10px 0px 0px;
    background: #012c23;
    height: 48px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 13px 0 23px;

    & > div:first-child {
      color: #81d6ba;
      font-size: 12px;
      font-weight: 500;
    }

    & > img:first-of-type {
      width: 70px;
      height: 48px;
    }

    & > img:last-of-type {
      width: 20px;
      height: 20px;
    }
  }

  .desc {
    color: #fff;
    font-size: 12px;
    text-align: center;
    padding: 0 12px;
    font-weight: 500;
    margin: 35px 0 10px 0;
  }

  .btns {
    width: 90%;
    margin: auto;
    height: 40px;
    background: #ffd400;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    color: #06131c;
    font-weight: 500;
  }
}
</style>
