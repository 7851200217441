import { createApp, reactive, h } from "vue";
import VerifyDialogForPhoneOrEmail from "@/components/VerifyDialogForPhoneOrEmailRender.vue";
import router from "@/router/index";
import http from "@/utils/http";
import { createI18n } from "vue-i18n";
import cn from "@/locales/cn.json";
import en from "@/locales/en.json";
import br from "@/locales/bra.json";
const messages = {
  cn,
  en,
  br,
};

const i18n = createI18n({
  // locale: "cn",
  locale: localStorage.getItem("language") || "en",
  fallbackLocale: "en",
  messages,
});
const params = reactive({
  title: i18n.global.t('bind_phone'),
  verifyType: 2,
  phone: "",
  onCloseEvent: () => {
    withdrawTools.hide();
  },
  onEndVerifyEvent: () => {
    router.push({ name: "set-password", query: { type: "0", from: "mine" } });
  },
  prompt:i18n.global.t('prompt'),
  enter_your_phone_number:i18n.global.t('enter_your_phone_number'),
  enter_email_address:i18n.global.t('enter_email_address'),
  enter_verification_code:i18n.global.t('enter_verification_code'),
  obter:i18n.global.t('obter'),
  binding:i18n.global.t('binding'),
});

// 初始化对话框和 Vue 应用实例变量
let dialog = null;
let appInstance = null;
let _amountType = 1; // 提款类型 1现金 2佣金 3奖金提现任务
let closeEventCallback = null;

function createDialog() {
  dialog = document.createElement("dialog");
  document.body.appendChild(dialog);
}

function fetchUserInfo() {
  const data = {
    action: "my_account",
    data: {},
  };

  http
    .post("userCenter", data)
    .then((response) => {
      const userInfo = response.data.data;
      if (userInfo.pay_out_pwd_set) {
        router.push({
          name: "m-withdrawal-page",
          query: {
            amountType: _amountType,
          },
        });
      } else {
        withdrawTools.show(userInfo.phone);
      }
    })
    .catch((error) => {
      console.log("error", error);
    });
}

const withdrawTools = {
  withdrawEvent(amountType, closeCallback) {
    _amountType = amountType;
    closeEventCallback = closeCallback;
    fetchUserInfo();
  },
  show(phone) {
    createDialog();
    if (appInstance) {
      appInstance.unmount();
      dialog.innerHTML = "";
    }
    params.phone = phone;

    appInstance = createApp({
      
      render() {
        return h(VerifyDialogForPhoneOrEmail, params);
      },
    });
    appInstance.mount(dialog);
    dialog.showModal();
  },

  hide() {
    if (closeEventCallback) {
      closeEventCallback();
    }

    if (dialog) {
      dialog.close();
    }
  },
};

export { withdrawTools };
