<template>
  <div class="container">
    <MineEmpty v-if="listData?.data_list.length === 0"></MineEmpty>
    <div class="list" v-else>
      <ul>
        <li v-for="(item, index) in listData?.data_list" :key="index">
          <div class="li-top">
            <span :class="item.task_type === 1 ? 'yellow' : 'green'">+{{ $tools.formatCurrency(parseFloat(item.amount)) }}</span>
            <span :class="item.status === 1 ? 'white' : item.status === 2 ? 'blue' : 'green'">{{
              item.status === 1 ? $t('waiting_for_betting') : item.status === 2 ? $t('em_andamento') : $t('concluído')
            }}</span>
          </div>
          <div class="li-center">
            <span
              >{{ $t('effective_betting_is_required') }}: <i> {{ $tools.formatCurrency(parseFloat(item.task_amount)) }}</i></span
            >
            <span>x {{ item.task_rate }} {{ $t('multiple') }}</span>
          </div>
          <div class="li-bottom">
            <span>{{ $t('source') }}: {{ item.task_title }}</span>
            <span>{{ item.done_time }}</span>
          </div>
        </li>
      </ul>
      <LoadMoreComp :curCount="listData?.data_list.length" :totalCount="listData?.count" @loadMoreEvent="queryData()"> </LoadMoreComp>
    </div>
  </div>
</template>
<script>
import MineEmpty from "@/components/MineEmpty.vue";
import LoadMoreComp from "@/components/LoadMoreComp.vue";
export default {
  components: { MineEmpty, LoadMoreComp },
  data() {
    return {
      listData: null,
      page: -1,
    };
  },
  mounted() {
    this.$emit("update:title", this.$t('bet_task_page_title'));
    this.queryData();
  },
  methods: {
    async queryData() {
      const page = this.page + 1;
      const data = {
        action: "user_pay_out_task_list",
        data: {
          page,
        },
      };
      try {
        const response = await this.$http.post("userWallet", data);
        console.log("response", response);
        this.listData = response.data;
        this.page = this.listData.page;
      } catch (error) {
        console.log("error", error);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  padding: 0 15px;

  // .item {
  //   display: flex;
  //   justify-content: space-between;
  //   background: #0a1f32;
  //   border-radius: 4px;
  //   height: 80px;
  //   padding: 15px;
  //   margin-bottom: 5px;

  //   & > .box {
  //     display: flex;
  //     flex-direction: column;
  //     justify-content: space-between;

  //     & > .title {
  //       color: #96a7c6;
  //       font-size: 11px;
  //       font-weight: 500;
  //     }

  //     & > .value {
  //       font-size: 18px;
  //       font-weight: bold;
  //     }

  //     & > .multiple {
  //       color: #ffffff;
  //       font-size: 12px;
  //       font-weight: 500;
  //     }

  //     & > .info {
  //       color: #96a7c6;
  //       font-size: 11px;
  //       font-weight: 500;
  //     }

  //     .des {
  //       color: #ffffff;
  //       font-size: 12px;
  //       font-weight: 500;
  //     }

  //     .yellow {
  //       color: #ffd400;
  //     }

  //     .green {
  //       color: #01b740;
  //     }

  //     .white {
  //       color: #ffffff;
  //     }

  //     .blue {
  //       color: #277ce6;
  //     }
  //   }

  //   & > .box:first-of-type {
  //     align-items: flex-start;
  //   }

  //   & > .box:last-of-type {
  //     align-items: flex-end;
  //   }
  // }
  .list {
    margin-top: 15px;

    ul {
      li {
        height: 100px;
        background: #006a56;
        border: 1px solid #007a63;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        padding: 8px 10px;
        box-sizing: border-box;
        margin-bottom: 12px;

        .li-top {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-bottom: 1px solid #007a63;
          padding-bottom: 4px;

          span {
            font-size: 18px;
            color: #7bffa9;
            font-weight: bold;

            &:last-child {
              font-size: 12px;
              color: #ffffff;
            }
          }
        }

        .li-center {
          display: flex;
          justify-content: space-between;
          font-size: 12px;
          line-height: 3;
          color: #00c6a1;

          span {
            font-weight: 500;

            i {
              font-style: normal;
              color: #fff;
            }

            &:last-child {
              color: #fff;
            }
          }
        }

        .li-bottom {
          display: flex;
          justify-content: space-between;
          font-size: 11px;
          color: #00c6a1;
          font-weight: 500;
        }
      }

      .yellow {
        color: #ffd400 !important;
      }

      .green {
        color: #7bffa9 !important;
      }

      .white {
        color: #ffffff !important;
      }

      .blue {
        color: #ffe97b !important;
      }
    }
  }
}
</style>
