<template>
  <div class="launcher">
    <img class="logo" src="@/assets/launch/logo.webp" alt="" />
    <!-- <div class="loading">
      <img src="@/assets/launch/icon.webp" alt="" />
      <div>Carregamento em curso</div>
    </div> -->
  </div>
</template>
<script>
export default {
  mounted() {
    if ("uc" in this.$route.query) {
      sessionStorage.setItem("uc", this.$route.query.uc);
    }
    setTimeout(() => {
      this.$router.push({ name: "casino", query: this.$route.query });
    }, 3000);
  },
};
</script>
<style lang="scss" scoped>
.launcher {
  width: 100vw;
  height: 100vh;
  background: #015545;
  display: flex;
  flex-direction: column;
  align-items: center;

  .logo {
    margin-top: 191px;
    width: 220px;
    height: 45px;
  }

  .loading {
    margin-top: 69px;
    display: flex;
    flex-direction: column;
    align-items: center;

    & > img {
      width: 40px;
      height: auto;
    }

    & > div {
      margin-top: 6px;
      color: #ffd400;
      font-size: 12px;
      font-weight: bold;
    }
  }
}
</style>
