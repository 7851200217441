<template>
  <div class="wrapper" :style="{ height: $tools.isMobile() ? '90%' : '100%' }">
    <iframe v-if="gameHtml !== ''" :srcdoc="gameHtml" width="100%" height="100%" frameborder="0"></iframe>
    <iframe v-else :src="gameUrl" width="100%" height="100%" frameborder="0"></iframe>
    <img class="back" src="@/assets/back2.png" alt="" @click="backHandle" />
    <img class="wallet" src="@/assets/detail/wallet.webp" alt="" @click="handleOfWallet" />
  </div>
  <!-- 支付弹窗 -->
  <dialog ref="payDialog">
    <div class="dialog-pay-wrapper">
      <div class="header-box">
        <div>Depósito</div>
        <img class="icon" src="@/assets/detail/dialog_wallet_icon.webp" alt="" />
        <img class="close" src="@/assets/detail/close.webp" alt="" @click="$refs.payDialog.close()" />
      </div>
      <DepositCollectComp @depositEvent="handleOfDeposit"></DepositCollectComp>
    </div>
  </dialog>
  <!-- 支付二维码弹窗 -->
  <ModalPopupCenterComp v-if="orderInfo !== null">
    <div class="dialog-qr-code-wrapper">
      <div class="header-box">
        <div>PIX</div>
        <img class="icon" src="@/assets/detail/pix.webp" alt="" />
        <img class="close" src="@/assets/detail/close.webp" alt="" @click="handleOfCloseQrCode" />
      </div>
      <div class="content-box">
        <div class="box1">
          <div class="id">{{ orderInfo.orderId }}</div>
          <div class="line"></div>
          <div class="money-box">
            <div class="title">Valor</div>
            <div class="value">{{ $tools.formatCurrency(orderInfo?.amount / 100) }}&nbsp;R$</div>
          </div>
        </div>
        <div class="box2">
          <div class="des">
            Abra o app com sua chave PlX cadastradaescolha Pagar com Pix e escaneie o QR Code ou copie e cole o código.
            Código de pagamento único, por favor,
            nǎo pague repetidamente, isso causará depósito anormal.
          </div>
          <QrcodeVue class="qr-code" :value="orderInfo?.qrcodeRaw" :size="200"></QrcodeVue>
          <div class="btn" @click="handleOfCopy">
            <div>PIX CÓPIA É COLA</div>
            <img src="@/assets/pay/copy.webp" alt="" />
          </div>
        </div>
      </div>
    </div>
  </ModalPopupCenterComp>
</template>

<script>
import ModalPopupCenterComp from "@/components/ModalPopupCenterComp.vue";
import DepositCollectComp from "@/components/DepositCollectComp.vue";
import QrcodeVue from "qrcode.vue";
import clipboard from "clipboard";
export default {
  components: {
    QrcodeVue,
    DepositCollectComp,
    ModalPopupCenterComp,
  },
  data() {
    return {
      gameUrl: "",
      gameHtml: "",
      id: "",
      type: 0,
      providerId: "",
      payTabIndex: 0,
      payInfoData: null,
      selRechargeIndex: -1,
      dailyDealIndex: 0,
      orderInfo: null,
      userOrder: null
    };
  },
  mounted() {
    this.id = this.$route.params.id;
    this.providerId = this.$route.query.pId;
    this.type = parseInt(this.$route.query.type);
    this.setupData();
  },
  methods: {
    handleOfCloseQrCode() {
      window.location.reload();
    },
    handleOfCopy() {
      clipboard.copy(this.orderInfo.qrcodeRaw);
      this.$toast.success(this.$t('transaction_ID_copied'));
    },
    handleOfDeposit(order, userOrder) {
      this.orderInfo = order;
      this.userOrder = userOrder;
      this.$refs.payDialog.close();
    },
    handleOfWallet() {
      this.$refs.payDialog.showModal();
    },
    backHandle() {
      this.$router.push("/m-game-details/" + this.id);
    },
    setupData() {
      const params = { id: this.id, game_type: this.type };
      this.$http
        .post("updateSession", { data: params })
        .then((response) => {
          // 请求成功后的处理
          const data = response.data;

          if ("game_html" in data && data.game_html !== "" && data.game_html !== null) {
            this.gameHtml = data.game_html;
            return;
          }

          // 如果有game_url，直接使用
          if ("game_url" in data && data.game_url !== "" && data.game_url !== null) {
            this.gameUrl = data.game_url;
            return;
          }

          // 没有需要自己拼接
          const userInfo = this.$store.state.userInfo;
          let pgparamStr = "";
          const pgparam = userInfo.pgparam;
          for (let key in pgparam) {
            if (key === "game_url") {
              continue;
            }
            if (pgparam[key] != null && Object.prototype.hasOwnProperty.call(pgparam, key)) {
              pgparamStr += "&" + key + "=" + pgparam[key];
            }
          }
          let url =
            "https://m.pgsoft-games.com/" +
            this.id +
            "/index.html?ops=" +
            data.ops +
            "&op=" +
            data.ocp +
            "&btt=1" +
            pgparamStr +
            "&__refer=" +
            pgparam.game_url +
            "&or=static.pgsoft-games.com&__hv=1f8e1d3b";
          this.gameUrl = url;
        })
        .catch((error) => {
          console.error("error: ", error);
          this.$router.go(-1);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.wrapper {
  position: relative;
  width: 100%;
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .back {
    position: absolute;
    z-index: 100;
    top: 14px;
    left: 15px;
    width: 40px;
    height: 40px;
  }

  .wallet {
    position: absolute;
    z-index: 100;
    top: 14px;
    left: 65px;
    width: 40px;
    height: 40px;
  }
}

.dialog-pay-wrapper {
  width: 320px;
  height: 538px;
  background: #015545;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .header-box {
    height: 48px;
    background: #012c23;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    &>div {
      position: absolute;
      color: #81d6ba;
      font-size: 12px;
      font-weight: 500;
      left: 23px;
      top: 15px;
    }

    .icon {
      width: 67px;
      height: 47px;
    }

    .close {
      position: absolute;
      width: 20px;
      height: 20px;
      top: 14px;
      right: 15px;
    }
  }
}

.dialog-qr-code-wrapper {
  width: 320px;
  background: #015545;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .header-box {
    height: 48px;
    background: #012c23;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    &>div {
      position: absolute;
      color: #81d6ba;
      font-size: 12px;
      font-weight: 500;
      left: 23px;
      top: 15px;
    }

    .icon {
      width: 67px;
      height: 47px;
    }

    .close {
      position: absolute;
      width: 20px;
      height: 20px;
      top: 14px;
      right: 15px;
    }
  }

  &>.content-box {
    display: flex;
    flex-direction: column;
    padding: 15px 10px;

    &>.box1 {
      background: #ffffff;
      border-radius: 10px;
      min-height: 45px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 5px 10px 8px;

      &>.id {
        color: #9e9e9e;
        font-size: 10px;
        font-weight: 500;
        text-align: right;
      }

      &>.line {
        margin-top: 8px;
        min-height: 1px;
        background-color: #d8d8d8;
      }

      &>.money-box {
        margin-top: 5px;
        display: flex;
        justify-content: space-between;

        &>.title {
          color: #000000;
          font-size: 12px;
          font-weight: bold;
        }

        &>.value {
          color: #000000;
          font-size: 18px;
          font-weight: bold;
        }
      }
    }

    &>.box2 {
      margin-top: 5px;
      background: #ffffff;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      padding: 5px 10px 10px;

      &>.des {
        color: #000000;
        font-size: 9px;
        font-weight: normal;
        text-align: left;
      }

      &>.qr-code {
        align-self: center;
        margin-top: 30px;
        width: 147px;
        width: 145px;
      }

      &>.btn {
        margin-top: 40px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #ebaf5b;
        height: 40px;
        border-radius: 10px;
        padding: 0 12px;

        &>div {
          color: #ffffff;
          font-size: 12px;
          font-weight: bold;
        }

        &>img {
          width: 16px;
          height: 17px;
        }
      }
    }
  }
}
</style>
