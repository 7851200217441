<template>
  <div class="container">
    <div class="top-wrapper">
      <div class="info">
        <img :src="opts[selIndex].icon" alt="" />
        <div>{{ opts[selIndex].des }}</div>
      </div>
      <div class="opts-wrapper" @click="isShowOpts = true">
        <div class="selected">
          <div>{{ opts[selIndex].name }}</div>
          <img src="@/assets/download/arrow_down.webp" alt="" />
        </div>
        <div class="opts" v-if="isShowOpts">
          <div class="list">
            <div class="item" v-for="(item, i) in opts" :key="i" @click.stop="handleOfOptsItem(i)">
              {{ item.name }}
            </div>
          </div>
          <img src="@/assets/download/arrow_up.webp" alt="" @click.stop="isShowOpts = false" />
        </div>
      </div>
    </div>
    <div class="main">
      <div class="list">
        <div class="item" v-for="(item, index) in opts[selIndex].info" :key="index">
          <div class="text">{{ item.text }}</div>
          <div class="img-box">
            <img :src="item.img" alt="" />
          </div>
          <div class="btn" v-if="item.btnText !== ''" @click="handleOfDownLoad">{{ item.btnText }}</div>
        </div>
      </div>
    </div>
    <div class="award-wrapper" v-if="!isFinishNewerTask">
      <div class="des">{{ $t('safari_des6') }}</div>
      <div class="btn" @click="$router.push({ name: 'newer-task' })">{{ $t('claim_a_bonus_of_5') }}</div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    let selIndex = 1;
    if (this.$tools.isiOSDevice()) {
      selIndex = 0;
      this.$tools.newerActivityFinish({ download_app_finish: 1 });
    }
    return {
      opts: [
        {
          name: "Safari",
          des: this.$t('safari_browser'),
          icon: require("@/assets/download/safari.webp"),
          info: [
            {
              text: this.$t('safari_des1'),
              img: require("@/assets/download/ios1.webp"),
              btnText: "",
            },
            {
              text: this.$t('safari_des2'),
              img: require("@/assets/download/ios2.webp"),
              btnText: "",
            },
            {
              text: this.$t('safari_des3'),
              img: require("@/assets/download/ios3.webp"),
              btnText: "",
            },
            {
              text: this.$t('safari_des4'),
              img: require("@/assets/download/ios4.webp"),
              btnText: "",
            },
            {
              text: this.$t('safari_des5'),
              img: null,
              btnText: "",
            },
          ],
        },
        {
          name: "GooglePlay PWA",
          des: this.$t('google_play_pwa'),
          icon: require("@/assets/download/google_play.webp"),
          info: [
            {
              text: this.$t('google_play_pwa_des1'),
              img: require("@/assets/download/pwa1.webp"),
              btnText: "Baixar PWA",
            },
            {
              text: this.$t('google_play_pwa_des2'),
              img: require("@/assets/download/pwa2.webp"),
              btnText: "",
            },
            {
              text: this.$t('google_play_pwa_des3'),
              img: require("@/assets/download/pwa3.webp"),
              btnText: "",
            },
            {
              text: this.$t('google_play_pwa_des4'),
              img: null,
              btnText: "",
            },
          ],
        },
        {
          name: "Android APK",
          des: this.$t('android_APK'),
          icon: require("@/assets/download/android.webp"),
          info: [
            {
              text: this.$t('android_APK_des1'),
              img: require("@/assets/download/apk1.webp"),
              btnText: "Baixar APK",
            },
            {
              text: this.$t('android_APK_des2'),
              img: require("@/assets/download/apk2.webp"),
              btnText: "",
            },
          ],
        },
      ],
      selIndex,
      isShowOpts: false,
      pwaUrl:
        "https://pwa.eg-wintop.com/2663670955/2663670955_content.html?channel_id=4&rb_pixel_id=1506655133272254&promote_url_id=7550253154&rb_tid=0&invite_code=",
      apkUrl: this.$global.data.cdn_url + this.$global.data.apk_down_url,
      isFinishNewerTask: true,
    };
  },
  mounted() {
    this.$emit("update:title", "Baixar APP");
    this.fetchNewerTaskInfo();
  },
  methods: {
    async fetchNewerTaskInfo() {
      const data = {
        action: "new_user_active_info",
        data: {},
      };
      const response = await this.$http.post("activity", data);
      const info = response.data.new_user_active_info;
      if (info === null) {
        return;
      }

      this.isFinishNewerTask = Object.keys(info).every((key) => {
        return key.endsWith("finish") && info[key] === 1;
      });
    },
    handleOfDownLoad() {
      this.$tools.newerActivityFinish({ download_app_finish: 1 });
      // 创建一个链接
      const link = document.createElement("a");
      if (this.selIndex === 1) {
        // 设置链接的属性
        link.href = this.pwaUrl;
      } else {
        link.href = this.apkUrl;
      }

      // 使用URL对象来解析链接
      var url = new URL(link.href);
      // 获取路径的最后一部分，即文件名
      var fileName = url.pathname.split("/").pop();
      link.download = fileName;

      // 将链接添加到文档中
      document.body.appendChild(link);
      // 触发链接点击事件
      link.click();
      // 移除链接
      document.body.removeChild(link);
    },
    handleOfOptsItem(index) {
      this.isShowOpts = false;
      this.selIndex = index;
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  padding: 0 12px;

  .top-wrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 12px;
    & > .info {
      display: flex;
      align-items: center;

      & > img {
        width: 20px;
        height: auto;
      }

      & > div {
        margin-left: 5px;
        color: #ffffff;
        font-size: 12px;
        font-weight: 500;
      }
    }

    & > .opts-wrapper {
      display: flex;
      flex-direction: column;
      position: relative;

      & > .selected {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #006a56;
        border-radius: 5px;
        width: 159px;
        height: 40px;
        padding: 0 10px;

        & > div {
          color: #ffffff;
          font-size: 12px;
          font-weight: 500;
        }

        & > img {
          width: 22px;
          height: auto;
        }
      }

      & > .opts {
        position: absolute;
        width: 159px;
        display: flex;
        justify-content: space-between;
        background: #004134;
        padding: 0 10px;

        & > .list {
          display: flex;
          flex-direction: column;
          border-radius: 5px;

          & > .item {
            text-align: left;
            height: 40px;
            line-height: 40px;
            color: #ffffff;
            font-size: 12px;
            font-weight: 500;
          }
        }

        & > img {
          margin-top: 9px;
          width: 22px;
          height: 22px;
        }
      }
    }
  }

  .main {
    margin-top: 15px;
    display: flex;

    & > .list {
      display: flex;
      flex-direction: column;

      & > .item {
        display: flex;
        flex-direction: column;
        align-items: center;

        & > .text {
          align-self: flex-start;
          text-align: left;
          color: #ffffff;
          font-size: 12px;
          font-weight: 500;
        }

        & > .img-box {
          margin-top: 10px;
          margin-bottom: 20px;
          display: flex;

          & > img {
            width: 177px;
            height: auto;
          }
        }

        & > .btn {
          margin-bottom: 20px;
          width: 100%;
          background: #10b349;
          border-radius: 5px;
          height: 45px;
          line-height: 45px;
          color: #ffffff;
          font-size: 14px;
          font-weight: bold;
        }
      }
    }
  }

  .award-wrapper {
    display: flex;
    flex-direction: column;

    & > .des {
      color: #81d6ba;
      font-size: 12px;
      font-weight: 500;
    }

    & > .btn {
      margin-top: 12px;
      margin-bottom: 15px;
      height: 45px;
      line-height: 45px;
      background: #ffd400;
      border-radius: 5px;
      color: #000000;
      font-size: 14px;
      font-weight: bold;
    }
  }
}
</style>
