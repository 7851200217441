<template>
  <div class="container">
    <div class="wrapper">
      <div class="level">
        <div class="level-card">
          <img class="level-bg" src="../../../assets/level/level-bg0.png" alt="" />
          <img class="level-img" src="../../../assets/level/level0.png" alt="" />
          <div class="level-value">LV.0</div>
          <div class="level-name">Ordinary Member</div>
          <div class="progress">
            <div class="progress-bg">
              <div class="progress-cur"></div>
            </div>
            <div class="ruler">
              <div>普通：0</div>
              <div>铜级VIP：1</div>
            </div>
          </div>
          <div class="des">When you reach level 1,you will become a Bronze VIP</div>
        </div>
      </div>
      <div class="immunities">
        <div class="sction-title">{{ immunities.title }}</div>
        <div class="cell-box">
          <div class="cell" v-for="(item, i) in immunities.list" :key="i">
            <div class="cell-top">
              <div class="cell-top-left">{{ item.title }}</div>
              <div class="cell-top-right" v-show="item.subTitle !== ''">
                <img class="cell-img" :src="item.img" alt="" />
                <div class="cell-subtitle">{{ item.subTitle }}</div>
              </div>
            </div>
            <div class="cell-bottom">{{ item.des }}</div>
          </div>
        </div>
      </div>
      <div class="safeguard">
        <div class="safeguard-title">{{ safeguard.title }}</div>
        <div class="safeguard-content">
          <div class="content-top">{{ safeguard.subTitle }}</div>
          <div class="content-bottom">{{ safeguard.des }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      safeguard: {
        title: "Immunities safeguard",
        subTitle: "每月存款",
        des: "青铜20，白银300，黄金3000，铂金30000，钻石300000，王冠3000000.",
      },
      immunities: {
        title: "Immunities",
        list: [
          {
            title: "每日转盘",
            img: require("../../../assets/level/level0.png"),
            subTitle: "青铜VIP开启",
            des: "每天1次免费抽奖。VIP级别越高，抽奖价值越大。",
          },
          {
            title: "下注返利",
            img: require("../../../assets/level/level1.png"),
            subTitle: "白银VIP开启",
            des: "第一天的有效小猪，在第二天可获得返水。",
          },
          {
            title: "黄金俱乐部",
            img: require("../../../assets/level/level2.png"),
            subTitle: "黄金俱乐部",
            des: "每周至少10000奖金。",
          },
          {
            title: "提款手续费",
            img: "",
            subTitle: "",
            des: "青铜5%，白银4%，黄金4%，铂金3%，钻石2%，王冠1%",
          },
        ],
      },
    };
  },
};
</script>
<style lang="scss" scoped>
.container {
  display: flex;

  .wrapper {
    margin: 30px 0;
    display: flex;
    flex-direction: column;

    .level {
      display: flex;
      flex-direction: column;

      .level-card {
        padding: 20px 20px 15px;
        border-radius: 8px;
        background: linear-gradient(138deg, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 0) 45%), #58504f;
        max-width: 440px;
        display: flex;
        flex-direction: column;
        position: relative;

        .level-bg {
          position: absolute;
          top: 0;
          right: 0;
          width: 170px;
          height: auto;
          background-size: 100%;
        }

        .level-img {
          position: absolute;
          width: 120px;
          height: auto;
          top: 4px;
          right: 0;
        }

        .level-value {
          align-self: flex-start;
          font-size: 30px;
          font-weight: normal;
          color: #ffeecb;
        }

        .level-name {
          align-self: flex-start;
          font-size: 18px;
          font-weight: 600;
          color: #ffffff;
        }

        .progress {
          margin: 32px 160px 0 0;
          display: flex;
          flex-direction: column;
          .progress-bg {
            height: 10px;
            border-radius: 5px;
            width: 100%;
            background: #000000;
            display: flex;
            flex-direction: column;
            justify-content: center;
            .progress-cur {
              background: #e9c97e;
              height: 7px;
              width: 50%;
              border-radius: 5px;
            }
          }

          .ruler {
            margin-top: 3px;
            display: flex;
            justify-content: space-between;
            div {
              font-size: 10px;
              font-weight: normal;
              color: #ffffff;
            }
          }
        }

        .des {
          align-self: flex-start;
          margin-top: 10px;
          font-size: 10px;
          font-weight: normal;
          color: #ffffff;
          opacity: 0.6;
        }
      }
    }

    .immunities {
      margin-top: 30px;
      margin-bottom: 10px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .sction-title {
        margin-bottom: 12px;
        font-size: 12px;
        font-weight: 500;
        color: #e9c97e;
      }

      .cell-box {
        display: flex;
        flex-wrap: wrap;
        .cell {
          min-width: 480px;
          margin-right: 20px;
          margin-bottom: 20px;
          border-radius: 8px;
          overflow: hidden;
          display: flex;
          flex-direction: column;
          .cell-top {
            display: flex;
            height: 46px;
            justify-content: space-between;
            align-items: center;
            background: #202020;
            padding: 0 16px;
            .cell-top-left {
              font-size: 15px;
              font-weight: 600;
              color: #e9c97e;
            }

            .cell-top-right {
              display: flex;
              .cell-img {
                width: 24px;
                height: 20px;
              }
              .cell-subtitle {
                font-size: 12px;
                font-weight: normal;
                color: #ffffff;
              }
            }
          }
          .cell-bottom {
            padding: 0 32px;
            height: 46px;
            background: #1a1a1a;
            font-size: 12px;
            font-weight: normal;
            color: #ffffff;
            opacity: 0.6;
            display: flex;
            align-items: center;
            text-align: left;
          }
        }
      }
    }

    .safeguard {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .safeguard-title {
        font-size: 12px;
        font-weight: 500;
        color: #e9c97e;
        margin-bottom: 12px;
      }

      .safeguard-content {
        min-width: 480px;
        display: flex;
        flex-direction: column;
        border-radius: 8px;
        overflow: hidden;
        .content-top {
          padding: 0 32px;
          font-size: 15px;
          font-weight: 600;
          color: #e9c97e;
          background: #202020;
          height: 46px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
        }
        .content-bottom {
          padding: 0 32px;
          font-size: 12px;
          font-weight: normal;
          color: #ffffff;
          opacity: 0.6;
          background: #1a1a1a;
          height: 46px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          text-align: left;
        }
      }
    }
  }

  // @media (max-width: 768px) {
  //   .wrapper {
  //     margin: 15px 0;
  //   }

  //   .wrapper .immunities .cell-box .cell {
  //     min-width: 0px;
  //     flex: 0 1 100%;
  //     margin-right: 0;
  //   }

  //   .wrapper .safeguard .safeguard-content {
  //     min-width: 0;
  //     width: 100%;
  //   }
  // }
}
</style>
